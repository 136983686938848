import React, { useState, useEffect} from "react";
import { Grid, Button } from "@mui/material/";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Form, Formik, Field } from "formik";
import { TextField as TF } from "formik-material-ui";
import * as Yup from "yup";
import AlertInlineMain from "components/common/AlertInlineMain";
import { setServiceTime } from "../../../src/store/actions/RouteAction";
import { useDispatch } from "react-redux";

export default function ServiceTime({submitConfigData, setConfirmServiceTime}) {
  let dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const [initialValues, setInitialValues] = useState({ serviceTime: 60}); 
  const [valueNotSet, setValueNotSet] = useState(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [finalValues, setFinalValues] = useState({
    serviceTime: 1
  });

  const onSubmit = async (e) => {
    try {
      await axios.post("/servicetime", {serviceTime: e.serviceTime *60});

      if(setConfirmServiceTime){
        setConfirmServiceTime(false)
      }

      getServiceTime();
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Service Time updated!",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "",
          message: "",
        });
      }, 1000);

    } catch (err) {
      if (err.response?.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: err.response.data.message,
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Request Failed!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Request Failed!",
          });
        }, 3000);
      }
    }
  };

  const getServiceTime = async () => {
  try {
    const { data } = await axios.get("/servicetime");
    if (data?.serviceTime) { 
      if(setConfirmServiceTime){
        setConfirmServiceTime(false)
      }
      setInitialValues({ serviceTime: data?.serviceTime / 60, })
      setFinalValues({ serviceTime: data?.serviceTime / 60 })
      setValueNotSet(false);
      sessionStorage.setItem("serviceTime", data?.serviceTime);
      dispatch(setServiceTime(data?.serviceTime));
    }
  } catch (err) {
    setValueNotSet(true);
    setInitialValues({ serviceTime: 1, })
    setFinalValues({ serviceTime: 1 })
    if (err.response?.status === 401) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: err.response.data.message,
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "",
        });
      }, 3000);
    } else {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Service time not set!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "Service time not set!",
        });
      }, 3000);
    }
  }

  }

  const validationSchema = Yup.object().shape({
    serviceTime: Yup.number()
      .required("Service Time is required!")
      .integer("Service Time must be an integer")
      .min(1, "Service Time must be greater than 0")
      .positive("Service Time must be greater than 0"),
  });
  

  useEffect(() => {
    if(submitConfigData) onSubmit({serviceTime: finalValues.serviceTime});
  },[submitConfigData])

  useEffect(() => { 
    getServiceTime();
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize >
        {({ values, isValid, dirty, handleChange, setFieldValue, errors}) => {
          
          const handleValueChange = (e) => {
            handleChange(e);
            setFinalValues({
              serviceTime : parseInt(e?.target?.value)
            })
            setConfirmServiceTime(true)
          }

          return (
            <Form>
              <Grid container columnSpacing={2} >
                <Grid item mt={1} lg={6} md={6} sm={6} xs={12} >
                { valueNotSet && <span style={{ fontSize: "0.8rem", color: "red", }} > This is the default value, please update the Service Time!</span>}
                </Grid> 
              </Grid>
              <Grid container columnSpacing={2} >
                <Grid item mt={2} lg={6} md={6} sm={6} xs={12}>
                  <Field onChange={(e) => handleValueChange(e)} component={TF} sx={{ marginTop: "1rem" }} value={values?.serviceTime} error={errors?.serviceTime} helperText={errors?.serviceTime} name="serviceTime" label="Service Time (Minutes)" variant="outlined" type="number" fullWidth required data-testid="service_time"/>
                </Grid> 
              </Grid>
              <Grid container mt={2}>
                <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"} >
                  <Button variant="contained" color="primary" type="submit" disabled={ !valueNotSet && (!isValid || !dirty)} data-testid="save_service_time_btn"> Save </Button>
                </Grid>
              </Grid>
              {alert.showAlert && (<Grid item md={12} m={2}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid>)}
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
