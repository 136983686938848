import React from "react";
import { Grid, Divider, Button } from "@mui/material";
import { Form, Formik, Field } from "formik";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import { TextField as TF } from "formik-material-ui";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ChipInput from "material-ui-chip-input";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import LoadingButton from "@mui/lab/LoadingButton";
import StartLocation from "./StartLocation";
import EndLocation from "./EndLocation";
import DialogAlertMain from "components/common/DialogAlertMain";
import useRouteSettingsFunctions from "./commonFunctions/useRouteSettingsFunctions"

const timeFiledStyle = {
  color: "rgba(0, 0, 0, 0.54)",
  padding: "0",
  fontSize: "0.7rem",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontWeight: "400",
  lineHeight: "1",
  letterSpacing: "0.00938em",
}

export default function CreateVehicle() {
  const { setBreakStart, setBreakEnd, backendCall, alert, initialValues, value
    , marks, addBreaks, handleAvailability, handleSlider, handleAddChip, handleDeleteChip
    , onSubmit, validationSchema, alignment, setInitialValues, oneVehicle, breakStart, breakEnd, chips, setAlert, breaks } = useRouteSettingsFunctions();
  return (
    <Grid container justifyContent={"flex-start"} alignContent="center" p={2}>
      {backendCall ? (
        <Grid container mt={10}>
          <Grid container item alignItems="center" justifyContent="center">
            <Grid item
              lg={"auto"}
              md={"auto"}
              sm={"auto"}
              xs={"auto"}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
          {({ values, isValid, dirty, handleChange, setFieldValue }) => {

            return (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12} mt={2}>
                    Vehicle Availability
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} mt={2}>
                    <ToggleButtonGroup color="primary" value={alignment} disabled={backendCall} exclusive
                      onChange={(e) => handleAvailability(e.target.value, setFieldValue)} >
                      <ToggleButton size="small" value="available" style={{ textTransform: "none" }}>
                        Available </ToggleButton>
                      <ToggleButton size="small" value="unavailable" style={{ textTransform: "none" }}>
                        Unavailable </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} mt={2}>
                    Start location </Grid>
                  <Grid item xs={12} md={12} lg={12} mt={2}>
                    <StartLocation
                      disabledFelid={alignment === "unavailable"}
                      setFieldValue={setFieldValue}
                      setInitialValues={setInitialValues}
                      values={values} /> </Grid>
                  {values?.startAddress && (
                    <Grid item xs={12} md={12} mt={2}>
                      <Typography variant="h6" style={{ fontSize: "1rem" }}> Start location -{" "}
                        {values.startAddress || oneVehicle?.routeSettings?.startAddress} </Typography>
                    </Grid>)}
                  <Grid item xs={12} md={12} lg={12} mt={2}>
                    End location </Grid>
                  <Grid item xs={12} md={12} lg={12} mt={2}>
                    <EndLocation disabledFelid={alignment === "unavailable"} setFieldValue={setFieldValue}
                      setInitialValues={setInitialValues} values={values} />
                  </Grid>
                  {values?.endAddress && (
                    <Grid item xs={12} md={12} mt={2}>
                      <Typography variant="h6" style={{ fontSize: "1rem" }}> End location -{" "}
                        {values.endAddress || oneVehicle?.routeSettings?.endAddress}
                      </Typography>
                    </Grid>)}
                  <Grid item xs={12} md={12} lg={12} mt={2}>
                    Working Hours </Grid>
                  <Grid container item xs={12} md={12} lg={12} mt={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      Working Day Start</Grid>
                    <Grid item xs={12} md={12} lg={12} mt={2}>
                      <label style={timeFiledStyle}>Start Time</label>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Field name="workingStartTime" type="time" component={TF}
                        disabled={alignment === "unavailable"} />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} mt={2}>
                      <label style={timeFiledStyle}>End Time</label>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Field name="workingEndTime" type="time"
                        component={TF} disabled={alignment === "unavailable"} /> </Grid>
                  </Grid>
                  {/* <Grid item xs={12} md={12} mt={2}>
                    <Divider style={{ width: "90%" }} />
                  </Grid> */}
                  {/* <Grid container item xs={12} md={12} mt={2} spacing={1}>
                    <Grid item xs={12} md={12}>
                      <Button variant="contained" startIcon={<AddBoxIcon />}
                        onClick={() => addBreaks(setFieldValue, values)}
                        disabled={breakStart === null || breakEnd === null || alignment === "unavailable"} >
                        Add new Break </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid item xs={12} md={12} lg={12}>
                        <label style={timeFiledStyle}>Start Time</label>
                      </Grid>
                      <Field name="breakStart" type="time"
                        component={TF} value={breakStart} disabled={alignment === "unavailable"}
                        onChange={(newValue) => { setBreakStart(newValue.target.value); }} /> </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid item xs={12} md={12} lg={12}>
                        <label style={timeFiledStyle}>End Time</label>
                      </Grid>
                      <Field name="breakEnd" type="time" component={TF}
                        value={breakEnd} disabled={alignment === "unavailable"}
                        onChange={(newValue) => { setBreakEnd(newValue.target.value); }} /></Grid>
                    <Grid> {breaks.length > 0 && breaks.map((breakItem, index) => {
                      return (
                        <Grid item xs={12} md={12} lg={12} mt={2}>
                          <span style={{ fontSize: "1.2rem" }}> <b>{breakItem?.start}</b> to {" "}
                            <b>{breakItem?.end}</b>  </span> </Grid>);
                    })} </Grid>
                  </Grid> */}
                  <Grid item xs={12} md={12} mt={2}> <Divider style={{ width: "90%" }} /> </Grid>
                  <Grid item xs={12} md={12} lg={12}> Loads </Grid>
                  <Grid container item xs={12} md={12} lg={12} spacing={1}>
                    <Grid item xs={12} md={12}>
                      <Field name="pickups" label="Pickups" type="number" variant="outlined"
                        disabled={alignment === "unavailable"} onChange={handleChange}
                        component={TF} fullWidth ></Field>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field name="deliveries" label="Deliveries" type="number" variant="outlined"
                        disabled={alignment === "unavailable"} onChange={handleChange}
                        component={TF} fullWidth ></Field>
                    </Grid> </Grid>
                  <Grid item xs={12} md={12} lg={12}> Tags </Grid>
                  <Grid item xs={12} md={12} xl={12} mt={3} mb={2}>
                    <ChipInput label="Tags" value={chips.length > 0 ? chips : []}
                      disabled={alignment === "unavailable"} onAdd={(chip) => handleAddChip(chip, setFieldValue)}
                      onDelete={(chip, index) => handleDeleteChip(chip, setFieldValue)}
                      variant="outlined" fullWidth /> </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography id="non-linear-slider" gutterBottom> Speed: {value}x </Typography>
                    <Box sx={{ width: 250 }}>
                      <Slider value={value ? value : 1} disabled={alignment === "unavailable"}
                        min={0.5} defaultValue={1} step={0.5} max={2} onChange={(e) => handleSlider(e, setFieldValue)}
                        marks={marks} valueLabelDisplay="auto" aria-labelledby="non-linear-slider" exclude /> </Box>
                  </Grid>
                  <Grid item xs={12} md={12} mt={2}> <Divider style={{ width: "90%" }} /> </Grid>
                  <Grid container item xs={12} md={12} spacing={1}>
                    <Grid item xs={12} md={12}> Cost </Grid>
                    <Grid item xs={12} md={12}>
                      <Field name="fixed" label="Fixed" type="number" variant="outlined"
                        disabled={alignment === "unavailable"} onChange={handleChange}
                        component={TF} fullWidth ></Field>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field name="serviceTime" label="Service Time(USD/hr)" type="number" variant="outlined"
                        disabled={alignment === "unavailable"} onChange={handleChange}
                        component={TF} fullWidth ></Field>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field name="drivingTime" label="Driving Time(USD/hr)" type="number" variant="outlined"
                        disabled={alignment === "unavailable"} onChange={handleChange}
                        component={TF} fullWidth ></Field>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field name="idleTime" label="Idle Time(USD/hr)" type="number" variant="outlined"
                        disabled={alignment === "unavailable"} onChange={handleChange}
                        component={TF} fullWidth ></Field>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field name="breakTime" label="Break Time(USD/hr)" type="number" variant="outlined"
                        disabled={alignment === "unavailable"} onChange={handleChange}
                        component={TF} fullWidth ></Field>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field name="perMile" label="Per Mile(USD/mile)" type="number" variant="outlined"
                        disabled={alignment === "unavailable"} onChange={handleChange}
                        component={TF} fullWidth ></Field>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field name="perStop" label="Per Stop(USD/stop)" type="number" variant="outlined"
                        disabled={alignment === "unavailable"} onChange={handleChange}
                        component={TF} fullWidth ></Field>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} mt={2}>
                    <Divider style={{ width: "90%" }} />
                  </Grid>
                  <Grid item xs={12} md={12} mt={2}>
                    <Field name="notes" label="Notes" variant="outlined"
                      disabled={alignment === "unavailable"} onChange={handleChange}
                      component={TF} fullWidth ></Field>
                  </Grid>
                  <Grid item xs={12} md={12} mt={2}>
                    <LoadingButton data-testid="save-vSettings" loading={backendCall} type="submit" variant="contained" size="small"
                      disabled={!isValid || !dirty || alignment === "unavailable"} > Save </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
      <DialogAlertMain alert={alert} setAlert={setAlert} />
    </Grid>
  );
}
