import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import { Grid } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import { TextField as TF } from 'formik-material-ui';
import AlertInlineMain from "components/common/AlertInlineMain";
export default function AlertDialog({
  setOpen,
  open,
  data,
  setStatus,
  newStatus,
  componentType,
  role,
  serviceType,
  franchiseeId,
}) {
  const axios = useAxiosPrivate()
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const [initialValues, setInitialValues] = useState({
    name: '',
    price: '',
    id: '',
  });

  useEffect(() => {
    if (componentType !== 'create' && role === 'franchisee') {
      setInitialValues({
        name: `${data.item?.name ? data.item?.name : data.name}`,
        price: `${data.price ? data.price : ''}`,
        id: `${data.id ? data.id : ''}`,
      });
    } else if (componentType !== 'create' && role === 'franchisor') {
      setInitialValues({
        name: `${data.item?.name ? data.item?.name : data.name}`,
        price: `${data.price ? data.price : ''}`,
        id: `${data.id ? data.id : ''}`,
      });
    } else {
      setInitialValues({
        name: '',
        price: '',
      });
    }

    // eslint-disable-next-line
  }, [data, componentType]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (e, { resetForm }) => {
  
    try {
      const data = {
        name: e.name,
        price: e.price,
        serviceType: serviceType,
        id: initialValues.id,
      };


      await axios.post(`/franchiseeItems/${franchiseeId}`, data);

      setStatus(!newStatus);
      setOpen(false);

      resetForm();

      if (componentType === 'create') {
        setTimeout(() => {
          setAlert({
            showAlert: true,
            severity: 'success',
            message: 'Create Success!',
          });
        }, 3000);

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Request Success!',
          });
        }, 3000);
      } else {
        setTimeout(() => {
          setAlert({
            showAlert: true,
            severity: 'success',
            message: 'Item update Success!',
          });
        }, 3000);

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Request Success!',
          });
        }, 3000);
      }
    } catch (error) {
      setTimeout(() => {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Create failed!',
        });
      }, 3000);

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'success',
          message: 'Create failed!',
        });
      }, 3000);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    price: Yup.number()
    .required("Price is required")
    .min(1, "The price must be greater than 0!")
    .test(
      "Is positive?",
      "The price must be to the 2 decimal points !",
      (value) => (value + "").match(/^[0-9]*(\.[0-9]{0,2})?$/),
    ),
    
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='lg'
      >
        <DialogContent>
          <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
            {componentType === 'create' ? 'Create Item' : 'Edit Item'}
            {open ? (
              <IconButton
                aria-label='close'
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 8,
                  color: 'grey',
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ values, isValid }) => {
              return (
                <Form noValidate autoComplete='off'>
                  <Grid container spacing={2} m={1} xs={12} md={12} xl={12}>
                    <Grid item xs={12} md={6}>
                      <Field
                        name='price'
                        label='Price ($)'
                        component={TF}
                        type='number'
                        variant='outlined'
                        fullWidth
                        required
                      ></Field>
                    </Grid>
                  </Grid>

                  <DialogActions>
                    <Button
                      variant='contained'
                      color='success'
                      type='submit'
                      autoFocus
                      disabled={!isValid}
                    >
                      {componentType === 'create' ? 'CREATE' : 'SAVE'}
                    </Button>
                  </DialogActions>

                  {alert.showAlert && (
                    <Grid item md={12} m={2}>
                      <AlertInlineMain setAlert={setAlert} alert={alert} />
                    </Grid>
                  )}
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
