import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Card from '@mui/material/Card';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from '@mui/material/TextField';
import PleaseCreateTimslots from './PleaseCreateTimslots';
import LoadingButton from "@mui/lab/LoadingButton";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { TextField as TF } from 'formik-material-ui';
import YmDreformat from 'components/common/Functions/YmDreformat';
import ButtonGroup from '@mui/material/ButtonGroup';

function CreateSchedule({ setStatus, status, selected, refresh }) {
  const axios = useAxiosPrivate()
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [zones, setZones] = useState([]);
  const [limtValidated, setLimitValidated] = useState(true);
  const [endError, setEndError] = useState('');
  const [backendCall, setBackendCall] = useState(false);
  const convertDate = (d) => { return d?.toLocaleDateString('en-CA'); };
  const dateIni = new Date(selected);
  dateIni.setUTCHours(0, 0, 0, 0);
  const dateVal = convertDate(dateIni);
  const [startDate, setStartDate] = useState(dateIni);
  const [endDate, setEndDate] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [publishStatus, setPublishStatus] = useState(true);
  const [unattendedLimitCount, setUnattendedLimitCount] = useState('');
  const [errorUnattend, setErrorUnattend] = useState(false)
  const [deliveryTimeSlots, setDeliveryTimeSlots] = useState([]);
  const [zone, setZone] = useState('');
  const initial = { zone: '', unLimit: unattendedLimitCount, startDate: dateVal, endDate: dateVal, isPublished: publishStatus, };
  const [initialValues, setInitialValues] = useState(initial);
  const [alert, setAlert] = useState({ showAlert: false, severity: 'success', message: '', });
  const [scheduleType, setScheduleType] = useState(1);
  const [selectedDays, setSelectedDays] = useState([]);
  const [showBothTypes, setShowBothTypes] = useState(false);


  useEffect(() => {

    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const daysInRange = [];
    const currentDate = new Date(startDate);

    if (endDate === null || endDate === undefined || isNaN(endDate)) {
      const dayName = dayNames[currentDate.getDay()];
      daysInRange.push(dayName);
    } else {
      while (currentDate <= endDate) {
        const dayName = dayNames[currentDate.getDay()];
        if (!daysInRange.includes(dayName)) {
          daysInRange.push(dayName);
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }

    setSelectedDays(daysInRange);

  }, [startDate, endDate])

  const handleClickOpen = () => {
    getZones();
    setOpen(true);
  };

  const handleZone = (e) => {
    const val = e.target.value;
    setZone(val);
    setInitialValues((prevState) => ({ ...prevState, zone: val, isPublished:publishStatus}));
  };

  const handleClose = () => {
    setInitialValues(initial);
    setStartDate(dateIni);
    setEndDate(null);
    setZones([]);
    setZone('');
    setTimeSlots([]);
    setDeliveryTimeSlots([]);
    setPublishStatus(true);
    setUnattendedLimitCount('')
    setLimitValidated(true);
    setOpen(false);
    refresh();
    setStatus(!status);
  };

  useEffect(() => {

    const getBothTypes = async () => {
      const result = await axios.get(`/schedules/zones/all`);
      if (result.data.length > 0) {
        setShowBothTypes(true);
      }
    }

    getBothTypes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // get zones
  const getZones = async () => {
    try {

      let data = [];

      if (scheduleType === 1 || scheduleType === 2) {
        const result = await axios.get(`/${scheduleType === 1 ? 'schedules' : 'delivery'}/zones`);
        data = result.data;
      } else {
        const result = await axios.get(`/schedules/zones/all`);
        data = result.data;
      }
      const zoneArray = data.map((zone) => { return { value: zone._id, label: zone.zoneName, }; });
      if (zoneArray.length > 0) {
        setZones(zoneArray);
        setStatus(!status);
        setInitialValues(initial);
        setLimitValidated(false);
        setEndError('');
        // setOpen(true);
      } else { setOpenAlert(true); }
    } catch (err) {
      setAlert({ showAlert: true, severity: 'error', message: 'Zone Loading Failed!', });
      setTimeout(() => { setAlert({ showAlert: false, severity: 'error', message: 'Zone Loading Failed!', }); }, 4000);
    }
  };

  const handleZoneChange = async (event) => {
    try {
      const zone = event.target.value;

      let data = [];
      let deliveryData = [];
      if (scheduleType === 1 || scheduleType === 2) {
        const result = await axios.get(`/${scheduleType === 1 ? 'schedules' : 'delivery'}/timeslots/${zone}`);
        data = result.data;
      } else {
        const resultSchedules = await axios.get(`/schedules/timeslots/${zone}`);
        const resultDelivery = await axios.get(`/delivery/timeslots/${zone}`);

        data = resultSchedules.data;
        deliveryData = resultDelivery.data;

        let array = [];
        // return data to timeslots
        deliveryData.forEach((timeSlot) => {
          array.push({ id: timeSlot._id, timeSlot: `${timeSlot.timeSlots} (${timeSlot.zoneName})`, limit: Number(timeSlot.limit), error: false, });
        });

        setDeliveryTimeSlots(array);

      }

      let array = [];
      // return data to timeslots
      data.forEach((timeSlot) => {
        array.push({ id: timeSlot._id, timeSlot: `${timeSlot.timeSlots} (${timeSlot.zoneName})`, limit: Number(timeSlot.limit), error: false, });
      });

      setTimeSlots(array);
    } catch (err) {
      setAlert({ showAlert: true, severity: 'error', message: 'Timeslot Loading Failed!', });
      setTimeout(() => { setAlert({ showAlert: false, severity: 'error', message: 'Timeslot Loading Failed!', }); }, 3000);
    }
  };

  const formatDate = (inputDate) => {
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  useEffect(()=>{
    if(unattendedLimitCount !== '' || unattendedLimitCount !== ' '){
      setErrorUnattend(false)
    }
  },[unattendedLimitCount])

  // insert data to database
  const onSubmit = async (e) => {
    if(unattendedLimitCount === '' || unattendedLimitCount === ' '){
      return setErrorUnattend(true)
    }
    setBackendCall(true);
    let deliveryArray = [];
    const array = timeSlots?.map((t) => {
      return { id: t.id, limit: t.limit };
    });

    if (array.length < 0) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Please select a timeslot!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "Please select a timeslot!",
        });
      }, 3000);
    }

    if (scheduleType === 3) {

      deliveryArray = deliveryTimeSlots?.map((t) => {
        return { id: t.id, limit: t.limit };
      });

      if (deliveryArray.length < 0) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Please select a timeslot!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Please select a timeslot!",
          });
        }, 3000);
      }

    }

    try {

      const data =
      {
        zone: e.zone,
        startDate: YmDreformat(formatDate(startDate)),
        endDate: (endDate === null || endDate === undefined || isNaN(endDate)) ? null : YmDreformat(formatDate(endDate)),
        unattendedLimit: unattendedLimitCount,
        isPublished:
        publishStatus === "true" || publishStatus === true 
            ? true
            : false,
        selectedDays,
      };

      if (scheduleType === 3) {
        await axios.post(`/pickupDeliverySchedules`, { ...data, pickupTimeSlots: array, deliveryTimeSlots: deliveryArray });
        // await axios.post(`/schedules`, { ...data, timeSlots: array });
        // await axios.post(`/delivery`, { ...data, timeSlots: deliveryArray });

      } else {
        await axios.post(`/${scheduleType === 1 ? "schedules" : "delivery"}`, { ...data, timeSlots: array });
      }

      setAlert({
        showAlert: true,
        severity: "success",
        message: "Time slot created!",
      });

      setStartDate(dateIni);
      setEndDate(null);
      setInitialValues(initial);
      setZones([]);
      setZone("");
      setTimeSlots([]);
      setDeliveryTimeSlots([]);
      refresh();
      setStatus(!status);
      setPublishStatus(false);
      setUnattendedLimitCount('')
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "Time slot created!",
          message: "",
        });
        setBackendCall(false);
        setStatus(!status);
        setOpen(false);
      }, 1000);
    } catch (err) {
      setBackendCall(false);
      if (err?.response?.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: err.response.data.message,
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: err.response.data.message,
          });
        }, 10000);
      } else {
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Request Failed!",
          });
        }, 3000);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    zone: Yup.string().required('Zone is required!'),
    startDate: Yup.string().required('Start Date is required!'),
    // endDate: Yup.date().required("End Date is required!"),
    // unLimit: Yup.number().required('Limit is required')
    //   .typeError('Please enter a valid number')
    //   .min(0, 'Minimum atleast 0')
    //   .test('is-decimal', 'invalid decimal', (value) => (value + '').match(/^\d*$/)),
  });

  const handleLimitChange = (e, id, error, type) => {

    const vala = e.target.value;
    const err = Yup.number().required('Limit is required')
      .typeError('Please enter a valid number')
      .min(0, 'Minimum atleast 0')
      .test('is-decimal', 'invalid decimal', (value) => (value + '').match(/^\d*$/))
      .isValidSync(vala);
    setLimitValidated(!err);

    const timeSlot = (type === 'delivery' ? deliveryTimeSlots : timeSlots).map((slot) => {
      if (slot.id === id) { return { ...slot, limit: vala, error: !err, }; }
      return slot;
    });

    if (type === 'delivery') {
      setDeliveryTimeSlots(timeSlot);
    } else {
      setTimeSlots(timeSlot);
    }
  };

  const dateChecker = (startDate, endDate) => {
    if (startDate > endDate) {
      // setAlert({ showAlert: true, severity: 'error', message: 'Start date cannot be greater than end date!', });
      setEndError('Start date cannot be greater than end date!');
      setTimeout(() => { setAlert({ showAlert: false, severity: 'error', message: 'Start date cannot be greater than end date!', }); }, 4000);
    } else {
      setEndError('');
    }
  };

  const handleStartDateChange = (e, setFieldValue) => {
    setStartDate(e);
    dateChecker(e, endDate);
    const val = convertDate(e);
    setFieldValue('startDate', val);
  };

  const handleEndDateChange = (e, setFieldValue) => {
    let adjustedEndDate = new Date(e.setHours(5, 30, 0, 0))

    setEndDate(adjustedEndDate);
    dateChecker(startDate, adjustedEndDate);
    const val = convertDate(adjustedEndDate);
    setFieldValue('endDate', val);
  };

  const handleChangeScheduleType = (e) => {
    setScheduleType(e?.target?.value)
  }

  const isDayNameInRange = (day) => {
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const daysInRange = [];
    const currentDate = new Date(startDate);

    if (endDate === null || endDate === undefined || isNaN(endDate)) {
      return dayNames[currentDate.getDay()] === day;
    }

    while (currentDate <= endDate) {
      const dayName = dayNames[currentDate.getDay()];
      if (!daysInRange.includes(dayName)) {
        daysInRange.push(dayName);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return daysInRange.includes(day);
  }

  const addSelectedDays = (day) => {

    // Convert selectedDays to a Set
    let days = new Set(selectedDays);

    // If the day already exists in the Set, remove it
    if (days.has(day)) {
      days.delete(day);
    } else {
      // Otherwise, add the new day to the Set
      days.add(day);
    }

    // Convert the Set back to an array and update selectedDays
    setSelectedDays(Array.from(days));

  }

  useEffect(() => {
    getZones();
    setTimeSlots([]);
    setDeliveryTimeSlots([]);
    setZone('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleType])

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <Button variant='contained' color='primary' onClick={handleClickOpen} fullWidth > <h3>Create Schedules</h3> </Button>
          {openAlert && (<PleaseCreateTimslots title={'Please Create Time Slots'} message={'Please Create Time Slots to create Schedule'}
            setStatus={setStatus} status={status} setOpenAlert={setOpenAlert} openAlert={openAlert} />)} </Grid>
        <Grid item xs={12} md={12}>
          <Dialog fullWidth={'md'} maxWidth={'md'} open={open} onClose={handleClose} >
            <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} >
              {({ values, isValid, handleChange, errors, setErrors, setFieldValue, }) => {
                return (<Form noValidate autoComplete='off' encType='multipart/form-data' >
                  <DialogTitle style={{ cursor: 'move' }}> Create Schedule </DialogTitle>
                  <DialogContent>
                    <Grid item spacing={1}>
                      <Grid container spacing={2} >
                        <Grid container item mt={1} xs={12} md={6} xl={6}>
                          <FormControl fullWidth> <InputLabel id='zone-selector-name'> Zone </InputLabel>
                            <Select labelId='zone-selector' id='zone-selector' name='zone' value={zone} label='Zone' required
                              onChange={(e) => { handleZone(e); handleZoneChange(e); }} >
                              {zones?.map((option) => (<MenuItem key={option.value} value={option.value} > {option.label} </MenuItem>))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid container item mt={1} xs={12} md={6} xl={6} justifyContent={"end"}>
                          <FormControl style={{ padding: "1px", minWidth: "127px" }}>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={scheduleType}
                              onChange={handleChangeScheduleType}
                              inputProps={{ 'aria-label': 'Without label' }}
                            >
                              <MenuItem value={1}>Pickups</MenuItem>
                              <MenuItem value={2}>Deliveries</MenuItem>
                              <MenuItem value={3} disabled={!showBothTypes}>Pickup & Deliveries</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container item spacing={1} justifyContent={'flex-middle'} >
                        <Grid item mt={1} xs={7} md={7} xl={7} lg={7}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker fullWidth label='Date' name='startDate' disablePast inputFormat='MM/dd/yyyy' value={startDate}
                              onChange={(e) => handleStartDateChange(e, setFieldValue)}
                              renderInput={(params) => (<TextField
                                // helperText='To duplicate this format for future dates, enter an end date. 
                                //     Otherwise, enter the start date to create one day.'
                                {...params} />)} /> </LocalizationProvider>
                        </Grid>
                        <Grid container item mt={1} xs={7} md={7} xl={7} lg={7} >
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker label='Template End Date: (optional)' name='endDate' fullWidth disablePast minDate={startDate}
                              inputFormat='MM/dd/yyyy' value={endDate} onChange={(e) => handleEndDateChange(e, setFieldValue)}
                              renderInput={(params) => (<TextField helperText={endError ? endError : `To duplicate this format for future dates, enter an end date. 
                                Otherwise, enter the start date to create one day.`} {...params} />)} />
                          </LocalizationProvider>
                        </Grid>
                        {/* <Grid item mt={1} xs={4} md={4} xl={4} lg={4}>
                          {endError ? (<Alert severity='error' style={{ marginTop: '10px' }} > {endError} </Alert>) : null} </Grid> */}
                        <Grid container item mt={1} xs={12} md={12} xl={12} lg={12} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <div style={{display:'flex', flexDirection:'column'}}>
                          <Field name='unLimit' label='Unattended Limit' type="number" variant='outlined' style={{ marginTop: '1rem', display: 'flex', }} value={unattendedLimitCount}  component={TF}   
                          onChange={(e) => {
                              const newValue = e.target.value.replace(/[^0-9]/g, '');
                              setUnattendedLimitCount(newValue);
                            }}></Field>
                            { errorUnattend && <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" style={{color:'red'}}>Limit is required</p>}
                           </div>
                          <ButtonGroup size="small" style={{ height: "56px", marginTop: "16px", marginLeft: "20px", border: "1px solid rgb(192 192 192)" }}>

                            <Button style={{ margin: "7px", borderRadius: "50%" }} variant={selectedDays.includes('Sun') ? "contained" : "text"} onClick={() => addSelectedDays('Sun')} disabled={!isDayNameInRange('Sun')} >S</Button>
                            <Button style={{ margin: "7px", borderRadius: "50%" }} variant={selectedDays.includes('Mon') ? "contained" : "text"} onClick={() => addSelectedDays('Mon')} disabled={!isDayNameInRange('Mon')} >M</Button>
                            <Button style={{ margin: "7px", borderRadius: "50%" }} variant={selectedDays.includes('Tue') ? "contained" : "text"} onClick={() => addSelectedDays('Tue')} disabled={!isDayNameInRange('Tue')} >T</Button>
                            <Button style={{ margin: "7px", borderRadius: "50%" }} variant={selectedDays.includes('Wed') ? "contained" : "text"} onClick={() => addSelectedDays('Wed')} disabled={!isDayNameInRange('Wed')} >W</Button>
                            <Button style={{ margin: "7px", borderRadius: "50%" }} variant={selectedDays.includes('Thu') ? "contained" : "text"} onClick={() => addSelectedDays('Thu')} disabled={!isDayNameInRange('Thu')} >T</Button>
                            <Button style={{ margin: "7px", borderRadius: "50%" }} variant={selectedDays.includes('Fri') ? "contained" : "text"} onClick={() => addSelectedDays('Fri')} disabled={!isDayNameInRange('Fri')} >F</Button>
                            <Button style={{ margin: "7px", borderRadius: "50%" }} variant={selectedDays.includes('Sat') ? "contained" : "text"} onClick={() => addSelectedDays('Sat')} disabled={!isDayNameInRange('Sat')} >S</Button>

                          </ButtonGroup>
                        </Grid>
                        <Grid container item spacing={1} xs={12} md={12}>
                          <Grid item md={12}> <h3>{scheduleType === 3 ? "Daily Pickup Time Limits" : "Daily Time Limits"}</h3> </Grid>

                          <Grid fullWidth item xs={12} sm={12} md={12}>
                            <Card>
                              {timeSlots?.map((t) => {
                                return (
                                  <Grid container key={t.timeSlot}>
                                    <Grid container item alignItems='center' justifyContent='center' spacing={1} padding={1} ml={2} xs={10} md={10} >
                                      <Grid item mt={1} mb={1} xs={4} md={4}> <p>{t.timeSlot}</p> </Grid>
                                      <Grid item mt={1} mb={1} xs={8} md={8}>
                                        <TextField name='timeSlotLimit' label='Limit' defaultValue={t.limit} error={t.error}
                                          helperText={t.error ? 'Enter Valid Number ' : ''} variant='outlined'
                                          onChange={(e) => { handleLimitChange(e, t.id, { errors, setErrors, }, "pickup"); }} />
                                      </Grid>
                                    </Grid>
                                  </Grid>);
                              })}
                            </Card>
                          </Grid>
                        </Grid>

                        {
                          scheduleType === 3 ?

                            <Grid container item spacing={1} xs={12} md={12}>
                              <Grid item md={12}> <h3>Daily Delivery Time Limits</h3> </Grid>

                              <Grid fullWidth item xs={12} sm={12} md={12}>
                                <Card>
                                  {deliveryTimeSlots?.map((t) => {
                                    return (
                                      <Grid container key={t.timeSlot}>
                                        <Grid container item alignItems='center' justifyContent='center' spacing={1} padding={1} ml={2} xs={10} md={10} >
                                          <Grid item mt={1} mb={1} xs={4} md={4}> <p>{t.timeSlot}</p> </Grid>
                                          <Grid item mt={1} mb={1} xs={8} md={8}>
                                            <TextField name='timeSlotLimit' label='Limit' defaultValue={t.limit} error={t.error}
                                              helperText={t.error ? 'Enter Valid Number ' : ''} variant='outlined'
                                              onChange={(e) => { handleLimitChange(e, t.id, { errors, setErrors, }, "delivery"); }} />
                                          </Grid>
                                        </Grid>
                                      </Grid>);
                                  })}
                                </Card>
                              </Grid>
                            </Grid>
                            :
                            <></>
                        }

                        <Grid item xs={6}>
                          <FormControl>
                            <FormLabel id='time-slots-radio-buttons'> Publish Schedule </FormLabel>
                            <RadioGroup name='isPublished' s value={publishStatus} onChange={(e)=> {setPublishStatus(e.target.value) }} defaultValue={true} >
                              <FormControlLabel value={true} control={<Radio />} label='Yes' />
                              <FormControlLabel value={false} control={<Radio />} label='No' />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {alert.showAlert && (
                        <Grid item md={12} m={2}>
                          <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, showAlert: false, })} > {alert.message} </Alert>
                        </Grid>)}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <LoadingButton data-testid="save-schedule-btn" loading={backendCall} color='success' type='submit'
                      disabled={(limtValidated || !zone) || (selectedDays.length === 0 ? true : false)} >
                      Save </LoadingButton>
                    <Button color='error' onClick={handleClose}> Close </Button>
                  </DialogActions>
                </Form>
                );
              }}
            </Formik>
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
}
export default CreateSchedule;
