import React, { useState } from "react";
import { Grid, Card, CardContent, CardHeader, Button } from "@mui/material";
import DataGridTable from "../../../components/layouts/DataGrid";
import { useNavigate } from "react-router-dom";

import Chip from "@mui/material/Chip";
import moment from "moment";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

export default function CorporateReport() {
  let navigate = useNavigate();

  const columns = [
    { field: "orderId", headerName: "Order ID", minWidth: 200, valueGetter: (params) => {
        return `${params.row.orderId?.orderId || 'N/A'}`;
      },},
    {
      field: "customer",
      headerName: "Customer Name",
      minWidth: 200,
      valueGetter: (params) => {
        return `${params.row.customer?.firstName || ''} ${params.row.customer?.lastName || ''}`;
      },
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 200,
      valueGetter: (params) => {
        return `${params.row?.description ? params.row?.description : ''}`;
      },
    },
    {
        field: "amount",
        headerName: "Amount",
        minWidth: 200,
      },
    {
      field: "status",
      headerName: "Payment Status",
      minWidth: 200,
      renderCell: (params) => {
        switch (params.row.status) {
          case "refunded":
            return <Chip label="Refunded" color="primary" variant="outlined" />;
          case "failed":
            return <Chip label="Failed" color="primary" />;
          case "completed":
            return <Chip label="Completed" color="success" />;
          default:
            return <Chip label="Completed" color="success" />;
        }
      },
    },

    {
      field: "createdAt",
      headerName: "Date",
      minWidth: 300,
      valueGetter: (params) => {
        let createdAt = params?.row?.createdAt
        if (createdAt?.includes("Z")) {
            createdAt = (createdAt?.split("T")[0])
        } else {
            createdAt = params?.row?.createdAt
        }
        return `${params?.row?.createdAt ? moment(createdAt).format("MMMM Do dddd YYYY") : "Not applicable"}`;
      },
    },

  ];

  const onGoback = () => {
    navigate('/franchisee-reports')
  } 
  return (
    <>

      <Card>
        <CardContent>

        <Grid container justifyContent="space-between">

<Grid item >
  <IconButton onClick={()=>onGoback()}>
    <ArrowBackIcon sx={{ color: "#343434", }} />
  </IconButton>
</Grid>


  <Grid item >
  <div style={{width:'10px'}}></div>
</Grid>
        </Grid>

          <CardHeader title="Payments Report" />
          <Grid container spacing={1}>
         
            <Grid item xs={12} md={12} xl={12}>
              <DataGridTable
                title={`Corporate`}
                fetchDataUrl={`/reports/corporate-report`}
                columns={columns}
                fixHeight={true}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
