import React, { useEffect, useState } from 'react';
import useAxiosPrivate from "../utils/hooks/useAxiosPrivate";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { login, setFranchiseeTimeZone } from "../store/actions/authActions";
import { useNavigate } from "react-router-dom";
import { showNav } from "../store/actions/headerActions";

function SignInPage(props) {
    const axios = useAxiosPrivate()
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [message, setMessage] = useState('Please wait while signing in...');
  useEffect( () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const idUser = urlParams.get('id');
    const fetchData = async ()=> {
        try{
      const {data}= await axios.post("/auth/corporate/login/", {
        token,
        id:idUser,
      });
  
    const { fullName, role, accessToken, permittedRoutes, id, ownerName,
        onboardingStatus, check, timeZone, franchiseeTimeZone } = data;
      if (timeZone) { sessionStorage.setItem("timeZone", timeZone); }
      if (franchiseeTimeZone) {
        sessionStorage.setItem("franchiseeTimeZone", franchiseeTimeZone);
        dispatch(setFranchiseeTimeZone(franchiseeTimeZone)) }
      if (accessToken) { props.login( fullName, role, accessToken, permittedRoutes, id, ownerName, onboardingStatus, check ); }
      if (role === "franchisor") { navigate("/dashboard"); dispatch(showNav()); }
      if (role === "franchisee" || role === "corporate") {
        if (onboardingStatus === "completed" && check) {
          if (!franchiseeTimeZone) {
              navigate("/account-management");
            dispatch(showNav());
          } else {
            navigate("/franchisee-dashboard");
            dispatch(showNav());
          }
        }
        if (onboardingStatus === "onboarding" && accessToken) {
          navigate("/confirm", { state: { id: id } });
          dispatch(showNav());
        }
        if (onboardingStatus === "sales" && accessToken) {
          navigate("/sales-config", { state: { id: id, role: role } });
          dispatch(showNav());
        }
        if (onboardingStatus === "payment" && accessToken && role !== "corporate") {
          navigate("/verification", { state: { id: id } });
          dispatch(showNav());
        }
    }
    }catch(e){
        setMessage("Permission Denied!");
    }
}

fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
<div className="signin-page" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex:1 }}>
  <div className="loading-container" style={{ textAlign: 'center' }}>
    <p>{message}</p>
  </div>
</div>

  );
}

export default connect(null, { login })(SignInPage);
