import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CommonButton from '../../components/common/CommonButton';
import Grid from '@mui/material/Grid';
import AlertInlineMain from "components/common/AlertInlineMain";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import EditIcon from '@mui/icons-material/Edit';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': { padding: theme.spacing(2), },
  '& .MuiDialogActions-root': { padding: theme.spacing(1), },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = { children: PropTypes.node, onClose: PropTypes.func.isRequired, };

export default function Folder({ level1, level2, loadData, file, edit, id, filename, }) {
  const axios = useAxiosPrivate()
  let initialValues = { name: filename, };
  let folderValidation = Yup.object().shape({
    name: Yup.string().required('Folder Name is required!')
      .matches(/^[^\\/?%*:|"<>]+$/, 'Please enter valid Folder Name')
      .max(35, 'Max length for the Name is 35.'),
  });
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const submit = async (e, { resetForm }) => {
    try {
      if (!level1 && !level2) {
        if (file) { await axios.put('/folder/fileName', { id, name: e.name, }); }
        else if (edit) { await axios.put('/folder/folderName', { id, name: e.name, }); }
        else { await axios.post('/folder/', { name: e.name, }); }
        loadData('/folder/all');
        setTimeout(() => { setOpen(false); }, 3000);
      } else if (level1 && !level2) {
        if (file) { await axios.put('/folder/fileName', { id, name: e.name, }); }
        else if (edit) { await axios.put('/folder/folderName', { id, name: e.name, }); }
        else { await axios.post('/folder/', { name: e.name, parentId: level1, }); }
        loadData(`/folder/sub/${level1}`);
        setTimeout(() => { setOpen(false); }, 3000);
      } else if (level1 && level2) {
        if (file) { await axios.put('/folder/fileName', { id, name: e.name, }); }
        loadData(`/folder/sub/${level2}`);
        setTimeout(() => { setOpen(false); }, 3000);
      }

      if (file) {
        setAlert({
          showAlert: true,
          severity: 'success',
          message: 'File Name Updated successfully!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'success',
            message: 'File Name Updated successfully!',
          });
          resetForm();
        }, 3000);
      } else if (edit) {
        setAlert({
          showAlert: true,
          severity: 'success',
          message: 'Folder Name Updated successfully!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Folder Name Updated successfully!',
          });
          resetForm();
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: 'success',
          message: 'Folder Created successfully!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Folder Created successfully!',
          });
          resetForm();
        }, 3000);
      }
    } catch (error) {
      if (file) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'File Name update fail!!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: 'File Name update fail!',
          });
          resetForm();
        }, 3000);
      } else if (edit) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Folder Name update fail!!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: 'Folder Name update fail!',
          });
          resetForm();
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Folder create fail!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: 'Folder create fail!',
          });
          resetForm();
        }, 3000);
      }
    }
  };
  return (
    <div>
      {file ? (
        <IconButton color='warning' onClick={handleClickOpen} >
          <EditIcon />
        </IconButton>
      ) : edit ? (
        <IconButton color='warning' onClick={handleClickOpen} >
          <EditIcon />
        </IconButton>
      ) : ( <CommonButton variant={'contained'} text={'Create Folder'} action={handleClickOpen} color='success' /> )}
      <BootstrapDialog onClose={handleClose} fullWidth={'sm'} maxWidth={'sm'} open={open} >
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose} >
          {file ? 'Update File Name' : edit ? 'Update Folder Name' : 'Create Folder'}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik enableReinitialize initialValues={initialValues} onSubmit={submit} validationSchema={folderValidation} >
            {({ dirty, isValid, values }) => {
              return (
                <Form>
                  <Field name='name' component={TextField} placeholder='Name' variant='outlined' fullWidth margin='dense'
                    inputProps={{ style: { fontFamily: 'Overpass, sans-serif' }, }}></Field>
                  <Button style={{ marginTop: '1rem' }} variant='contained' disabled={!dirty || !isValid} type='submit'
                    color={file ? 'warning' : edit ? 'warning' : 'success'} >
                    {file ? 'Update' : edit ? 'Update' : 'Add'}
                  </Button>
                </Form>
              );
            }}
          </Formik>
          {alert.showAlert && (
            <Grid item alignItems='center' sm={12} md={12} xs={12}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <AlertInlineMain setAlert={setAlert} alert={alert} />
              </div>
            </Grid>
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
