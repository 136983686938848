import {
  SET_VAL,
  SET_OPEN,
  SET_DEPOT,
  SET_VEHICLE,
  SET_ONE_VEHICLE,
  REMOVE_ONE_VEHICLE,
  SET_DRIVER,
  SET_ONE_DRIVER,
  REMOVE_ONE_DRIVER,
  SET_ASSIGNMENT,
  SET_JOBS,
  SET_TIMELINE_SELECTED,
  SET_SERVICE_TIME,
  PROGRESS_DATA_GRID_FILTER,
  SET_CORPORATE_CUSTOMERS
} from "./types.js";
import axios from "../../utils/lib/axios";

export const setPageVal = (val) => (dispatch) => {
  //set to session storage
  sessionStorage.setItem("depoPage", val);
  dispatch({
    type: SET_VAL,
    payload: { depoPage: val },
  });
};

export const setOpen = (val) => (dispatch) => {
  //set to session storage
  sessionStorage.setItem("open", val);
  dispatch({
    type: SET_OPEN,
    payload: { open: val },
  });
};

export const setDepot = () => async (dispatch) => {
  //set to session storage
  const { data } = await axios.get("/depot");

  dispatch({
    type: SET_DEPOT,
    payload: { depot: data },
  });
};

export const setVehicle = () => async (dispatch) => {
  //set to session storage
  const { data } = await axios.get("/vehicle");
  dispatch({
    type: SET_VEHICLE,
    payload: { vehicle: data },
  });
};

export const setOneVehicle = (data) => async (dispatch) => {
  //set to session storage

  dispatch({
    type: SET_ONE_VEHICLE,
    payload: { oneVehicle: data },
  });
};

export const removeOneVehicle = (data) => async (dispatch) => {
  //set to session storage

  dispatch({
    type: REMOVE_ONE_VEHICLE,
    payload: { oneVehicle: {} },
  });
};

// Driver

export const setDriver = () => async (dispatch) => {
  //set to session storage
  const { data } = await axios.get("/driver");

  dispatch({
    type: SET_DRIVER,
    payload: { driver: data },
  });
};

export const setOneDriver = (data) => async (dispatch) => {
  //set to session storage

  dispatch({
    type: SET_ONE_DRIVER,
    payload: { oneDriver: data },
  });
};

export const removeOneDriver = (data) => async (dispatch) => {
  //set to session storage

  dispatch({
    type: REMOVE_ONE_DRIVER,
    payload: { oneDriver: {} },
  });
};


// Assignment


export const setAssignment = () => async (dispatch) => {
  //set to session storage
  const { data } = await axios.get("/assignments");

  dispatch({
    type: SET_ASSIGNMENT,
    payload: { assignment: data },
  });
};

// set jobs 

export const setJobs = (data) => async (dispatch) => {
  //set to session storage

  dispatch({
    type: SET_JOBS,
    payload: { jobs: data },
  });
};

// set serviceTime 
export const setServiceTime = (data) => async (dispatch) => {
  dispatch({
    type: SET_SERVICE_TIME,
    payload: { serviceTime: data },
  });
};

// set progress Data Grid Filter
export const setProgressDataGridFilter = (data) => async (dispatch) => {
  dispatch({
    type: PROGRESS_DATA_GRID_FILTER ,
    payload: { progressDataGridFilter: data },
  });
};

// set progress Data Grid Filter
export const setCorporateCustomers = () => async (dispatch) => {
  
  const { data } = await axios.get(`/customer/manualcustomer`);
  dispatch({
    type: SET_CORPORATE_CUSTOMERS ,
    payload: { corporateCustomers: data },
  });
};

export const setTimelineSelected = (data) => async (dispatch) => {
  //set to session storage

  dispatch({
    type: SET_TIMELINE_SELECTED,
    payload: { timelineSelected : data },
  });
};