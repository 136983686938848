import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { useSelector, useDispatch } from "react-redux";
import Divider from "@mui/material/Divider";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import CircularProgress from "@mui/material/CircularProgress";

import {
  setOneDriver,
  setOpen,
  setDriver,
  setVehicle,
  removeOneDriver
} from "../../../store/actions/RouteAction";
import { Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";

import DialogAlertMain from "components/common/DialogAlertMain";

export default function FolderList() {
  const axios = useAxiosPrivate()
  const dispatch = useDispatch();

  const drivers = useSelector((state) => state.route.driver);

  const oneDriver = useSelector((state) => state.route.oneDriver);

  const state = useSelector((state) => state.route.open);

  const [open, setOpenDepot] = React.useState(state);

  const [backendCall, setBackendCall] = useState(false);


  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const setDetails = () => {
    dispatch(setOpen(true));

    setOpenDepot(!open);
  };

  const deleteDriver = async (id, driver) => {

setBackendCall(true)

    try {
      await axios.delete(`/driver/${id}`);
      if(driver?.vehicle){
      await axios.put(`/vehicle/availability/${driver?.vehicle}`, {availability: "unavailable"});
}
      dispatch(setDriver());
      dispatch(setVehicle());

      dispatch(setOpen(false));
    setOpenDepot(!open);
    dispatch(removeOneDriver());

    setTimeout(() => {
      dispatch(setOpen(true));
    }, 200);
      
    setBackendCall(false)
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Driver deleted Successfully!",
      });
 
    } catch (error) {
      setBackendCall(true)
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Driver deletion Failed!",
      });

    }
  };

  const getOneDriver = (oneDriver) => {
    dispatch(setOneDriver(oneDriver));
    setDetails();
  };

  const checkDriver = (id) => {
    if (id === oneDriver?.id) {
      return true;
    }
  };

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>

<DialogAlertMain alert={alert} setAlert={setAlert} />

      {backendCall ? (
      <Grid container
      mt={10}
    >
      <Grid container item
        alignItems="center"
        justifyContent="center">
        <Grid item
          lg={"auto"}
          md={"auto"}
          sm={"auto"}
          xs={"auto"}
        >
          <CircularProgress />
        </Grid>
      </Grid>
    </Grid>

      ):(drivers?.length > 0 &&
        drivers.map((driver) => (
          <div>
            <ListItem
              style={{
                cursor: "pointer",
                backgroundColor: checkDriver(driver.id) ? "#ADD8E6" : "white",
              }}
              onClick={() => getOneDriver(driver)}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => deleteDriver(driver?._id, driver)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <ContactPageIcon />
                </Avatar>
              </ListItemAvatar>
              <span
                      style={{
                  fontWeight: "500",
                }} >
                
                {driver?.name}
              </span>
            </ListItem>
            <Divider style={{ width: "90%", marginTop: "1rem" }} />
          </div>)
        ))}
    </List>
  );
}
