import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import AlertInlineMain from "components/common/AlertInlineMain";
import * as Yup from "yup";
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import { Grid } from "@mui/material";
import { Form, Formik, Field } from "formik";
import { TextField as TF } from "formik-material-ui";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export default function EditDialog({ setOpen, open, data, setStatus, newStatus, componentType, role, serviceType, franchiseeId, subject, }) {
  const axios = useAxiosPrivate()
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState("");
  const [categoryVal, setCategoryVal] = useState("Other");
  const [initialValues, setInitialValues] = useState({
    name: "",
    price: "",
    id: "",
    category: "Other",
  });

  useEffect(() => {
    if (componentType !== "create" && (role === "franchisee" || role === "corporate" || role === "manager")) {
      setInitialValues({
        name: `${data.item?.name ? data.item?.name : data.name}`,
        price: `${data.price ? data.price.toFixed(2) : ""}`,
        id: `${data.id ? data.id : ""}`,
        category: `${data.item.category ? data.item.category : "Other"}`,
      });
      setCategoryVal(`${data.item.category ? data.item.category : "Other"}`)
    } else if (componentType !== "create" && role === "franchisor") {
      setInitialValues({
        name: `${data.item?.name ? data.item?.name : data.name}`,
        price: `${data.price ? data.price.toFixed(2) : ""}`,
        id: `${data.id ? data.id : ""}`,
        category: `${data.category ? data.category : "Other"}`,
      });
      setCategoryVal(`${data.category ? data.category : "Other"}`)
    } else {
      setInitialValues({
        name: "",
        price: "",
        category: "Other",
      });
      setCategoryVal("Other")
    }

    // eslint-disable-next-line
  }, [data, componentType, setOpen, open]);

  const priceChangeRequest = async () => {
    try {
      const priceData = await axios.get(`/request/price-change/${data?.id}`);
      if (priceData.data) {
        setMessage("Requested Price: $" + priceData?.data?.requestedPrice.toFixed(2) + "");
        setDisable(true);
      } else {
        setMessage("");
        setDisable(false);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (data.id && role !== "franchisor") { priceChangeRequest(); }
    // eslint-disable-next-line
  }, [data]);

  const handleClose = (resetForm, setFieldValue) => {
    setOpen(false);
    resetForm();
    // setFieldValue("name", "");
    // setFieldValue("price", "");
    // setFieldValue("category", "Other");
    // setCategoryVal("Other")
  };

  const onSubmit = async (e, { resetForm }) => {
    try {
      const franchisorData = {
        name: e?.name,
        price: Number(e?.price.toFixed(2)),
        serviceType: serviceType,
        id: initialValues?.id,
        category: e?.category,
      };

      const franchisorEditData = {
        price: Number(e.price.toFixed(2)),
        id: initialValues.id,
        category: e?.category,
      };

      const franchiseeData = {
        requestedPrice: Number(e.price.toFixed(2)),
        originalPrice: initialValues?.price,
        name: e.name,
        serviceType: serviceType,
        serviceId: initialValues.id,
        subject: subject,
        category: e?.category,
        itemId: data?.itemId,
      };
      
      //in service item create section
      if (role === "franchisor" && !franchiseeId) {
        if (componentType === "create") { await axios.post("/item", franchisorData); }
        else { await axios.put("/item", franchisorEditData); }
      }
      //in franchisee management section
      if (role === "franchisor" && franchiseeId) { await axios.post("/franchiseeItems", franchisorData); }
      if (role === "franchisee" || role === "corporate" || role === "manager") { await axios.post("/request/price-change", franchiseeData); }

      setStatus(!newStatus);
      setOpen(false);
      resetForm();
      setCategoryVal("Other")
      if (componentType === "create") {
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Create Success!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Request Success!",
          });
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Item update Success!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Request Success!",
          });
        }, 3000);
      }

    } catch (error) {
      resetForm();
      setCategoryVal("Other");
      if (error.response.status === 403) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: `${error.response.data.message}`,
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Request Success!",
          });
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Create failed!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Create failed!",
          });
        }, 3000);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    price: Yup.number().required("Price is required")
      .min(1, "The price must be greater than 0!")
      .test("Is positive?", "The price must be to the 2 decimal points !",
        (value) => (value + "").match(/^[0-9]*(\.[0-9]{0,2})?$/),),
    category: Yup.string().required("Category is required")
  });

  return (
    <Grid container>
      <Grid container item alignItems="center" justifyContent="center" >
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize >
          {({ values, isValid, setFieldValue, resetForm }) => {
            return (
              <Dialog open={open} onClose={()=>handleClose(resetForm, setFieldValue)} fullWidth maxWidth="lg" >
                <DialogContent>
                  <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                    {componentType === "create" ? "Create Item" : "Edit Item"}
                    {open ? (
                      <IconButton onClick={()=> handleClose(resetForm, setFieldValue)}
                        sx={{ position: "absolute", right: 10, top: 8, color: "grey", }}>
                        <CloseIcon />
                      </IconButton>
                    ) : null}
                  </DialogTitle>
                  <Form noValidate autoComplete="off">
                    <Grid container >
                      <Grid container item alignItems="center" justifyContent="center" >

                        <Grid item lg={12} md={12} sm={12} xs={12} >
                          <FormControl fullWidth style={{ marginTop: "2rem" }}
                            required={role === "franchisor"}
                            disabled={role === "franchisee" || componentType !=="create"} >
                            <InputLabel shrink htmlFor="category">
                              Category
                            </InputLabel>
                            <Select
                              name="category"
                              value={categoryVal}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setCategoryVal(newValue);
                                setFieldValue("category", newValue);
                              }}
                              label="Category"
                              inputProps={{
                                id: "category",
                              }}
                            >
                              <MenuItem value={"Tops"}> Tops </MenuItem>
                              <MenuItem value={"Bottoms"}> Bottoms </MenuItem>
                              <MenuItem value={"Full Body"}> Full Body </MenuItem>
                              <MenuItem value={"Other"}> Other </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12} >
                          <Field data-testid="name-service" name="name" label="Name" component={TF} variant="outlined" fullWidth
                            required={role === "franchisor"}
                            disabled={role === "franchisee" || componentType !=="create"}
                            style={{ width: "99.1%", marginTop: "1rem" }}></Field>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Field data-testid="price-service" name="price" label='Price ($)' style={{ width: "99.1%", marginTop: "1rem" }} component={TF}
                            type="number" variant="outlined" fullWidth required ></Field>
                        </Grid>
                        {message && (
                          <Grid container item xs={12} md={12} justifyContent="flex-end" >
                            <Chip color="warning" label={message} />
                          </Grid>)}
                      </Grid>
                    </Grid>
                    <DialogActions>
                      <Button data-testid="save-service" variant="contained" color="success" type="submit" autoFocus
                        sx={{
                          marginTop: "1rem",
                          width: { lg: "5rem", md: "5rem", sm: "100%", xs: "100%", }
                        }} disabled={!isValid || disable} >
                        {(role === "franchisee" || role === "corporate" || role === "manager") ? "REQUEST" : "SAVE"}
                      </Button>
                    </DialogActions>
                    {alert.showAlert && (
                      <Grid item md={12} m={2}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid>
                    )}
                  </Form>
              </DialogContent>
        </Dialog>
        );
      }}
      </Formik>
      </Grid>
    </Grid>

  );
}
