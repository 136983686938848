import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete } from "@mui/material";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Typography, Grid, TextField, FormControl} from "@mui/material";
import { Form, Formik, Field } from "formik";
import { TextField as TF } from "formik-material-ui";
import AlertMain from "components/common/AlertMain";

export default function AlertDialog({ setOpen, open, data, setStatus, newStatus, setReqNumber, setPriceChangeReqNumber }) {
  const axios = useAxiosPrivate()
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  }); 

  const zipString = data?.zipCode?.join();

  const [initialValues, setInitialValues] = useState({
    id: "",
    requestId: "",
    subject: "",
    franchiseeName: "",
    franchiseOwnerName: "",
    email: "",
    zipCode: "",
    description: "",
    from: "",
    to: "",
    status: "",
    resources: [],
  });

  useEffect(() => {
    setInitialValues({
      id: `${data?.id}`,
      requestId: `${data?.requestId}` || "",
      subject: `${data?.subject}` || "",
      franchiseeName: `${data?.requester?.fullName}` || "",
      franchiseOwnerName: `${data?.requester?.ownerName ? data?.requester?.ownerName : ""}` || "",
      email: `${data?.email}` || "",
      zipCode: `${data?.zipCode}` || "",
      description: `${data?.description}` || "",
      from: data?.from,
      to: data?.to,
      status: `${data?.status}` || "",
      resuorces: `${data?.resuorces}` || [],
      requestedPrice: `${data?.requestedPrice?.toFixed(2)}` || "",
      originalPrice: `${data?.originalPrice?.toFixed(2)}` || "",
      itemId: `${data?.itemId ? data?.itemId : ""}`,
      name: `${data?.name ? data?.name : ""}`,
    }); 
    
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const changeStatus = async (status) => {
    try {
      if (status === "approve") {
        if (data?.requestedPrice) {
          await axios.put(`/request/price-change/update-status`, { status: "approved", id: initialValues.id, });
          setPriceChangeReqNumber();
        } else {
          await axios.put("/request/update-status", { id: initialValues.id, status: "approved", });
          setReqNumber();
        }

        setAlert({
          showAlert: true,
          severity: "success",
          message: "Request Approved",
        });

        setTimeout(() => {
          handleClose();

            setAlert({
              showAlert: false,
              severity: "success",
              message: "Create Success!",
            });
          
        }, 3000);

        initialValues.status = "approved";
      }
      if (status === "denie") {
        if (data?.requestedPrice) {
          await axios.put(`/request/price-change/update-status`, { status: "denied", id: initialValues.id, });
          setPriceChangeReqNumber();
         
        } else {
          await axios.put("/request/update-status", { id: initialValues.id, status: "denied",});
          setReqNumber();
        }

        setAlert({
          showAlert: true,
          severity: "error",
          message: "Request Denied",
        });

        setTimeout(() => {
          handleClose();
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 3000);

        initialValues.status = "denied";
      }

      setStatus(!newStatus);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Request Failed!",
      });

      setTimeout(() => {
        handleClose();

        setAlert({
          showAlert: false,
          severity: "error",
          message: "",
        });

      }, 3000);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" >
        <DialogContent>
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Request View
            {open ? (
              <IconButton onClick={handleClose}
                sx={{ position: "absolute", right: 10, top: 8, color: "grey", }} >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <Grid container item spacing={2} xs={12} md={12} xl={12} justifyContent={"flex-start"} p={4} >
            <Formik initialValues={initialValues} enableReinitialize>
              {({ values, setFieldValue }) => {
                return (
                  <Form noValidate autoComplete="off" encType="multipart/form-data" >
                    <Grid container item spacing={2} xs={12} md={12} xl={12} justifyContent={"flex-start"} >

                        {!(data?.zipCode) && (
                          <>
                            <Grid item xs={6} md={6}>
                            <Field name="itemId" label="Item Id*" component={TF} variant="outlined" fullWidth disabled /> </Grid>

                            <Grid item xs={6} md={6}>
                            <Field name="name" label="Name" component={TF} variant="outlined" fullWidth disabled /> </Grid>
                          </>
                        )}

                      <Grid item xs={6} md={6}>
                        <Field name="requestId" label="Request Id*" component={TF} variant="outlined" fullWidth disabled /> </Grid>
                      <Grid item xs={12} md={6}>
                        <Field name="subject" label="Subject*" component={TF} variant="outlined" fullWidth disabled />
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}>
                        <Field name="franchiseeName" label="Franchisee Business Name" component={TF} variant="outlined" fullWidth disabled />
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}>
                        <Field name="franchiseOwnerName" label="Franchisee owner name" component={TF} variant="outlined" fullWidth disabled /> </Grid>
                      {data?.email && (
                        <Grid item xs={12} md={12} xl={12}> <Field name="email" label="Corresponding Email Address" component={TF} variant="outlined" fullWidth disabled /> </Grid>
                      )}
                    </Grid>
                    {data?.zipCode?.length > 0 && (
                      <Grid container item spacing={1} xs={12} md={12} xl={12} mt={1} >
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth>
                            <Autocomplete name="zipCode" id="tags-outlined" defaultValue={zipString} disabled
                              options={[]} getOptionLabel={(option) => option}
                              onChange={(event, newValue) => { setFieldValue("zipCode", newValue); }}
                              filterSelectedOptions
                              renderInput={(params) => (<TextField {...params} label=" Marketing area(Zip coded)"
                                placeholder="Marketing area(Zip coded)" /> )} />
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                    {data?.zipCode?.length > 0 && (
                      <Grid container justifyContent={"flex-start"} p={1} mt={5} >
                        <Grid item>
                          <Typography gutterBottom variant="h5" component="div" style={{ fontSize: "1.5rem" }} >
                            Campaign Duration </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {data?.zipCode?.length > 0 && (
                      <Grid container spacing={2} justifyContent={"flex-start"}>
                        <Grid item>
                          <Field name="from" label={`From`} value={values?.from} component={TF} type="date" disabled />
                        </Grid>
                        <Grid item>
                          <Field name="from" label={`To`} value={values?.to} component={TF} type="date" disabled />
                        </Grid>
                        <Grid item xs={12} md={12} xl={12}>
                          <Field name="description" label="Description" component={TF} variant="outlined" fullWidth disabled multiline rows={5} />
                        </Grid>
                        <Grid container justifyContent={"flex-start"} p={1} mt={5} >
                          <Grid item>
                            <Typography gutterBottom variant="h5" component="div" p={1} style={{ fontSize: "1.5rem" }} >
                              Resources </Typography>
                            {data?.resuorces?.map((resource, index) => (
                              <Grid key={index} p={1} xs={12} md={12} item>
                                <a href={resource.url} style={{ textDecoration: "none" }} target="_blank" rel="noreferrer" > {resource.name} </a>
                              </Grid>
                            ))}
                            {data.resuorces?.length > 0 && ( <Grid item>* click to view resources</Grid> )}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {data?.originalPrice && (
                      <Grid item xs={12} md={12} xl={12} mt={2}>
                        <Field name="originalPrice" label="Original Price ($)" component={TF} variant="outlined" fullWidth type="number" disabled />
                      </Grid>
                    )}

                    {data?.requestedPrice && (
                      <Grid item xs={12} md={12} xl={12} mt={2}>
                        <Field name="requestedPrice" label="Requested Price ($)" component={TF} variant="outlined" fullWidth type="number" disabled />
                      </Grid>
                    )}
                  <AlertMain setAlert={setAlert} alert={alert} /> </Form>
                );
              }}
            </Formik>
          </Grid>
        </DialogContent>

        {initialValues.status === "pending" &&
          <DialogActions>
            <Button data-testid="approve-btn" variant="contained" color="success" onClick={() => changeStatus("approve")} autoFocus > Approve </Button>
            <Button variant="contained" color="error" onClick={() => changeStatus("denie")} autoFocus > Deny </Button>
        </DialogActions>}
      </Dialog>
    </div>
  );
}
