import React, { useState,useEffect } from 'react';
import { Grid } from '@mui/material';
import Pie from 'components/layouts/Pie';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";

export default function PieChart() {
  const axios = useAxiosPrivate()
  const [filteredData, setFilteredData] = useState([]);
  const getPieData = async () => {
    try {
      const { data } = await axios.get('/reports/franchiser-sales/pie');
      setFilteredData(data);
    } catch (err) {
      setFilteredData([0, 0, 0]);
    }
  };

  useEffect(() => {
    getPieData();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid>
      <Pie filteredData={filteredData} />
    </Grid>
  );
}
