import React from "react";
import { Button} from "@mui/material";
import { Link } from 'react-router-dom';
import { Grid } from "@material-ui/core";

export default function RoyaltyManagement() {
  return (

    <Grid container style={{backgroundColor: "#f6f6f6", paddingTop:"10rem", paddingBottom:"10rem"}}>
        
      <Grid container item
        alignItems="center"
        justifyContent="center"
        direction="row"
        lg={12}
        >
        <Grid item
          lg={7}
          md={7}
          sm={8}
          xs={10} >
          <Button variant="contained"
            sx={{
              marginBottom: "1rem",
              marginLeft: {
                lg:"14vw",
                md:"0rem",
                sm:"0rem",
                Xs:"0",
              },
              width: {
                lg:"50%",
                md:"100%",
                sm:"100%",
                xs:"100%",
              },
              height: "5rem", fontSize: "0.9rem"
            }}
            size="large" component={Link} to="/royalty-schemes">
            Royalty Program
          </Button>
        </Grid>
        
        <Grid item 
        lg={7}        
        md={7}        
        sm={8}
        xs={10}
        >
          <Button variant="contained"
            sx={{
              marginTop: "1rem", marginBottom: "1rem",
              marginLeft: {
                lg:"14vw",
                md:"0rem",
                sm:"0rem",
                Xs:"0",
              },
              width: {
                lg:"50%",
                md:"100%",
                sm:"100%",
                xs:"100%",
              },
              height: "5rem", fontSize: "0.9rem"
            }}
            size="large" component={Link} to="/royalty-payments">
              Payments
          </Button>
        </Grid>
        
          <Grid item 
          lg={7}          
          md={7}          
          sm={8}
          xs={10}
          >
          <Button
             sx={{marginTop:"1rem", marginBottom:"1rem",  marginLeft: {
              lg:"14vw",
              md:"0rem",
              sm:"0rem",
              Xs:"0",
            },
            width: {
              lg:"50%",
              md:"100%",
              sm:"100%",
              xs:"100%",
            }, height:"5rem", fontSize:"0.9rem"}}
            variant="contained" size="large">
              Royalty Reports
          </Button>
        </Grid>
        
          </Grid>
        </Grid>
  );
}
