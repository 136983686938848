import { useState } from 'react';
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import * as Yup from 'yup';

function CreateTimeSlotsFunctions({ setStatus, status, scheduleType }) {
  const axios = useAxiosPrivate()
  const [open, setOpen] = useState(false);
  const [zones, setZones] = useState([]);
  const [zone, setZone] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [disab, setDisabled] = useState(true);
  const [initialValues, setInitialValues] = useState({
    zone: '',
    limit: '',
    startTime: '',
    endTime: '',
    isPublished: true,
  });

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const handleClickOpen = () => {
    setInitialValues({
      zone: '',
      limit: '',
      startTime: '',
      endTime: '',
      isPublished: true,
    });
    setTimeout(() => {
      getZones();
      setOpen(true);
    }, 100);
    setDisabled(true);
    setStatus(!status);
  };

  const handleClose = () => {
    setOpen(false);
    setInitialValues({
      zone: '',
      limit: '',
      startTime: '',
      endTime: '',
      isPublished: '',
    });
    setStartTime(null);
    setEndTime(null);
    setZone('');
    setZones([]);
    setStatus(!status);
  };

  const handleZone = (e, setFieldValue) => {
    const val = e.target.value;
    setZone(val);
    setFieldValue('zone', val);
  };
  // get zones
  const getZones = async () => {
    try {
      const { data } = await axios.get('/createZone');
      const zoneArray = data.map((zone) => { return { value: zone._id, label: zone.zoneName, }; });
      setZones(zoneArray);
    } catch (err) {
      if (err.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: err.response.data.message,
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: '',
          });
          handleClose();
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Zone Loading Failed!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: 'Zone Loading Failed!',
          });
          handleClose();
        }, 3000);
      }
    }
  };

  const isObjectEmpty = (obj) => {
    const requiredKeys = ['endTime', 'isPublished', 'limit', 'startTime', 'zone'];

    for (const key of requiredKeys) {
        if (!obj.hasOwnProperty(key) || isEmpty(obj[key])) {
            return true;
        }
    }
    return false;
}

const isEmpty = (value) => {
      if (value === undefined || value === null) {
        return true;
    }
    if (Array.isArray(value) || typeof value === 'string') {
        return value.length === 0;
    }
    if (typeof value === 'object') {
        return Object.keys(value).length === 0;
    }
    return false;
}

  // insert data to database
  const onSubmit = async (e) => {

    if(!isObjectEmpty(e)){
      try {
        const data = {
          zone: e.zone,
          limit: e.limit,
          startTime: e.startTime,
          endTime: e.endTime,
          isPublished: e.isPublished,
        };
        
        setStartTime(null);
        setEndTime(null);
        setZone('');
        setZones([]);
        await axios.post(`/${scheduleType === 1 ? 'schedules':'delivery'}/timeslot`, {
          zone: data.zone,
          limit: data.limit,
          startTime: data.startTime,
          endTime: data.endTime,
          isPublished: data.isPublished,
        });
        setStatus(!status);
        setAlert({
          showAlert: true,
          severity: 'success',
          message: 'Time slot created!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'Time slot created!',
            message: '',
          });
          setOpen(false);
        }, 1000);
      } catch (err) {
        if (err.response.status === 401) {
          setAlert({
            showAlert: true,
            severity: 'error',
            message: err.response.data.message,
          });
          setTimeout(() => {
            setAlert({
              showAlert: false,
              severity: 'error',
              message: '',
            });
            handleClose();
          }, 4000);
        } else {
          setAlert({
            showAlert: true,
            severity: 'error',
            message: 'Request Failed!',
          });
          setTimeout(() => {
            setAlert({
              showAlert: false,
              severity: 'error',
              message: 'Request Failed!',
            });
            handleClose();
          }, 4000);
        }
      }
    }
  };

  const validationSchema = Yup.object().shape({
    zone: Yup.string().required('Zone is required!'),
    startTime: Yup.string().required('Start Time is required!'),
    endTime: Yup.string().required('End Time is required!'),
    limit: Yup.number().required('Limit is required')
      .typeError('Please enter a valid number')
      .min(0, 'Minimum atleast 0')
      .test('is-decimal', 'invalid decimal', (value) =>
        (value + '').match(/^\d*$/)
      ),
  });

  const convertTo24Hour = (e) => { return e?.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hourCycle: 'h23', }); };

  // endtime should be greater than starttime
  const timeChecker = (startT, endT) => {
    if (endT !== null && startT !== null) {
      if (startT.getTime() >= endT.getTime()) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'End time should be greater than start time!',
        });
        setTimeout(() => {
          setDisabled(true);
          setAlert({
            showAlert: false,
            severity: 'error',
            message: '',
          });
        }, 3000);
      } else {
        setDisabled(false);
      }
    }
  };

  const handleStartTimeChange = (e, setFieldValue) => {
    const time = convertTo24Hour(e);
    timeChecker(e, endTime);
    setStartTime(e);
    setFieldValue('startTime', time);
  };

  const handleEndTimeChange = (e, setFieldValue) => {
    const time = convertTo24Hour(e);
    timeChecker(startTime, e);
    setEndTime(e);
    setFieldValue('endTime', time);
  };

  return {
    open, zones, zone, disab, initialValues, alert, handleClickOpen,
    handleZone, onSubmit, validationSchema, handleStartTimeChange, handleEndTimeChange,
    handleClose, startTime, endTime, setAlert
  }
}
export default CreateTimeSlotsFunctions;
