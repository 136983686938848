import React from "react";
import { Grid, Card, CardContent, Stack, Button, IconButton } from "@mui/material";
import GeneralSettings from "./GeneralSettings";
import RouteSettings from "./RouteSettings";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../../store/actions/RouteAction";

export default function CreateVehicle() {
  const [section, setSection] = React.useState(0);
  const state = useSelector((state) => state.route.open);
  const dispatch = useDispatch();
  const [open, setOpenDepot] = React.useState(state);
  const oneVehicle = useSelector((state) => state.route.oneVehicle);

  const setDetails = () => {
    dispatch(setOpen(false));

    setOpenDepot(!open);
  };

  return (
    <>
      <Grid
        container
        pl={2}
        pr={2}
        mt={2}
        sx={{
          backgroundColor: "#E4E4E4",
          borderTopLeftRadius: "1rem",
          borderTopRightRadius: "1rem",
          // borderBottomLeftRadius: "1rem",
          // borderBottomRightRadius: "1rem",
        }}
      > 
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}

          mt={2}
          alignItems="center"
          justifyContent="center"
        mb={1}
        >
          <IconButton size="medium" onClick={setDetails}>
            <ArrowCircleLeftIcon
              sx={{
                fontSize: "2.5rem",
                color: '#1976D2'

              }}
            />
          </IconButton>
        </Grid>


        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          alignItems="center"
          justifyContent="center"
        // mb={2}
        >
          <Stack direction="row"
            mt={2}
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Button
              size="small"
              sx={{
                color: section === 0 ? "#1976D2" : "black",
                fontSize:"1rem",
                textTransform:"capitalize",
                backgroundColor: section === 0 ? "white" : "none",
                // boxShadow: section === 1 ? "0px 8px 10px rgba(0, 0, 0, 0.1)" :"none",
                pointerEvents: section === 0 ? "none" : true,
                borderBottomLeftRadius:"0",
                borderBottomRightRadius:"0",
              }}
              onClick={() => setSection(0)}
            >
              General Settings
            </Button>
            {oneVehicle?.color && (
              <Button
              sx={{
                color: section === 1 ? "#1976D2" : "black",
                fontSize:"1rem",
                textTransform:"capitalize",
                  backgroundColor: section === 1 ? "white" : "none",
                  // boxShadow: section === 0 ? "0px 8px 10px rgba(0, 0, 0, 0.1)" :"none",
                  pointerEvents: section === 1 ? "none" : true,
                borderBottomLeftRadius:"0",
                borderBottomRightRadius:"0",
              }}
                size="small"
                onClick={() => setSection(1)}
              >
                Routing Settings
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>

      <Card style={{ width: "100%", marginBottom: "1rem", height: "100%" }}>
        <CardContent>

          {/* <Grid item xs={12} md={12} lg={12}>
            
          </Grid> */}
          {section === 0 ? <GeneralSettings /> : <RouteSettings />}
          {/* <Grid item xs={12} md={12} lg={12}>
            
          </Grid> */}

        </CardContent>
      </Card>

    </>
  );
}
