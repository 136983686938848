import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Button, Typography, CircularProgress } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import AlertInlineMain from "components/common/AlertInlineMain";
const Reports = () => {
  const axios = useAxiosPrivate()
  let navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ showAlert: false, severity: 'success', message: '', });
  const userType = sessionStorage.getItem('managerType')
  const alertSetter = (severity, message) => {
    setAlert({ showAlert: true, severity, message, });
    setTimeout(() => { setAlert({ showAlert: false, severity, message, }); }, 3000);
  };

  const getLoginLink = async () => {
    setLoading(true)

    try {
      const { data } = await axios.get('/reports/franchisee-payment/login-link');
      window.open(data.url, '_blank');
      setTimeout(() => { setLoading(false); }, 2000);
    } catch (err) {
      alertSetter('error', 'Failed to fetch login link');
      setLoading(false);
    }
  }

  useEffect(() => {
    // get user role
    if (!userRole) {
      const role = window.sessionStorage.getItem('role');
      setUserRole(role);
    } else {
      return;
    }
  }, [userRole]);

  return (
    <Card>
      <CardContent>
        {loading ? (
          <Grid container >
            <Grid container item direction='row' justifyContent='center' alignItems='center'
              style={{ marginTop: "30vh", marginBottom: "30vh", textAlign: "center", }}
              spacing={3} >
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Typography> Please Wait... Stripe dashboard loading. </Typography>
              </Grid>
              <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"} > 
              <CircularProgress />
              </Grid> 
            </Grid>
          </Grid>
        ) : (<Grid container >
          <Grid container item justifyContent='center' alignItems='center' >
            <Typography gutterBottom variant='h5' component='div' style={{ font: 'bold', textAlign: "center" }} >
              Reports </Typography>
            <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent='center' alignItems='center' >
              <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"}>
                <Button style={{ width: '20rem', height: '10rem', borderRadius: 10, backgroundColor: '#fff5fa',
                    fontSize: 24, fontWeight: 'bold', margin: "2rem", paddingBottom: "1rem" }} variant='outlined'
                  onClick={() => { if (userRole === 'franchisor') { navigate('/sales-report'); }
                  else if (userRole === 'franchisee' || userRole === 'manager' || userRole === 'corporate') { navigate('/franchisee-sales-report'); } }} >
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }} >
                    <TrendingUpIcon style={{ fontSize: '8rem' }} /> <p>Sale</p> </div>
                </Button>
              </Grid>
              <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"}>
                <Button style={{ width: '20rem', height: '10rem', borderRadius: 10, backgroundColor: '#fff5fa', fontSize: 24,
                    fontWeight: 'bold', margin: "2rem" }} variant='outlined'
                    onClick={() => {
                    if (userRole === 'franchisor') { window.open('https://dashboard.stripe.com/login', '_blank');                    ;
                    }else if (userRole === 'franchisee' || (userRole === 'manager' && (!userType || userType !== "corporate"))) { getLoginLink(); }
                    else{ navigate('/corporate-payments-report');}
                      }} >

                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }} >
                    <AccountBalanceIcon style={{ fontSize: '7rem' }} />
                    <p>Payments</p> </div>
                </Button>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent='center' alignItems='center' >
              <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"}>
                <Button variant='outlined' style={{
                      width: '20rem', height: '10rem', borderRadius: 10, backgroundColor: '#fff5fa',
                      fontSize: 24, fontWeight: 'bold', margin: "2rem" }} 
                    onClick={() => {
                      if (userRole === 'franchisor') { navigate('/customer-reports'); }
                      else if (userRole === 'franchisee' || userRole === 'manager' || userRole === 'corporate') { navigate('/franchisee-customer-reports'); } }} >
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }} >
                    <PeopleAltIcon style={{ fontSize: '7rem' }} />
                    <p>Customers</p>
                  </div>
                </Button>
              </Grid>

              <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"}>
                <Button variant='outlined' style={{
                      width: '20rem', height: '10rem', borderRadius: 10, backgroundColor: '#fff5fa',
                    fontSize: 24, fontWeight: 'bold', margin: "2rem"
                  }}
                    onClick={() => {
                      if (userRole === 'franchisee' || userRole === 'manager' || userRole === 'corporate') { navigate('/franchisee-delivery-report'); }
                  }}
                  >
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }} >
                    <LocalShippingIcon style={{ fontSize: '7rem' }} />
                    <p>Delivery</p>
                  </div>
                </Button>
              </Grid>
            </Grid>
            {alert.showAlert && ( <Grid item md={12}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid> )}
          </Grid>
        </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default Reports;
