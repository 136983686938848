import React, { useState, useEffect } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Grid, TextField, CardHeader, Card } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import AlertInlineMain from "components/common/AlertInlineMain";
import { useStyles } from 'utils/styles/styles';

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className='icon' />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className='icon' />;
}

export default function DataGridManualOrders({
  columns,
  title,
  height,
  filteredItems,
  alert,
  setAlert,

}) {
  const classes = useStyles();

  return (
    <Grid container className={classes.root1} spacing={1}
      alignItems="center"
      justifyContent="center"
    >
      <Grid container item
        alignItems="center"
        justifyContent="center">

        <Grid item
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >

          <Card>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={`${title.toUpperCase()}`} />
            <Grid
              item
              xs={12}
              md={12}
              style={{ height: !height ?? '60vh', margin: '1rem', padding: 0 }}
            >
              <DataGrid
                getRowHeight={() => 'auto'}
                getRowId={(row: any) =>  row.User + row.First}
                autoHeight
                columns={columns}
                disableSelectionOnClick={true}
                disableColumnSelector={true}
                disableDensitySelector={true}
                rows={filteredItems}
                components={{
                  Toolbar: GridToolbar,
                  ColumnSortedDescendingIcon: SortedDescendingIcon,
                  ColumnSortedAscendingIcon: SortedAscendingIcon,
                  LoadingOverlay: LinearProgress,
                }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [
                        {
                          columnField: 'price',
                          operatorValue: '>',
                          value: '2.5',
                        },
                      ],
                    },
                  },
                }}
              />
            </Grid>
            {alert.showAlert && (
              <Grid item md={12} m={2}>
                <AlertInlineMain setAlert={setAlert} alert={alert} />
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}