import React, { useState, useEffect } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Grid, TextField, CardHeader, Card, Table, TableCell, TableContainer, TableRow, Paper, } from '@mui/material';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import LinearProgress from '@mui/material/LinearProgress';
import AlertInlineMain from "components/common/AlertInlineMain";
import { useStyles } from 'utils/styles/styles';

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className='icon' />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className='icon' />;
}

function ccyFormat(num) {
  return `$${num ? num?.toFixed(2) : 0}`;
}

export default function DataGridTable({
  columns,
  fetchDataUrl,
  payload,
  status,
  title,
  newData,
}) {
  const axios = useAxiosPrivate()
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState({});

  const [alert, setAlert] = useState({
    // eslint-disable-line
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const onSearch = (e) => {
    const text = String(e?.target?.value).toLowerCase();
    setSearchText(text);

    if (text) {
      // eslint-disable-next-line
      const result = rows?.filter((item) => {
        const str = JSON.stringify(item).toLowerCase();

        if (str?.search(text) >= 0) return item;
      });
      // setPage(0);
      setFilteredItems(result);
    } else {
      setFilteredItems(rows);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, payload]);

  useEffect(() => {
    if (newData) {
      setFilteredItems(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);

  const loadData = async () => {
    try {
      setLoading(true);
      setRows([]);
      setTotal({});
      const { data } = await axios.post(fetchDataUrl, payload);
      setFilteredItems(data?.data);
      setTotal(data?.total);
      setRows(data);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Authorized Access!',
        });
        setTimeout(() => setAlert({ showAlert: false, severity: 'error', message: '' }), 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Something went wrong!',
        });
        setTimeout(() => setAlert({ showAlert: false, severity: 'error', message: '' }), 3000);
      }
    } finally {
      setLoading(false);
    }
  };
  const containerStyle = {
    margin: '16px',
    display: 'flex',
  };

  const columnStyle = {
    flex: 1,
    textAlign: 'left',
    padding: '0 10px',
  };

  return (
    <Grid container className={classes?.root1} spacing={1}>
      <Grid fullWidth item xs={12} sm={12} md={12}>
        <Card>
          <CardHeader title={`${title}`} />
          <Grid container spacing={2}>
            {' '}
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                label='Search'
                fullWidth
                variant='outlined'
                style={{ margin: '1rem' }}
                onChange={onSearch}
                value={searchText}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{ height: '60vh', margin: '1rem', padding: 0 }}
          >
            <DataGrid
              autoHeight
              columns={columns}
              disableSelectionOnClick={true}
              disableColumnSelector={true}
              disableDensitySelector={true}
              rows={filteredItems.filter(item => item?.id != null)}
              loading={loading}
              components={{
                Toolbar: GridToolbar,
                ColumnSortedDescendingIcon: SortedDescendingIcon,
                ColumnSortedAscendingIcon: SortedAscendingIcon,
                LoadingOverlay: LinearProgress,
              }}
              initialState={{
                filter: {
                  filterModel: {
                    items: [
                      {
                        columnField: 'price',
                        operatorValue: '>',
                        value: '2.5',
                      },
                    ],
                  },
                },
              }}
            />
          </Grid>
      <div style={containerStyle}>
        <div style={columnStyle}>TOTAL</div>
        <div style={columnStyle}>{ccyFormat(total?.sales)}</div>
        {(sessionStorage.getItem('role') === 'corporate' || sessionStorage.getItem('managerType') === 'corporate') ?
          <></>
          :<>
            <div style={columnStyle}>{ccyFormat(total?.royaltyFees)}</div>
            <div style={columnStyle}>{ccyFormat(total?.netSales)}</div>
          </>}
      </div>
          
          {alert?.showAlert && (
            <Grid item md={12} m={2}>
             <AlertInlineMain setAlert={setAlert} alert={alert} />
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}
