import React from "react";
import { Grid, Card, CardContent, FormControl, MenuItem, Select, InputLabel, Typography, CircularProgress } from "@material-ui/core";
import Button from "../../components/UI/ReusableButton";
import { Form, Formik, Field } from "formik";
import { TextField as TF } from "formik-material-ui";
import ChipInput from "material-ui-chip-input";
import AlertMain from "components/common/AlertMain";
import AccountFunctions from "./commonFunctions/AccountFunctions"

export default function Account() {

  const { role, alert, initialValues, royaltySchemes, stillLoading, validationSchema, onSubmit,
    timeZoneChangeWhenNull, timeZoneChange, timeZoneVal, navigate, setAlert } = AccountFunctions();

  const managerType = sessionStorage.getItem("managerType");

  return (
    <Card>
      {stillLoading ? (
        <Grid container >
          <Grid container item alignItems="center" justifyContent="center">
            <Grid item>
              <CircularProgress style={{ marginTop: "20vw", marginBottom: "20rem", }} />
            </Grid>
          </Grid>
        </Grid>) : !timeZoneVal && (role === "franchisee" || role === "corporate") ? (
          <Grid container justifyContent={"center"}>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <FormControl fullWidth
                style={{ marginTop: "2rem", marginBottom: "5rem", }} >
                <InputLabel id="demo-simple-select-label" InputLabelProps={{ shrink: true }} style={{ marginBottom: "0.2rem", }} >
                  Select your timezone </InputLabel>
                <Select name="timeZone" value={timeZoneVal} label="Select your timezone" onChange={timeZoneChangeWhenNull}>
                  <MenuItem value={"US/Central"}> US/Central </MenuItem>
                  <MenuItem value={"US/Mountain"}> US/Mountain </MenuItem>
                  <MenuItem value={"US/Pacific"}> US/Pacific </MenuItem>
                  <MenuItem value={"US/Eastern"}> US/Eastern </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>) : (
        <CardContent>
          <Grid container justifyContent={"center"}>
            <Grid item>
              <Typography gutterBottom variant="h5" component="div" style={{ font: "bold" }}>
                Account Details </Typography>
            </Grid>
          </Grid>
          {(role === "franchisee" || role === "corporate" || role === "manager") && <Grid container justifyContent={"center"}>
            <Grid item lg={3} md={12} sm={12} xs={12} >
              <FormControl fullWidth style={{ marginTop: "2rem", marginBottom: "5rem", }}>
                <InputLabel id="demo-simple-select-label" InputLabelProps={{ shrink: true }}
                  style={{ marginBottom: "0.2rem" }} > Select your timezone </InputLabel>
                <Select name="timeZone" value={timeZoneVal} label="Select your timezone" onChange={timeZoneChange}>
                  <MenuItem value={"US/Central"}> US/Central </MenuItem>
                  <MenuItem value={"US/Mountain"}> US/Mountain </MenuItem>
                  <MenuItem value={"US/Pacific"}> US/Pacific </MenuItem>
                  <MenuItem value={"US/Eastern"}> US/Eastern </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>}
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
            {({ values, isValid, dirty, handleChange, }) => {
              return (
                <Form noValidate autoComplete="off" encType="multipart/form-data">
                  <Grid container item spacing={2} lg={12} md={12} sm={12} xs={12} justifyContent={"flex-start"}>
                    <Grid item lg={6} md={12} sm={12} xs={12}>

                      <Field name="fID" label={(role === "franchisee" || sessionStorage.getItem('managerType') === "franchisee") ? "Franchisee ID" : "Corporate ID"} variant="outlined" component={TF} fullWidth disabled></Field>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Field component={TF} name="firstName" label={(role === "franchisee" || sessionStorage.getItem('managerType') === "franchisee") ? "Franchisee Business Name" : "Corporate Business Name"} variant="outlined"
                        onChange={handleChange} fullWidth disabled />
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Field name="ownerName" component={TF} label={(role === "franchisee" || sessionStorage.getItem('managerType') === "franchisee") ? "Franchisee owner name" : "Corporate owner name"} variant="outlined" disabled={role === "manager"}
                        onChange={handleChange} fullWidth required></Field>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Field name="email" component={TF} label="Email Address" variant="outlined"
                        fullWidth required disabled onChange={handleChange} ></Field>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <ChipInput label={(role === 'corporate' || (sessionStorage.getItem("managerType") === "corporate" && role === "manager")) ? "Approved Zip Codes for Corporate / Zip Codes" : "Approved Zip Codes for Franchisee / Zip Codes"}
                        value={initialValues.serviceRegions} variant="outlined"
                        sx={{ height: "2rem", overflow: "scroll" }} disabled />
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Field component={TF} name="address" label="Address"
                        variant="outlined" fullWidth disabled onChange={handleChange} ></Field>
                    </Grid>

                    {((role === "franchisee" || role === "manager") && (managerType !== "corporate")) &&
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        <InputLabel id="demo-simple-select-label" InputLabelProps={{ shrink: true }}
                          style={{ marginBottom: "-0.3rem", marginLeft: "0.8rem", fontSize: "0.75rem" }}>
                          Royalty Program </InputLabel>
                        <FormControl fullWidth>
                          <Select labelId="demo-simple-select-label" id="demo-simple-select"
                            name="royaltyScheme" value={values.royaltyScheme} label="Royalty Program"
                            onChange={handleChange} disabled
                            variant="outlined">
                            {royaltySchemes.map((option) => (
                              <MenuItem name="royaltyScheme" required key={option.id} value={option.id}
                                style={{ display: "flex", justifyContent: "space-between", }} >
                                <Typography>{option.scheme_Name}</Typography>
                                <Typography> {option.royalty_Percentage}% </Typography> </MenuItem>))}
                          </Select>
                        </FormControl>
                      </Grid>
                    }

                    <Grid item xs={12} md={12}>
                      <Typography
                        style={{ color: "#0522fc", fontWeight: "bold", marginTop: "1rem", }} >
                        Payment Notes</Typography>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Field name="note" component={TF} label="Payment type and reference" variant="outlined"
                        fullWidth onChange={handleChange} ></Field>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} md={4} style={{ marginTop: "1rem" }}>
                    <Button style={{ width: "8rem" }} variant="contained" color="secondary"
                      onClick={() => { navigate("/view-zone"); }} > Zones </Button>
                  </Grid>
                  <Grid style={{ marginTop: "1rem" }}>
                    <Button style={{ width: "8rem" }} variant="contained" color="secondary"
                      type="submit" disabled={!isValid || !dirty} > update </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
          <AlertMain setAlert={setAlert} alert={alert} />
        </CardContent>)}
    </Card >
  );
}
