import React, { useState } from "react";
import DataGrid from "../layouts/DataGrid";
import { Button, Chip, Grid, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText } from "@mui/material";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Typography } from "@material-ui/core";
import AlertMain from "components/common/AlertMain";

export default function Test() {
  const axios = useAxiosPrivate()
  let navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [idForOpenDialog, setidForOpenDialog] = useState("");

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const changeStatus = async (newStatus, data) => {
    try {
      await axios.put("/user/update-status", {
        status: newStatus,
        id: data.id,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Status changed successfully!",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 2000);
      setStatus(!status);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Status changed failed!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "",
        });
      }, 2000);
    }
  };

  const onDelete = async (id) => {
    try {
      const {data} = await axios.put(`/user/deleteFranchisee/${id}`);
      setAlert({
        showAlert: true,
        severity: "success",
        message: `${data?.role === 'corporate' ? "Corporate" : "Franchisee"} deleted successfully!`,
      });
      setOpen(false)
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 2000);
      setStatus(!status);
    } catch (error) {
      setOpen(false)
      if (error.response.status === 403) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: `${error.response.data.message}`,
        });
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Franchisee delete failed!",
        });
      }
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "",
        });
      }, 4000);
    }
  };

  const columns = [
    { field: "roleID", headerName: "Location ID", width: 150 },
    {
      field: "firstName", headerName: "Location Name", width: 220,
      valueGetter: (params) => { return `${params.row.firstName}`; },
    },
    {
      field: "state", headerName: "State", width: 150,
      valueGetter: (params) => { return `${params.row.state ? params.row.state : ""}`; },
    },
    {
      field: "role", headerName: "Location Type", width: 150,
      valueGetter: (params) => { return `${params.row.role ? params.row.role : ""}`; },
    },
    {
      field: "createdAt", headerName: "Established Date", width: 280,
      valueGetter: (params) => { return `${moment(params.row.createdAt).format("MMMM Do YYYY")}`; },
    },
    {
      field: "status", headerName: "Status", width: "200",
      renderCell: (params) => {
        const onClick = (e, status) => {
          e.stopPropagation(); // don't select this row after clicking
          changeStatus(status, params.row);
        };
        if (params.row.onboardingStatus === "onboarding") {
          return (<Chip label={`${params.row.onboardingStatus}`} color="secondary" variant="outlined" />);
        } else if (params.row.onboardingStatus === "sales") {
          return (<Chip label={`Zones & Scheduling`} onClick={(e) => onClick(e, "inactive")} color="primary" variant="outlined" />);
        } else if (params.row.onboardingStatus === "payment") {
          return (<Chip label={`${params.row.onboardingStatus}`} color="warning" variant="outlined" />);
        } else if (params.row.onboardingStatus === "completed") {
          return (<Chip label={`${params.row.onboardingStatus}`} color="success" variant="outlined" />);
        } else {
          return (<div></div>);
        }
      },
      valueGetter: (params) => {
        if (params.row.onboardingStatus === "onboarding") {
          return (`${params.row.onboardingStatus}`)
        }
        if (params.row.onboardingStatus === "sales") {
          return (`${params.row.onboardingStatus}`)
        }
        if (params.row.onboardingStatus === "payment") {
          return (`${params.row.onboardingStatus}`)
        }
        if (params.row.onboardingStatus === "completed") {
          return (`${params.row.onboardingStatus}`)
        }
      }
    },
    {
      field: "edit", headerName: "Edit", sortable: false, disableExport: true,
      renderCell: (params) => {
        const onClick = () => {
          const id = params.row._id;
          navigate("/update-franchisee", { state: { id, update: true } });
        };
        return (<Button onClick={onClick}> <EditIcon /> </Button>);
      },
    },
    {
      field: "delete", headerName: "Delete", sortable: false, disableExport: true,
      renderCell: (params) => {
        const openDialog = () => {
          const id = params.row._id;
          setidForOpenDialog(id)
          setOpen(true)
        }

        return (
          <>
            <Button onClick={openDialog} > <DeleteIcon /> </Button>
          </>
        );
      },
    },
    {
      field: "action", headerName: "Action", sortable: false, disableExport: true,
      width: "150",
      renderCell: (params) => {
        const openDashboard = () => {
          const id = params.row._id;
          const token = sessionStorage.getItem("accessToken");
          const url = `/await-signin?id=${id}&token=${token}`;
          window.open(url, '_blank', 'noopener,noreferrer');
        }
        if (params.row.role === "corporate") {
          return (

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <Button variant="contained" onClick={openDashboard}>Sign in</Button>
            </div>
          );
        }

      },
    },
  ];
  return (
    <Grid container>
      <Grid container item xs={12} md={12} justifyContent="flex-end" mr={4}>
        <Button variant="contained" component={Link} to="/franchiseeMgt" data-testid="create-location">+ Create Location</Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <DataGrid autoHeight columns={columns} fetchDataUrl="/user/getFranchisee" status={status} title="Locations Management" updatePath="/update-franchisee/" />
      </Grid>
      <AlertMain setAlert={setAlert} alert={alert} />
      <Dialog open={open} onClose={() => setOpen(false)}  >
              <DialogTitle id='alert-dialog-title'> Delete Location </DialogTitle>
              <DialogContent sx={{ width: "20rem", }} >
                <DialogContentText id='alert-dialog-description'>
                  <Typography> Are you Sure? </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button sx={{ color: "red", }} onClick={() => onDelete(idForOpenDialog)}> Yes </Button>
                <Button onClick={() => setOpen(false)} > No </Button>
              </DialogActions>
            </Dialog>
    </Grid>
  );
}
