import React, { useState, useEffect } from "react";
import { Grid, Typography, Card, CardContent, Button, IconButton,  Stack } from "@mui/material";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField as TF } from "formik-material-ui";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { setOpen, setDriver } from "../../../store/actions/RouteAction";
import DialogAlertMain from "components/common/DialogAlertMain";

const generatePassword = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789abcdefghijklmnopqrstuvwxyz";
  let result = "@";
  const charactersLength = characters?.length;
  for (let i = 0; i < length; i++) {
    result += characters?.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export default function CreateVehicle() {
  const axios = useAxiosPrivate()
  const dispatch = useDispatch();
  const [backendCall, setBackendCall] = useState(false);
  const oneDriver = useSelector((state) => state.route.oneDriver);
  const setDetails = () => {
    dispatch(setOpen(false));
  };
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [initialValues, setInitialValues] = React.useState({
    name: "",
    email: "",
    password: "",
    speedLimit: 0,
    stationaryLimit: 0,
    mobileNumber: "",
  });
  const onSubmit = async (values, { resetForm }) => {
    try {
      setBackendCall(true);
      if (oneDriver?.name) {
        await axios.put(`/driver/${oneDriver?._id}`, values);
        dispatch(setDriver());
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Update Success!",
        });
      } else {
        await axios.post("/driver", values);
        resetForm();
        setInitialValues({
          name: "",
          email: "",
          password: "",
          speedLimit: 0,
          stationaryLimit: 0,
          mobileNumber: "",
        });
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Create Success!",
        });
        dispatch(setDriver());}
    } catch (e) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: e?.response?.data ? e?.response?.data : "Error" ,
      });
    } finally {
      setBackendCall(false);
    }
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").max(30, "Name is too long"),
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("Password is required")
      .min(8, "Must Contain 8 Characters"),
    speedLimit: Yup.number().required("Speed Limit is required")
      .test(
        "Is positive?",
        "The value must be greater than or equal to 0!",
        (value) => value >= 0
      ),
    stationaryLimit: Yup.number().required("Stationary Limit is required")
      .test(
        "Is positive?",
        "The value must be greater than or equal to 0!",
        (value) => value >= 0
      ),
    mobileNumber: Yup.string()
          // eslint-disable-next-line
    .matches(/^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/, "Please enter valid Phone Number")
    .required("Mobile Number is required"),
  });

  useEffect(() => {
    if (oneDriver) {
      setInitialValues({ name: oneDriver?.name, email: oneDriver?.email, id: oneDriver?._id, speedLimit: oneDriver?.speedLimit,
        stationaryLimit: oneDriver?.stationaryLimit, mobileNumber: oneDriver?.mobileNumber, password: oneDriver?.plainPass, });
    } else {
      setInitialValues({ name: "", email: "", id: "", speedLimit: 0, stationaryLimit: 0, mobileNumber: "", password: "", });
    }
  }, [oneDriver]);

  return (
    <>
      <Grid container pl={2} pr={2} mt={2} sx={{ backgroundColor: "#E4E4E4", borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem"}} > 
        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} mb={1} alignItems="center" justifyContent="center" >
          <IconButton size="medium" onClick={setDetails}>
            <ArrowCircleLeftIcon sx={{ fontSize: "2.5rem", color: '#1976D2' }} />
          </IconButton>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} alignItems="center" justifyContent="center">
          <Stack direction="row" mt={2} alignItems="center" justifyContent="center">
          <Typography size="small" sx={{ color: "#1976D2", fontSize: "1.034rem", textTransform: "capitalize", backgroundColor: "white", fontWeight: "500",
                padding: "0.32rem", borderTopLeftRadius:"0.3rem", borderTopRightRadius:"0.3rem", }}>  Driver Settings </Typography>
          </Stack>
        </Grid>
      </Grid>
    <Card > 
      <DialogAlertMain setAlert={setAlert} alert={alert} />
      <CardContent>
        <Grid container justifyContent={"flex-start"} alignContent="center" pt={2}>
          <Grid item container xs={12} md={12}>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize >
              {({values, isValid, dirty, handleChange, setFieldValue, setFieldError, setFieldTouched, }) => {
                return (
                  <Form>
                    <Grid container item xs={12} md={12} spacing={2}>
                      <Grid item xs={12} md={12}>
                        {" "}
                        <Field name="name" label="Driver Name" variant="outlined" component={TF} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        {" "}
                        <Field name="email" label="Email" variant="outlined" component={TF} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Field name="password" label="Password" variant="outlined" component={TF} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Button variant="contained" color="primary"
                          onClick={() => {
                            const password = generatePassword(8);
                            setFieldValue("password", password, false);
                            setFieldError("password", "");
                            setFieldTouched("password", false);
                          }}> {oneDriver?.name ? "Reset Password" : "Generate Password"} </Button>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        {" "}
                        <Field name="speedLimit" label="Speed Limit" type="number" variant="outlined" component={TF}
                          onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        {" "}
                        <Field name="stationaryLimit" label="Stationary Limit" type="number" variant="outlined"
                          component={TF} onChange={handleChange} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        {" "}
                        <Field name="mobileNumber" label="Mobile Number" variant="outlined" component={TF} onChange={handleChange} fullWidth />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} mt={2}>
                      <LoadingButton data-testid="create-driver-btn" loading={backendCall} type="submit" variant="contained" size="small" disabled={!isValid || !dirty}>
                        {oneDriver?.name ? "Update" : "Create"}</LoadingButton>
                    </Grid>
                  </Form> ); }}
            </Formik>
          </Grid>
        </Grid>
      </CardContent>
      </Card>
      </>
  );}
