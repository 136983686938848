import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AlertInlineMain from "components/common/AlertInlineMain";
import { Autocomplete, FormControl } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import { Form, Formik } from "formik";

const months = [
  {
    text: "January",
    value: "01",
  },
  {
    text: "February",
    value: "02",
  },
  {
    text: "March",
    value: "03",
  },
  {
    text: "April",
    value: "04",
  },
  {
    text: "May",
    value: "05",
  },

  {
    text: "June",
    value: "06",
  },
  {
    text: "July",
    value: "07",
  },
  {
    text: "August",
    value: "08",
  },
  {
    text: "September",
    value: "09",
  },
  {
    text: "October",
    value: "10",
  },
  {
    text: "November",
    value: "11",
  },
  {
    text: "December",
    value: "12",
  },
];

export default function AlertDialog({ setOpen, open, setSelected, dataset }) {
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    year: "",
    month: "",
  });

  const [year, setYear] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (e, { resetForm }) => {
    try {
      setSelected(e);

      setYear(null);
      setOpen(false);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Create failed!",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "Create failed!",
        });
      }, 3000);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {open ? (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 8,
                  color: "grey",
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            // validationSchema={validationSchema}
            enableReinitialize
          >
            {({ values, isValid, setFieldValue }) => {
              return (
                <Form noValidate autoComplete="off">
                  <Grid container p={1}
                    rowSpacing={2}
                  >
                    <Grid item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Year"
                          views={["year"]}
                          value={year}
                          onChange={(newValue) => {
                            setYear(newValue);
                            let dateObj = JSON.stringify(newValue);
                            let newVal = dateObj.replace('"', "");
                            let val = newVal?.split("-")[0];
                            setFieldValue("year", val);
                          }}
                          onKeyDown={(e) => e.preventDefault()}
                          renderInput={(params) => (
                            <TextField
                              onKeyDown={(e) => e.preventDefault()}
                              onMouseDown={(e) => e.preventDefault()}
                            fullWidth
                              {...params} required />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item 
                    lg={12} 
                    md={12} 
                    sm={12} 
                    xs={12} >
                      <FormControl fullWidth>
                        <Autocomplete
                          name="month"
                          id="tags-outlined"
                          options={months}
                          getOptionLabel={(option) => option.text}
                          onChange={(event, newValue) => {
                            setFieldValue("month", newValue?.value);
                          }}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Month"
                              placeholder="Month"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <DialogActions>
                    <Button
                      variant="contained"
                      color="success"
                      type="submit"
                      autoFocus
                      disabled={!isValid}
                    >
                      Apply
                    </Button>
                  </DialogActions>

                  {alert.showAlert && (
                    <Grid item md={12} m={2}>
                       <AlertInlineMain setAlert={setAlert} alert={alert} />
                    </Grid>
                  )}
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
