import React, { useEffect, useMemo, useState } from "react";
import { Grid, Dialog, DialogTitle, DialogContent, Button, DialogActions, InputLabel, Select, FormControlLabel } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogContentText from '@mui/material/DialogContentText';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
// validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import SearchLocation from "./GoogleMapsAutocomplete";
import { FormControl, MenuItem, FormGroup, Checkbox } from "@mui/material";
import DateSlashToDashReformat from "components/common/Functions/DateSlashToDashReformat";
import { IMaskInput } from 'react-imask';

let copyNewJobSchema = Yup.object().shape({
    firstName: Yup.string().required('Firstname is required'),
    lastName: Yup.string().required('Lastname is required'),
    address: Yup.string().required("Email is required!"),
    phoneNumber: Yup.string().matches(
        /^\+[0-9]{1}\s[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
        "Please enter a valid Phone Number with country code"
    ).required("Phone Number is required"),
    time: Yup.string().required('Time is required'),
    instruction: Yup.string().required('Instruction is required'),
});

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+0 000-000-0000"
            definitions={{
                '0': /[0-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

function CopyNewJobDialog(props) {
    const axios = useAxiosPrivate();
    const [zipCode, setZipCode] = useState("");
    //eslint-disable-next-line
    const [initialValues, setInitialValues] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        address: "",
        nickName: "",
        apartmentNo: "",
        zipCode: "",
        location: { lat: "", lng: "" },
        date: new Date().toISOString().split("T")[0],
    });

    const deliveryStatusTypes = [
        "DELIVERED",
        "SET_TO_DELIVER",
        "DELIVERY_CANCELED",
        "DELIVER_IN_PROGRESS",
    ]

    const pickupStatusTypes = [
        "SET_TO_PICKUP",
        "PICKED_UP",
        "PICKUP_CANCELED",
        "PICKUP_IN_PROGRESS",
    ]

    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [checked3, setChecked3] = useState(false);

    const handleChange1 = (event) => { setChecked1(event.target.checked); };
    const handleChange2 = (event) => { setChecked2(event.target.checked); };
    const handleChange3 = (event) => { setChecked3(event.target.checked); };

    function handleOrderType() {
        if (checked1 && !checked2 && !checked3) return 1;
        else if (!checked1 && checked2 && !checked3) return 2;
        else if (!checked1 && !checked2 && checked3) return 3;
        else if (!checked1 && checked2 && checked3) return 4;
        else if (checked1 && !checked2 && checked3) return 5;
        else if (checked1 && checked2 && !checked3) return 7;
        else if (checked1 && checked2 && checked3) return 9;
    };

    function checkOrderType(number) {
        let checked1 = false;
        let checked2 = false;
        let checked3 = false;

        if (number == 1) {
            checked1 = true;
        }
        if (number == 2) {
            checked2 = true;
        }
        if (number == 3) {
            checked3 = true;
        }
        if (number == 4) {
            checked2 = true;
            checked3 = true;

        }
        if (number == 5) {
            checked1 = true;
            checked3 = true;
        }
        if (number == 7) {
            checked1 = true;
            checked2 = true;
        }
        if (number == 9) {
            checked1 = true;
            checked2 = true;
            checked3 = true;

        }

        setChecked1(checked1)
        setChecked2(checked2)
        setChecked3(checked3)

    }

    const [selectedStatus, setSelectedStatus] = useState(props?.editJobDetails?.status);

    const handleSelectedStatus = (e) => {
        setSelectedStatus(e.target.value)
    }

    useEffect(() => {
        setSelectedStatus(props?.editJobDetails?.status);
    }, [props?.editJobDetails])

    const fomratedPayloadDate = useMemo(() => {
        const returnFormatedDate = DateSlashToDashReformat(props?.date);
        return returnFormatedDate
    }, [props?.date]);

    useEffect(() => {
        if (props?.editJobDetails) {
            setInitialValues({
                firstName: props?.editJobDetails?.customerDetail?.firstName,
                lastName: props?.editJobDetails?.customerDetail?.lastName,
                phoneNumber: props?.editJobDetails?.phoneNumber,
                email: props?.editJobDetails?.customerDetail?.email,
                address: props?.editJobDetails?.address,
                nickName: "",
                apartmentNo: props?.editJobDetails?.orderDetail?.apartmentNo,
                zipCode: props?.editJobDetails?.orderDetail?.zipCode,
                location: props?.editJobDetails?.location,
                time: props?.editJobDetails?.timeFrame,
                instruction: props?.editJobDetails?.instruction,
                date: fomratedPayloadDate,//new Date().toISOString().split("T")[0],
                status: selectedStatus,
            });

            checkOrderType(props?.editJobDetails?.orderDetail?.orderType)

        }
    }, [props?.editJobDetails])

    const [backendCall, setBackendCall] = useState(false);
    const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });
    const [driverDetails, setDriverDetails] = useState(null);

    const handleSelectDriverChange = (event) => {
        let vehicleData = null;
        vehicleData = props?.vehiclesInfo && props?.vehiclesInfo.filter((e) =>
            e.driver._id === event.target.value
        )
        setDriverDetails(vehicleData[0])
    };

    useEffect(() => {
        let editVehicleData = null;
        editVehicleData = props?.vehiclesInfo && props?.vehiclesInfo.filter((e) =>
            e?.driver?._id === props?.editJobDetails?.driverID
        )
        if (editVehicleData?.length > 0) {
            setDriverDetails(editVehicleData[0])
        }
        else if (props?.vehiclesInfo) {
            setDriverDetails(props?.vehiclesInfo[0])
        }
    }, [props])

    function alertHandleClick() {
        props.onClose();
        setZipCode('');
        setAlert({
            ...alert,
            showAlert: false,
        });
    }

    const formatDateString = (date) => {
        const formattedDate = new Date(date).toLocaleDateString('en-US', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        });
        return formattedDate;
    }

    const onSubmit = async (e, { resetForm, setSubmitting }) => {
        try {

            const orderType = handleOrderType();

            const data = {
                First: e.firstName,
                Last: e.lastName,
                Notes: "",
                Phone: '+' + e.phoneNumber.replace(/[^\w]/g, ""),
                Zip: `Zip: ${e.zipCode}`,
                Zone: "",
                deliveries: props.type === 1 ? "0" : "1", // 0 is pickup & 1 is delivery
                diliverDate: formatDateString(e?.date),
                diliverTimeSlot: e.time,
                pickupDate: formatDateString(e?.date),
                pickupTimeSlot: e.time,
                address: e.address,
                location: e.location,
                instruction: e.instruction,
                notes: e.notes,
                driverID: driverDetails?.driver?._id,
                vehicleID: driverDetails?._id,
                type: props.type === 1 ? "COLLECTION" : "DELIVERY",
                dateActual: e?.date,
                jobParentId: props?.editJobDetails?._id,
                jobChildId: props?.editJobDetails?.id,
                status: selectedStatus,
                originalDate: e?.date,
                orderId: props?.editJobDetails?.orderID,
                orderType: orderType,
            }
            setBackendCall(true);
            const dispatchData = await axios.get(`/job/job-franchisee/${e?.date}`)
            if (dispatchData?.data?.dispatch) {
                await axios.post(`/job/custom/create`, data);
                setAlert({ showAlert: true, severity: "success", message: "Job copied successful!", });
            } else {
                setAlert({ showAlert: true, severity: "error", message: `There are no dispatched routes on ${e?.date}`, });
            }
            resetForm();
            setSubmitting(false);
        } catch (error) {
            if (error.response.status === 500) {
                setAlert({ showAlert: true, severity: "error", message: "Job update failed!", });
            } else { setAlert({ showAlert: true, severity: "error", message: error?.response?.data ?? "Something went wrong!", }); }
            resetForm();
            setSubmitting(false);
            setZipCode("");
        }
        setBackendCall(false);
    };

    return (
        <>
            {alert.showAlert ? (<Dialog
                open={true}
                onClose={alertHandleClick}
                fullWidth={'sm'}
                maxWidth={'sm'}
            >
                <DialogTitle id='alert-dialog-title'
                    sx={{
                        textTransform: "uppercase",
                        color: alert?.severity === "success" ? "green" : "red"
                    }}>{alert?.severity}</DialogTitle>

                <DialogContent>
                    <DialogContentText
                        sx={{
                            fontSize: "1rem",
                        }}
                        id='alert-dialog-description'>
                        {alert.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={alertHandleClick}
                        variant='contained'
                        // color={alert?.severity === "success" ? 'success' : 'error'}
                        style={{
                            backgroundColor: alert?.severity === "success" ? "#2e7d32" : "#d32f2f",
                            color: "white"
                        }}
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>) : (<Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle>Copy Job</DialogTitle>
                <DialogContent>
                    <Grid container lg={12} md={12} sm={12} xs={12} justifyContent="center" alignItems="center" >
                        <Grid container item lg={12} md={12} sm={12} xs={12} alignItems="center" justifyContent="center" >
                            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={copyNewJobSchema} enableReinitialize >
                                {({ dirty, isValid, values, setFieldValue, resetForm }) => {
                                    return (
                                        <Grid container item justifyContent="center" alignItems="center" lg={12} md={12} sm={12} xs={12} >
                                            <Form style={{ width: "35rem" }}>
                                                <Grid item xs={12} sm={6}>
                                                    <Field name="firstName" label="First Name" component={TextField}
                                                        variant="outlined" fullWidth margin="dense" />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Field name="lastName" label="Last Name" component={TextField}
                                                        variant="outlined" fullWidth margin="dense" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Field name="phoneNumber"
                                                        label="Phone Number"
                                                        component={TextField}
                                                        variant="outlined"
                                                        fullWidth margin="dense"
                                                        InputProps={{
                                                            inputComponent: TextMaskCustom,
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Field name="time" label="Pickup / delivery time" component={TextField} placeholder="6:00 PM - 7:00 PM"
                                                        variant="outlined" fullWidth margin="dense" ></Field>
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Field name="date" label="Pickup / delivery date" component={TextField} placeholder="Date" type="date"
                                                        variant="outlined" fullWidth margin="dense" ></Field>
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ margin: "8px 0px 8px 0px" }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Driver</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={driverDetails?.driver?._id}
                                                            label="Driver"
                                                            onChange={handleSelectDriverChange}
                                                        >
                                                            {props?.vehiclesInfo && props?.vehiclesInfo.map((e) =>
                                                                <MenuItem key={e.driver._id} value={e.driver._id}>{e?.driver?.name}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ margin: "8px 0px 8px 0px" }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={selectedStatus}
                                                            label="Driver"
                                                            onChange={handleSelectedStatus}
                                                            disabled={selectedStatus === 'DELIVER_IN_PROGRESS' || selectedStatus === 'PICKUP_IN_PROGRESS' ? true : false}
                                                        >
                                                            {
                                                                (props?.editJobDetails?.type === "COLLECTION" ? pickupStatusTypes : deliveryStatusTypes).map((e) =>
                                                                    <MenuItem value={e}>{e}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <label style={{
                                                        color: "rgba(0, 0, 0, 0.54)",
                                                        padding: "0",
                                                        fontSize: "1rem",
                                                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                                        fontWeight: "400",
                                                        lineHeight: "1",
                                                        letterSpacing: "0.00938em",
                                                    }}>Service Type</label>
                                                    <FormGroup>
                                                        <FormControlLabel control={<Checkbox checked={checked1} onChange={handleChange1} />} label="Wash/Dry/Fold Service" />
                                                        <FormControlLabel control={<Checkbox checked={checked2} onChange={handleChange2} />} label="Dry Cleaning" />
                                                        <FormControlLabel control={<Checkbox checked={checked3} onChange={handleChange3} />} label="Tailoring & Alterations" />
                                                    </FormGroup>
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Field name="instruction" label="Instructions" component={TextField}
                                                        variant="outlined" fullWidth margin="dense" ></Field>
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <SearchLocation setFieldValue={setFieldValue} setZipCode={setZipCode} dirty={dirty} isValid={isValid} values={{ address: props?.editJobDetails?.address }} />
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "0.4rem" }} >
                                                    <Field name="zipCode" placeholder="Zip Code" label="Zip Code" component={TextField}
                                                        value={zipCode} variant="outlined" fullWidth margin="dense" disabled ></Field>
                                                </Grid>

                                                <DialogActions>
                                                    <Button type="button" onClick={() => {
                                                        setZipCode("")
                                                        props.onClose();
                                                        resetForm();
                                                    }}>Cancel</Button>
                                                    <LoadingButton loading={backendCall} variant="contained"
                                                        disabled={!isValid}
                                                        type="submit" size="large" > Copy </LoadingButton>
                                                </DialogActions>
                                            </Form>
                                        </Grid>
                                    );
                                }}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>)}
        </>

    );
}

export default CopyNewJobDialog;