import { LOGIN, LOGOUT, TOGGLE, ONBOARDING ,TIMEZONE} from "./types";
import axios from "../../utils/lib/axios";

export const login =
  (fullName, role, accessToken, permittedRoutes, id, ownerName, onboardingStatus, check, type, email) =>
  (dispatch) => {

      try {
        axios.interceptors.request.use(
          function (config) {
            config.headers["Authorization"] = "Bearer " + accessToken;
            return config;
          },
          null,
          { synchronous: true }
        );

        // save to the session storage
        sessionStorage.setItem("accessToken", accessToken);
        sessionStorage.setItem("fullName", fullName);
        sessionStorage.setItem("role", role);
        sessionStorage.setItem("routes", JSON.stringify(permittedRoutes));
        sessionStorage.setItem("id", id);
        sessionStorage.setItem("ownerName", ownerName);
        sessionStorage.setItem("onboardingStatus", onboardingStatus);
        sessionStorage.setItem("managerType", type);
        sessionStorage.setItem("email", email);

        dispatch({
          type: LOGIN,
          payload: {
            accessToken,
            fullName,
            role,
            routes: permittedRoutes,
            id,
            ownerName,
            onboardingStatus
          },
        });
      } catch (error) { }
    };

export const logout = () => (dispatch) => {
  axios.interceptors.request.use(
    (config) => {
      // Do something before request is sent

      delete config.headers["Authorization"];
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // remove items from session storage
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("fullName");
  sessionStorage.removeItem("role");
  sessionStorage.removeItem("routes");
  sessionStorage.removeItem("id");
  sessionStorage.removeItem("ownerName");
  sessionStorage.removeItem("onboardingStatus");
  sessionStorage.removeItem("routingLocation");
  sessionStorage.removeItem("timeZone");
  sessionStorage.removeItem("franchiseeTimeZone");
  sessionStorage.removeItem("depoPage");
  sessionStorage.removeItem("serviceTime");
  sessionStorage.removeItem("email");

  // axios.defaults.headers.common.Authorization = null;

  setTimeout(() => {
    dispatch({
      type: LOGOUT,
    });
  }, 70);
};

export const toggleDrawer = () => (dispatch) => {
  dispatch({
    type: TOGGLE,
  });
};

export const logged = (payload) => (dispatch) => {
  dispatch({
    type: ONBOARDING,
    payload
  });
}

export const setFranchiseeTimeZone = (payload) => (dispatch) => {
  dispatch({
    type: TIMEZONE,
    payload
  });
}
