import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, CardActions, CardHeader, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { login, setFranchiseeTimeZone } from "../../store/actions/authActions";
import { connect } from "react-redux";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import styles from "../../utils/styles/Login.module.css";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch } from "react-redux";
import { showNav } from "../../store/actions/headerActions";
import AlertInlineMain from "components/common/AlertInlineMain";
// validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// import FormikField from "../formikField/FormikField";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(1), height: "100%", },
  center: { textAlign: "center", },
  padding: { padding: theme.spacing(3), },

  btn1: { color: "#35bfff", fontWeight: "700", padding: "0.5rem 1rem", textTransform: "none",
    "&:hover": { backgroundColor: "#d6eef8", textShadow: "none", },
  },
}));

let initialValues = {  email: "", password: "", };

let SignUpSchema = Yup.object().shape({
  email: Yup.string().required("Email is required!"),
  password: Yup.string().required("Password is required!"),
});
function Login(props) {
  const axios = useAxiosPrivate()
  const dispatch = useDispatch();
  const classes = useStyles();
  const [backendCall, setBackendCall] = useState(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  let navigate = useNavigate();
  const submit = async (e) => {
    try {
      setBackendCall(true);
      const data = await axios.post("/auth/login/", {
        email: e.email,
        password: e.password,
      });

      const { fullName, role, accessToken, permittedRoutes, id, ownerName,
        onboardingStatus, check, timeZone, franchiseeTimeZone, type, email } = data.data;

      if (timeZone) { sessionStorage.setItem("timeZone", timeZone); }
      if (franchiseeTimeZone) {
        sessionStorage.setItem("franchiseeTimeZone", franchiseeTimeZone);
        dispatch(setFranchiseeTimeZone(franchiseeTimeZone)) }
      if (accessToken) { props.login( fullName, role, accessToken, permittedRoutes, id, ownerName, onboardingStatus, check, type, email); }
      if (role === "franchisor") { navigate("/dashboard"); dispatch(showNav()); }
      if (role === "franchisee" || role === "corporate" || role === "manager") {
        if (onboardingStatus === "completed" && check) {
          if (!franchiseeTimeZone) {
              navigate("/account-management");
            dispatch(showNav());
          } else {
            navigate("/franchisee-dashboard");
            dispatch(showNav());
          }
        }
        if (onboardingStatus === "onboarding" && accessToken) {
          navigate("/confirm", { state: { id: id, role: role } });
          dispatch(showNav());
        }
        if (onboardingStatus === "sales" && accessToken) {
          navigate("/sales-config", { state: { id: id, role: role } });
          dispatch(showNav());
        }
        if (onboardingStatus === "payment" && accessToken && role !== "corporate") {
          navigate("/verification", { state: { id: id, role: role} });
          dispatch(showNav());
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Please check your email and password",
        });
      } else if (error.response.status === 501) {
        setAlert({
          showAlert: true,
          severity: "error",
          message:
            "You are temporary block. Please contact your administrator!",
        });
      } else if (error.response.status === 404) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Your account is disabled, please contact administrator!",
        });
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: error?.response?.data ? error?.response?.data : "Server error!",
        });
      }
    } finally {
      setBackendCall(false);
    }
  };

  useEffect(() => {
    dispatch(showNav());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.root} spacing={1} >
      <div className={styles.Wrapper}> 
        <div className={styles.Right}>
          <div className={styles.Login}>
            <Grid item md={12}>
              <Card className={classes.padding} variant="outlined">
                <CardHeader title="Welcome back!" className={classes.center} ></CardHeader>
                <Formik initialValues={initialValues} onSubmit={submit} validationSchema={SignUpSchema}>
                  {({ dirty, isValid }) => {
                    return (
                      <Form>
                        <CardContent>
                          <Field name="email" label="Email" component={TextField}
                            variant="outlined" fullWidth margin="dense" data-testid="admin_login_email"></Field>
                          <Field name="password" label="Password" component={TextField}
                            variant="outlined" fullWidth margin="dense" type="password" data-testid="admin_login_password"></Field>
                        </CardContent>
                        <CardActions>
                          <LoadingButton loading={backendCall} variant="contained" color="primary"
                            fullWidth disabled={!dirty || !isValid} type="submit" data-testid="admin_login_button"> login </LoadingButton>
                        </CardActions>
                        <Grid container >
                        <Grid container item alignItems="center" justifyContent="center" 
                          lg={12} md={12} sm={12} >
                          <Grid item>
                            <Button className={classes.btn1} onClick={() => navigate("/forgot-password")} >
                              I forgot my password </Button>
                          </Grid>
                          </Grid>
                          </Grid>
                      </Form> ); }}
                </Formik>
              </Card>
            </Grid>
            {alert.showAlert && (<Grid item md={12} style={{ marginTop: '1rem' }}>
              <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid>)}
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default connect(null, { login })(Login);
