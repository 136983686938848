import React from 'react'
import { Alert, Snackbar } from "@mui/material";



export default function AlertMain({ setAlert, alert, anchorOrigin}) {
    
    return (
      
    <Snackbar
    open={alert.showAlert}
    sx={{ marginTop: "7vh" }}
    // autoHideDuration={5000}
    onClose={() =>
      setAlert({
        ...alert,
        showAlert: false,
      })}
        anchorOrigin={anchorOrigin ??{ vertical: "top", horizontal: "right" }} >
      <Alert
        severity={alert.severity}
        onClose={() =>
          setAlert({
            ...alert,
            showAlert: false,
          })
        }
      >
        {alert.message}
      </Alert> 
        </Snackbar>
        
  )
}
