import React, { useEffect, useState } from 'react';
import { Grid, Chip, Button, Checkbox } from '@mui/material';
import DataGrid from '../layouts/DataGrid';
import EditIcon from '@mui/icons-material/Edit';
import EditDialog from './EditDialog';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from 'react-router-dom';
import AlertInlineMain from "components/common/AlertInlineMain";

export default function ServiceTwo() {
  const axios = useAxiosPrivate()
  const role = useSelector((state) => state.auth.role);
  const franchiseeId = useSelector((state) => state.other.selectedId);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [componentType, setComponentType] = useState('create');
  const [status, setStatus] = useState(false);
  const [columns, setColumns] = useState([]);
  const [newData, setNewData] = useState([]);
  let navigate = useNavigate();
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const selecteduserName = sessionStorage.getItem("selectedIduserName");
  const ownerNameforFranchisor = sessionStorage.getItem("ownerNameforFranchisor");
  const userRoleType = sessionStorage.getItem("roleTypeCreate");

  let url = `/franchiseeItems/tailoring/${franchiseeId}`;

  const handleClickOpen = (componentType) => {
    setComponentType(componentType);
    setOpen(true);
  };

  const loadData = async () => {
    try {
      const { data } = await axios.get(url);
      setNewData(data);
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Authorized Access!',
        });
      } else {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Something went wrong!',
        });
      }
    }
  };
  
  const displayItemInShop = async (data) => {
    try {
      await axios.post('/franchiseeItemDisplay', { name: data.name, itemId: data.id, });
      loadData();
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Something went wrong!',
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'error',
          message: 'Something went wrong!',
        });
      }, 3000);
    }
  };

  const franchisorColumns = [
    { field: 'itemId', headerName: 'ITEM ID', flex: 1, minWidth: 150 },
    { field: 'name', headerName: 'ITEM NAME', flex: 1, minWidth: 150,
      valueGetter: (params) => { return `${ params.row.item?.name ? params.row.item?.name : params.row.name }`; }, },
    { field: 'price', headerName: 'PRICING ($)', flex: 1, minWidth: 150 },
    { field: 'PricedBy', headerName: 'Priced By', flex: 1, minWidth: 150,
      renderCell: (params) => {
        return <Chip label={`${params?.row?.priceBy === "Franchisee" ? ownerNameforFranchisor : "Franchisor"}`}
          color={params?.row?.priceBy === "Franchisee" ? "primary" : "secondary"} />
      },
    },
    { field: 'status', headerName: 'STATUS', flex: 1, minWidth: 150,
      renderCell: (params) => { 
        if (params.row.status === true) {
          return ( <Chip label={`Active`} color='primary' variant='outlined' /> );
        } else {
          return (<Chip label={`Inactive`} color='error' variant='outlined' />);
        }
      },
    },
    { field: 'action', headerName: 'EDIT', sortable: false, flex: 1, minWidth: 150, disableExport: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setData(params.row);
          handleClickOpen();
        };
        return ( <Button onClick={onClick}> <EditIcon /> </Button> );
      },
    },
  ];
  const franchiseeColumns = [
    { field: 'itemId', headerName: 'ITEM ID', flex: 1, minWidth: 150 },
    { field: 'name', headerName: 'ITEM NAME', flex: 1, minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.item?.name ? params.row.item?.name : params.row.name}`;
      },
    },
    { field: 'price', headerName: 'PRICING ($)', flex: 1, minWidth: 150 },
    { field: 'displayItem', headerName: 'DISPLAY IN SHOP', flex: 1, minWidth: 150,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          displayItemInShop(params.row);
        };
        if (params.row.displayInShop) { return ( <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }} checked onClick={onClick} /> );
        } else {
          return ( <Checkbox inputProps={{ 'aria-label': 'Checkbox demo' }} onClick={onClick} /> );
        }
      },
    },
    { field: 'action', headerName: 'EDIT', sortable: false, flex: 1, minWidth: 150, disableExport: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setData(params.row);
          handleClickOpen();
        };
        return ( <Button onClick={onClick}> <EditIcon /> </Button> );
      },
    },
  ];

  useEffect(() => {
    if (role === 'franchisor') { setColumns(franchisorColumns); }
    if (role === 'franchisee') { setColumns(franchiseeColumns); }
    // eslint-disable-next-line
  }, [role]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid container item xs={12} md={12} lg={12} justifyContent={'flex-end'} >
          {role === 'franchisor' && !franchiseeId && (
            <Button variant='contained' onClick={() => handleClickOpen('create')} > + Create Item </Button> )}
        </Grid>
        <Grid container item xs={12} md={12} lg={12} ml={2}>
          <IconButton
            onClick={() => {
              sessionStorage.removeItem("selectedIduserName");
              sessionStorage.removeItem("ownerNameforFranchisor");
              navigate('/update-franchisee', {
                state: { id: franchiseeId, update: true },
              })
            }}
            color='primary'
            aria-label='add to shopping cart'
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <DataGrid autoHeight columns={columns} fetchDataUrl={`${url}`} newData={newData} status={status} title={`${selecteduserName} : ${userRoleType === "franchisee" ? `Franchisee` : `Corporate`} Items`} />
        </Grid>
      </Grid>
      {alert.showAlert && ( <Grid item md={12}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid> )}
      <EditDialog franchiseeId={franchiseeId} handleClickOpen={handleClickOpen} setOpen={setOpen} open={open} data={data} setStatus={setStatus}
        newStatus={status} componentType={componentType} role={role} serviceType={'tailoring'}
      />
    </>
  );
}
