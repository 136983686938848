import React, { useState, useEffect } from "react";
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, } from "@mui/material/";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import AlertInlineMain from "components/common/AlertInlineMain";
export default function UnattendedTime({ openEditor, setOpenEditor }) {
  const axios = useAxiosPrivate()
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [disab, setDisabled] = useState(true);
  const [initialValues, setInitialValues] = useState({ startTime: null, endTime: null, });

  const timeSetter = (d) => {
    const hour = d?.split(":")[0];
    if (hour === "24" || hour === "00") { return new Date(2022, 0, 1, 0, d?.split(":")[1]); }
    else { return new Date(2022, 1, 1, hour, d?.split(":")[1]); }
  };

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const convertTo24Hour = (e) => {
    return e?.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      hourCycle: 'h23',
    });
  };

  // endtime should be greater than starttime
  const timeChecker = (startT, endT) => {
    if (endT !== null && startT !== null) {
      if (startT.getTime() >= endT.getTime()) {
        setDisabled(true);
        setAlert({
          showAlert: true,
          severity: "error",
          message: "End time should be greater than start time!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 3000);
      } else {
        setDisabled(false);
      }
    }
  };

  const handleStartTimeChange = (t, setFieldValue) => {
    const time = convertTo24Hour(t);
    timeChecker(t, endTime);
    setStartTime(t);
    setFieldValue("startTime", time);
  };

  const handleEndTimeChange = (t, setFieldValue) => {
    const time = convertTo24Hour(t);
    timeChecker(startTime, t);
    setEndTime(t);
    setFieldValue("endTime", time);
  };

  const hanldleClose = () => {
    setStartTime(null);
    setEndTime(null);
    setAlert({
      showAlert: false,
      severity: "error",
      message: "",
    });
    setOpenEditor(false);
  };

  const onSubmit = async (e, { resetForm }) => {
    const data = { startTime: e.startTime, endTime: e.endTime, };
    if (data.startTime === initialValues.startTime && data.endTime === initialValues.endTime) {
      hanldleClose();
      return;
    }
    try {
      resetForm();
      await axios.put("/user/unattended-times", data);
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Time slot updated!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "",
          message: "",
        });
        setOpenEditor(false);
      }, 1000);
    } catch (err) {
      if (err.response?.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: err.response.data.message,
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Request Failed!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Request Failed!",
          });
        }, 3000);
      }
    }
  };

  const getUnattendedTime = async () => {
    try {
      const { data } = await axios.get("/user/unattended");
      setInitialValues({ startTime: data?.startTime, endTime: data?.endTime, });
      setStartTime(timeSetter(data?.startTime));
      setEndTime(timeSetter(data?.endTime));
      setDisabled(false);
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Data fetched!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "Data fetched!",
          message: "",
        });
      }, 1000);
    } catch (err) {
      if (err.response?.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: err.response.data.message,
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Request Failed!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Request Failed!",
          });
        }, 3000);
      }
    }
  };
  useEffect(() => {
    getUnattendedTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openEditor]);

  const validationSchema = Yup.object().shape({
    startTime: Yup.string().required("Start Time is required!"),
    endTime: Yup.string().required("End Time is required!"),
  });

  const isValidTime = (time) => {
    const parsedDate = new Date(time);
    return !isNaN(parsedDate);
  }

  return (
    <>
      <Dialog fullWidth maxWidth={"md"} open={openEditor} onClose={hanldleClose} >
        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} >
          {({ isValid, resetForm, setFieldValue, }) => {
            return (
              <Form>
                <DialogTitle style={{ cursor: "move" }} id="time-slot-create-id" >
                  Unattended Time Slot
                </DialogTitle>
                <DialogContent>
                  <Grid container item spacing={1} justifyContent={"flex-middle"} >
                    <Grid item mt={2} xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker label="Start Time" name="startTime" value={startTime} closeOnSelect={true} placeholder="Start Time"
                          onChange={(e) => handleStartTimeChange(e, setFieldValue) }
                          renderInput={(params) => <TextField {...params} />} />
                      </LocalizationProvider>
                    </Grid> 
                    <Grid item mt={2} xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker label="End Time" name="endTime" value={endTime} closeOnSelect={true} placeholder="End Time"
                          onChange={(e) => handleEndTimeChange(e, setFieldValue) }
                          renderInput={(params) => <TextField {...params} />} />
                      </LocalizationProvider>
                    </Grid>
                    {alert.showAlert && ( <Grid item md={12} m={2}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid> )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" color="primary" onClick={onSubmit} type="submit" disabled={(!isValid || disab) || !(isValidTime(startTime) && isValidTime(endTime))} >
                    Save
                  </Button>
                  <Button variant="contained" color="secondary" onClick={hanldleClose} > Close </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
}
