import React, { useState, useEffect } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Grid, TextField, CardHeader, Card } from "@mui/material";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import LinearProgress from "@mui/material/LinearProgress";
import AlertInlineMain from "components/common/AlertInlineMain";
import {styled } from '@mui/material/styles';

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

const StyledDataGrid = styled(DataGrid)(() => ({

  //style delivery
  '& .super-app-theme--DELIVERY-SET_TO_DELIVER': {
    backgroundColor: "#65b3f7",
    '&:hover': {
      backgroundColor: "#419ff2",
    },
  },
  '& .super-app-theme--DELIVERY-DELIVERED': {
    backgroundColor: "#65f796",
    '&:hover': {
      backgroundColor: "#41f27d",
    },
  },
  '& .super-app-theme--DELIVERY-DELIVERY_CANCELED': {
    backgroundColor: "#f76565",
    '&:hover': {
      backgroundColor: "#f24141",
    },
  },

//style collection
  '& .super-app-theme--COLLECTION-SET_TO_PICKUP': {
    backgroundColor: "#65b3f7",
    '&:hover': {
      backgroundColor: "#419ff2",
    },
  },
  '& .super-app-theme--COLLECTION-PICKED_UP': {
    backgroundColor: "#65f796",
    '&:hover': {
      backgroundColor: "#41f27d",
    },
  },
  '& .super-app-theme--COLLECTION-PICKUP_CANCELED': {
    backgroundColor: "#f76565",
    '&:hover': {
      backgroundColor: "#f24141",
    },
  },

}));


export default function CustomSortIcons({
  columns,
  fetchDataUrl,
  status,
  title,
  setCircularBarData,
  filterModel,
  editJobDialogOpen,
  addJobDialogOpen,
  isCopyJob
}) {

  const axios = useAxiosPrivate()
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);


  const [alert, setAlert] = useState({
    // eslint-disable-line
    showAlert: false,
    severity: "success",
    message: "",
  });

  const onSearch = (e) => {
    const text = String(e.target.value).toLowerCase();
    setSearchText(text);

    if (text) {
      // eslint-disable-next-line
      const result = rows.filter((item) => {
        const str = JSON.stringify(item).toLowerCase();

        if (str.search(text) >= 0) return item;
      });
      // setPage(0);
      setFilteredItems(result);
    } else {
      setFilteredItems(rows);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, fetchDataUrl, editJobDialogOpen, addJobDialogOpen,isCopyJob]);



  const loadData = async () => {
    try {
      setLoading(true);
      setRows([]);
      const { data } = await axios.get(fetchDataUrl);
      if (data) {
        setCircularBarData(data?.infoValuesObj)
        setFilteredItems(data?.jobs);
        setRows(data?.jobs);
      } else {
        setFilteredItems([]);
        setRows([]);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        if (error.response?.data.message) {
          setAlert({
            showAlert: true,
            severity: "error",
            message: `${error.response.data.message}`,
          });
          setTimeout(
            () =>
              setAlert({ showAlert: false, severity: "error", message: "" }),
            3000
          );
        } else {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Authorized Access!",
          });
          setTimeout(
            () =>
              setAlert({ showAlert: false, severity: "error", message: "" }),
            3000
          );
        }
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Something went wrong!",
        });
        setTimeout(
          () => setAlert({ showAlert: false, severity: "error", message: "" }),
          3000
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container >
      <Grid fullWidth item xs={12} sm={12} md={12}>
        <Card>
          <CardHeader title={`${title}`} />
          <Grid container spacing={2}>
            {" "}
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                label="Search"
                fullWidth
                variant="outlined"
                style={{ margin: "1rem" }}
                onChange={onSearch}
                value={searchText}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            style={{ height: "60vh", margin: "1rem", padding: 0 }}
          >
            <StyledDataGrid
              columns={columns}
              disableSelectionOnClick={true}
              disableColumnSelector={true}
              loading={loading}
              disableDensitySelector={true}
              rows={filteredItems}
              filterModel={filterModel}
              getRowClassName={(params) => `super-app-theme--${params.row.type}-${params.row.status}`}
              components={{
                Toolbar: GridToolbar,
                ColumnSortedDescendingIcon: SortedDescendingIcon,
                ColumnSortedAscendingIcon: SortedAscendingIcon,
                LoadingOverlay: LinearProgress,
              }}
              initialState={{
                filter: {
                  filterModel: {
                    items: [
                      {
                        columnField: "price",
                        operatorValue: ">",
                        value: "2.5",
                      },
                    ],
                  },
                },
              }}
            />
          </Grid>
          {alert.showAlert && (
            <Grid item md={12} m={2}>
              <AlertInlineMain setAlert={setAlert} alert={alert} />
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}
