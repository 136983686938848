import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Tabs, Tab } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Coupons from './Coupons';
import Promocodes from './Promocodes';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Customers = () => {
  const location = useLocation();
  const [value, setValue] = useState(0);
  const z = useSelector((state) => state?.auth?.role);
  const [role, setRole] = useState(z);

  useEffect(() => {
    setRole(z);
  }, [z]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (location.state?.value) {
      setValue(location.state?.value);
    } else {
      setValue(0);
    }
  }, [location.state?.value]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          {role === 'franchisor' && <Tab label='REWARDS' {...a11yProps(0)} />}
          {(role === 'franchisee' || role === 'manager' || role === 'corporate') && (
            <Tab label='PROMOCODES' {...a11yProps(1)} />
          )}
        </Tabs>
      </Box>
      {value === 0 && role === 'franchisor' ? (
        <TabPanel value={value} index={0}>
          <Coupons />
        </TabPanel>
      ) : value === 0 && (role === 'franchisee' || role === 'manager' || role === 'corporate') ? (
        <TabPanel value={value} index={0}>
          <Promocodes />
        </TabPanel>
      ) : null}
    </Box>
  );
};

export default Customers;
