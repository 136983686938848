import { useState, useEffect } from "react";
import * as Yup from "yup";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { setJobs } from "../../../store/actions/RouteAction";
import moment from "moment-timezone";

const undefinedValueChecker = (value) => value === undefined ? 0 : Number(value);

export default function useOrderViewFunctions({ editData, setStatus, status, open, setOpen, setUpdateOrders }) {

  const validationSchema = Yup.object().shape({
    reason: Yup.string().required("Reason is required"),
    extra: Yup.string().required("Fees is required"),

  });
  // add one day for validation (can't able to set today date)
  const timeZone = sessionStorage.getItem("franchiseeTimeZone") ? sessionStorage.getItem("franchiseeTimeZone") : Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDate = moment().tz(timeZone).format("YYYY-MM-DD");
  const todayDate = moment(currentDate).add(1, 'day').format("YYYY-MM-DD");

  const axios = useAxiosPrivate();
  let dispatch = useDispatch();
  const [extra, setExtra] = useState({ extra: "", reason: "", extraWeight: "", });
  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });
  const [extraList, setExtraList] = useState([]);
  const [showItems, setShowItems] = useState([]);
  const [subTotal, setSubTotal] = useState(0);


  const [exChargedisable, setexChargedisable] = useState(true);
  const [exWeightdisable, setexWeightdisable] = useState(true);
  const [exChargeFeesdisable, setexChargeFeesdisable] = useState(true);
  const [exChargeReasondisable, setexChargeReasonFeesdisable] = useState(true);

  const [waitPDdate, setWaitPDdate] = useState(false);
  const [pickUpDateValue, setpickUpDateValue] = useState(null);
  const [deliveryDateValue, setdeliveryDateValue] = useState(null);
  const [specialCareDate, setSpecialCareDate] = useState(null);
  //validate dates
  const [oldPickUpDateValue, setOldPickUpDateValue] = useState(null);
  const [oldDeliveryDateValue, setOldDeliveryDateValue] = useState(null);
  const [oldSpecialCareDate, setOldSpecialCareDate] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setStatus(!status);
    setExtraList([]);
    setSubTotal(0);
    setShowItems([]);
    setExtra({ extra: "", reason: "", extraWeight: "", });
  };

  const calculateInitialSubTotal = () => {
    let specialCarePrice = 0;
    if (editData.specialDate) {
      // eslint-disable-next-line
      specialCarePrice = undefinedValueChecker(editData?.specialCarePrice);
    }
    let total = 0 + undefinedValueChecker(editData?.servicePrice - (((
      editData?.orderType === "8" || editData?.orderType === "6" ||
      editData?.orderType === "10" || editData?.orderType === "4" ||
      editData?.orderType === "2" || editData?.orderType === "3")
      && editData?.specialCarePrice) ? editData?.specialCarePrice : 0)) +
      undefinedValueChecker(editData?.specialCarePrice === undefined ? 0 : Number(editData?.specialCarePrice)) +
      undefinedValueChecker(editData?.processingFee) - 0 + undefinedValueChecker(editData?.extra) - undefinedValueChecker(editData?.discount);
    editData?.extraCharges?.forEach((ele) => { total += undefinedValueChecker(ele?.extra); });
    setSubTotal(total);
  };

  useEffect(() => {
    setExtraList(editData?.extraCharges);
    calculateInitialSubTotal();
    setExtra({ extra: "", reason: "", extraWeight: (extra?.extraWeight !== "" && extra?.extraWeight >= 0) ? extra?.extraWeight : editData?.extraDraftWeight });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const handleChange = (e) => {
    const regex = e.target.value.match(/^[0-9]*(\.[0-9]{0,2})?$/)
    if (e.target.name === "extraWeight") {

      if (e.target.value === ""
        || e.target.value <= 0
        || !regex
      ) {
        setexWeightdisable(true);
      } else {
        setexWeightdisable(false);
      }
      setExtra((pre) => ({ ...pre, extraWeight: e.target.value, }));
    } else if (e.target.name === "extra") {

      if (e.target.value === "" || e.target.value <= 0 || !regex) {
        setexChargedisable(true);
        setexChargeFeesdisable(true);
      } else {
        setexChargedisable(false);
        setexChargeFeesdisable(false);
      }

      setExtra((pre) => ({ ...pre, extra: e.target.value, }));
    } else if (e.target.name === "reason") {
      if (e.target.value !== "") {
        setexChargeReasonFeesdisable(false)
      } else {
        setexChargeReasonFeesdisable(true)
      }
      setExtra((pre) => ({ ...pre, reason: e.target.value, }));
    }
  };

  const addShowItems = (e) => {
    if (e === "dryClean") { setShowItems(editData?.dryItem); }
    else if (e === "alteration") { setShowItems(editData?.tailItem); }
    else { let temp = [{ itemId: { price: editData.servicePrice, }, },]; setShowItems(temp); }
  };


  //change Pickup and delivery date
  const cngPickupDelivery = async () => {
    setWaitPDdate(true);
    let dateData = {};
    if (editData?.isManualOrder === true) {
      if (!editData?.pickupDate) {
        dateData = { deliveryDate: deliveryDateValue }

      } else if (!editData?.diliverDate) {
        dateData = { pickupDate: pickUpDateValue }
      }
    } else {
      dateData = { pickupDate: pickUpDateValue, deliveryDate: deliveryDateValue, specialDate: specialCareDate }
    }
    try {
      const { data, status } = await axios.put(`/order/updatePickupDelivery/${editData?._id ?? editData?.id}`, dateData);
      // setWaitPDdate(false);  
      if (status === 200) { setAlert({ showAlert: true, severity: "success", message: data?.message, }); }
      setTimeout(() => { setAlert({ showAlert: false, severity: "success", message: "Date updated Successfully!", }); }, 3000);
      setUpdateOrders && setUpdateOrders(data);
    } catch (error) {
      setAlert({ showAlert: true, severity: "error", message: "Date update Fail!", });
      setTimeout(() => { setAlert({ showAlert: false, severity: "success", message: "Date update Fail!", }); }, 3000);
      // setWaitPDdate(false);
    } finally { dispatch(setJobs()); }
  };

  useEffect(() => {
    setWaitPDdate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickUpDateValue, deliveryDateValue, specialCareDate])

  //status change
  const changeStatus = async (status) => {
    try {
      const data = { orderId: editData._id, orderStatus: status, };
      await axios.put("orders/update-status", data);
      handleClose();
      setAlert({ showAlert: true, severity: "success", message: "Status change success!", });
      setTimeout(() => { setAlert({ showAlert: false, severity: "success", message: "Status change success!", }); }, 3000);
    } catch (error) {
      setAlert({ showAlert: true, severity: "error", message: "Status change Fail!", });
      setTimeout(() => { setAlert({ showAlert: false, severity: "error", message: "Status change Fail!", }); }, 3000);
    }
  };


  //month calculator
  const calMonth = (val) => {
    switch (val) {
      case 'January':
        return '01';
      case 'February':
        return '02';
      case 'March':
        return '03';
      case 'April':
        return '04';
      case 'May':
        return '05';
      case 'June':
        return '06';
      case 'July':
        return '07';
      case 'August':
        return '08';
      case 'September':
        return '09';
      case 'October':
        return '10';
      case 'November':
        return '11';
      case 'December':
        return '12';
      default:
        return null;
    }
  }

  //full date mapper
  const dateMapper = (mapDate) => {
    if (mapDate) {
      let r = mapDate?.split(",");
      let month = r[1]?.trim().split(" ")[0];
      let date = r[1]?.trim().split(" ")[1];
      let year = r[2]?.trim();
      return `${year}-${calMonth(month)}-${date >= 10 ? date : `0` + date}`
    }
  }

  useEffect(() => {
    if (editData?.isManualOrder) {
      if (editData?.pickupDate) {
        let pickupDate = dateMapper(editData?.pickupDate)
        setpickUpDateValue(pickupDate);
        //for save button validation
        setOldPickUpDateValue(pickupDate);
      } if (editData?.diliverDate) {
        let diliverDate = dateMapper(editData?.diliverDate)
        setdeliveryDateValue(diliverDate);
        //for save button validation
        setOldDeliveryDateValue(diliverDate);
      }
    }
    else if (editData?.pickupDate && editData?.diliverDate) {
      let pickupDate = dateMapper(editData?.pickupDate)
      let diliverDate = dateMapper(editData?.diliverDate)
      let specialDate = dateMapper(editData?.specialDate)
      setpickUpDateValue(pickupDate);
      setdeliveryDateValue(diliverDate);
      setSpecialCareDate(specialDate);
      //for save button validation
      setOldPickUpDateValue(pickupDate)
      setOldDeliveryDateValue(diliverDate)
      setOldSpecialCareDate(specialDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData])

  return {
    todayDate, alert, extraList, handleChange,
    changeStatus, validationSchema, handleClose, extra, setpickUpDateValue, setdeliveryDateValue,
    setSpecialCareDate, cngPickupDelivery, waitPDdate, pickUpDateValue, oldPickUpDateValue, deliveryDateValue,
    oldDeliveryDateValue, specialCareDate, oldSpecialCareDate, addShowItems, showItems,
    exChargedisable, exWeightdisable, subTotal, setAlert, undefinedValueChecker, setExtraList, setSubTotal,
    setexChargedisable, setexWeightdisable, setExtra, exChargeFeesdisable, exChargeReasondisable,
  }
}
