import React from 'react'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@material-ui/core';

export default function DialogAlertMain({
  setAlert,
  alert,
  onClose,
  setZipCode,
  type,
}) {


  function alertHandleClick() {
    onClose();
    setZipCode('');
    setAlert({
      ...alert,
      showAlert: false,
    });
  }


  return (

    <Dialog
      open={true}
      onClose={alertHandleClick}
      fullWidth={'sm'}
      maxWidth={'sm'}
    >
      <DialogTitle id='alert-dialog-title'
        sx={{
          textTransform: "uppercase",
          color: alert?.severity === "success" ? "green" : "red"
        }}>{alert?.severity}</DialogTitle>

      <DialogContent>
        <DialogContentText
          sx={{
            fontSize: "1rem",
          }}
          id='alert-dialog-description'>
          {alert.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {type === "addUser" ? (<Button
          onClick={alertHandleClick}
          variant='contained'
          // color={alert?.severity === "success" ? 'success' : 'error'}
          style={{
            backgroundColor: alert?.severity === "success" ? "#2e7d32" : "#d32f2f",
            color: "white"
          }}
        >
          Ok
        </Button>) : (
          <Button
            onClick={() => {
              // onClose();
              // setZipCode("");
              setAlert({
                ...alert,
                showAlert: false,
              });
            }}
            variant='contained'
            // color={alert?.severity === "success" ? 'success' : 'error'}
            style={{
              backgroundColor: alert?.severity === "success" ? "#2e7d32" : "#d32f2f",
              color: "white"
            }}
          >
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>

  )
}
