import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material/";
import RouteNav from "components/Depot/RouteNav";

import Header from "components/routing/Header"; 
import { hideNav } from "store/actions/headerActions";
import { useDispatch, useSelector } from "react-redux";
import DetailMain from "components/Depot/DetailMain";
import CreateDepot from "components/Depot/Depo/CreateDepo";
import CreateVehicle from "components/Depot/Vehicle/CreateVehicle";
import CreateDriver from "components/Depot/Driver/CreateDriver";
import CreateAssignment from "components/Depot/Assignment/Assignment";

export default function Main() {
  let dispatch = useDispatch(); 

  const state = useSelector((state) => state.route);

  const pageNo = state.depoPage;
  const open = state.open;

  //lg
  const [column1, setColumn1] = useState(4);
  const [column2, setColumn2] = useState(0);
  const [column3, setColumn3] = useState(8);

  //md
  const [mdColumn1, mdSetColumn1] = useState(4);
  const [mdColumn2, mdSetColumn2] = useState(0);
  const [mdColumn3, mdSetColumn3] = useState(8);

//sm
  const [smColumn1, smSetColumn1] = useState(4);
  const [smColumn2, smSetColumn2] = useState(0);
  const [smColumn3, smSetColumn3] = useState(8);

  useEffect(() => {

  //lg
    if (open) {
      setColumn1(6);
      setColumn2(6);
      setColumn3(6);
    } else {
      setColumn1(4);
      setColumn2(12);
      setColumn3(8);
    }

//md
    if (open) {
      mdSetColumn1(12);
      mdSetColumn2(6);
      mdSetColumn3(12);
    } else {
      mdSetColumn1(12);
      mdSetColumn2(12);
      mdSetColumn3(12);
    }

//smDown
if (open) {
  smSetColumn1(12);
  smSetColumn2(12);
  smSetColumn3(12);
} else {
  smSetColumn1(12);
  smSetColumn2(12);
  smSetColumn3(12);
}    
  }, [pageNo, open]);

  useEffect(() => {
    dispatch(hideNav());
    //eslint-disable-next-line
  }, []);

 
  return (
    <Grid
      container
      sx={{
        paddingTop: "0px",
        marginTop: "0px",
      }} >

      <Grid container item>
        <Grid item

          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <Header navFrom={"setup"} />
        </Grid>
      </Grid>

      <Grid container item
        lg={column1}
        md={mdColumn1}
        sm={smColumn1}
        xs={smColumn1}
        justifyContent="center"
      >
        <Grid item
          lg={column2}
          md={mdColumn2}
          sm={smColumn2}
          xs={smColumn2}
        >
          <DetailMain />
        </Grid>

        {open && (
          <Grid item
            lg={pageNo === 3 ? "7" : column2}
            md={pageNo === 3 ? "7" :mdColumn2}
            sm={pageNo === 3 ? "8" :smColumn2}
            xs={pageNo === 3 ? "12" :smColumn2}
          >
            {pageNo === 0 && <CreateDepot />}
            {pageNo === 1 && <CreateVehicle />}
            {pageNo === 2 && <CreateDriver />}
            {pageNo === 3 && <CreateAssignment />}
          </Grid>
        )}

      </Grid>

      <Grid container item
        lg={column3}
        md={mdColumn3}
        sm={smColumn3}
        xs={smColumn3}
      >
        <Grid item
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <RouteNav />
        </Grid>
      </Grid>
    </Grid>
  );
}
