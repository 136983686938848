import React, { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AlertInlineMain from "components/common/AlertInlineMain";
import { Grid, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Form, Formik, Field } from "formik";
import { Button } from '@mui/material';
import { TextField as TF } from "formik-material-ui";
import { orderStatusGetter } from "utils/commonFunctions";

export default function SearchJobsForm({ filteredItems, setFilteredItems }) {
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    jobType: "",
    date: "",
    jobStatus: "",
    driver: "",
    firstName: "",
    lastName: "",
  });

  const jobTypeOptions = [
    "COLLECTION",
    "DELIVERY"
  ]

  const jobStatusOptions = [
    'Placing order',
    'Set to pickup',
    'Picked up',
    'Processing',
    'Pickup cancelled',
    'Set to deliver',
    'Delivered',
    'Delivery cancelled',
    'Special set to deliver',
    'Special delivered',
    'Special delivery cancelled',
  ];

  const getFilteredData = (data) => {

    let filteredData = filteredItems;

    if (data?.jobType)
      filteredData = filteredData.filter(item => item.type.toLowerCase() == data.jobType.toLowerCase());
    if (data?.jobStatus)
      filteredData = filteredData.filter(item => {
        console.log(orderStatusGetter(item), data.jobStatus)
        return orderStatusGetter(item) == data.jobStatus
      }
      );
    if (data?.driver)
      filteredData = filteredData.filter(item => item.driverDetail.name.toLowerCase() == data.driver.toLowerCase());
    if (data?.firstName)
      filteredData = filteredData.filter(item => item.customerDetail.firstName.toLowerCase() == data.firstName.toLowerCase());
    if (data?.lastName)
      filteredData = filteredData.filter(item => item.customerDetail.lastName.toLowerCase() == data.lastName.toLowerCase());

    if (data?.date) {
      const searchDate = new Date(data?.date);
      filteredData = filteredData.filter(item => {
        return item.type == "DELIVERY" ?
          new Date(item.orderDetail?.diliverDate).getTime() == searchDate.getTime() :
          new Date(item.orderDetail?.pickupDate).getTime() == searchDate.getTime()
      })
    }

    return filteredData
  }

  const onSubmit = async (e, { resetForm }) => {
    try {

      console.log(e);
      setFilteredItems(getFilteredData(e))

    } catch (error) {
      console.error(error)
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Create failed!",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "Create failed!",
        });
      }, 3000);
    }
  };

  // Job type

  // Date

  // Job Status

  // Driver

  // Customer First Name (Check for manual orders)

  // Customer Last Name

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, isValid, setFieldValue, resetForm }) => {
          return (
            <Form noValidate autoComplete="off">
              <Grid container p={2}
                rowSpacing={2}
              >
                <Grid item
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Date"
                      value={values?.date}
                      onChange={(newValue) => {
                        newValue.setHours(0, 0, 0)
                        setFieldValue("date", newValue)
                      }}
                      renderInput={(params) => <Field component={TF} {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                >
                  <Field name="driver" component={TF} variant="outlined" placeholder="Enter Driver" label="Driver" />
                </Grid>

                <Grid item
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                >
                  <Field name="firstName" component={TF} variant="outlined" placeholder="Enter Cutomer First Name" label="Customer First Name" />
                </Grid>

                <Grid item
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                >
                  <Field name="lastName" component={TF} variant="outlined" placeholder="Enter Cutomer Last Name" label="Customer Last Name" />
                </Grid>

                <Grid item
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                  pr={2}
                >
                  <Field
                    name="jobType"
                    as={FormControl}
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel>Job Type</InputLabel>
                    <Select
                      value={values.jobType}
                      onChange={(e) => setFieldValue("jobType", e.target.value)}
                    >
                      {jobTypeOptions.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </Field>
                </Grid>


                <Grid item
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                  pr={2}
                >
                  <Field
                    name="jobStatus"
                    as={FormControl}
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel>Job Status</InputLabel>
                    <Select
                      value={values.jobStatus}
                      onChange={(e) => setFieldValue("jobStatus", e.target.value)}
                    >
                      {jobStatusOptions.map((status) => (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </Field>
                </Grid>

                <Grid container py={2}
                  rowSpacing={2}>
                  <Grid item pr={2} >
                    <Button type="submit" variant="contained">Search</Button>
                  </Grid>

                  <Grid item >
                    <Button onClick={() => {
                      resetForm();
                      setFilteredItems(filteredItems);
                      setInitialValues({
                        jobType: "",
                        date: "",
                        jobStatus: "",
                        driver: "",
                        firstName: "",
                        lastName: "",
                      })
                    }
                    } variant="outlined"  >Reset</Button>
                  </Grid>
                </Grid>

              </Grid>


              {alert.showAlert && (
                <Grid item md={12} m={2}>
                  <AlertInlineMain setAlert={setAlert} alert={alert} />
                </Grid>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
