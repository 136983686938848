import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";

const useStyles = makeStyles((theme) => ({
    titleName:{
        '@media (max-width: 900px)': {
            marginTop: '20px',  
        },
    },
  container: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center', 
    alignItems: 'center', 
    width: '100%',
  },
  titletxt: {
    marginTop: '10px',
    textAlign: 'left',
    '@media (max-width: 460px)': {
        fontSize: '14px',  
    },
  },
  valuestxt: {
    marginTop: '10px',
    fontWeight: 'Bold',
    '@media (max-width: 460px)': {
        fontSize: '14px',  
    },
  },
  spacer: {
    marginTop: '40px',
  },
  root: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ccc',
    borderRadius:'5px',
   paddingBottom:'30px',
   paddingTop:'30px',
   
  },
  rootContainer: {
    display: 'flex',
    width: '100%',
    marginLeft:'40px',
    '@media (max-width: 550px)': {
        marginLeft: '5px',
      },
  },
  header:{
    marginLeft:'40px',
    marginBottom:'20px',
    fontWeight: '500',
    '@media (max-width: 550px)': {
        marginLeft: '5px',
      },
  },
  
}));

export default function SalesOverview({salesDetails}) {
  const classes = useStyles();
  const axios = useAxiosPrivate();

  const [values, setValues] = useState({});

  useEffect(() => {
    getData()
  }, []);


  const getData = async ()=>{
    try{
        const { data } = await axios.get(
            '/reports/orderSummary'
          );
          console.log(data)
          setValues(data);
    }catch(e){
        console.log(e)
    }
  }

  const getYearFromDate = (dateString) => {
  // Replace ordinal indicator 'st', 'nd', 'rd', or 'th' to make it a valid date string
  const formattedDateString = dateString.replace(/(\d)(st|nd|rd|th)/, '$1');

  // Create a Date object from the formatted string
  const dateObject = new Date(formattedDateString);

  // Check if the Date object is valid
  if (isNaN(dateObject)) {
    return "";
  }

  // Extract the year from the Date object
  const year = dateObject.getFullYear();

  return year;
  }

  return (
    <div className={classes.root}>
             <h1 className={classes.header}>
          Sales Overview ({values?.totalSalesCount ? values?.totalSalesCount : 0 })
        </h1>
      <div className={classes.rootContainer}>
   
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <h2 >Sales Information</h2>
            <Grid container>
              <Box className={classes.container}>
                <Grid item xs={9}>
                  { salesDetails?.dateRange  &&
                <div className={classes.titletxt}>Pay Per Use Revenue from {salesDetails?.dateRange ? salesDetails?.dateRange : 'Loading...'}</div>
              }
                  <div className={classes.titletxt}>Pay Per Use Revenue in {values?.currentYear ? getYearFromDate(values?.currentYear) : 'Loading...'}</div>
                  <div className={classes.titletxt}>Pay Per Use Revenue from {values?.currentMonth ? values?.currentMonth : 'Loading...'}</div>
                  <div className={classes.titletxt}>Pay Per Use Revenue Today</div>
                  <div className={classes.titletxt}>Pay Per Use Revenue Last Month</div>
                  <div className={classes.titletxt}>Pay Per Use Total Revenue</div>
                </Grid>
                <Grid item xs={3}>
                {salesDetails?.dateRange &&
                  <div className={classes.valuestxt}>$ {salesDetails?.ordersCount ? salesDetails?.ordersCount :0}</div>

                } 
                  <div className={classes.valuestxt}>$ {values?.totalYearToDate ? values?.totalYearToDate : 0}</div>
                  <div className={classes.valuestxt}>$ {values?.totalMonthToDate ? values?.totalMonthToDate : 0}</div>
                  <div className={classes.valuestxt}>$ {values?.totalDayToDate ? values?.totalDayToDate : 0}</div>
                  <div className={classes.valuestxt}>$ {values?.totalLastMonth ? values?.totalLastMonth: 0}</div>
                  <div className={classes.valuestxt}>$ {values?.totalAll ? values?.totalAll : 0}</div>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <h2 className={classes.titleName} >User Information</h2>
            <Grid container>
              <Box className={classes.container}>
                <Grid item xs={9}>
                { salesDetails?.dateRange &&
                  <div className={classes.titletxt}>New Users from {salesDetails?.dateRange ? salesDetails?.dateRange : 'Loading...'}</div>

                } 
                  <div className={classes.titletxt}>New Users in {values?.currentYear ? getYearFromDate(values?.currentYear) : 'Loading...'}</div>
                  <div className={classes.titletxt}>New Users from {values?.currentMonth ? values?.currentMonth : 'Loading...'}</div>
                  <div className={classes.titletxt}>New Users Today</div>
                  <div className={classes.titletxt}>New Users Last Month</div>
                  <div className={classes.titletxt}>Total Users</div>
                  <div className={classes.spacer}></div>
                  <div className={classes.titletxt}>Pickups Today</div>
                  <div className={classes.titletxt}>Pickups Tomorrow</div>
                  <div className={classes.titletxt}>Pickups Yesterday</div>
                </Grid>
                <Grid item xs={3}>
                { salesDetails?.dateRange &&
                <div className={classes.valuestxt}>{salesDetails?.customers ? salesDetails?.customers : 0}</div>
              } 
                  <div className={classes.valuestxt}>{values?.customers?.yearToDate ? values?.customers?.yearToDate : 0}</div>
                  <div className={classes.valuestxt}>{values?.customers?.monthToDate ? values?.customers?.monthToDate : 0}</div>
                  <div className={classes.valuestxt}>{values?.customers?.today ? values?.customers?.today : 0}</div>
                  <div className={classes.valuestxt}>{values?.customers?.lastMonth ? values?.customers?.lastMonth : 0}</div>
                  <div className={classes.valuestxt}>{values?.customers?.total ? values?.customers?.total  : 0}</div>
                  <div className={classes.spacer}></div>
                  <div className={classes.valuestxt}>{values?.pickupOrders?.pickupsToday ? values?.pickupOrders?.pickupsToday : 0}</div>
                  <div className={classes.valuestxt}>{values?.pickupOrders?.pickupsTomorrow ? values?.pickupOrders?.pickupsTomorrow : 0}</div>
                  <div className={classes.valuestxt}>{values?.pickupOrders?.pickupsYesterday ? values?.pickupOrders?.pickupsYesterday : 0}</div>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
