// value === 'gg' ? ' - Go Green' : ' - Agrowcell'
import {
  SET_OPEN,
  SET_VAL,
  SET_DEPOT,
  SET_VEHICLE,
  SET_ONE_VEHICLE,
  REMOVE_ONE_VEHICLE,
  SET_DRIVER,
  SET_ONE_DRIVER,
  REMOVE_ONE_DRIVER,
  SET_ASSIGNMENT,
  SET_JOBS,
  SET_TIMELINE_SELECTED,
  SET_SERVICE_TIME,
  PROGRESS_DATA_GRID_FILTER,
  SET_CORPORATE_CUSTOMERS,
} from "../actions/types";

const initialState = {
  value: sessionStorage.getItem("depoPage") || 0,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_VAL:
      return {
        ...state,
        ...action.payload,
      };

    case SET_OPEN:
      return {
        ...state,
        ...action.payload,
      };

    case SET_DEPOT:
      return {
        ...state,
        ...action.payload,
      };

    case SET_VEHICLE:
      return {
        ...state,
        ...action.payload,
      };

    case SET_ONE_VEHICLE:
      return {
        ...state,
        ...action.payload,
      };

    case REMOVE_ONE_VEHICLE:
      return {
        ...state,
        ...action.payload,
      };

    case SET_DRIVER:
      return {
        ...state,
        ...action.payload,
      };

    case SET_ONE_DRIVER:
      return {
        ...state,
        ...action.payload,
      };

    case REMOVE_ONE_DRIVER:
      return {
        ...state,
        ...action.payload,
      };

    case SET_JOBS:
      return {
        ...state,
        ...action.payload,
      }
    
      case SET_SERVICE_TIME:
      return {
        ...state,
        ...action.payload,
      }

    case SET_ASSIGNMENT:
      return {
        ...state,
        ...action.payload,
      };

    case SET_TIMELINE_SELECTED:
      return {
        ...state,
        ...action.payload,
      };
    case PROGRESS_DATA_GRID_FILTER:
      return {
        ...state,
        ...action.payload,
      };
    case SET_CORPORATE_CUSTOMERS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
