import React, { useState } from 'react';
import DataGridTable from 'components/layouts/DataGrid';

import { Grid, Card } from '@mui/material';
import BasicDateRangePicker from 'components/common/BasicDateRangePicker';
import { Typography } from '@material-ui/core';

export default function OrderWeightReport() {

  const [status, setStatus] = useState(false);
  const [value, setValue] = useState(null);

  const columns = [
    {
      field: 'pickupDate', headerName: 'PICKUP DATE', minWidth: 100, flex: 0.2, valueGetter: (params) => {
        return String(params?.row?.pickupDate);
      },
    },
    {
      field: 'processedLbs', headerName: 'PROCESSED WEIGHT(lbs)', minWidth: 100, flex: 0.2,
      valueGetter: (params) => {
        return Number(params?.row?.extraDraftWeight || (params?.row?.isManualOrder ? 0 : 15))?.toFixed(2);
      },
    },
    {
      field: 'firstName',
      headerName: 'FIRST NAME',
      minWidth: 100,
      flex: 0.2,
      valueGetter: (params) => {
        return String(params?.row?.customer?.firstName);
      },
    },
    {
      field: 'lastName', headerName: 'LAST NAME', minWidth: 100, flex: 0.2,
      valueGetter: (params) => {
        return String(params?.row?.customer?.lastName);
      },
    },
    {
      field: 'services', headerName: 'SERVICES', minWidth: 100, flex: 0.2,
      valueGetter: (params) => {
        return String(generateServicesString(params?.row));
      },
    },
  ];

  const generateServicesString = (data) => {

    if (data?.isManualOrder) {
      return "Manual Order"
    } else {
      switch (data?.orderType) {
        case "1": return "Wash/Dry/Fold";
        case "2": return "Dry Cleaning";
        case "3": return "Tailoring";
        case "4": return "Dry Cleaning & Tailoring";
        case "5": return "Wash/Dry/Fold , Tailoring";
        case "6": return "Wash/Dry/Fold , Tailoring";
        case "7": return "Wash/Dry/Fold , Dry Cleaning";
        case "8": return "Wash/Dry/Fold , Dry Cleaning";
        case "9": return "Wash/Dry/Fold , Dry Cleaning, Tailoring";
        case "10": return "Wash/Dry/Fold , Dry Cleaning, Tailoring";
        default: return "-";
      }
    }

  }

  return (
    <Card>
      <Grid container
        columnSpacing={2}
        alignItems="center"
        justifyContent={{
          lg: "left",
          md: "left",
          sm: "center",
          xs: "center",
        }}
      >
        <Grid item
          ml={3}
          mt={1}
          lg={"auto"}
          md={"auto"}
          sm={"auto"}
          xs={"auto"}
        >
          <Typography variant='h4' component='h4' align='right'>
            Order Reports
          </Typography>
        </Grid>

        <Grid item mt={1}
          lg={"auto"}
          md={"auto"}
          sm={"auto"}
          xs={"auto"}
        >
          <BasicDateRangePicker
            value={value}
            setValue={setValue}
            setStatus={setStatus}
            status={status}
          />
        </Grid>

        <Grid item
          lg={12}
          md={12}
          sm={12}
          xs={12}>
          <DataGridTable
            fetchDataUrl={`/orders/completed`}
            columns={columns}
            status={status}
            title=''
            rangeObject={value}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
