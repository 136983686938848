import { ADD_SELECTED_ID, ROLE_TYPE_CREATE } from './types.js';

export const setSelectedID = (id) => (dispatch) => {
  //set to session storage
  sessionStorage.setItem('selectedId', id);
  dispatch({
    type: ADD_SELECTED_ID,
    payload: { selectedId: id },
  });
};

export const setRoleTypeCreate = (id) => (dispatch) => {
  //set to session storage
  sessionStorage.setItem('roleTypeCreate', id);
  dispatch({
    type: ROLE_TYPE_CREATE,
    payload: { roleTypeCreate: id },
  });
};