import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@material-ui/core/styles";
import { FileUploader } from "react-drag-drop-files";

function AcceptMaxFiles({ setImage, image, setSubmited, submited, setAlert }) {
  const fileTypes = ["JPG", "PNG", "GIF", "PDF", "XLS", "XLSX", "DOC", "DOCX" ];
  const [file, setFile] = useState([]);
  const [alertError, setAlertError] = useState(false);

  const handleChange = (file) => {
    const fileArray = Array.from(file)
  setFile(fileArray);
  };

  useEffect(() => {
    if(image.length === 5){
    setAlertError(true)
    }else{
    setAlertError(false)
    setImage((pre)=>[...pre, ...file])
    if(image.length > 0){
    setAlert({
      showAlert: true,
      severity: "success",
      message: "Files upload Success!",
    });
  }
    }
  }, [file]);

  const removeFile = (index) => {
    const updatedFiles = [...image];
    updatedFiles.splice(index, 1);
    setImage(updatedFiles);
    setAlertError(false)
    setAlert({
      showAlert: true,
      severity: "success",
      message: "File removed Success!",
    });
  };

  useEffect(() => {
    setFile([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submited]);

  return (
    <div style={{paddingLeft:"10px"}}>
    <FileUploader handleChange={handleChange} name="file" types={fileTypes} multiple={true} />
    { alertError && <p style={{color:'red', marginTop:"5px"}}>Maximum of 5 Files Allowed</p>}
    <div >
      <h4 style={{paddingTop:"30px"}}>Uploaded Files:</h4>
      <ul>
        {image?.map((uploadedFile, index) => (
          <li key={index} style={{padding:"5px"}}>
            <span>{uploadedFile?.name} - {uploadedFile?.size} bytes</span>
            <span style={{paddingLeft:"5px"}} onClick={() => removeFile(index)} className="remove-file">
                &#x2715;
              </span>
          </li>
        ))}
      </ul>
    </div>
  </div> 
   );
}

export default AcceptMaxFiles;