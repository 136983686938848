import React, { useState } from "react";
import Bar from "components/layouts/AdminBarChart";

import { Grid, Button } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterDialog from "components/dashboard/FilterDialog";

export default function LineChart({dataset}) {
 

  const [selected, setSelected] = useState({});

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} md={12} justifyContent="flex-end">
        <Button variant="contained" onClick={handleClickOpen}>
          {" "}
          <FilterAltIcon />
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        {" "}
        <Bar   selected={selected}/>
      </Grid>

      <FilterDialog
        setOpen={setOpen}
        open={open}
        dataset={dataset}
        setSelected={setSelected}
      />
    </Grid>
  );
}
