import React, { Fragment, useEffect, useMemo, useState, useRef } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
// import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import { useStyles } from "utils/styles/styles";
import {
  TextField, Popper, Grid, Typography,
  // InputAdornment,
} from "@material-ui/core";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  // const script = document.createElement("script"); // commmented this part since its not used in any other place and didnt make a difference to the overall behavior
  // script.setAttribute("async", "");
  // script.setAttribute("id", id);
  // script.src = src;
  // position.appendChild(script);
}

const autocompleteService = { current: null };
export default function SearchLocation({ setFieldValue, setInitialValues, values, }) {
  const classes = useStyles();
  const [value, setValue] = useState({ description: "", });
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_KEY}&libraries=places`,
        document.querySelector("head"), "google-maps");
    }
    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const getCoordinates = (placeId) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId }, (results, status) => {
      if (status === "OK") {
        const location = results[0].geometry.location;
        setFieldValue("location.latitude", location.lat());
        setFieldValue("location.longitude", location.lng());
      }
    });
  };

  useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const PopperMy = function (props) {
    return <Popper {...props} placement="none" />;
  };

  return (
    <Fragment>
      <Autocomplete PopperComponent={PopperMy} size="small" id="google-map-demo"
        getOptionLabel={(option) => typeof option === "string" ? option : option.description }
        filterOptions={(x) => x} options={options} autoComplete includeInputInList filterSelectedOptions
        defaultValue={{ description: "Homagama", }}
        value={value}
        onChange={(event, newValue) => {
          if (newValue) {
            getCoordinates(newValue.place_id);
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            setInitialValues((pre) => ({ ...values, address: newValue.description, }));
          } else {
            setValue(null);
            setFieldValue("location.latitude", null);
            setFieldValue("location.longitude", null);
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <div>
            <TextField {...params} name="address" variant="outlined" placeholder="Find Your Location.."
              InputLabelProps={{ shrink: false }}
              InputProps={{ ...params.InputProps,
              // startAdornment: ( <InputAdornment style={{ marginRight: "2rem" }} position="start" >
              //       <GpsFixedIcon className={classes.gpsIcon} />
              //     </InputAdornment>
              //   ),
              }}
              fullWidth
            />
          </div>
        )}
        renderOption={(option) => {
          let parts = [];
          if (option.structured_formatting) {
            const matches = option.structured_formatting.main_text_matched_substrings;
            parts = parse( option.structured_formatting.main_text, matches.map((match) => [ match.offset, match.offset + match.length,]) );
          }
          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }} > {part.text} </span> ))}
                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting && option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </Fragment>
  );
}
