import React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';


export default function CommonButton({ variant, text, action, color }) {
  const marginsSm = useMediaQuery("(max-width: 899px)");
  return (
    <>
      {marginsSm ? (
        <IconButton
          color={color}
          aria-label='create label'
          onClick={action}
          sx={{
            backgroundColor: 'green',
            color: 'white',
            position: 'fixed',
            right: 10,
            bottom: 8,
            zIndex: 1,
          }}
        >
          <AddIcon />
        </IconButton>
      ) : (
        <Button
          variant={variant}
          startIcon={<AddIcon />}
          sx={{ marginBottom: '1rem', marginTop: '0.5rem' }}
          onClick={action}
          color={color}
        >
          {text.toUpperCase()}
        </Button>
      )}
    </>
  );
}
