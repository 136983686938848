import React, { useState, useEffect, useMemo } from 'react';
import { Dialog, DialogTitle, Grid, DialogActions, DialogContent, Button, FormControl, RadioGroup, FormControlLabel,
  Radio, MenuItem, Select, Checkbox, ListItemText, InputLabel, OutlinedInput, } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AlertInlineMain from "components/common/AlertInlineMain";
import { TextField } from 'formik-material-ui';
import { Form, Formik, Field } from 'formik';
import { couponValidationSchema } from './Validations';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";

export default function EditCoupon({ open, handleClose, serviceName, setServiceName, alertSetter, setStatus, services, uniqueArray,
  editData, MenuProps, mainServices, handleServiceValue, mainServiceName }) {
  const axios = useAxiosPrivate();
  const [upload, setUpload] = useState(false);
  const data = useMemo(() => {
    return {
      name: editData?.name,
      description: editData?.description,
      points: editData?.points,
      discountType: editData?.discountType,
      discountValue: editData?.discountValue,
      imageName: editData?.imageName,
    };
  }, [editData]);
  const [initialValues, setInitialValues] = useState(data);
  const [image, setImage] = useState({});
  const [backednCall, setBackednCall] = useState(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  useEffect(() => {
    setInitialValues(data);
  }, [data]);

  const onSubmit = async (e, { resetForm }) => {
    try {
      const data = {
        name: e.name,
        description: e.description,
        services: {
          names: serviceName,
          types: uniqueArray(serviceName),
        },
        points: e.points,
        discount: {
          discountType: e.discountType,
          discountValue: e.discountValue,
        },
        image: {
          name: image.name ? image.name : editData?.imageName,
          url: image.url ? image.url : editData?.imageUrl,
        },
      };
      await axios.put(`/rewards/coupons/${editData?.id}`, data);
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Reward Successfully Updated",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "",
          message: "",
        });
        alertSetter(false, 'success', '');
        handleClose();
        setStatus();
        resetForm();
        setBackednCall(false);
      }, 3000);
      alertSetter(true, 'success', 'Reward Successfully Updated');

    } catch (error) {
      setBackednCall(false);
      alertSetter(true, 'error', 'Error in Updating Reward', 3000);
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Error in Updating Reward",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "",
          message: "",
        });
      }, 3000);
      setStatus();
    }
  };

  const uploadImage = async (e) => {
    try {
      e.preventDefault();
      setUpload(true);
      if (e.target.files?.[0]?.type.split('/')[0] !== 'image') {
        alertSetter(true, 'error', 'Please upload an image', 3000);
        return;
      }
      const formData = new FormData();
      formData.append('image', e.target.files[0]);
      const fileName = e.target.files[0].name;
      const { data } = await axios.post('/rewards/image', formData);
      setImage({
        name: fileName,
        url: data.url,
      });
    } catch (error) {
      alertSetter(true, 'error', 'Error in Uploading Image', 3000);
    } finally {
      setStatus();
      setUpload(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={'md'} maxWidth={'md'} >
        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={couponValidationSchema} >
          {({
            isValid,
            resetForm,
            handleChange,
            setFieldValue,
            setFieldError,
            setFieldTouched, }) => {
            return (
              <Form noValidate autoComplete='off' encType='multipart/form-data'>
                <DialogTitle style={{ cursor: 'move', alignSelf: 'center' }} id='time-slot-create-id' > Edit Reward Details </DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item mt={1} xs={12} md={6} lg={6}>
                      <Field name='name' label='Name' component={TextField} fullWidth variant='outlined' required />
                    </Grid>
                    <Grid item mt={1} xs={12} md={10} lg={10}>
                      <Field name='description' label='Description' component={TextField} fullWidth variant='outlined' required />
                    </Grid>
                    <Grid item mt={1} xs={12} md={7} lg={7}>
                      <FormControl fullWidth>
                        <InputLabel id='demo-multiple-checkbox-label'> Applicable Services <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <Select labelId='demo-multiple-checkbox-label' id='demo-multiple-checkbox' multiple value={mainServiceName}
                          onChange={(e) => {
                            handleChange(e);
                            const { target: { value }, } = e;
                            handleServiceValue( typeof value === 'string' ? value.split(',') : value );
                            setFieldValue('serviceNames', value, false);
                            setFieldError('serviceNames', '');
                            setFieldTouched('serviceNames', false);
                          }}
                          input={<OutlinedInput label='Applicable Services' />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                        >
                          {mainServices.map((ob) => (
                            <MenuItem key={ob.name} value={ob.name}>
                              <Checkbox checked={mainServiceName.indexOf(ob.name) > -1} />
                              <ListItemText primary={ob.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item mt={1} mr={1} xs={12} md={6} lg={6}>
                      <Field name='points' label='Points' component={TextField} fullWidth variant='outlined' required />
                    </Grid>
                    <Grid item mt={1} xs={12} md={6} lg={6}>
                      <Field name='discountValue' label='Discount amount' component={TextField} fullWidth variant='outlined' required />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <FormControl>
                        <RadioGroup name='discountType'
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue( 'discountType', e.target.value, false );
                            setFieldError('discountType', '');
                            setFieldTouched('discountType', false); }}
                          defaultValue={editData?.discountType} >
                          <FormControlLabel value='percentage' control={<Radio />} label='Percent ( % )' />
                          <FormControlLabel value='amount' control={<Radio />} label='Amount ( $ )' />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item mt={1} xs={12} md={6} lg={6}>
                      <Field name='imageName' helperText='Upload image 125x125 pixels' label='Image' component={TextField} fullWidth
                        disabled variant='outlined' required />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}
                      sx={{ display: 'flex', justifyContent: 'flex-right', marginTop: '1rem', width: '100%', }} >
                      <input type='file' name='imageFile' id='image' accept='image/*' style={{ display: 'none' }}
                        onChange={(e) => {
                          uploadImage(e);
                          setFieldValue( 'imageName', e.target.files[0].name, true );
                        }}
                      />
                      <label htmlFor='image'>
                        <LoadingButton fullWidth variant='contained' component='span' loading={upload} > Upload image </LoadingButton>
                      </label>
                    </Grid>
                    {alert.showAlert && ( <Grid item md={12} m={2}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid> )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button color='success' type='submit' disabled={!isValid || !(serviceName.length > 0) || backednCall} > Save </Button>
                  <Button color='error' onClick={handleClose}> Close </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
}
