import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AlertInlineMain from "components/common/AlertInlineMain";
import Grid from "@mui/material/Grid";

export default function DeleteSchedules({
    title,
    message,
    deleteAllSchedules,
    alert,
    setAlert,
    isLoading,
}) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleDelete = () => {
        deleteAllSchedules();
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);

    };

    return (
        <>
            <Grid item lg={12} md={12} sm={12} xs={12} >
                <Button data-testid="timeSlot-create-btn" variant='contained' color='error' fullWidth={true} onClick={handleClickOpen} disabled={isLoading}>
                    <h3>Delete All Schedules</h3>
                </Button>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={"sm"}
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                    {alert.showAlert && (
                        <Grid item md={12} m={2}>
                            <AlertInlineMain setAlert={setAlert} alert={alert} />
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} variant="contained" color="error">
                        Yes
                    </Button>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        style={{ backgroundColor: "grey" }}
                        autoFocus
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}