import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import AlertInlineMain from "components/common/AlertInlineMain";
import { TextField as TF } from 'formik-material-ui';

function CreatePopup(props) {
  const axios = useAxiosPrivate()
  const { setStatus, status } = props;
  const [open, setOpen] = useState(false);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setStatus(!status);
  };

  // insert data to database
  const onSubmit = async (e, { resetForm }) => {
    try {
      await axios.post('/royalty', {
        schemeID: e.schemeID,
        scheme_Name: e.scheme_Name,
        royalty_Percentage: e.royalty_Percentage,
      });

      resetForm();
      setOpen(false);
      setStatus(!status);
      setAlert({
        showAlert: true,
        severity: 'success',
        message: 'Data Saved!',
      });
    } catch (err) {
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Request Failed!',
      });
    }
  };

  const validationSchema = Yup.object().shape({
    scheme_Name: Yup.string()
      .max(100, 'Maximum length is 100')
      .required('Required'),
    royalty_Percentage: Yup.number()
      .typeError('Please enter a valid number')
      .required('Royality Percentage % is required')
      .min(0, 'Minimum atleast 0')
      .max(100, 'Allowed maximum is 100')
      .test('is-decimal', 'invalid decimal', (value) =>
        (value + '').match(/^[0-9]\d?(?:\.\d{0,2})?$/)
      )
  });

  const initialValues = {
    scheme_Name: '',
    royalty_Percentage: '',
  };

  return (
    <>
      <Button variant='contained' color='primary' onClick={handleClickOpen}>
        + Create Scheme
      </Button>

      <Dialog
        fullWidth={'xs'}
        maxWidth={'xs'}
        open={open}
        onClose={handleClose}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({
            touched,
            errors,
            values,
            isValid,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form noValidate autoComplete='off' encType='multipart/form-data'>
                <DialogTitle
                  style={{ cursor: 'move' }}
                  id='draggable-dialog-title'
                >
                  Create Scheme
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={1} justifyContent={'flex-middle'}>
                    <Grid item xs={6}>
                      <Field
                        name='scheme_Name'
                        label='Scheme Name'
                        variant='outlined'
                        style={{
                          marginTop: '1rem',
                          display: 'flex',
                        }}
                        component={TF}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Field
                        name='royalty_Percentage'
                        label='Royalty Percentage %'
                        variant='outlined'
                        style={{
                          marginTop: '1rem',
                          display: 'flex',
                        }}
                        component={TF}
                      />
                    </Grid>
                    {alert.showAlert && (
                      <Grid item md={12} m={2}>
                       <AlertInlineMain setAlert={setAlert} alert={alert} />
                      </Grid>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    color='success'
                    onClick={onSubmit}
                    type='submit'
                    disabled={!isValid}
                  >
                    Save
                  </Button>
                  <Button color='error' onClick={handleClose}>
                    Close
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
}

export default CreatePopup;
