import momentTimeZone from "moment-timezone";

export const formattedDateInOrderDetails = (dateInput) => {
    const franchiseeTimeZone = sessionStorage.getItem("franchiseeTimeZone")
    return dateInput ? momentTimeZone(dateInput).tz(franchiseeTimeZone).format('dddd, MMMM Do, YYYY') : "-";
}

export const formattedTimeInOrderDetails = (dateInput) => {
    const franchiseeTimeZone = sessionStorage.getItem("franchiseeTimeZone")
    return dateInput ? momentTimeZone(dateInput).tz(franchiseeTimeZone).format('h:mm A') : "-";
}

export const formattedTimeAndDateInOrderDetails = (dateInput) => {
    const franchiseeTimeZone = sessionStorage.getItem("franchiseeTimeZone")
    return dateInput ? momentTimeZone(dateInput).tz(franchiseeTimeZone).format('DD/MM/YYYY h:mm A') : "-";
}

export const orderStatusGetter = (data) => {
    switch ((data?.orderDetail?.specialCareTracker)
        ? data?.orderDetail?.specialCareTracker :
        data?.orderDetail?.statusTracker) {
        case 'placingOrder':
            return 'Placing order';
        case 'setToPickup':
            return 'Set to pickup';
        case 'pickup':
            return 'Picked up';
        case 'processing':
            return 'Processing';
        case 'pickupCancel':
            return 'Pickup cancelled';
        case 'setToDeliver':
            return 'Set to deliver';
        case 'completed':
            return 'Delivered';
        case 'deliveryCancel':
            return 'Delivery cancelled';
        case 'specialSetToDeliver':
            return 'Special set to deliver';
        case 'specialCompleted':
            return 'Special delivered';
        case 'specialDeliveryCancel':
            return 'Special delivery cancelled';
        default:
            return '-';
    }
}
