import addZeroToNumbers from "./AddZeroToNumbers"

export default function DateSlashToDashReformat(date) {
  const franchiseeTimeZone = sessionStorage.getItem("franchiseeTimeZone")
  
  const localDate = date?.toLocaleDateString('en-US', { timeZone: `${franchiseeTimeZone}` })

  if (localDate.includes("/")) {
    
    const reFormatDate = localDate.split("/")
    const reArrageDate = `${reFormatDate[2]}-${addZeroToNumbers(reFormatDate[0])}-${addZeroToNumbers(reFormatDate[1])}`
    return reArrageDate

  } else { 
    return localDate
  }

}
