import * as Yup from 'yup';

export const couponValidationSchema = Yup.object().shape({
    name: Yup.string()
        .max(200, 'Must be 200 characters or less')
        .required('Required'),
    description: Yup.string()
        .max(400, 'Must be 400 characters or less')
        .required('Required'),
    points: Yup.number()
        .min(1, 'Must be greater than 0')
        .lessThan(100000, 'Must be less than 100000')
        .required('Required')
        .test('is-decimal', 'invalid decimal', (value) => (value + '').match(/^[1-9]\d*(?:\.\d{1,3})?$/) ),
    discountType: Yup.string(),
    discountValue: Yup.number().when(
        'discountType', // check if discountType is percentage or amount
        (discountType, schema) =>
            discountType === 'percentage'
                ? schema
                    .min(0.01, 'Must be greater than 0.01')
                    .max(100, 'Must be less than 100')
                    .test('is-decimal', 'invalid percentage', (value) =>
                        (value + '').match(/^(?:100|\d{1,2})(?:\.\d{1,2})?$/) // ^[1-9]\d?(?:\.\d{0,2})?
                    )
                : schema
                    .min(1, 'Must be greater than 0')
                    .lessThan(100000, 'Must be less than 100000')
                    .test('is integer', 'Must be an integer', (value) =>
                        (value + '').match(/^[1-9]\d*$/)
                    )
    ),
    imageName: Yup.string().required('image is required'),
});

export const promoValidationSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Name must be at least 3 characters')
        .max(200, 'Must be 200 characters or less')
        .required('Required'),
    description: Yup.string()
        .max(400, 'Must be 400 characters or less')
        .required('Required'),
    discountType: Yup.string(),
    discountValue: Yup.number().when(
        'discountType', // check if discountType is percentage or amount
        (discountType, schema) =>
            discountType === 'percentage'
                ? schema
                    .min(0.01, 'Must be greater than 0.01')
                    .max(100, 'Must be less than 100')
                    .test('is-decimal', 'invalid percentage', (value) =>
                        (value + '').match(/^(?:100|\d{1,2})(?:\.\d{1,2})?$/) // ^[1-9]\d?(?:\.\d{0,2})?$ 
                    )
                : schema
                    .min(1, 'Must be greater than 0')
                    .lessThan(100000, 'Must be less than 100000')
                    .test('is integer', 'Must be an integer', (value) =>
                        (value + '').match(/^[1-9]\d*$/)
                    )
    ),
    sequence: Yup.number()
        .min(1, 'Must be greater than 0')
        .max(100000, 'Must be less than 100000')
        .test('is integer', 'Must be an integer', (value) =>
            (value + '').match(/^[1-9]\d*$/)
        )
        .required('Required'),
    promocode: Yup.string()
        .matches(/^[A-Z\s]+$/, "Only capital letters are allowed for this field ")
        .min(5)
        .max(5)
        .required('promocode is required'),
    
    inviteType: Yup.string()
    // .test((value)=>console.log(value))
    ,
    emails: Yup.string()
        // .test((value)=>console.log(value))
        .when('inviteType', {
            is: "promoForcsvList",
            then: Yup.string().required('csv is required'),
        }),
});