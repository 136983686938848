import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, Grid, DialogActions, DialogContent, Button, FormControl, RadioGroup, FormControlLabel, Radio,
  MenuItem, Select, Checkbox, ListItemText, InputLabel, OutlinedInput, FormLabel, } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import LoadingButton from '@mui/lab/LoadingButton';
import Papa from 'papaparse';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import AlertInlineMain from "components/common/AlertInlineMain";
import { promoValidationSchema } from './Validations';
import sortServiceNameArray from 'components/common/SortServiceNames';

const initial = { name: '', description: '', discountType: 'percentage', discountValue: 0, promocode: '', sequence: 1, inviteType: 'promoForAll'}

export default function ViewPromocode({ open, handleClose, serviceName, setServiceName, generatePromoCode, setStatus,
    services, uniqueArray, MenuProps, viewPromoData }) {

  // eslint-disable-next-line no-unused-vars
  const [initialValues, setInitialValues] = useState(initial);

  useEffect(() => {
    setInitialValues({
      name: viewPromoData?.name, 
      description: viewPromoData?.description, 
      discountType: viewPromoData?.discountType, 
      discountValue: viewPromoData?.discountValue, 
      promocode:  viewPromoData?.promocode, 
      sequence: viewPromoData?.sequence, 
      inviteType: 'promoForAll'
    })

  },[viewPromoData])

  const [radioButton, setRadioButton] = useState('promoForAll');

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={'md'} maxWidth={'md'} >
        <Formik enableReinitialize initialValues={initialValues} validationSchema={promoValidationSchema} >
          {({
            values,
            isValid,
            resetForm,
            handleChange,
            setFieldValue,
            setFieldError,
            setFieldTouched,
          }) => {
            return (
              <Form noValidate autoComplete='off' encType='multipart/form-data'>
                <DialogTitle style={{ cursor: 'move' }} id='time-slot-create-id' > Promocode Details </DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item mt={1} xs={12} md={6} lg={6}>
                      <Field name='name' label='Name' component={TextField} fullWidth variant='outlined' required disabled/>
                    </Grid>
                    <Grid item mt={1} xs={12} md={10} lg={10}>
                      <Field name='description' label='Description' component={TextField} fullWidth variant='outlined' required disabled/>
                    </Grid>
                    <Grid item mt={1} xs={12} md={7} lg={7}>
                      <FormControl fullWidth>
                        <InputLabel id='demo-multiple-checkbox-label'> Applicable Services <span style={{ color: "red" }}>*</span> </InputLabel>
                        <Select labelId='demo-multiple-checkbox-label' id='demo-multiple-checkbox' multiple name='serviceNames' value={sortServiceNameArray(viewPromoData?.serviceName)} disabled
                          input={<OutlinedInput label='Applicable Services' />}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                        >
                          {services.map((ob) => (
                            <MenuItem key={ob.name} value={ob.name}>
                              <Checkbox checked={serviceName.indexOf(ob.name) > -1} />
                              <ListItemText primary={ob.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item mt={1} xs={12} md={6} lg={6}>
                      <Field name='promocode' label='Promo Code' component={TextField} fullWidth variant='outlined' required disabled/>
                    </Grid>
                    <Grid item mt={1} xs={12} md={5} lg={5}
                      sx={{ display: 'flex', justifyContent: 'flex-right', marginTop: '1rem', width: '100%', }} >
                    </Grid> 
                    <Grid item mt={{ lg:4, md:4, sm:3, xs:4 }} mb={{ lg:4, md:4, sm:3, xs:4 }} 
                    lg={radioButton === "promoForAll" ? 12:5}
                    md={radioButton === "promoForAll" ? 12:5} 
                    sm={radioButton === "promoForAll" ? 12:12} 
                    xs={radioButton === "promoForAll" ? 12:12} >
                    <FormControl> <FormLabel > Who you want to apply this promocode to? </FormLabel>
                          <RadioGroup 
                          name='inviteType' 
                          value={viewPromoData?.fileName === "promoForAll" ? "promoForAll" : "promoForcsvList"}
                          >
                            <FormControlLabel value={"promoForAll"} control={<Radio />} disabled  label='Apply for all' />
                            <FormControlLabel value={"promoForcsvList"} control={<Radio />} disabled  label={`Use email list CSV ${`${viewPromoData?.fileName !== 'promoForAll' && viewPromoData?.fileName ? ` - ${viewPromoData?.fileName}` : ''}`}`} />
                          </RadioGroup>
                        </FormControl>
                    </Grid> 
                    <Grid item lg={3} md={3} sm={4} xs={12} >
                      <FormControl>
                        <RadioGroup name='discountType'
                          defaultValue={viewPromoData?.discountType} >
                          <FormControlLabel value='percentage' control={<Radio />} disabled label='Percent ( % )' />
                          <FormControlLabel value='amount' control={<Radio />} disabled label='Amount ( $ )' />
                        </RadioGroup>
                      </FormControl>
                    </Grid> 
                    <Grid item mt={1} lg={7} md={7} sm={7} xs={12} >
                      <Field name='discountValue' label='Discount amount' component={TextField} fullWidth variant='outlined' required disabled/>
                    </Grid> 
                    <Grid item  lg={3} md={3}  sm={4}  xs={12} >
                      <FormControl>
                        <FormLabel component='legend'>One per user</FormLabel>
                        <RadioGroup row name='sequenceType' defaultValue={viewPromoData?.sequence === 1 ? true : false} >
                          <FormControlLabel value={true} control={<Radio />} disabled label='Yes' />
                          <FormControlLabel value={false} control={<Radio />} disabled label='No' />
                        </RadioGroup>
                      </FormControl>
                    </Grid> 
                    <Grid item mt={1} lg={7} md={7} sm={7} xs={12}  >
                      <Field name='sequence' label='Sequence' component={TextField} fullWidth variant='outlined' required disabled/>
                    </Grid> 
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button color='error' onClick={handleClose}> Close </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
}
