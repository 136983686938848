import React, { Fragment, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Drawer,
  List,
  Chip,
  Typography,
  Divider,
  ListItem,
  IconButton,
  ListItemText,
} from "@material-ui/core";

import { toggleDrawer } from "../../store/actions/authActions";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import pkgJson from "../../../package.json";

//icons

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FaceIcon from "@material-ui/icons/Face";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import AddToHomeScreenIcon from "@material-ui/icons/AddToHomeScreen";
import PageviewIcon from "@material-ui/icons/Pageview";
import BackupIcon from "@material-ui/icons/Backup";
import PaymentIcon from "@material-ui/icons/Payment";
import WorkIcon from "@material-ui/icons/Work";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import FilterFramesIcon from '@mui/icons-material/FilterFrames';
import PeopleIcon from '@mui/icons-material/People';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SettingsIcon from '@mui/icons-material/Settings';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  show: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  chipStyles: {
    backgroundColor: "#f55c47",
    marginBottom: "1.5rem",
  },
  listItemSelected: {
    backgroundColor: "#ff0000",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();

  let navigate = useNavigate();

  let dispatch = useDispatch();

  let isDrawerOpen = useSelector((state) => state.auth.isDrawerOpen);

  const newName = sessionStorage.getItem("fullName");

  const name = newName?.split("undefined")[0];

  const token = sessionStorage.getItem("accessToken") || "";
  let permissions = JSON.parse(sessionStorage.getItem("routes"));
  const userRole = sessionStorage.getItem("role");
  const managerType = sessionStorage.getItem("managerType");
  
  const handleDrawerClose = () => {
    dispatch(toggleDrawer());
  };

  const onToggle = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    dispatch(toggleDrawer());
  };

  const onRoute = (path) => {
    if (window.location.pathname !== path) { 
      navigate(path);
    }
    dispatch(toggleDrawer());
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>

      <Divider />
      <div style={{ textAlign: "center" }} className={classes.show}>
        <Typography
          variant="h6"
          style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
        >
          LWL - {pkgJson.version}
        </Typography>

        {token && (
          <Fragment>
            <Chip
              icon={<FaceIcon />}
              label={name}
              variant="outlined"
              className={classes.chipStyles}
            />
          </Fragment>
        )}
      </div>
      <Divider />
      <List>
        <Fragment>
          <List>
            {permissions &&
              permissions.map((route, index) => (
                <Fragment key={index}>
                  <ListItem
                    button
                    className={classes.nested}
                    key={index}
                    onClick={() => onRoute(route.path)}
                  >
                    {route.name === "Dashboard" && (
                      <>
                        <DashboardIcon />
                        &nbsp; &nbsp; <ListItemText primary={"Dashboard"} />
                      </>
                    )}
                    {route.name === "Schedule" && (
                      <>
                        <EventNoteIcon />
                        &nbsp; &nbsp; <ListItemText primary={"Schedules"} />
                      </>
                    )}
                    {route.name === "Schedule Settings" && (
                      <>
                        <SettingsIcon />
                        &nbsp; &nbsp; <ListItemText primary={"Others"} />
                      </>
                    )}
                    {route.name === "User Managment" && (userRole==="franchisee" ||  userRole==="corporate" ) && (
                      <>
                        <PeopleIcon />
                        &nbsp; &nbsp; <ListItemText primary={"Managers"} />
                      </>
                    )}
                    {route.name === "Orders" && (
                      <>
                        <FilterFramesIcon />
                        &nbsp; &nbsp; <ListItemText primary={"Orders"} />
                      </>
                    )}
                    {route.name === "Franchisee Mgt" && (
                      <>
                        <LocationOnIcon />
                        &nbsp; &nbsp;{" "}
                        <ListItemText primary={"Locations"} />
                      </>
                    )}
                    {route.name === "View Franchisee" && (
                      <>
                        <AccountCircleIcon />
                        &nbsp; &nbsp;{" "}
                        <ListItemText primary={"View Franchisee"} />
                      </>
                    )}

                    {route.name === "LWL Requests" && (
                      <>
                        <AddToHomeScreenIcon />
                        &nbsp; &nbsp; <ListItemText primary={"LWL Requests"} />
                      </>
                    )}
                    {route.name === "LWL Request view" && (
                      <>
                        <PageviewIcon />
                        &nbsp; &nbsp;{" "}
                        <ListItemText primary={"LWL Request view"} />
                      </>
                    )}
                    {route.name === "LWL Learning" && (
                      <>
                        <MenuBookIcon />
                        &nbsp; &nbsp; <ListItemText primary={"Learning"} />
                      </>
                    )}
                    {route.name === "Royalty" && ( userRole === "franchisee" || userRole === "manager" )&& (
                      <>
                        <PaymentIcon />
                        &nbsp; &nbsp; <ListItemText primary={"Royalty"} />
                      </>
                    )}
                    {route.name === "LWL Services" && (
                      <>
                        <WorkIcon />
                        &nbsp; &nbsp; <ListItemText primary={"Services"} />
                      </>
                    )}
                     {route.name === "Routing" && (userRole==="franchisee" || userRole==="corporate" || userRole === "manager") && (
                      <>
                        <AltRouteIcon />
                        &nbsp; &nbsp; <ListItemText primary={"Routing"} />
                      </>
                    )}
               
                    {route.name === "Franchisee Learning" && (
                      <>
                        <MenuBookIcon />
                        &nbsp; &nbsp; <ListItemText primary={"Learning"} />
                      </>
                    )}
                    {route.name === "Customers" && (
                      <>
                        <PeopleIcon />
                        &nbsp; &nbsp; <ListItemText primary={"Rewards"} />
                      </>
                    )}
                 {route.name === "CustomersF" && (
                      <>
                        <PeopleIcon />
                        &nbsp; &nbsp; <ListItemText primary={"Promotions"} />
                      </>
                    )}
                    {route.name === "Royalty Payment" && (
                      <>
                        <MonetizationOnIcon />
                        &nbsp; &nbsp;{" "}
                        <ListItemText primary={"Royalty Payment"} />
                      </>
                    )}
                    {route.name === "Account Management" && (
                      <>
                        <AccountCircleIcon />
                        &nbsp; &nbsp;{" "}
                        <ListItemText primary={"Account Management"} />
                      </>
                    )}
                    {route.name === "Reports" && (
                      <>
                        <AllInboxIcon />
                        &nbsp; &nbsp; <ListItemText primary={"Reports"} />
                      </>
                    )}
                  </ListItem>
                </Fragment>
              ))}
          </List>
        </Fragment>
      </List>
    </div>
  );

  return (
    <div>
      {token && (
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={onToggle("left", false)}
          variant="persistent"
        >
          {list("left")}
        </Drawer>
      )}
    </div>
  );
}
