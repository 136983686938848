import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Map from 'components/jobs/Map';

export default function AlertDialog({ open, setOpen, locationData, center, dotsLineDataArray }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Map locationData={locationData} center={center} dotsLineDataArray={dotsLineDataArray} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
