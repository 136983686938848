import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardContent, Typography, Grid, TextField, FormControl, Autocomplete, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { TextField as TF } from "formik-material-ui";
import DropZone from "../layouts/DropZone3";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LoadingButton from '@mui/lab/LoadingButton';
// import AlertMain from "components/common/AlertMain";
import DialogAlertMain from "components/common/DialogAlertMain";

export default function Request() {
  const axios = useAxiosPrivate()
  const user = useSelector((state) => state);
  const navigate = useNavigate();
  const role = useSelector((state) => state.auth.role);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

   // add one day for validation (can't able to set today date)
const todayPlusOne = new Date();
todayPlusOne.setDate(todayPlusOne.getDate() + 1);
const todayDate = todayPlusOne.toISOString().split("T")[0]

  const [loadButton, setLoadButton] = useState(false);
  const [from, setFrom] = useState(todayDate);
  const [submited, setSubmited] = useState(false)
  const [zipCodes, setZipCodes] = useState([]);
  const [to, setTo] = useState(todayDate);
  const [image, setImage] = useState([]);
  const [initialValues, setInitialValues] = useState({
    subject: "",
    franchiseeName: `${user.auth.fullName?.split("undefined")[0]
      ? user.auth.fullName?.split("undefined")[0]
      : ""
      }`,

    franchiseOwnerName: `${user.auth.ownerName ? user.auth.ownerName : ""}`,
    email: "",
    zipCode: [],
    description: "",
    from: todayDate,
    to: todayDate,
    resources: [],
  });

  const getUserData = async () => {
    try {
      const { data } = await axios.get("/user/getZips");
      const zips = data.serviceRegions;
      setZipCodes(zips);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Something went wrong!",
      });
    }
  };

  const uploadData = () => {
    try { if (image.length > 5) { throw Error("Maximum file limit is 5"); }



    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Files upload fail! (Maximum file limit is 5)",
      });
    }
  };

  const onSubmit = async (e, { resetForm, setFieldValue }) => {
    setLoadButton(true)
    try {
      let bodyFormData = new FormData();
      for (let i = 0; i < image.length; i++) {
        bodyFormData.append(`image`, image[i]);
      }
      for (let i = 0; i < e.zipCode.length; i++) {
        bodyFormData.append("zipCode[]", zipCodes[i]);
      }
      const data = {
        subject: e.subject,
        franchiseeName: e?.franchiseeName,
        franchiseOwnerName: e?.franchiseOwnerName,
        email: e.email,
        description: e.description,
        from: e.from,
        to: e.to,
      };
      for (let key in data) {
        bodyFormData.append(key, data[key]);
      }
      await axios.post("/request", bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoadButton(false)
      setSubmited(!submited)
      resetForm();
      setFrom(todayDate);
      setTo(todayDate);
      setFieldValue("zipCode", []);
      setImage([]);
      setInitialValues({
        subject: "",
        franchiseeName: `${user.auth.fullName.split("undefined")[0]
          ? user.auth.fullName.split("undefined")[0]
          : ""
          }`,

        franchiseOwnerName: `${user.auth.ownerName ? user.auth.ownerName : ""}`,
        email: "",
        zipCode: [],
        description: "",
        from: "",
        to: "",
        resources: [],
      });
    
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Request Success!",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "Request Success!",
        });

      }, 3000);
    } catch (error) {
      setLoadButton(false)
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Request failed!",
      });
      
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "Request Success!",
        });
      }, 3000);
    }
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCodes.length === 0]);

  useEffect(() => {
    if (image.length !== 0) {
      uploadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    subject: Yup.string().required("Subject is required")
      .max(150, "Maximum length is 150"),
    description: Yup.string().required("Description is required").max(350, "Maximum length is 350"),
    zipCode: Yup.array().required("Zip Code is required")
      .min(1, "Zip Code is required"),
    //date 
    from: Yup.date().required("Start Date is required"),
    to: Yup.date().required("End Date is required")
      .when("from", (from, schema) => from && schema.min(from, "End date must be later than or equal to Start Date")),
  });

  return (
    <Card>
      <CardContent>
        <Grid style={{ marginRight: "30rem" }} item>
          <IconButton onClick={() => navigate("/franchisee-request-view")} color="primary" >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid container justifyContent={"center"}>
          <Grid item>
            <Typography gutterBottom variant="h5" component="div" style={{ font: "bold" }} >
              Marketing Campaign Requests
            </Typography>
          </Grid>
        </Grid>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize >
          {({
            errors,
            values,
            isValid,
            setFieldValue,
          }) => {
            return (
              <Form noValidate autoComplete="off" encType="multipart/form-data">
                <Grid container item spacing={2} xs={12} md={12} xl={12} justifyContent={"flex-start"} >
                  <Grid item xs={12} md={6}> <Field name="subject" label="Subject" component={TF} variant="outlined" fullWidth required /></Grid>
                  <Grid item xs={6} md={6}></Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <Field name="franchiseeName" label={`${(role === "corporate" || (sessionStorage.getItem("managerType") === "corporate" && role === "manager"))  ? 'Corporate' : 'Franchisee'} Business Name`} component={TF} variant="outlined"
                      fullWidth disabled >{`${user.auth.fullName.split("undefined")[0]}`}</Field> </Grid>
                  <Grid item xs={12} md={6} xl={6}> <Field name="franchiseOwnerName" label={`${(role === "corporate" || (sessionStorage.getItem("managerType") === "corporate" && role === "manager")) ? 'Corporate' : 'Franchisee'} owner name`} component={TF}
                      variant="outlined" fullWidth disabled >{`${user.auth.ownerName}`}</Field>
                  </Grid>
                  <Grid item xs={12} md={12} xl={12}> <Field name="email" label="Corresponding Email Address" component={TF}
                      variant="outlined" fullWidth required /></Grid>
                </Grid>
                <Grid container spacing={1} item xs={12} md={12} xl={12} mt={1}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <Autocomplete name="zipCode" multiple id="tags-outlined" options={zipCodes} value={values.zipCode}
                        getOptionLabel={(option) => option} onChange={(event, newValue) => { setFieldValue("zipCode", newValue); }}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField {...params} label=" Marketing area(Zip coded)"
                            placeholder="Marketing area(Zip coded)" required
                            name="zipCode" error={errors.zipCode} helperText={errors.zipCode} /> )} />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container justifyContent={"flex-start"} p={1} mt={5}>
                  <Grid item>
                    <Typography gutterBottom variant="h5" component="div" style={{ fontSize: "1.5rem" }} > Campaign Duration </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent={"flex-start"}>
                  <Grid item> <Field name="from" label={`From`} value={from} component={TF}
                      onChange={(newValue) => { setFrom(newValue?.target?.value); setFieldValue("from", newValue?.target?.value); }}
                      type="date" InputProps={{ inputProps: { min: `${todayDate}` } }} onKeyDown={(e) => e.preventDefault()}
                      error={errors?.from} helperText={errors?.from} /> </Grid>
                  <Grid item> <Field name="to" label={`To`} value={to} component={TF}
                      onChange={(newValue) => {
                        setTo(newValue?.target?.value);
                        setFieldValue("to", newValue?.target?.value); }}
                      type="date" InputProps={{ inputProps: { min: `${from}` } }}
                      onKeyDown={(e) => e.preventDefault()} error={errors?.to} helperText={errors?.to} /> </Grid>
                  <Grid item xs={12} md={12} xl={12}> <Field name="description" label="Description" component={TF} variant="outlined"
                      fullWidth required multiline rows={5} ></Field>
                  </Grid> 
                  <Grid container justifyContent={"flex-start"} p={1} mt={5}>
                    <Grid item>
                      <Typography gutterBottom variant="h5" component="div" p={1} style={{ fontSize: "1.5rem" }} > Resources </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} xl={12}>
                    <DropZone setImage={setImage} image={image} setSubmited={setSubmited} submited={submited} setAlert={setAlert}/>
                  </Grid>
                  <Grid item style={{ marginTop: "1rem" }}> <LoadingButton loading={loadButton} variant="contained" type="submit"
                      disabled={!isValid || image.length > 5} > Submit </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
        <DialogAlertMain alert={alert} setAlert={setAlert} />
      </CardContent>
    </Card>
  );
}