import React, { useEffect } from "react";
import { Grid, Button, Card, CardContent } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  setOpen,
  setDriver,
  removeOneDriver,
} from "../../store/actions/RouteAction";
import DriverList from "../Depot/Driver/DriverList";

export default function DriverMain() {
  let dispatch = useDispatch();

  const [open, setOpenDepot] = React.useState(false);

  const setDetails = () => {

    dispatch(setOpen(false));
    setOpenDepot(!open);
    dispatch(removeOneDriver());

    setTimeout(() => {
      dispatch(setOpen(true));
    }, 200);

  };

  useEffect(() => {
    dispatch(setDriver());
    //eslint-disable-next-line
  }, []);

  return (
    <Card style={{ width: "100%" }}>
      <CardContent>
        <Grid
          container
          justifyContent={"center"}
          alignContent="flex-start"
          p={2}
        >
          <Grid item xs={12} md={12} lg={12}>
            <Button variant="outlined" size="small" onClick={setDetails}>
              Add new Driver
            </Button>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <DriverList />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
