import React, { useState, useEffect } from "react";
import { Grid, FormControl, TextField, Chip, Typography, Button } from "@material-ui/core";
// import Button from "../../components/UI/ReusableButton";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { TextField as TF } from "formik-material-ui";
import { useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AlertInlineMain from "components/common/AlertInlineMain";
import containsInvalidZip from "./commonFunctions/InvalidZipValidation";

export default function CreateZone({ setCreateZone, setConfirmZone }) {
  const user = useSelector((state) => state);
  const axios = useAxiosPrivate()
  // eslint-disable-next-line
  const [er, setEr] = useState(true);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [zipAlert, setZipAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const [initialValues, setInitialValues] = useState({
    zoneName: "",
    zipCodes: [],
  });
  const [zipCodeList, setZipCodeList] = useState([]);
  const [currentZips, setCurrentZips] = useState([]);
  const onSubmit = async (e, { resetForm }) => {
    try {
      setEr(true);
      const data = {
        zoneName: e.zoneName,
        zipCodes: e.zipCodes,
      };
      if (e.zipCodes.length === 0) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Please enter at least one Zip code",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 3000);
      } else {
        resetForm();
        await axios.post("/createZone", data);
        setInitialValues({ zoneName: "", zipCodes: [] });
        get();
        getCurrentZips();
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Zone Created Successfully!",
        });

        if (setConfirmZone) {
          setConfirmZone(false);
        }

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 3000);
      }
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: error.response.data.message,
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Zone Created failed!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 3000);
      }
    }
  };

  // get Company
  const get = async (id) => {
    try {
      const { data } = await axios.get(`/user/${user.auth.id}`);

      setZipCodeList(data.serviceRegions);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
    }
  };

  const getCurrentZips = async (id) => {
    try {
      const { data } = await axios.get("/createZone");
      const zipsArray = data.map((item) => item.zipCodes);
      const newArr = Array.prototype.concat(...zipsArray);

      setCurrentZips(newArr);
      if (newArr.length > 0) {
        setCreateZone() ? setCreateZone() : void 0;

        setConfirmZone(false)
      }
    } catch (err) {
      if (err.response.status === 401) {
        if (setConfirmZone) {
          setConfirmZone(true);
        }
        setAlert({
          showAlert: true,
          severity: "error",
          message: err.response.data.message,
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 4000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Data Loading Failed!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Data Loading Failed!",
          });
        }, 4000);
      }
    }
  };

  const checkFranchiseeZips = (newValue, setFieldError, setFieldValue) => {
    if(!containsInvalidZip(newValue, zipCodeList)){
    try {
      let duplicates = currentZips.filter(function (val) {
        return newValue.indexOf(val) !== -1;
      });
      let uniqueArray = duplicates.filter((item, pos) => {
        return duplicates.indexOf(item) === pos;
      });
      setFieldValue("zipCodes", newValue, false);
      if (uniqueArray.length > 0) {
        setEr(true);
        setZipAlert({
          showAlert: true,
          severity: "error",
          message: `Zip code is already exists ${uniqueArray}`,
        });
        setTimeout(() => {
          setZipAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 3000);
        setFieldError("zipCodes", `Zip code is already exists ${uniqueArray}`);
      } else {
        setEr(false);
        setFieldError("zipCodes", "");
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
    }
  }
  };

  useEffect(() => {
    get();
    getCurrentZips();
    setEr(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialValues.zipCodes.length > 0) {
      checkFranchiseeZips();
    }
    // eslint-disable-next-line
  }, [initialValues.zipCodes]);

  const validationSchema = Yup.object().shape({
    zoneName: Yup.string()
      .max(300, "Maximum length for the zone name is 15!")
      .required("Zone name required"),
  });

  return (
    <div>
      <Grid container>
        <Grid item>
          <Typography gutterBottom variant="h5" component="div" style={{ font: "bold" }} > Zones </Typography>
        </Grid>
      </Grid>

      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize >
        {({values,
          isValid,
          dirty,
          handleChange,
          setFieldValue,
          setFieldError,
        }) => {
          return (
            <Form noValidate autoComplete="off">
              <Grid container style={{ marginTop: "1rem"}} >
                <Grid item container > 
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                    <Field name="zoneName" component={TF} label="Zone Name" variant="outlined" fullWidth required onChange={handleChange} ></Field>
                  </Grid> 
                  <Grid item columnSpacing={5} lg={12} md={12} sm={12} xs={12} >
                    <FormControl component="fieldset">
                      <Autocomplete multiple freeSolo autoHighlight id="zipCodes" name="zipCodes" options={zipCodeList} getOptionLabel={(e) => e}
                        // eslint-disable-next-line
                        value={values.zipCodes}
                        onChange={(event, newValue) => { checkFranchiseeZips( newValue, setFieldError, setFieldValue ); }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => ( <Chip key={index} size="small" label={option ? option : ""} {...getTagProps({ index })} /> ))}
                        // eslint-disable-next-line
                        renderInput={(params) => 
                          ( 
                          <TextField 
                          style={{ maxWidth: "25rem", minWidth: "15rem", marginTop: "1rem" }}
                          label="Zip Codes"
                          {...params}
                          variant="outlined" 
                          />
                          )
                        }                            
                            />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {zipAlert.showAlert && ( <Grid item md={12} m={2}> <AlertInlineMain setAlert={setZipAlert} alert={zipAlert} /> </Grid> )}
              <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={12} style={{ marginTop: "1rem" }}>
                <Button style={{backgroundColor:"#1976D2"}} variant="contained" color="primary" type="submit" size="small" disabled={!isValid && !dirty} > Save </Button>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {alert.showAlert && ( <Grid item md={12} mt={2} style={{ marginTop: "1rem" }}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid> )}
    </div>
  );
}
