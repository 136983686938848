import React, { useState } from "react";
import { Grid, Card, CardContent, CardHeader, Button } from "@mui/material";
import DataGridTable from "../../components/layouts/DataGrid";
import Chip from "@mui/material/Chip";
import OrderView from "./OrderView";
import moment from "moment";


export default function OrderManagement() {
  const [status, setStatus] = useState(false);
  const [type, setType] = useState("pending");
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({});

  const columns = [
    { field: "orderId", headerName: "Order ID", minWidth: 100 },
    {
      field: "customer",
      headerName: "Customer Name",
      minWidth: 200,
      valueGetter: (params) => {
        return `${params.row.customer?.firstName || ''} ${params.row.customer?.lastName || ''}`;
      },
    },
    {
      field: "orderStatus",
      headerName: "Order Status",
      minWidth: 200,
      renderCell: (params) => {
        switch (params.row.orderStatus) {
          case "pending":
            return <Chip label="Pending" color="primary" />;
          case "process":
            return <Chip label="Processing" color="secondary" />;
          case "completed":
            return <Chip label="Completed" color="success" />;
          default:
            return <Chip label="Pending" color="primary" />;
        }
      },
    },

    {
      field: "orderType",
      headerName: "Services",
      minWidth: 250,
      valueGetter: (params) => {
        if (params.row.isManualOrder) {
          return "Manual Order"
        } else {
          switch (params.row.orderType) {
            case "1": return "Wash/Dry/Fold";
            case "2": return "Dry Cleaning";
            case "3": return "Tailoring";
            case "4": return "Dry Cleaning & Tailoring";
            case "5": return "Wash/Dry/Fold , Tailoring";
            case "6": return "Wash/Dry/Fold , Tailoring";
            case "7": return "Wash/Dry/Fold , Dry Cleaning";
            case "8": return "Wash/Dry/Fold , Dry Cleaning";
            case "9": return "Wash/Dry/Fold , Dry Cleaning, Tailoring";
            case "10": return "Wash/Dry/Fold , Dry Cleaning, Tailoring";
            default: return "-";
          }
        }
      },
    },
    {
      field: "pickupDate",
      headerName: "Pickup Date",
      minWidth: 300,
      valueGetter: (params) => {
        let pickupDate = params?.row?.pickupDate
        if (pickupDate?.includes("Z")) {
          pickupDate = (pickupDate?.split("T")[0])
        } else {
          pickupDate = params?.row?.pickupDate
        }
        return `${params?.row?.pickupDate ? moment(pickupDate).format("MMMM Do dddd YYYY") : "Not applicable"}`;
      },
    },
    {
      field: "diliverDate",
      headerName: "Delivery Date",
      minWidth: 300,
      valueGetter: (params) => {
          let diliverDate = params?.row?.diliverDate
          if (diliverDate?.includes("Z")) {
            diliverDate = (diliverDate?.split("T")[0])
          } else {
            diliverDate = params?.row?.diliverDate
          }
          return `${params?.row?.diliverDate ? moment(diliverDate).format("MMMM Do dddd YYYY") : "Not applicable"}`;
      },
    },
    {
      field: 'specialDate',
      headerName: 'Special Care Delivery Date',
      minWidth: 250,
      flex: 0.3,
      valueGetter: (params) => {
        let specialDate = params.row.specialDate
        if (specialDate?.includes("Z")) {
          specialDate = (specialDate?.split("T")[0])
        } else { 
          specialDate = params.row.specialDate
        }
        return `${params?.row?.specialDate ? moment(specialDate).format("MMMM Do dddd YYYY") : "Not applicable"}`;
      },
    },
    {
      field: "view",
      headerName: "View",
      minWidth: 100,
      disableExport: true,
      renderCell: (params) => {
        const onClickStatus = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setEditData(params.row);
          setOpen(true);
          setStatus(!status);
        };

        return <Button onClick={onClickStatus} data-testid={params.row.orderId}>View</Button>;
      },
    },
  ];

  const loadData = (e) => {
    setTimeout(() => {
      setType(e.target.name);
      setStatus(!status);
    }, 10);
  };

  return (
    <>
      <Card>
        <CardContent>
          <CardHeader title="Order Management" />
          <Grid container spacing={1}>
            <Grid container item spacing={1} xs={12} md={12} xl={12}>
              <Grid item xs={4} md={4} xl={4}>
                <Button
                  name="pending"
                  variant="contained"
                  color="primary"
                  fullWidth={true}
                  onClick={loadData}
                >
                  Pending
                </Button>
              </Grid>
              <Grid item xs={4} md={4} xl={4}>
                <Button
                  name="process"
                  variant="contained"
                  color="primary"
                  fullWidth={true}
                  onClick={loadData}
                >
                  Processing
                </Button>
              </Grid>
              <Grid item xs={4} md={4} xl={4}>
                <Button
                  name="completed"
                  variant="contained"
                  color="primary"
                  fullWidth={true}
                  onClick={loadData}
                >
                  Completed
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <DataGridTable
                title={`${type.toUpperCase() === "PROCESS" ? "PROCESSING" : type.toUpperCase()} Orders`}
                fetchDataUrl={`/orders/${type}`}
                columns={columns}
                status={status}
              />
            </Grid>
            <OrderView
              editData={editData}
              setStatus={setStatus}
              status={status}
              open={open}
              setOpen={setOpen}
            />
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
