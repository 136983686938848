import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import CircularProgress from '@mui/material/CircularProgress';

export default function Verification() {
  let navigate = useNavigate();
  const axios = useAxiosPrivate()
  const [message, setMessage] = useState('');
  const [stripeError, setStripeError] = useState('');
  const [backendCall, setBackendCall] = useState(false);

  let { state } = useLocation();

  const completeSalesConfig = async () => {
    //franchisee-onboarding
    try {
      const refreshUrl = `${window.location.origin}/fail`;
      const returnUrl = `${window.location.origin}/success/${state.id}`;

      const { data } = await axios.post('/user/franchisee-onboarding', {
        refreshUrl,
        returnUrl,
      });

      window.location.replace(data.url);
    } catch (error) {
      console.log(error);
    }
  };

  const redirect = async () => {
    try {
      setBackendCall(true);
      setMessage('Please wait until your data verification...');
      const { data } = await axios.put(`/user/confirmation/${state?.id}`, {
        status: 'completed',
      });

      if (data.status === 'completed') {
        setMessage('Payment process was successful');
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } else if (data.status === 'payment') {
        setMessage('Payment process was not successful , please check again!');
        setStripeError(data.disabled_reason);
      }
    } catch (error) {
      setMessage('Something went wrong , please check again!');
    } finally {
      setBackendCall(false);
    }
  };

  useEffect(() => {
    redirect();
    //eslint-disable-next-line
  }, []);
  return (
    <Grid
      container
      spacing={1}
      alignContent='center'
      style={{ paddingTop: '10rem' }}
    >
      <Grid
        container
        justifyContent='center'
        item
        xs={12}
        md={12}
        alignContent='center'
      >
        <Typography variant='h5'>{message}</Typography>
      </Grid>
      <Grid
        container
        justifyContent='center'
        item
        xs={12}
        md={12}
        alignContent='center'
      >
        {stripeError && (
          <Box mt={2}>
            <Typography variant='body1'>{stripeError}</Typography>
          </Box>
        )}
      </Grid>

      <Grid
        container
        justifyContent='center'
        item
        xs={12}
        md={12}
        alignContent='center'
      >
        {backendCall && (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        )}
      </Grid>
      <Grid
        container
        justifyContent='center'
        item
        xs={12}
        md={12}
        mt={1}
        style={{ marginTop: '1rem' }}
      >
        <Button
          variant='contained'
          color='primary'
          onClick={completeSalesConfig}
          data-testid="check_info"
        >
          Check info
        </Button>
      </Grid>
    </Grid>
  );
}
