import { useState, useEffect } from "react";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFranchiseeTimeZone } from "store/actions/authActions";

export default function Account() {
  const axios = useAxiosPrivate()
  const navigate = useNavigate();
  const user = useSelector((state) => state);
  const role = sessionStorage.getItem("role");
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [initialValues, setInitialValues] = useState({
    fID: "",
    firstName: "",
    ownerName: "",
    email: "",
    suitNo: "",
    address: "",
    serviceRegions: [],
    royaltyScheme: "",
    pricing: { serviceOne: 450, serviceTwo: 1200, serviceThree: 850 },
    note: "",
  });
  const dispatch = useDispatch();
  const [royaltySchemes, setRoyaltySchemes] = useState([]);

  //time Zone chanage
  const [timeZoneVal, setTImeZone] = useState(null);
  const [stillLoading, setStillLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required!")
      .trim()
      .matches(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter a valid email"
      )
      .max(320, "Must be less than 320 digits"),
    ownerName: Yup.string()
      .max(100, "Maximum length for the owner name is 100!")
      .required("Owner name required"),
    firstName: Yup.string()
      .max(100, "Maximum length for the Franchisee business name is 100!")
      .required("Owner name required"),
  });

  const onSubmit = async (e, { resetForm }) => {

    setStillLoading(true)
    try {
      const data = {
        fID: e.fID,
        firstName: e.firstName,
        email: e.email,
        ownerName: e.ownerName,
        address: e.address,
        serviceRegions: e.serviceRegions,
        royaltyScheme: e.royaltyScheme,
        note: e.note,
        role,
      };
      await axios.put(`/user/${user.auth.id}`, data);
      setAlert({
        showAlert: true,
        severity: "success",
        message: `${role === "manager" ? "Manager" : "Franchisee"} update Successfully!`,
      });
      get();
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 3000);

      setStillLoading(false)
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${role === "manager" ? "Manager" : "Franchisee"} update failed!`,
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "",
        });
      }, 3000);
      setStillLoading(false)
    }
  };

  // time Zone Change When Null
  const timeZoneChangeWhenNull = async (e) => {
    setStillLoading(true)
    try {
      await axios.post(`/user/timeZone/update`, { timeZone: e.target.value });
      await getTimeZone();
      navigate("/schedules")
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      setStillLoading(false)
    }
  };

  //time Zone change 
  const timeZoneChange = async (e) => {
    setStillLoading(true)
    try {
      await axios.post(`/user/timeZone/update`, { timeZone: e.target.value });
      await getTimeZone();
      setStillLoading(false)
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      setStillLoading(false)
    }
  };

  // get time Zone change
  const getTimeZone = async () => {
    setStillLoading(true)
    try {
      const { data } = await axios.get(`/user/timezone/get`);
      setTImeZone(data)
      dispatch(setFranchiseeTimeZone(data))
      sessionStorage.setItem("franchiseeTimeZone", data);

      setStillLoading(false)
    } catch (e) {
      setStillLoading(false)
      // eslint-disable-next-line
      console.log(e);
    }
  }

  // get Company
  const get = async (id) => {
    setStillLoading(true)
    try {
      const { data } = await axios.get(`/user/userAccount/details`);
      setInitialValues({
        fID: data.roleID,
        firstName: data.firstName,
        email: data.email,
        address: data.address,
        ownerName: data.ownerName,
        serviceRegions: data.serviceRegions,
        royaltyScheme: data.royaltyScheme,
        note: data.note,
      });
      setStillLoading(false)
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "",
        });
      }, 3000);
      setStillLoading(false)
    }
  };

  const getRoyalty = async () => {
    try {
      const { data } = await axios.get("/royalty/");
      setRoyaltySchemes(data);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "",
        });
      }, 3000);
    }
  };

  useEffect(() => {
    if (!((window.sessionStorage.getItem("role") === "corporate") ||  ((window.sessionStorage.getItem("managerType") === "corporate") && (window.sessionStorage.getItem("role") === "manager")))) {
      getRoyalty();
    }
    get();
    getTimeZone();
    // if (timeZoneVal == null && user) { getTimeZone(); } commenting this validation because we are calling getTimeZone function already
    // eslint-disable-next-line
  }, []);

  return {
    role, alert, initialValues, royaltySchemes, stillLoading, validationSchema, onSubmit,
    timeZoneChangeWhenNull, timeZoneChange, timeZoneVal, navigate, setAlert
  };
}
