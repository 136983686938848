import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { Skeleton, Grid, Button } from "@mui/material";

const mapStyles = {
  height: "60vh",
  width: "100%",
};

const zoom = 10;

const options = {
  zoomControl: true,
  streetViewControl: true,
  mapTypeControl: true,
  fullscreenControl: true,
};

const MapContainer = ({ state }) => {

  const center = {
    lat: Number(state?.location?.latitude),
    lng: Number(state?.location?.longitude),
  };

 
  const [map, setMap] = useState(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_KEY,
    libraries: ["places"],
  });
 
  const [google, setGoogle] = useState(window.google = window.google ? window.google : {})

  const onLoad = useCallback((map) => {

    if(map===null){
      const bounds = new window.google.maps.LatLngBounds(center);
    setGoogle(window.google)
    map.fitBounds(bounds);
    setMap(map)
    }
      // eslint-disable-next-line
    }, [map])


  const [icon, setIcon] = useState(null);

  useEffect(() => {
    if(isLoaded){
    setIcon({
      path: "M 15.385 2.412 C 10.195 2.412 6 6.597 6 11.772 c 0 1.21 0.586 3.06 1.5 5.082 c 0.914 2.02 2.142 4.231 3.371 6.273 c 2.256 3.746 4.156 6.373 4.514 6.873 c 0.357 -0.5 2.258 -3.127 4.513 -6.873 c 1.23 -2.042 2.458 -4.252 3.372 -6.273 c 0.913 -2.022 1.5 -3.872 1.5 -5.083 c 0 -5.174 -4.196 -9.359 -9.385 -9.359 Z m 0 2 c 4.136 0 7.5 3.364 7.5 7.5 s -3.364 7.5 -7.5 7.5 s -7.5 -3.364 -7.5 -7.5 s 3.364 -7.5 7.5 -7.5 Z m 0 1 a 6.492 6.492 0 0 0 -6.5 6.5 c 0 3.596 2.904 6.5 6.5 6.5 s 6.5 -2.904 6.5 -6.5 s -2.904 -6.5 -6.5 -6.5 Z m 0 1.5 l 2.535 2.34 v -0.11 a 0.5 0.5 0 1 1 1 0 v 0.909 a 0.51 0.51 0 0 1 -0.012 0.113 l 0.977 0.9 h -1.242 v 4.846 h -2.4 v -3.115 h -1.716 v 3.115 h -2.4 v -4.846 h -1.242 l 4.5 -4.152 Z", // svg here
      fillColor: state?.color,
      fillOpacity: 0.7,
      strokeWeight: 0,
      scale: 2,
      anchor: new google.maps.Point(15, 30)
    });
    }  
    // eslint-disable-next-line
  }, [state, isLoaded]); 

  

  if (!isLoaded) {
    return (
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        variant="rectangular"
        width={"100%"}
        height={"100vh"}
      />
    );
  }

  return isLoaded ? (
    <Grid container spacing={1}> 

      {/* Google Map Box */}
      <Grid item xs={12} md={12} lg={12}>
        <GoogleMap
          center={center}
          zoom={zoom}
          mapContainerStyle={mapStyles}
          options={options}
           // onLoad={(map) => setMap(map)}
           onLoad={onLoad}
        >
          <Marker position={center} icon={icon} />

          <Button
            sx={{
              top: "60px",
              left: "10px",
              zIndex: "11"
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              map.panTo(center);
              map.setZoom(zoom);
            }}
          >
            Center Map
          </Button>

        </GoogleMap>
      </Grid>
    </Grid >
      
    ): <></>;
};
export default MapContainer;
