import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ServiceOneFrEdit from 'components/services/ServiceOneFranchisorEdit';
import ServiceTwo from 'components/services/ServiceTwo';
import ServiceThree from 'components/services/ServiceThree';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
export default function AlertDialog({ open, number, setOpen, franchiseeId, ownerName, selectedTypeOption}) {
  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='lg'
      >
        <DialogTitle id='alert-dialog-title'>
          {open ? (
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 10,
                top: 8,
                color: 'grey',
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
          {number === 1
            ? 'Wash/Dry/Fold'
            : number === 2
            ? 'Dry Cleaning'
            : number === 3
            ? 'Tailoring and alteration'
            : ''}
        </DialogTitle>
        <DialogContent>
          {number === 1 ? (
            <ServiceOneFrEdit franchiseeId={franchiseeId} ownerName={ownerName} selectedTypeOption={selectedTypeOption} />
          ) : number === 2 ? (
            <ServiceTwo franchiseeId={franchiseeId} />
          ) : number === 3 ? (
            <ServiceThree franchiseeId={franchiseeId} />
          ) : (
            ''
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
