import React from 'react'
import { Alert } from "@mui/material";

export default function AlertMain({ setAlert, alert}) {
    return (
      <Alert
      severity={alert.severity}
      onClose={() =>
        setAlert({
          ...alert,
          showAlert: false, }) } >
      {alert.message}
    </Alert>
  )
}
