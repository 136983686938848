import React, { useEffect, useMemo, useState } from "react";
import Tabs from "@mui/material/Tabs";
import { Grid, TextField } from "@mui/material";
import Tab from "@mui/material/Tab";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Deliveries from "./Deliveries";
import Pickups from "./Pickups";
import Vehicles from "./Vehicles";
import ProgressBar from "./ProgressBar";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button } from "@material-ui/core";
import AddNewJobDialog from "./AddNewJobDialog";
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import EditNewJobDialog from "./EditNewJobDialog";
import DateSlashToDashReformat from "components/common/Functions/DateSlashToDashReformat";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function IconLabelTabs({ date, setDate }) {
  const axios = useAxiosPrivate();
  const [value, setValue] = useState(0);
  const [circularBarData, setCircularBarData] = useState({});
  const [addJobDialogOpen, setAddJobDialogOpen] = useState(false);
  const [editJobDialogOpen, setEditJobDialogOpen] = useState(false);
  const [vehiclesInfo, setVehiclesInfo] = useState(null);
  const [editJobDetails, setEditJobDetails] = useState(null);
  const [isJobsDispatched, setIsJobsDispatched] = useState(false);

  useEffect(() => {

    const getVehicals = async () => { 
      const { data } = await axios.get("/vehicle");
      let vehicles = [];
      for (const v of data) {
        if(v?.driver) vehicles.push(v)
      }
      setVehiclesInfo(vehicles)
    }

    getVehicals();

  },[])

  useEffect(() => {
    checkIfDispatched();
  },[date])

  const fomratedPayloadDate = useMemo(() => { 
    const returnFormatedDate = DateSlashToDashReformat(date);
    return returnFormatedDate 
  }, [date]);

  const checkIfDispatched = async () => {

    try {
      const { data } = await axios.get(`/job/job-franchisee/${fomratedPayloadDate}`)

      if(data?.dispatch){
        setIsJobsDispatched(data?.dispatch)
      }else{
        setIsJobsDispatched(data?.dispatch)
      }

    } catch (error) {
			console.log("error :-", error)
    }
  }

  const addNewJobOpenDialog = () => {
    setAddJobDialogOpen(true);
  };
  const addNewJobCloseDialog = () => {
    setAddJobDialogOpen(false);
  };
  const editNewJobCloseDialog = () => {
    setEditJobDialogOpen(false);
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
    <Box sx={{ width: "100%", marginTop: "1rem" }}>
      <Grid container
        rowSpacing={{
          lg: 0,
          md: 4,
          sm: 4,
          xs: 4,
        }}
      >
        <Grid container item
          alignItems="center"
          justifyContent="center"
          lg={6}
          md={12}
          sm={12}
          xs={12}
          mt={2}
        >
          <Grid item
            lg={"auto"}
            md={"auto"}
            sm={"auto"}
            xs={"auto"}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date"
                value={date}
                onChange={(newValue) => {
                  newValue.setHours(0, 0, 0)
                  setDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid container item
          alignItems="center"

          justifyContent={{
            lg: "center",
            md: "center",
            sm: "center",
            xs: "center"
          }}

          lg={6}
          md={12}
          sm={12}
          xs={12}
        >
          <Grid item>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  icon={<AltRouteIcon fontSize="small" />}
                  label="Deliveries"
                  {...a11yProps(0)}
                />
                <Tab
                  icon={<FeaturedPlayListIcon fontSize="small" />}
                  label={"Pickups"}
                  {...a11yProps(1)}
                />
                <Tab
                  icon={<FeaturedPlayListIcon fontSize="small" />}
                  label={"Vehicles"}
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
          </Grid>
        </Grid>

      </Grid>
      {
        value === 0 ? (
          <div>
          <Box style={{ marginTop: "1rem" }}>
            <ProgressBar circularBarData={circularBarData}
              filterDataObj={{
                inProgress: "Set to deliver",
                completed: "Delivered",
                fail: "Delivery Cancelled"
              }} />
          </Box>
          <div
            style={{
              display:"flex",
              flexDirection:"row",
              justifyContent:"flex-end"
                   }}>
           {isJobsDispatched && <Button variant="contained" style={{backgroundColor:"#4bb8f2"}} onClick={() => setAddJobDialogOpen(true)}>Add Delivery</Button>}
          </div>  
         </div>
        ) : value === 1 ? (
          <>
          <Box style={{ marginTop: "1rem" }}>
            <ProgressBar circularBarData={circularBarData}
              filterDataObj={{
                inProgress: "Set to pickup",
                completed: "Processing",
                fail: "Pickup Cancelled"
              }}
            />
          </Box>
          <div
             style={{
               display:"flex",
               flexDirection:"row",
               justifyContent:"flex-end"
                    }}
           >
           {isJobsDispatched && <Button variant="contained" style={{backgroundColor:"#4bb8f2"}}  onClick={() => setAddJobDialogOpen(true)}>Add Pickup</Button>}
            </div>          
          </>

        ) : (
          ""
        )
      }
      {
        value === 0 ? (
          <TabPanel value={value} index={0}>
            <Deliveries date={date} status="DELIVERY" setCircularBarData={setCircularBarData} setEditJobDialogOpen={setEditJobDialogOpen} setEditJobDetails={setEditJobDetails} editJobDialogOpen={editJobDialogOpen} addJobDialogOpen={addJobDialogOpen}/>
          </TabPanel>
        ) : value === 1 ? (
          <TabPanel value={value} index={1} >
            <Pickups date={date} status="COLLECTION" setCircularBarData={setCircularBarData} setEditJobDialogOpen={setEditJobDialogOpen} setEditJobDetails={setEditJobDetails} editJobDialogOpen={editJobDialogOpen} addJobDialogOpen={addJobDialogOpen}/> 
          </TabPanel>
        ) : value === 2 ? (
          <TabPanel value={value} index={2}>
            <Vehicles date={date} />
          </TabPanel>
        ) : (
          ""
        )
      }
    </Box >
    <AddNewJobDialog open={addJobDialogOpen} onClose={addNewJobCloseDialog} vehiclesInfo={vehiclesInfo} type={value} date={date} />
    <EditNewJobDialog open={editJobDialogOpen} onClose={editNewJobCloseDialog} vehiclesInfo={vehiclesInfo} type={value} date={date} editJobDetails={editJobDetails}/>
  </>
  );
}
