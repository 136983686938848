import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AlertInlineMain from "components/common/AlertInlineMain";
import Grid from '@mui/material/Grid';

export default function DeleteRecord({
  title,
  message,
  deleteRecord,
  alert,
  setAlert,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setAlert({
      showAlert: false,
      severity: 'success',
      message: '',
    });
    setOpen(true);
  };

  const handleDelete = () => {
    deleteRecord();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton aria-label='delete' color='error' onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={'sm'}
        maxWidth={'sm'}
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {message}
          </DialogContentText>
          {alert.showAlert && (
            <Grid item md={12} m={2}>
              <AlertInlineMain setAlert={setAlert} alert={alert} />
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} variant='contained' color='error'>
            Yes
          </Button>
          <Button
            onClick={handleClose}
            variant='contained'
            style={{ backgroundColor: 'grey' }}
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
