import React, { Fragment, useState } from 'react';
import { CardContent } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { useLocation, useNavigate } from 'react-router-dom';
import FolderIcon from '@mui/icons-material/Folder';
import FolderViewTable from './MFolderViewCardList';
import DeleteConfirmation from '../../../components/common/DeleteConfirmation';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import Folder from '../Folder';
import Paper from '@mui/material/Paper';
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import AlertMain from 'components/common/AlertMain';

const MFolderView = (props) => {
  const axios = useAxiosPrivate()
  const [level1, setLevel1] = useState('');
  const [level2, setLevel2] = useState('');
  const [level1n, setLevel1n] = useState('');
  const [level2n, setLevel2n] = useState('');
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  }); 
  const { state } = useLocation();

  const TableB = ({ item, changeStatus, classes, onUpdate, loadData }) => {
    const changeUrl = (id, name) => {
      if (!level1 && !level2) {
        setLevel1n(name);
        setLevel1(id);
        loadData(`/folder/sub/${id}`);
      } else if (level1 && !level2) {
        setLevel2n(name);
        setLevel2(id);
        loadData(`/folder/sub/${id}`);
      }
    };

    const role = sessionStorage.getItem('role');
    const history = useNavigate();

    const changeShare1 = async (id, share) => {
      await axios.put('/folder/fileshare', {
        id,
        share: !share,
      });

      setAlert({ showAlert: true, severity: "success", message: !share ? "Successfully Shared" : "Share disabled", });
      setTimeout(() => { setAlert({ showAlert: false, severity: "success", message: "", }); }, 3000);
      if (!level1 && !level2) { loadData('/folder/all'); }
      else if (level1 && !level2) { loadData(`/folder/sub/${level1}`); }
      else if (level1 && level2) { loadData(`/folder/sub/${level2}`); }
    };

    const changeShare2 = async (id, share) => {
      await axios.put('/folder/foldershare', { id, share: !share, });
      setAlert({ showAlert: true, severity: "success", message: !share ? "Successfully Shared" : "Share disabled", });
      setTimeout(() => { setAlert({ showAlert: false, severity: "success", message: "", }); }, 3000);

      if (!level1 && !level2) { loadData('/folder/all'); }
      else if (level1 && !level2) { loadData(`/folder/sub/${level1}`); }
      else if (level1 && level2) { loadData(`/folder/sub/${level2}`); }
    };

    const changeDirection = (id) => { history('/franchiseeListView'); };

    return (
      <>
       <AlertMain setAlert={setAlert} alert={alert} />
      <Fragment>
        {item.size ? (
          <Paper style={{ margin: '0.5rem', padding: '0.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', }} >
            <div key={item.name}>
              <a target='_blank' rel='noopener noreferrer' href={item.url} style={{ textDecoration: 'none', color: '#000' }} >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {' '}
                  <DescriptionIcon />
                  <p style={{ paddingLeft: 5 }}>
                    {' '}
                    {item.name.length > 20 ? item.name.substring(0, 20) + '...' : item.name}
                  </p>
                </div>
              </a>
            </div>

            {(role === 'franchisor' || state?.user === 'user') && (
              <div key={item.owner.lastName}>
                {state?.user.length !== 24 && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Folder level1={level1} level2={level2} loadData={loadData} file='file' id={item._id} filename={item.name} />
                    <DeleteConfirmation title={'File Deletion?'} message={ 'This file will be permanently deleted from this Drive' }
                      changeStatus={changeStatus} id={item._id} status={item.status} file='file' />
                    {state?.user === 'user' && (
                      <IconButton color={item.share ? 'success' : 'error'} onClick={() => changeShare1(item._id, item.share)} >
                        <ShareIcon />
                      </IconButton> )}
                  </div> )}
              </div> )}
          </Paper>
        ) : item.different ? (
          <Paper style={{ margin: '0.5rem', padding: '0.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', }} >
            <div onClick={changeDirection}>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', }} >
                {' '}
                <FolderSharedIcon />
                <p style={{ paddingLeft: 5 }}> Franchisee Shared</p>
              </div>
            </div>
          </Paper>
        ) : (
          <Paper style={{ margin: '0.5rem', padding: '0.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', }} >
            <div onClick={() => changeUrl(item._id, item.name)} key={item.name}>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', }} >
                {' '}
                <FolderIcon />
                <p style={{ paddingLeft: 5 }}>
                  {' '}
                  {item.name.length > 20 ? item.name.substring(0, 20) + '...' : item.name}
                </p>
              </div>
            </div>

            {(role === 'franchisor' || state?.user === 'user') && (
              <div key={item.owner.lastName}>
                {state?.user.length !== 24 && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Folder level1={level1} level2={level2} loadData={loadData} edit='edit' id={item._id} filename={item.name} />
                    <DeleteConfirmation title={'Folder Deletion?'} message={ 'This folder will be permanently deleted from this Drive' }
                      changeStatus={changeStatus} id={item._id} status={item.status} />
                    {state?.user === 'user' && (
                      <IconButton color={item.share ? 'success' : 'error'} onClick={() => changeShare2(item._id, item.share)} >
                        <ShareIcon />
                      </IconButton> )}
                  </div> )}
              </div> )}
          </Paper> )}
        </Fragment>
        </>
    );
  };

  return (
    <CardContent style={{ padding: '1rem' }}>
      <h2 style={{ marginBottom: '1rem' }}>LWL Learning</h2>
      <FolderViewTable items={[]} TableB={TableB} fetchDataUrl='/folder/all' statusChangeUrl='/folder/status' level1n={level1n}
        level2n={level2n} level1={level1} level2={level2} learn={state?.user} setLevel1={setLevel1}
        setLevel2={setLevel2} setLevel1n={setLevel1n} setLevel2n={setLevel2n} {...props} />
    </CardContent>
  );
};

export default MFolderView;
