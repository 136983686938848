import React, { useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import DataGridTable from "../layouts/DataGrid";
import AlertInlineMain from "components/common/AlertInlineMain";
import moment from "moment";
const numberWithCommas = (x) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};



function RoyaltyPaymentsPopup(props) {
  const { setOpen, open, row, setStatus, status } = props;
  const timeZone = sessionStorage.getItem("franchiseeTimeZone") ? sessionStorage.getItem("franchiseeTimeZone") : Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const columns = [
    {
      field: "role_ID",
      headerName: "Franchisee ID",
      align: "left",
      width: 150,
    },
    {
      field: "date",
      headerName: "Payment Date",
      width: 160,
      align: "left",
      valueGetter: (params) => {
        return `${moment.tz(params.row.date, `${timeZone}`).format( "DD/MM/YYYY" )}`;
      },
    },
    {
      field: "payment",
      headerName: "payment $",
      align: "left",
      headerAlign: "left",
      width: 150,
      valueGetter: (params) => {
        function calculateSales(value) {
          return numberWithCommas(Number(value).toFixed(2));
        }
        return `$${calculateSales(params.row.payment ?? 0)}`;
      },
    },
    {
      field: "status",
      headerName: "Status",
      align: "left",
      width: 130,
      renderCell: (params) => {
        if (params.row.status) {
          return (
            <Typography variant="body2" color="primary">
              Paid
            </Typography>
          );
        } else {
          return (
            <Typography variant="body2" color="primary">
              Not Paid
            </Typography>
          );
        }
      },
      valueGetter: (params) => `${params.row.status ? "Paid" : "Not Paid"}`
    },
    {
      field: "description",
      headerName: "description",
      width: 300,
      align: "left",
    },
  ];


  const handleClose = () => {
    setOpen(false);
    setStatus(!status);
  };



  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}

        onClose={handleClose}
      >
        <DialogTitle sx={{ cursor: "move", alignSelf: "center" }}>Payment History</DialogTitle>
        <DialogContent>

          <Grid item /*style={{ height: 350, width: "100%" }}*/ xs={12} md={12}>
            <DataGridTable
              fetchDataUrl={`/royalty/payments/${row}`}
              columns={columns}
              status={status}
              title="Royalty Payments History"
            />

            {alert.showAlert && (
              <Grid item md={12} m={2}>
                <AlertInlineMain setAlert={setAlert} alert={alert} />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RoyaltyPaymentsPopup;
