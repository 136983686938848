import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Paper } from "@material-ui/core";
import ConstructionIcon from '@mui/icons-material/Construction';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
// import CalculateIcon from '@mui/icons-material/Calculate';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import { showNav } from 'store/actions/headerActions';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { useDispatch, useSelector } from "react-redux";

export default function Header({ navFrom }) {
  const mapLocation = useSelector((s) => s.route?.depot);
  let dispatch = useDispatch();
  const [locationforRoute, setLocationforRoute] = useState(null);
  const [Address, setAddress] = useState("Loading...");
  const navigate = useNavigate();

  const navigateTo = (path) => { dispatch(showNav()); navigate(path); };

  const getAddress = () => {
    if (locationforRoute) { setAddress(locationforRoute) }
    else {
      const address = mapLocation?.address !== 'undefined' ? mapLocation?.address : null;
      sessionStorage.setItem("routingLocation", JSON.stringify(address));
      setAddress(locationforRoute)
    }
  };

  useEffect(() => {
    getAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationforRoute, mapLocation]);

  useEffect(() => {
    setLocationforRoute(JSON.parse(sessionStorage.getItem('routingLocation') !== 'undefined' ? sessionStorage.getItem('routingLocation') : null))
  }, [mapLocation])

  const onGoback = () => {
    sessionStorage.removeItem("routingLocation");
    navigateTo('/franchisee-dashboard')
  }

  return (

    <Paper elevation={2} >
      <Grid container
        style={{ height: "100%", paddingTop: "0.8rem", paddingBottom: "0.8rem", paddingLeft: "0.5rem", paddingRight: "0.5rem" }}
        columnSpacing={{ lg: 2, md: 0, sm: 0, xs: 0, }}
        rowSpacing={{ lg: 0, md: 2, sm: 2, xs: 2, }} >
        
        {/* 1st section */}
        <Grid container item alignItems="center" ustifyContent="center" lg={4} md={12} sm={12} xs={12} >
          <Grid item >
            <IconButton onClick={onGoback} >
              <ArrowBackIcon sx={{ color: "#343434", }} />
            </IconButton>
          </Grid>
        </Grid>

        {/* 2nd section */}
        <Grid container item alignItems="center" justifyContent="center" lg={"auto"} md={12} sm={12} xs={12} >
          <Grid item>
            <Box sx={{ backgroundColor: "#CECECE", paddingTop: "0.5rem", paddingBottom: "0.5rem", paddingLeft: "0.5rem", paddingRight: "0.5rem",
                width: { lg: "16rem", md: "20rem", sm: "20rem", xs: "20rem", }, borderRadius: "0.4rem" }} >
              <Typography variant="h6" style={{ color: "black", fontSize: "1rem", width: "10rem", lineHeight: "1.3rem" }} >
                <span style={{ fontWeight: 400, fontSize: "0.8rem" }}> Location:</span> <br></br>
                <span style={{ fontSize: "0.9rem" }} > {Address} </span>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* 3rd section */}
        <Grid container item alignItems="center" justifyContent="center" lg={"auto"} md={12} sm={12} xs={12} columnSpacing={2} >
          <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"} >
            <Button variant="text" onClick={() => navigate("/routing-main")}
              disabled={navFrom === "routing" ? true : false}
              sx={{ backgroundColor: (navFrom === "routing") ? "#1976D2" : "none", }} >
              <Grid container> 
                <Grid container item alignItems="center" >
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                    <AltRouteIcon sx={{ fontSize: "2rem", color: (navFrom === "routing") ? "white" : "#343434" }} />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                    <Typography sx={{
                      textTransform: "capitalize", color: (navFrom === "routing") ?
                        "white" : "#343434", fontSize: "0.9rem", fontWeight: 500
                    }} > Routing </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Button>
          </Grid>
          <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"} >
            <Button variant="text" onClick={() => navigate("/depot-main")} disabled={navFrom === "setup" ? true : false}
              sx={{ backgroundColor: (navFrom === "setup") ? "#1976D2" : "none", }} >
              <Grid container> 
                <Grid container item alignItems="center" justifyContent="center" >
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                    <ConstructionIcon sx={{ fontSize: "2rem", color: (navFrom === "setup") ? "white" : "#343434", }} />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                    <Typography sx={{ textTransform: "capitalize", color: (navFrom === "setup") ? "white" : "#343434", fontSize: "0.9rem", fontWeight: 500 }} >
                      Setup </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Button>
          </Grid>
          <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"} >
            <Button variant="text" onClick={() => navigate("/jobs")} disabled={navFrom === "jobs" ? true : false}
              sx={{ backgroundColor: (navFrom === "jobs") ? "#1976D2" : "none", }} >
              <Grid container>
                <Grid container item alignItems="center" justifyContent="center" >
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                    <ContactMailOutlinedIcon sx={{ fontSize: "2rem", color: (navFrom === "jobs") ? "white" : "#343434", }} />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                    <Typography sx={{ textTransform: "capitalize", color: (navFrom === "jobs") ? "white" : "#343434", fontSize: "0.9rem", fontWeight: 500 }} >
                      Jobs </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Button>
          </Grid>
          <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"} >
            <Button variant="text" onClick={() => navigate("/jobs-search")} disabled={navFrom === "jobs search" ? true : false}
              sx={{ backgroundColor: (navFrom === "jobs search") ? "#1976D2" : "none", }} >
              <Grid container>
                <Grid container item alignItems="center" justifyContent="center" >
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                    <SearchOutlinedIcon sx={{ fontSize: "2rem", color: (navFrom === "jobs search") ? "white" : "#343434", }} />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                    <Typography sx={{ textTransform: "capitalize", color: (navFrom === "jobs search") ? "white" : "#343434", fontSize: "0.9rem", fontWeight: 500 }} >
                      Jobs search</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Button>
          </Grid>
          {/* <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"} >
            <Button variant="text" onClick={() => navigate("/matrix")}
              disabled={navFrom === "matrix" ? true : false}
              sx={{ backgroundColor: (navFrom === "matrix") ? "#1976D2" : "none", }}>
              <Grid container>
                <Grid container item alignItems="center" justifyContent="center" >
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                    <CalculateIcon sx={{ fontSize: "2rem", color: (navFrom === "matrix") ? "white" : "#343434", }} />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                    <Typography sx={{ textTransform: "capitalize", color: (navFrom === "matrix") ? "white" : "#343434", fontSize: "0.9rem", fontWeight: 500 }} >
                      Matrix </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Button>
          </Grid> */}
        </Grid>
      </Grid>
    </Paper>
  );
}
