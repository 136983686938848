import React, {useState, useEffect} from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Grid, Card, CardContent, Typography, Alert, MenuItem } from "@mui/material";
import { TextField } from "formik-material-ui";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(100, "Maximum length for the manager first name is 100!")
    .required("First Name is required"),
  lastName: Yup.string()
  .max(100, "Maximum length for the manager last name is 100!")
  .required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  createPassword: Yup.string().required("Password is required!")
      .matches(
        /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
        "One Uppercase, One Lowercase, One Number,one special case Character and Must Contain 8 Characters")
      .min(8, "Must Contain 8 Characters"),
});



const CreateManagerForm = () => {
    const axios = useAxiosPrivate()
    let navigate = useNavigate();
    const { state } = useLocation();
    const [backendCall, setBackendCall] = useState(false);
    const [componentType, setcomponentType]=  useState("create");
    const [initialValues, setInitialValues] = useState({
      managerId: "",
      firstName: "",
      lastName: "",
      email: "",
      createPassword: "",
      id: "",
    });
  
    const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });

    useEffect(() => {
        if (state && state?.id) {
        setcomponentType("update");
        initialValues.firstName = state.data.firstName;
        initialValues.lastName = state.data.ownerName;
        initialValues.email = state.data.email;
        initialValues.managerId = state.data.roleID;
        initialValues.status = state.data.status;
        initialValues.id = state.data._id;
        }
      }, [state?.id]);

    const onSubmit = async (e, { resetForm }) => {
      if(componentType === "update"){

        try {
          try {
              setBackendCall(true);
              const save = {
                  id:e.id,
                  status:e.status
              };
              await axios.put("/user/manager/update-status", save);
              resetForm();
              setInitialValues({
                id:e.id,
                status:e.status
              });
              setAlert({
                showAlert: true,
                severity: "success",
                message: "Manager Updated Successfully!",
              });
              navigate("/managers-view");
            } catch (error) {
              setBackendCall(false);
             if (error.response.data === "Email already exists!") {
                setAlert({
                  showAlert: true,
                  severity: "error",
                  message: "Email already exists!",
                });
              } else if (
                error.response.data.email[0] === '"email" must be a valid email'
              ) { setAlert({
                  showAlert: true,
                  severity: "error",
                  message: "Email must be a valid!",
                });
              } else { setAlert({
                  showAlert: true,
                  severity: "error",
                  message: "Manager create failed!",
                });
              } 
            } 
        } catch (error) {
          console.error("Form submission error:", error);
        }finally{
          setBackendCall(false);            
      }

      }else{
        try {
          try {
              setBackendCall(true);
              const save = {
                firstName: e.firstName,
                ownerName: e.lastName,
                password: e.createPassword,
                email:e.email,
                role:'manager'
              };
              await axios.post("/user", save);
              resetForm();
              setInitialValues({
                  managerId: "",
                  firstName: "",
                  lastName: "",
                  email: "",
                  createPassword: ""
              });
              setAlert({
                showAlert: true,
                severity: "success",
                message: "Manager created Successfully!",
              });
              navigate("/managers-view");
            } catch (error) {
              setBackendCall(false);
             if (error.response.data === "Email already exists!") {
                setAlert({
                  showAlert: true,
                  severity: "error",
                  message: "Email already exists!",
                });
              } else if (
                error.response.data.email[0] === '"email" must be a valid email'
              ) { setAlert({
                  showAlert: true,
                  severity: "error",
                  message: "Email must be a valid!",
                });
              } else { setAlert({
                  showAlert: true,
                  severity: "error",
                  message: "Manager create failed!",
                });
              } 
            } 
        } catch (error) {
          console.error("Form submission error:", error);
        }finally{
          setBackendCall(false);            
      }
      }
     
    };

    const onGoback = () => {
      navigate('/managers-view')
    }
  
    return (
      <Card>
        <CardContent>
          <Grid container justifyContent="space-between" mb={5}>

          <Grid item >
            <IconButton onClick={()=>onGoback()}>
              <ArrowBackIcon sx={{ color: "#343434", }} />
            </IconButton>
          </Grid>
      


            <Grid item>
              <Typography gutterBottom variant="h5" component="div">
               {componentType === "create" ?  "Create User" : "Update User" }
              </Typography>
            </Grid>

            <Grid item >
            <div style={{width:'10px'}}></div>
          </Grid>
          </Grid>
          <Formik
            initialValues={initialValues}
            validationSchema={componentType === "create" ? validationSchema : ""}
            onSubmit={onSubmit}
          >
              {({ values, isValid, handleChange, setFieldValue, }) => (
              <Form>
                <Grid container spacing={2}>
                <Field
                      name="id"
                      label="ObjectId"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      style={{ display: "none" }}
                    />
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="firstName"
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      disabled={componentType === "update" ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="lastName"
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      disabled={componentType === "update" ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      disabled={componentType === "update" ? true : false}
                    />
                  </Grid>
                  {componentType === "create"  &&
                  <Grid item xs={12}>
                    <Field
                      name="createPassword"
                      label="Create Password"
                      type="text"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                    }
                {componentType === "update"  &&

               <Grid item xs={12}>
                  <Field name="status" label="Status" variant="outlined" fullWidth component={TextField} select>
                      <MenuItem key="pending" value="pending" disabled="Pending">
                        Pending
                      </MenuItem>
                      <MenuItem key="active" value="active" >
                      Active
                      </MenuItem>
                      <MenuItem key="inactive" value="inactive">
                      Inactive
                      </MenuItem>
                  </Field>
                </Grid>
}                   
                  <Grid item xs={12}>
                          <LoadingButton loading={backendCall} style={{ width: "8rem" }} variant="contained" type="submit">
                          {componentType === "create" ?  "Create" : "Update" }
                          </LoadingButton>
                           
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          {alert.showAlert && (
            <Grid item md={12} mt={2}>
              <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, showAlert: false, }) } > {alert.message} </Alert>
            </Grid> )}
        </CardContent>
      </Card>
    );
  };
  
  export default CreateManagerForm;
  