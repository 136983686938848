import React, { useState } from 'react';
import DataGridTable from 'components/layouts/DataGrid4';

import { Grid, Card } from '@mui/material';
import BasicDateRangePicker from 'components/common/BasicDateRangePicker';
import { Typography } from '@material-ui/core';

export default function DaywiseSales() {
  const [status, setStatus] = useState(false);
  const [value, setValue] = useState([]);

  const columnsFranchisee = [
    { field: 'date', headerName: 'DATE', minWidth: 100, flex: 0.2 },
    // {
    //   field: 'franchiseeId',
    //   headerName: 'Franchisee ID',
    //   minWidth: 100,
    //   flex: 0.2,
    // },
    {
      field: 'sales', headerName: 'SALES', minWidth: 100, flex: 0.2,
      valueGetter: (params) => {
        return Number(params?.row?.sales).toFixed(2);
      },
    },
    {
      field: 'royaltyFees',
      headerName: 'Royalty Fees',
      minWidth: 100,
      flex: 0.2,
      valueGetter: (params) => {
        return Number(params?.row?.royaltyFees).toFixed(2);
      },
    },
    {
      field: 'netSales', headerName: 'NET SALES', minWidth: 100, flex: 0.2,
      valueGetter: (params) => {
        return Number(params?.row?.netSales).toFixed(2);
      },
    },
  ];

  const columnsCorporate = [
    { field: 'date', headerName: 'DATE', minWidth: 100, flex: 0.2 },
    // {
    //   field: 'franchiseeId',
    //   headerName: 'Franchisee ID',
    //   minWidth: 100,
    //   flex: 0.2,
    // },
    {
      field: 'sales', headerName: 'SALES', minWidth: 100, flex: 0.2,
      valueGetter: (params) => {
        return Number(params?.row?.sales).toFixed(2);
      },
    },
  
  ];
  return (
    <Card>
      <Grid container
        columnSpacing={2}
        alignItems="center"
        justifyContent={{
          lg: "left",
          md: "left",
          sm: "center",
          xs: "center",
        }}
      >
        <Grid item
          ml={3}
          mt={1}
          lg={"auto"}
          md={"auto"}
          sm={"auto"}
          xs={"auto"}
        >
          <Typography variant='h4' component='h4' align='right'>
            Sales Figure
          </Typography>
        </Grid>

        <Grid item mt={1}
          lg={"auto"}
          md={"auto"}
          sm={"auto"}
          xs={"auto"}
        >
          <BasicDateRangePicker
            value={value}
            setValue={setValue}
            setStatus={setStatus}
            status={status}
          />
        </Grid>

        <Grid item
          lg={12}
          md={12}
          sm={12}
          xs={12}>
          <DataGridTable
            fetchDataUrl={`/reports/franchisee-sales/detail`}
            columns={ (sessionStorage.getItem('role') === 'corporate' || sessionStorage.getItem('managerType') === 'corporate')?  columnsCorporate : columnsFranchisee}
            status={status}
            payload={{
              startDate: value?.startDate,
              endDate: value?.endDate,
            }}
            title=''
          />
        </Grid>
      </Grid>
    </Card>
  );
}
