import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import AlertMain from 'components/common/AlertMain';
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";

import { Grid } from '@mui/material';

import { Form, Formik, Field } from 'formik';
import { TextField as TF } from 'formik-material-ui';

export default function AlertDialog({
  setOpen,
  open,
  balance,
  accBalance,
  getBalance,
}) {
  const axios = useAxiosPrivate()
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const [loading, setLoading] = useState(false);

  const initialValues = {
    amount: balance ?? 0,
    payment: '',
  };

  const handleClose = () => {
    if(!loading){
    setOpen(false);
    getBalance();}
  };

  const onSubmit = async (e, { resetForm }) => {
    try {
      setLoading(true);
      const data = {
        amount: e.amount,
        payment: e.payment,
        description: e.description,
      };

      if (data.amount > accBalance) {

        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Insufficient Balance',
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: '',
          });
        }, 3000);
        return;
      }

      await axios.post('/payment', data);

      setAlert({
        showAlert: true,
        severity: 'success',
        message: 'Payment Success!',
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'success',
          message: '',
        });

        handleClose();
      }, 3000);
      resetForm();
    } catch (error) {
      if (error.response.status === 403) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: `${error.response.data.message}`,
        });

        // setTimeout(() => {
        //   setAlert({
        //     showAlert: false,
        //     severity: 'error',
        //     message: 'Payment Failed!',
        //   });
        // }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Payment Failed!',
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'success',
            message: '',
          });
        }, 3000);
      }
    } finally {
      getBalance();
      setLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string().max(300, 'Must be 300 characters or less'),
    amount: Yup.number()
      .typeError('Please enter a valid number')
      .required('Amount required')
      .positive()
      .test(
        "Is positive?",
        "The amount must be to the 2 decimal points !",
        (value) => (value + "").match(/^[0-9]*(\.[0-9]{0,2})?$/),
      ),
      // .test('is-decimal', 'invalid decimal', (value) =>
      //   (value + '').match(/^\d*$/)
      // ),
  });

  return (
    <>
    {loading && <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 999, }}></div>}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='sm'
      >
        <DialogContent>
          <DialogTitle>
            Payment
            {open ? (
              <IconButton
                aria-label='close'
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 8,
                  color: 'grey',
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ values, isValid, dirty }) => {
              return (
                <Form noValidate autoComplete='off'>
                  <Grid container spacing={2} 
                  // xs={12} md={12} xl={12}
                  >
                    <Grid item xs={12} md={12}>
                      <Typography variant='h6'>
                        Account balance : 
                        {`$${Number(accBalance).toFixed(2)}` ?? 'loading...'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        name='amount'
                        label='Amount'
                        component={TF}
                        variant='outlined'
                        fullWidth
                        type='number'
                        required
                      ></Field>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        name='description'
                        label='Description'
                        component={TF}
                        variant='outlined'
                        fullWidth
                      ></Field>
                    </Grid>
                  </Grid>

                  <DialogActions>
                    <Button
                      data-testid="royaltyPayBtn"
                      variant='contained'
                      color='success'
                      type='submit'
                      autoFocus
                      disabled={!isValid || !dirty || (Number(accBalance) === 0) || loading}
                    >
                     { loading ? 'wait' : 'Pay'}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
          <AlertMain setAlert={setAlert} alert={alert} />
        </DialogContent>
      </Dialog>
    </>
  );
}
