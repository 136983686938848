import React, { useEffect, useState } from 'react';
import { Grid, Chip, Button, Checkbox } from '@mui/material';
import DataGrid from '../layouts/DataGrid';
import EditIcon from '@mui/icons-material/Edit';
import EditDialog from './EditDialog';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useSelector } from 'react-redux';
import AlertMain from 'components/common/AlertMain';
import { debounce } from "debounce";

export default function ServiceTwo({ franchiseeId }) {
  const axios = useAxiosPrivate()
  const role = useSelector((state) => state.auth.role);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [componentType, setComponentType] = useState('create');
  const [newData, setNewData] = useState([]); 
  const [loadDisabled, setloadDisabled] = useState(false)
  const franchiseeName = sessionStorage.getItem("ownerName");
  const roleType = sessionStorage.getItem("role") === "manager" ?  sessionStorage.getItem("managerType") : sessionStorage.getItem("role") ;

  const [status, setStatus] = useState(false);
  const [columns, setColumns] = useState([]);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  let url;
  
  if (role === 'franchisor') { url = '/item/dryCleaning'; }
  if (role === 'franchisee' || role === 'corporate' || role === "manager") { url = '/franchiseeItems/dryCleaning'; }
  const handleClickOpen = (componentType) => {
    setComponentType(componentType);
    setOpen(true);
  };
  
  const loadData = async () => {
    setloadDisabled(true)
    try {

      const result = await axios.get(url);

      if(result.status === 200){
        let data = result?.data;
        if (role === 'franchisee' || role === 'corporate' || role === 'manager') { // only franchisor can see all the items, other users can see only the active items
          
          const statusTrueRows = data.filter(item => item?.item ? item?.item?.status === true : !item?.item)

          setNewData(statusTrueRows);

        } else {
          setNewData(data);
        }
      }

      setloadDisabled(false)

    } catch (error) {
      setloadDisabled(false)
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Authorized Access!',
        });
      } else {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Something went wrong!',
        });
      }
    }
  };

  const changeStatus = async (newStatus, data) => {
    setloadDisabled(true)
    try { await axios.put('/item/update-status', { status: newStatus, id: data.id, });
      setloadDisabled(false)
      loadData();
    } catch (error) {
      setloadDisabled(false)
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Something went wrong!',
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'error',
          message: 'Something went wrong!',
        });
      }, 3000);
    }
  };

  const displayItemInShop = async (data) => { 

        try {
          await axios.post('/franchiseeItemDisplay', { itemId: data.item ? data?.item?.id : data?.id, }); 
          loadData();
        } catch (error) { 
          setAlert({
            showAlert: true,
            severity: 'error',
            message: 'Something went wrong!',
          });
          setTimeout(() => {
            setAlert({
              showAlert: false,
              severity: 'error',
              message: 'Something went wrong!',
            });
          }, 3000);
        }

  };

  const debouncedDisplayItemInShop = debounce(displayItemInShop, 300);

  const franchisorColumns = [
    { field: 'itemId', headerName: 'ITEM ID', flex: 1, minWidth: 150 },
    { field: 'name', headerName: 'ITEM NAME', flex: 1, minWidth: 150 },
    { field: 'price', headerName: 'PRICING ($)', flex: 1, minWidth: 150 },

    { field: 'status', headerName: 'STATUS', flex: 1, minWidth: 150,
      renderCell: (params) => {
        const onClick = (e, status) => {
          e.stopPropagation(); // don't select this row after clicking
          changeStatus(status, params?.row);
        };
        if (params?.row?.status === true) {
          return ( <Chip onClick={(e) => onClick(e, false)} label={`Active`} color='primary' variant='outlined' disabled={loadDisabled} /> );
        } else {
          return ( <Chip onClick={(e) => onClick(e, true)} label={`Inactive`} color='error' variant='outlined' disabled={loadDisabled} /> );
        }
      },
      valueGetter: (params) => params?.row?.status === true ? "Active" : "Inactive"
    },

    { field: 'action', headerName: 'EDIT', flex: 1, minWidth: 150, sortable: false, disableExport: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setData(params?.row);
          handleClickOpen();
        };
        return ( <Button onClick={onClick}> <EditIcon  /> </Button> );
      },
    },
  ];

  const franchiseeColumns = [
    { field: 'itemId', headerName: 'ITEM ID', flex: 1, minWidth: 150 },
    { field: 'name', headerName: 'ITEM NAME', flex: 1, minWidth: 150,
      valueGetter: (params) => { return `${params?.row?.item?.name ? params?.row?.item?.name : params?.row?.name }`; },
    },
    { field: 'price', headerName: 'PRICING ($)', flex: 1, minWidth: 150 },
    { field: 'PricedBy', headerName: 'Priced By', flex: 1, minWidth: 150,
      renderCell: (params) => {
      return <Chip label={`${params?.row?.priceBy==="Franchisee" ? roleType : "Franchisor"}`} color={params?.row?.priceBy==="Franchisee" ? "primary" : "secondary"} />
      },
      valueGetter: (params) => `${params?.row?.priceBy==="Franchisee" ? roleType : "Franchisor"}`
    },
    { field: 'displayItem', headerName: 'DISPLAY IN SHOP', flex: 1, minWidth: 150,
      renderCell: (params) => {
        
        const onClick = (e) => {

          e.stopPropagation();
          debouncedDisplayItemInShop(params?.row);
            
        }
        return ( <Checkbox inputProps={{ "aria-label": "Checkbox demo" }} checked={params?.row?.displayInShop} onClick={onClick}/> );
      },
      valueGetter: (params) => params?.row?.displayInShop
    },
    { field: 'action', headerName: 'EDIT', flex: 1, minWidth: 150, sortable: false, disableExport: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setData(params?.row);
          handleClickOpen();
        };
        return ( <Button disabled={!params?.row?.item} onClick={onClick}><EditIcon /></Button> );
      },
    },
    { field: 'priceChangeRequested', headerName: 'PRICE STATUS', flex: 1, minWidth: 150,
    renderCell: (params) => { return (params?.row?.priceChangeRequested ? <p style={{color:"#FEA432", fontWeight:400}}>Price Change Requested</p> : '' )},
  },
  ];

  useEffect(() => {
    if (role === 'franchisor') { setColumns(franchisorColumns); }
    if (role === 'franchisee' || role === 'corporate' || role === "manager") { setColumns(franchiseeColumns); }
    // eslint-disable-next-line
  }, [role]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid container item xs={12} md={12} lg={12} justifyContent={'flex-end'} >
          {role === 'franchisor' && !franchiseeId && (
            <Button data-testid="create-item-btn" variant='contained' onClick={() => handleClickOpen('create')} > + Create Item </Button> )}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <DataGrid autoHeight columns={columns} fetchDataUrl={`${url}`} role={role} newData={newData} status={status}
            title={`${role === 'franchisor' && !franchiseeId ? 'Franchisor Items' : role === 'franchisor' && franchiseeId ? `${role === 'corporate' || (sessionStorage.getItem("managerType") === "corporate" && role === "manager") ? 'Corporate' : 'Franchisee'} Items` : `${(role === 'corporate' || (sessionStorage.getItem("managerType") === "corporate" && role === "manager")) ? 'Corporate' : 'Franchisee'} Items` }`} />
        </Grid>
      </Grid>
      <AlertMain setAlert={setAlert} alert={alert} />
      <EditDialog handleClickOpen={handleClickOpen} setOpen={setOpen} open={open} data={data} setStatus={setStatus}
        newStatus={status} componentType={componentType} role={role} serviceType={'dryCleaning'} subject={'Price Change : Dry Cleaning'} />
    </>
  );
}
