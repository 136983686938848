import React, { useState } from 'react';
import { Dialog, DialogTitle, Grid, DialogActions, DialogContent, Button, FormControl, RadioGroup, FormControlLabel, Radio,
  MenuItem, Select, Checkbox, ListItemText, InputLabel, OutlinedInput, FormLabel, } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import LoadingButton from '@mui/lab/LoadingButton';
import Papa from 'papaparse';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import AlertInlineMain from "components/common/AlertInlineMain";
import { promoValidationSchema } from './Validations';

const initial = { name: '', description: '', discountType: 'percentage', discountValue: 0, promocode: '', sequence: 1, inviteType: 'promoForAll'}

export default function CreatePromocode({ open, handleClose, serviceName, setServiceName, generatePromoCode, setStatus,
  services, uniqueArray, MenuProps, handleServiceName, mainServices, mainServiceName}) {

  const axios = useAxiosPrivate()
  const [csvFile, setCsvFile] = useState([]);
  const [upload, setUpload] = useState(false);
  const [one, setOne] = useState(true);
  const [backednCall, setBackednCall] = useState(false);
  const [alert, setAlert] = useState({ showAlert: false, severity: 'success', message: '', });
  const [radioButton, setRadioButton] = useState('promoForAll');

  // eslint-disable-next-line no-unused-vars
  const [initialValues, setInitialValues] = useState(initial);
  const alertSetter = (showAlert, severity, message, time) => {
    if (time) {
      alertSetter(showAlert, severity, message);
      setTimeout(() => { alertSetter(false, severity, ''); }, time);
    } else {
      setAlert({ showAlert: showAlert, severity: severity, message: message, });
    }
  };

  const updateData = (results) => {
    const data = results;
    try {

      const validLength = data.filter((item) => item !== undefined);
      if (validLength.length > 0) {
        const file = validLength.filter((i) => i !== '');
        const unique = [...new Set(file.flat())];
        setCsvFile([...unique]);
      } else {
        alertSetter(true, 'error', 'Invalid CSV file', 3000);
      }
    } catch (error) {
      alertSetter(true, 'error', 'Please upload valid csv', 3000);
    }
  };


  //CSV upload
  const handleChangeForCsvFile = (e) => {
    try {
      setUpload(true);
      const fileType = e.target.files[0].type;
      const fileName = e.target.files[0].name;
      
      const fileExtension = fileName.split('.').pop();
      if (fileType !== 'application/vnd.ms-excel' && fileExtension !== 'csv') {
        alertSetter(true, 'error', 'Only CSV file is allowed', 2000);
      } else {

        let emails = [];

        if (e.target.files[0]) {
          readCsvFile(e.target.files[0])
            .then((csvContent) => {

              emails = extractEmails(csvContent);

              if (emails.length < 1) {
                setCsvFile([]);
                alertSetter(true, 'error', 'Please, Upload a CSV file', 2000);
              } 
              else { 
                updateData(emails);
              }

            })
            .catch((error) => {
              console.error("Error reading CSV file:", error);
            });
        }
      }
      setUpload(false);
      return fileName;
    } catch (error) {
      alertSetter(true, 'error', 'Please, Upload a CSV file', 2000);
      setUpload(false);
    }
  };

  const readCsvFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const csvContent = event.target.result;
        resolve(csvContent);
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsText(file);
    });
  }

  const extractEmails = (csvContent) => {
    const lines = csvContent.split('\n');
    const emails = [];
  
    // Start from the second line (index 1) to skip the header
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i].trim(); // Remove leading and trailing whitespaces
      if (line.length > 0) {
        // Assuming each line contains an email address
        const email = line.split(',')[0].trim();
        if(isValidEmail(email)) emails.push(email);
      }
    }
  
    return emails;
  }


const isValidEmail = (email) => {
  // Regular expression for a basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

  const onSubmit = async (e, { resetForm }) => {
    try {
      const payLoad = { promocode:e.promocode, }   
      const res = await axios.post('/rewards/promocodes/check', payLoad);
      if (res.status === 200) {
      try {
        setBackednCall(true);
        const data = { name: e.name, description: e.description,
          discount: { discountType: e.discountType, discountValue: e.discountValue, },
          promocode: e.promocode,
          services: { names: serviceName, types: uniqueArray(serviceName), },
          emails: e.inviteType ==="promoForAll" ? "promoForAll" : [...csvFile],
          fileName: e.inviteType ==="promoForAll" ? "promoForAll" : e.emails,
          sequence: e.sequence,
        };
        await axios.post('/rewards/promocodes', data);
        alertSetter(true, 'success', 'Promocode Created Successfully');
        setTimeout(() => {
          alertSetter(false, 'success', '');
          resetForm();
          setBackednCall(false);
          handleClose();
          setStatus();
          setRadioButton('promoForAll')
        }, 3000);
        setAlert({ showAlert: true, severity: "success", message: "Promocode Created Successfully", });
        setTimeout(() => { setAlert({ showAlert: false, severity: "", message: "", }); }, 3000);
      } 
        
      catch (error) {
        setBackednCall(false);
        alertSetter(true, 'error', 'Error in Creating Promocode', 3000);
        setAlert({ showAlert: true, severity: "error", message: "Error in Creating Promocode", });
        setTimeout(() => { setAlert({ showAlert: false, severity: "", message: "", }); }, 3000);
        setStatus();
        }
      }   
    } catch (err) { 
        setAlert({ showAlert: true, severity: "error", message: "Promocode Already in-use!", });
        setTimeout(() => { setAlert({ showAlert: false, severity: "error", message: "", }); }, 2000);
      }
  };

  const checkAvailable = async (val) => {  
    try {
      const payLoad = { promocode:val, }   
      const res = await axios.post('/rewards/promocodes/check', payLoad);
      if (res.status === 200) { 
        setAlert({ showAlert: true, severity: "success", message: "Promocode available to use", });
        setTimeout(() => { setAlert({ showAlert: false, severity: "success", message: "", }); }, 2000);
      }
    } catch (err) {
        setAlert({ showAlert: true, severity: "error", message: "Promocode Already in-use!", });
        setTimeout(() => {
          setAlert({ showAlert: false, severity: "error", message: "", });
        }, 2000);
      }
    }

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={'md'} maxWidth={'md'} >
        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={promoValidationSchema} >
          {({
            values,
            isValid,
            resetForm,
            handleChange,
            setFieldValue,
            setFieldError,
            setFieldTouched,
          }) => {
            return (
              <Form noValidate autoComplete='off' encType='multipart/form-data'>
                <DialogTitle style={{ cursor: 'move' }} id='time-slot-create-id' > Promocode Details </DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item mt={1} xs={12} md={6} lg={6}>
                      <Field name='name' label='Name' component={TextField} fullWidth variant='outlined' required />
                    </Grid>
                    <Grid item mt={1} xs={12} md={10} lg={10}>
                      <Field name='description' label='Description' component={TextField} fullWidth variant='outlined' required />
                    </Grid>
                    <Grid item mt={1} xs={12} md={7} lg={7}>
                      <FormControl fullWidth>
                        <InputLabel id='demo-multiple-checkbox-label'> Applicable Services <span style={{ color: "red" }}>*</span> </InputLabel>
                        <Select labelId='demo-multiple-checkbox-label' id='demo-multiple-checkbox' multiple name='serviceNames' value={mainServiceName}
                          onChange={(e) => {
                            handleChange(e);
                            const { target: { value }, } = e;
                            handleServiceName( typeof value === 'string' ? value.split(',') : value )
                            setFieldValue('serviceNames', value, false);
                            setFieldError('serviceNames', '');
                            setFieldTouched('serviceNames', false);
                          }}
                          input={<OutlinedInput label='Applicable Services' />}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                        >
                          {mainServices.map((ob) => (
                            <MenuItem key={ob.name} value={ob.name}>
                              <Checkbox checked={mainServiceName.indexOf(ob.name) > -1} />
                              <ListItemText primary={ob.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item mt={1} xs={12} md={6} lg={6}>
                      <Field name='promocode' label='Promo Code' component={TextField} fullWidth variant='outlined' required />
                    </Grid>
                    <Grid item mt={1} xs={12} md={5} lg={5}
                      sx={{ display: 'flex', justifyContent: 'flex-right', marginTop: '1rem', width: '100%', }} >
                      <Button variant='contained' color='primary'
                        onClick={() => {
                          const promocode = generatePromoCode(5);
                          checkAvailable(promocode).then(() => {
                            setFieldValue('promocode', promocode, true);
                            setFieldError('promocode', '');
                          });
                        }}
                      > Generate promocode </Button>
                    </Grid> 
                    <Grid item mt={{ lg:4, md:4, sm:3, xs:4 }} mb={{ lg:4, md:4, sm:3, xs:4 }} 
                    lg={radioButton === "promoForAll" ? 12:5}
                    md={radioButton === "promoForAll" ? 12:5} 
                    sm={radioButton === "promoForAll" ? 12:12} 
                    xs={radioButton === "promoForAll" ? 12:12} >
                    <FormControl> <FormLabel > Who you want to apply this promocode to? </FormLabel>
                          <RadioGroup name='inviteType' value={radioButton}
                            onChange={(e) => {
                              setRadioButton(e.target.value);
                              setFieldValue('inviteType', e.target.value);
                            }} >
                            <FormControlLabel value={"promoForAll"} control={<Radio />} label='Apply for all' />
                            <FormControlLabel value={"promoForcsvList"} control={<Radio />} label='Use email list CSV' />
                          </RadioGroup>
                        </FormControl>
                    </Grid> 
                    {radioButton === "promoForcsvList" && 
                    <Grid item mt={{lg:7, md:7, sm:0, xs:0 }} mb={{ lg:7, md:7, sm:4, xs:4 }} 
                    lg={4} md={4} sm={6} xs={6}>
                      <Field name='emails' helperText='Upload csv' component={TextField} fullWidth disabled variant='outlined' required />
                      </Grid>} 
                    {radioButton === "promoForcsvList" &&
                      <Grid item sx={{ display: 'flex', justifyContent: 'flex-right', width: '100%', }}
                      mt={{ lg:8, md:8, sm:1, xs:1 }}
                      mb={{ lg:8, md:8, sm:0, xs:0 }} 
                      xs={6} sm={6} md={2} lg={2} >
                        <input type='file' name='csv' id='csv'
                          accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          const name = handleChangeForCsvFile(e);
                          setFieldValue('emails', name, true);
                        }} />
                      <label htmlFor='csv'>
                        <LoadingButton fullWidth variant='contained' component='span' loading={upload} > Upload CSV </LoadingButton>
                          <a href="https://firebasestorage.googleapis.com/v0/b/mediaservice-f6142.appspot.com/o/email.csv?alt=media&token=041d409f-3aa2-4409-af64-520e6c938116" >
                              Download Sample CSV
                          </a>
                      </label> 
                    </Grid>} 
                    <Grid item lg={3} md={3} sm={4} xs={12} >
                      <FormControl>
                        <RadioGroup name='discountType'
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue( 'discountType', e.target.value, false );
                            setFieldError('discountType', '');
                            setFieldTouched('discountType', false);
                          }}
                          defaultValue={'percentage'} >
                          <FormControlLabel value='percentage' control={<Radio />} label='Percent ( % )' />
                          <FormControlLabel value='amount' control={<Radio />} label='Amount ( $ )' />
                        </RadioGroup>
                      </FormControl>
                    </Grid> 
                    <Grid item mt={1} lg={7} md={7} sm={7} xs={12} >
                      <Field name='discountValue' label='Discount amount' component={TextField} fullWidth variant='outlined' required />
                    </Grid> 
                    <Grid item  lg={3} md={3}  sm={4}  xs={12} >
                      <FormControl>
                        <FormLabel component='legend'>One per user</FormLabel>
                        <RadioGroup row name='sequenceType' defaultValue={true}
                          onChange={(e) => {
                            if (e.target.value === 'false') { setOne(false); }
                            else {
                              setOne(true);
                              setFieldValue('sequence', 1, false);
                              setFieldError('sequence', '');
                              setFieldTouched('sequence', false);
                            }
                          }} >
                          <FormControlLabel value={true} control={<Radio />} label='Yes' />
                          <FormControlLabel value={false} control={<Radio />} label='No' />
                        </RadioGroup>
                      </FormControl>
                    </Grid> 
                    <Grid item mt={1} lg={7} md={7} sm={7} xs={12}  >
                      <Field name='sequence' label='Sequence' component={TextField} disabled={one} fullWidth variant='outlined' required />
                    </Grid> 
                    {alert.showAlert && ( <Grid item md={12} m={2}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid> )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button color='success' type='submit' disabled={!isValid || !(serviceName.length > 0) || backednCall} > Save </Button>
                  <Button color='error' onClick={handleClose}> Close </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
}
