import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import HomeIcon from "@mui/icons-material/Home";

export default function FolderList() {
  const depot = useSelector((state) => state.route.depot);

  return (
    <List sx={{ width: "100%", maxWidth: 360 }}>
      {depot && (
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <HomeIcon style={{ color: depot?.color }} />
            </Avatar>
          </ListItemAvatar>
          <span
            style={{
              fontWeight: "500",
            }} >
            {depot?.name}
          </span>
          <Divider />
        </ListItem>
      )}
    </List>
  );
}
