import React, { useCallback, useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Grid, Button, FormControlLabel, Switch, Typography, styled, TextField as TextBox, } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Autocomplete, Checkbox, FormGroup, Stack } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import DateSlashToDashReformatTimeZone from 'components/common/Functions/DateSlashToDashReformatTimeZone';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import CircularProgress from "@mui/material/CircularProgress";
import TwentyFourToAmPm from 'components/common/Functions/TwentyFourToAmPm';
import DialogAlertMain from "components/common/DialogAlertMain";
import { useSelector, useDispatch } from "react-redux";
import { setCorporateCustomers } from "store/actions/RouteAction";
import AmPmTo24 from 'components/common/Functions/AmPmTo24';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {

    '&.Mui-checked': {
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: "#1976D2",
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',

  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: "#1976D2",
    borderRadius: 20 / 2,
  },
}));

const OrderForm = () => {
  let dispatch = useDispatch();
  const getCorporateCustomers = useSelector((state) => state?.route?.corporateCustomers);
  const axios = useAxiosPrivate();
  const [startTime, setStartTime] = useState(null);
  // const [endTime, setEndTime] = useState(null);
  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([{}]);
  const marginsSm = useMediaQuery("(max-width: 766px)");
  const today = DateSlashToDashReformatTimeZone(new Date(new Date().setDate(new Date().getDate() + 1)));
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);

  const [initialValues, setInitialValues] = useState({
    date: today,
    startTime: "",
    endTime: "",
    orderNote: "",
    pickupNote: "",
    pickupOrDeliveryNote: "",
    customerId: ""
  });

  const validationSchema = Yup.object().shape({
    date: Yup.string().required("Kindly choose a date or adjust the default date by toggling back and forth"),
    startTime: Yup.string().required("Please select a Start time or modify the default hours and minutes"),
    endTime: Yup.string()
      .required("Please select a End time or modify the default hours and minutes")
      .test("Is positive?", "End Time must be greater than Start Time", (value) => value > startTime),
    customerId: Yup.string().required("Please select Customer Id"),
    typeOfOrder: Yup.boolean().required("Please select type Of order time slot"),
  });

  const loadInitialValues = useCallback(async () => {
    dispatch(setCorporateCustomers());
    //eslint-disable-next-line
  }, [getCorporateCustomers])

  const getLastOrder = useCallback(async (setFieldValue, customerId) => {

    try {
      const { data } = await axios.get(`/order/lastorder/${customerId}`);
      if (data[0]?.diliverDate) {
        setInitialValues({
          date: today,
          startTime: AmPmTo24(data[0]?.diliverTimeSlot?.split("-")[0].trim()),
          endTime: AmPmTo24(data[0]?.diliverTimeSlot?.split("-")[1].trim()),
          orderNote: data[0]?.orderNote ?? "",
          pickupOrDeliveryNote: data[0]?.deliveryNote,
          typeOfOrder: true,
          customerId: customerId
        })
        setFieldValue("startTime", AmPmTo24(data[0]?.diliverTimeSlot?.split("-")[0].trim()) ?? "")
        setFieldValue("endTime", AmPmTo24(data[0]?.diliverTimeSlot?.split("-")[1].trim()) ?? "")
        setFieldValue("orderNote", data[0]?.orderNote ?? "")
        setFieldValue("pickupOrDeliveryNote", data[0]?.deliveryNote ?? "")
        setFieldValue("typeOfOrder", true)
        setFieldValue("customerId", customerId)

        setStartTime(AmPmTo24(data[0]?.diliverTimeSlot?.split("-")[0].trim()) ?? "")

      } else if (data[0]?.pickupDate) {
        setInitialValues({
          date: today,
          startTime: AmPmTo24(data[0]?.pickupTimeSlot?.split("-")[0].trim()),
          endTime: AmPmTo24(data[0]?.pickupTimeSlot?.split("-")[1].trim()),
          orderNote: data[0]?.orderNote ?? "",
          pickupOrDeliveryNote: data[0]?.pickupNote,
          typeOfOrder: false,
          customerId: customerId
        })
        setFieldValue("startTime", AmPmTo24(data[0]?.pickupTimeSlot?.split("-")[0].trim()) ?? "")
        setFieldValue("endTime", AmPmTo24(data[0]?.pickupTimeSlot?.split("-")[1].trim()) ?? "")
        setFieldValue("orderNote", data[0]?.orderNote ?? "")
        setFieldValue("pickupOrDeliveryNote", data[0]?.pickupNote ?? "")
        setFieldValue("typeOfOrder", false)
        setFieldValue("customerId", customerId)

        setStartTime(AmPmTo24(data[0]?.pickupTimeSlot?.split("-")[0].trim()) ?? "")

      } else {
        setInitialValues({
          date: today,
          startTime: "11:30",
          endTime: "12:30",
          orderNote: "",
          pickupOrDeliveryNote: "",
          typeOfOrder: false,
          customerId: customerId
        })
        setFieldValue("startTime", "11:30")
        setFieldValue("endTime", "12:30")
        setFieldValue("orderNote", "")
        setFieldValue("pickupOrDeliveryNote", "")
        setFieldValue("typeOfOrder", false)
        setFieldValue("customerId", customerId)

        setStartTime("")
      }
    } catch (error) {
      console.log(error);
    }

    //eslint-disable-next-line
  }, [])


  const onSubmit = async (e, { resetForm, setSubmitting }) => {
    const orderType = handleOrderType();

    const dateOptions = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      timeZone: 'UTC'
    };

    let payload
    if (e?.typeOfOrder) {
      payload = {
        diliverDate: new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(e?.date)),
        diliverTimeSlot: `${TwentyFourToAmPm(e?.startTime)} - ${TwentyFourToAmPm(e?.endTime)}`,
        deliveryNote: e?.pickupOrDeliveryNote,
        orderNote: e?.orderNote,
        customer: e?.customerId,
        orderType: orderType
      }
    } else if (!e?.typeOfOrder) {
      payload = {
        pickupDate: new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(e?.date)),
        pickupTimeSlot: `${TwentyFourToAmPm(e?.startTime)} - ${TwentyFourToAmPm(e?.endTime)}`,
        pickupNote: e?.pickupOrDeliveryNote,
        orderNote: e?.orderNote,
        customer: e?.customerId,
        orderType: orderType
      }

    }

    try {
      await axios.post("/order/manualorder", payload);
      setAlert({ showAlert: true, severity: "success", message: "Success!", });
    } catch (error) {
      setAlert({ showAlert: true, severity: "error", message: `${error?.response?.data?.message ?? "Error"}`, });
    } finally {
      resetForm({ date: "", startTime: "", endTime: "", orderNote: "", pickupOrDeliveryNote: "", typeOfOrder: false });
      setChecked1(false);
      setChecked2(false);
      setChecked3(false);
    }
  };

  useEffect(() => {
    if (getCorporateCustomers === undefined || !options) {
      loadInitialValues();
    } else {
      setOptions(getCorporateCustomers)
    }
    //eslint-disable-next-line
  }, [getCorporateCustomers, options]);

  const handleChange1 = (event) => { setChecked1(event.target.checked); };
  const handleChange2 = (event) => { setChecked2(event.target.checked); };
  const handleChange3 = (event) => { setChecked3(event.target.checked); };

  function handleOrderType() {
    if (checked1 && !checked2 && !checked3) return 1;
    else if (!checked1 && checked2 && !checked3) return 2;
    else if (!checked1 && !checked2 && checked3) return 3;
    else if (!checked1 && checked2 && checked3) return 4;
    else if (checked1 && !checked2 && checked3) return 5;
    else if (checked1 && checked2 && !checked3) return 7;
    else if (checked1 && checked2 && checked3) return 9;
  };

  return (
    <Grid container>

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize >
        {({ dirty, isValid, values, setFieldValue, resetForm, handleChange, isSubmitting }) => {
          return (
            <Form>
              <Grid container item alignItems="center" justifyContent="center" lg={12} md={12} sm={12} xs={12}>
                <Grid item style={{ marginTop: "4rem", width: selectedOption ? "0" : "80vw" }} lg={12} md={12} sm={12} xs={12}>
                  {options ? (<Autocomplete
                    name="customerId"
                    fullWidth
                    value={selectedOption}
                    onChange={(e, newValue) => {
                      resetForm();
                      getLastOrder(setFieldValue, newValue?.id);
                      setFieldValue("date", today);
                      setTimeout(() => {
                        setFieldValue("customerId", newValue?.id);
                        setSelectedOption(newValue);
                      }, 500);
                    }}
                    options={options}
                    getOptionLabel={(option) => option?.fullName}
                    renderInput={(params) => (
                      <TextBox fullWidth {...params} label="Select a User" variant="outlined" />
                    )}
                  />) : (<CircularProgress />)}
                </Grid>
              </Grid>
              {selectedOption && (
                <Grid container item spacing={2} style={{ marginTop: "4rem", marginLeft: "0.05rem", width: marginsSm ? "98vw" : "80vw" }} lg={12} md={12} sm={12} xs={12} alignItems="center" justifyContent="center">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography style={{
                      color: "#3d3d3d",
                      marginTop: '1rem',
                      marginBottom: '1rem',
                      fontSize: "1.1rem",
                      textTransform: "uppercase",
                      fontWeight: "600",
                      textAlign: "center",
                    }}>--Create New Order--</Typography>
                  </Grid>
                  <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography
                        style={{
                          fontWeight: "600",
                          color: values?.typeOfOrder ? "#d6d4d4" : "#50C878",
                          fontSize: "1.4rem", textTransform: "uppercase"
                        }}>Pickup</Typography>
                      <Field name="typeOfOrder">
                        {({ field }) => (
                          <FormControlLabel control={<MaterialUISwitch checked={values?.typeOfOrder} {...field} color="primary" />
                          } />
                        )}
                      </Field>
                      <Typography style={{ fontWeight: "600", color: values?.typeOfOrder ? "#50C878" : "#d6d4d4", fontSize: "1.4rem", textTransform: "uppercase" }} >Delivery</Typography>
                    </Stack>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography style={{ textAlign: "center" }}> <span style={{ fontSize: "1.1rem", fontWeight: "600" }}>
                      {values?.typeOfOrder ? "Delivery" : "Pickup"} Address</span>: {selectedOption?.location[0]?.address ?? "Loading..."}</Typography>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <label style={{
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "0",
                      fontSize: "1rem",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: "400",
                      lineHeight: "1",
                      letterSpacing: "0.00938em",
                    }}>Date:</label>
                    <Field
                      name="date"
                      component={TextField}
                      onChange={(newValue) => {
                        setFieldValue("date", newValue?.target?.value);
                      }}
                      variant="outlined"
                      fullWidth
                      type="date"
                      InputProps={{ inputProps: { min: `${today}` } }}
                      required
                    ></Field>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <label style={{
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "0",
                      fontSize: "1rem",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: "400",
                      lineHeight: "1",
                      letterSpacing: "0.00938em",
                    }}>Start Time:</label>
                    <Field
                      onChange={(newValue) => {
                        setStartTime(newValue?.target?.value);
                        setFieldValue("startTime", newValue?.target?.value);
                      }}
                      value={values?.startTime}
                      name="startTime"
                      type="time"
                      variant="outlined"
                      component={TextField}
                      fullWidth
                      required
                    ></Field>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <label style={{
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "0",
                      fontSize: "1rem",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: "400",
                      lineHeight: "1",
                      letterSpacing: "0.00938em",
                    }}>End Time:</label>
                    <Field
                      onChange={(newValue) => {
                        // setEndTime(newValue.target.value);
                        setFieldValue("endTime", newValue?.target?.value);
                      }
                      }
                      name="endTime"
                      value={values?.endTime}
                      type="time"
                      variant="outlined"
                      component={TextField}
                      InputProps={{ inputProps: { min: `${startTime}` } }}
                      fullWidth
                      required
                    ></Field>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <label style={{
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "0",
                      fontSize: "1rem",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: "400",
                      lineHeight: "1",
                      letterSpacing: "0.00938em",
                    }}>Service Type</label>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox checked={checked1} onChange={handleChange1} />} label="Wash/Dry/Fold Service" />
                      <FormControlLabel control={<Checkbox checked={checked2} onChange={handleChange2} />} label="Dry Cleaning" />
                      <FormControlLabel control={<Checkbox checked={checked3} onChange={handleChange3} />} label="Tailoring & Alterations" />
                    </FormGroup>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Field
                      name="pickupOrDeliveryNote"
                      label={values?.typeOfOrder ? "Delivery Note" : "Pickup Note"}
                      multiline
                      rows={4}
                      variant="outlined"
                      component={TextField}
                      fullWidth
                    ></Field>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Field
                      name="orderNote"
                      label="Order Note"
                      multiline
                      rows={4}
                      variant="outlined"
                      component={TextField}
                      fullWidth
                    ></Field>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "1rem", }}>
                    <Button
                      disabled={isSubmitting || !checked1 && !checked2 && !checked3}
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  </Grid>
                </Grid>)}

            </Form>
          );
        }}
      </Formik>
      {alert?.showAlert && (
        <DialogAlertMain alert={alert} setAlert={setAlert} />
      )}
    </Grid>
  );
};

export default OrderForm;

