import React, { useEffect } from "react";
import { Grid, Button, Card, CardContent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpen,
  setDepot,
  setVehicle,
  setDriver,
} from "../../store/actions/RouteAction";
import DepotList from "../Depot/Depo/ViewDepot";

export default function DepotMain() {
  let dispatch = useDispatch();
  const depot = useSelector((state) => state.route.depot);
  const state = useSelector((state) => state.route.open);

  const [open, setOpenDepot] = React.useState(state);

  const setDetails = () => {
    dispatch(setOpen(true));

    setOpenDepot(!open);
  };

  useEffect(() => {
    dispatch(setDepot());
    dispatch(setVehicle());
    dispatch(setDriver());
    //eslint-disable-next-line
  }, []);

  return (
    <Card style={{ width: "100%" }}>
      <CardContent>
        <Grid
          container
          justifyContent={"center"}
          alignContent="flex-start"
          p={2}
        >
          <Grid item xs={12} md={12} lg={12}>
            <Button data-testid="create-depot" variant="outlined" size="small" onClick={setDetails}>
              {depot ? "Edit Depot" : "Create Depot"}
            </Button>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <DepotList />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
