import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import PleaseCreateTimslots from "./PleaseCreateTimslots";
import LoadingButton from "@mui/lab/LoadingButton";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { TextField as TF } from "formik-material-ui";
import YmDreformat from 'components/common/Functions/YmDreformat';

function CreateDeliverySchedule({ setStatus, status, selected,refresh}) {
  const axios = useAxiosPrivate()
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [zones, setZones] = useState([]);
  const [limtValidated, setLimitValidated] = useState(true);
  const [endError, setEndError] = useState("");
  const [backendCall, setBackendCall] = useState(false);
  const convertDate = (d) => {
    return d?.toLocaleDateString("en-CA");
  };
  const dateIni = new Date(selected);
  dateIni.setUTCHours(0, 0, 0, 0);
  const dateVal = convertDate(dateIni);
  const [startDate, setStartDate] = useState(dateIni);
  const [endDate, setEndDate] = useState(dateIni);
  const [timeSlots, setTimeSlots] = useState([]);
  const [zone, setZone] = useState("");
  const initial = { zone: "", unLimit: 0, startDate: dateVal, endDate: dateVal, isPublished: false, };
  const [initialValues, setInitialValues] = useState(initial);
  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });
  const handleClickOpen = () => { getZones(); };
  const handleZone = (e) => {
    const val = e.target.value;
    setZone(val);
    setInitialValues((prevState) => ({ ...prevState, zone: val, }));
  };
  const handleClose = () => {
    setInitialValues(initial);
    setStartDate(dateIni);
    setEndDate(dateIni);
    setZones([]);
    setZone("");
    setTimeSlots([]);
    setLimitValidated(true);
    setOpen(false);
    refresh();
    setStatus(!status);
  };
  // get zones
  const getZones = async () => {
    try {
      const { data } = await axios.get("/delivery/zones");
      const zoneArray = data.map((zone) => { return { value: zone._id, label: zone.zoneName, }; });
      if (zoneArray.length > 0) {
        setZones(zoneArray);
        setStatus(!status);
        setInitialValues(initial);
        setLimitValidated(false);
        setEndError("");
        setOpen(true);
      } else { setOpenAlert(true); }
    } catch (err) {
      setAlert({ showAlert: true, severity: "error", message: "Zone Loading Failed!", });
      setTimeout(() => { setAlert({ showAlert: false, severity: "error", message: "Zone Loading Failed!", }); }, 4000);
    }
  };
  const handleZoneChange = async (event) => {
    try {
      const zone = event.target.value;
      const { data } = await axios.get(`/delivery/timeslots/${zone}`);
      let array = [];
      // return data to timeslots
      data.forEach((timeSlot) => {
        array.push({ id: timeSlot._id, timeSlot: `${timeSlot.timeSlots} (${timeSlot.zoneName})`, limit: Number(timeSlot.limit), error: false, });
      });
      setTimeSlots(array);
    } catch (err) {
      setAlert({ showAlert: true, severity: "error", message: "Timeslot Loading Failed!", });
      setTimeout(() => { setAlert({ showAlert: false, severity: "error", message: "Timeslot Loading Failed!", }); }, 3000);
    }
  };
  // insert data to database
  const onSubmit = async (e, { resetForm }) => {
    setBackendCall(true);
    const array = timeSlots?.map((t) => { return { id: t.id, limit: t.limit, }; });
    if (array.length < 0) {
      setAlert({ showAlert: true, severity: "error", message: "Please select a timeslot!", });
      setTimeout(() => { setAlert({ showAlert: false, severity: "error", message: "Please select a timeslot!", }); }, 3000);
    }
    try {
      const data = {
        zone: e.zone, startDate: YmDreformat(e.startDate), endDate: YmDreformat(e.endDate), timeSlots: array, unattendedLimit: e.unLimit,
        isPublished: e.isPublished === "true" || e.isPublished === true ? true : false, };
      
      await axios.post("/delivery", {
        zone: data.zone, startDate: data.startDate, endDate: data.endDate,
        timeSlots: data.timeSlots, unattendedLimit: data.unattendedLimit, isPublished: data.isPublished, });
      setAlert({ showAlert: true, severity: "success", message: "Time slot created!", });
      resetForm();
      setStartDate(dateIni);
      setEndDate(dateIni);
      setInitialValues(initial);
      setZones([]);
      setZone("");
      setTimeSlots([]);
      refresh();
      setStatus(!status);
      setTimeout(() => { setAlert({ showAlert: false, severity: "Time slot created!", message: "", });
        setBackendCall(false);
        setStatus(!status);
        setOpen(false);
      }, 1000);
    } catch (err) {
      setBackendCall(false);
      if (err.response.status === 401) { setAlert({ showAlert: true, severity: "error", message: err.response.data.message, });
        setTimeout(() => { setAlert({ showAlert: false, severity: "error", message: err.response.data.message, });
          handleClose();
        }, 3000);
      } else {
        setAlert({ showAlert: true, severity: "error", message: "Request Failed!", });
        setTimeout(() => { setAlert({ showAlert: false, severity: "error", message: "Request Failed!", });
          handleClose();
        }, 3000);
      }
    }
  };
  const validationSchema = Yup.object().shape({
    zone: Yup.string().required("Zone is required!"),
    startDate: Yup.date().required("Start Date is required!"),
    endDate: Yup.date().required("End Date is required!"),
    unLimit: Yup.number().required("Limit is required")
      .typeError("Please enter a valid number")
      .min(0, "Minimum atleast 0")
      .test("is-decimal", "invalid decimal", (value) =>
        (value + "").match(/^\d*$/)
      ),
  });
  const handleLimitChange = (e, id) => {
    const vala = e.target.value;
    const err = Yup.number().required("Limit is required")
      .typeError("Please enter a valid number")
      .min(0, "Minimum atleast 0")
      .test("is-decimal", "invalid decimal", (value) =>
        (value + "").match(/^\d*$/)
      )
      .isValidSync(vala);
    setLimitValidated(!err);
    const timeSlot = timeSlots.map((slot) => {
      if (slot.id === id) { return { ...slot, limit: vala, error: !err, }; }
      return slot;
    });
    setTimeSlots(timeSlot);
  };
  const dateChecker = (startDate,endDate) => {
    if (startDate > endDate) {
      // setAlert({ showAlert: true, severity: "error", message: "Start date cannot be greater than end date!", });
      setEndError("Start date cannot be greater than end date!");
      setTimeout(() => { setAlert({ showAlert: false, severity: "error", message: "Start date cannot be greater than end date!", }); }, 4000);
    } else { setEndError(""); }
  };
  const handleStartDateChange = (e,setFieldValue) => {
    setStartDate(e);
    dateChecker(e,endDate);
    const val = convertDate(e);
    setFieldValue("startDate", val);
  };
  const handleEndDateChange = (e,setFieldValue) => {
    setEndDate(e);
    dateChecker(startDate,e);
    const val = convertDate(e);
    setFieldValue("endDate", val);
  };

  return (
    <>
      <Grid container rowSpacing={2}>
        <Grid item  lg={12} md={12} sm={12} xs={12} >
          <Button variant="contained" color="primary" onClick={handleClickOpen} fullWidth > <h3>Create Schedules</h3> </Button>
          {openAlert && (<PleaseCreateTimslots title={"Please Create Time Slots"}
            message={"Please Create Time Slots to create Delivery Schedule"} setStatus={setStatus} status={status}
            setOpenAlert={setOpenAlert} openAlert={openAlert} />)}
        </Grid>
        <Grid item xs={12} md={12}>
          <Dialog fullWidth maxWidth={"md"} open={open} onClose={handleClose} >
            <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} >
              {({ values, isValid, handleChange ,errors, setErrors,setFieldValue}) => {
                return (
                  <Form noValidate autoComplete="off" encType="multipart/form-data" >
                    <DialogTitle style={{ cursor: "move" }}> Create Delivery Schedule </DialogTitle> 
                    <DialogContent>
                      <Grid container item spacing={1}>
                        <Grid container item mt={1} xs={6} md={6} xl={6}>
                          <FormControl fullWidth>
                            <InputLabel id="zone-selector-name"> Zone </InputLabel>
                            <Select labelId="zone-selector" id="zone-selector" name="zone" value={zone} label="Zone" required
                              onChange={(e) => { handleZone(e); handleZoneChange(e); }} >
                              {zones?.map((option) => ( <MenuItem key={option.value} value={option.value} > {option.label} </MenuItem> ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid container item spacing={1} justifyContent={"flex-middle"} >
                          <Grid item mt={1} xs={7} md={7} xl={7} lg={7} >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker fullWidth label="Date" name="startDate" disablePast inputFormat="MM/dd/yyyy"
                                value={startDate} onChange={(e) => handleStartDateChange(e, setFieldValue)}
                                renderInput={(params) => ( <TextField
                                    // helperText="To duplicate this format for future dates, enter an end date. Otherwise, enter the start date to create one day."
                                    {...params} /> )} />
                            </LocalizationProvider>
                          </Grid> 
                          <Grid container item mt={1} xs={7} md={7} xl={7} lg={7} >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker label="Template End Date: (optional)" name="endDate" fullWidth inputFormat="MM/dd/yyyy"
                                value={endDate} disablePast minDate={startDate} onChange={(e) => handleEndDateChange(e, setFieldValue)} renderInput={(params) => (
                                  <TextField helperText={endError ? endError : `To duplicate this format for future dates, enter an end date. Otherwise, enter the start date to create one day.`} {...params} /> )} />
                            </LocalizationProvider>
                          </Grid>
                          {/* <Grid item mt={1} xs={4} md={4} xl={4} lg={4} >
                            {endError ? (<Alert severity="error" style={{ marginTop: "10px" }} > {endError} </Alert>) : null} </Grid> */}
                          <Grid container item mt={1} xs={7} md={7} xl={7} lg={7} >
                            <Field name="unLimit" label="Unattended Limit" variant="outlined" component={TF}
                              style={{ marginTop: "1rem", display: "flex", }} ></Field>
                          </Grid>
                          <Grid container item spacing={1} xs={12} md={12}>
                            <Grid item md={12}> <h3>Daily Time Limits</h3> </Grid>
                            <Grid fullWidth item xs={12} sm={12} md={12}>
                              <Card>
                                {timeSlots?.map((t) => {
                                  return (
                                    <Grid container key={t.timeSlot}>
                                      <Grid container item alignItems="center" justifyContent="center" spacing={1} padding={1}
                                        ml={2} xs={10} md={10} >
                                        <Grid item mt={1} mb={1} xs={4} md={4}> <p>{t.timeSlot}</p> </Grid>
                                        <Grid item mt={1} mb={1} xs={8} md={8}>
                                          <TextField name="timeSlotLimit" label="Limit" defaultValue={t.limit} error={t.error}
                                            helperText={ t.error ? "Enter Valid Number " : "" } variant="outlined"
                                            onChange={(e) => { handleLimitChange(e, t.id,{errors, setErrors}); }} />
                                        </Grid>
                                      </Grid>
                                    </Grid> ); })}
                              </Card>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl>
                              <FormLabel id="time-slots-radio-buttons"> Publish Delivery Schedule </FormLabel>
                              <RadioGroup name="isPublished" s value={values.isPublished} onChange={handleChange} defaultValue={false} >
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid> 
                        {alert.showAlert && (
                          <Grid item md={12} m={2}>
                            <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, showAlert: false, }) } > {alert.message} </Alert>
                          </Grid> )}
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <LoadingButton loading={backendCall} color="success" onClick={onSubmit} type="submit"
                        disabled={!isValid || limtValidated || zone===""} > Save </LoadingButton>
                      <Button color="error" onClick={handleClose}> Close </Button>
                    </DialogActions>
                  </Form> ); }}
            </Formik>
          </Dialog>
        </Grid>
      </Grid>
    </> ); }
export default CreateDeliverySchedule;
