import React, { useCallback, useEffect, useState } from "react";
import { Grid, Dialog, DialogTitle, DialogContent, Button, DialogActions, TextField as TextBox, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogAlertCustomOrder from "components/common/DialogAlertCustomOrder";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
// validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import SearchLocation from "./GoogleMapsAutocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { setCorporateCustomers } from "store/actions/RouteAction";
import DialogContentText from '@mui/material/DialogContentText';
import { IMaskInput } from 'react-imask';

let SignUpSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  address: Yup.string().required("Email is required!"),
  apartmentNo: Yup.string(),
  phoneNumber: Yup.string().matches(
    /^\+[0-9]{1}\s[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
    "Please enter a valid Phone Number with country code"
  ).required("Phone Number is required"),
  zipCode: Yup.string().required("Zip Code is required"),
  location: Yup.object(),
});

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+0 000-000-0000"
      definitions={{
        '0': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

function EditUserDialog(props) {
  const getCorporateCustomers = useSelector((state) => state.route.corporateCustomers);
  let dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([{}]);

  const axios = useAxiosPrivate();
  const [zipCode, setZipCode] = useState("");
  //eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    firstName: '', lastName: '', phoneNumber: '', email: '', address: "", nickName: "", apartmentNo: "", zipCode: "", location: { lat: "", lng: "", },
  });
  const [backendCall, setBackendCall] = useState(false);
  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });

  function alertHandleClick() {
    setAlert({
      ...alert,
      showAlert: false,
    });
  }

  // const checkZipCode = async (zipCode) => { await axios.post(`/user/serviceRegions/`, { zipcode: zipCode, }); };

  const submit = async (e, { resetForm, setSubmitting }) => {
    try {
      setBackendCall(true);
      // await checkZipCode(e.zipCode);
      await axios.put(`/customer/manualcustomer/${selectedOption?.id}`, { ...e, phoneNumber: '+' + e.phoneNumber.replace(/[^\w]/g, ""), });
      dispatch(setCorporateCustomers());
      resetForm();
      setSubmitting(false);
      setZipCode("");
      setSelectedOption(null);
      dispatch(setCorporateCustomers());
      setAlert({ showAlert: true, severity: "success", message: "Success!", });

    } catch (error) {
      if (error?.response?.status === 401) {
        setAlert({ showAlert: true, severity: "error", message: error?.response?.data ?? "Unauthorized !", });
      } else if (error?.response?.status === 403) {
        setAlert({ showAlert: true, severity: "error", message: "Invalid input !", });
      } else if (error?.response?.status === 404) {
        setAlert({ showAlert: true, severity: "error", message: `${error?.response?.data ?? "A zone does not exist for this zipcode"}`, });
      } else { setAlert({ showAlert: true, severity: "error", message: error?.response?.data ?? "Server error!", }); }

      // resetForm();
      // setSubmitting(false);
      // setZipCode("");
    }
    setBackendCall(false);
  };

  const loadInitialValues = useCallback(async () => {
    dispatch(setCorporateCustomers());
    //eslint-disable-next-line
  }, [getCorporateCustomers])


  useEffect(() => {
    if (getCorporateCustomers === undefined || !options) {
      loadInitialValues();
    } else {
      setOptions(getCorporateCustomers)
    }
    //eslint-disable-next-line
  }, [getCorporateCustomers, options]);

  return (
    <>
      {alert.showAlert ? (<Dialog
        open={true}
        onClose={alertHandleClick}
        fullWidth={'sm'}
        maxWidth={'sm'}
      >
        <DialogTitle id='alert-dialog-title'
          sx={{
            textTransform: "uppercase",
            color: alert?.severity === "success" ? "green" : "red"
          }}>{alert?.severity}</DialogTitle>

        <DialogContent>
          <DialogContentText
            sx={{
              fontSize: "1rem",
            }}
            id='alert-dialog-description'>
            {alert.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={alertHandleClick}
            variant='contained'
            // color={alert?.severity === "success" ? 'success' : 'error'}
            style={{
              backgroundColor: alert?.severity === "success" ? "#2e7d32" : "#d32f2f",
              color: "white"
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>) : (<Dialog open={props?.open} onClose={props?.onClose}>
        <DialogTitle >
          <Typography variant="h6" gutterBottom style={{ textAlign: "center" }} > Update User </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container lg={12} md={12} sm={12} xs={12} justifyContent="center" alignItems="center" >
            <Grid container item lg={12} md={12} sm={12} xs={12} alignItems="center" justifyContent="center" >
              <Formik initialValues={initialValues} onSubmit={submit} validationSchema={SignUpSchema} enableReinitialize >
                {({ dirty, isValid, values, setFieldValue, resetForm }) => {
                  return (
                    <Grid container item justifyContent="center" alignItems="center" >
                      <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginBottom: "3rem", width: "35rem" }}>
                        {options ? (<Autocomplete
                          fullWidth
                          value={selectedOption}
                          onChange={(e, newValue) => {
                            setSelectedOption(newValue);
                            setFieldValue("firstName", newValue?.firstName);
                            setFieldValue("lastName", newValue?.lastName);
                            setFieldValue("phoneNumber", newValue?.phoneNumber);
                            setFieldValue("email", newValue?.email);
                            setFieldValue("address", newValue?.location[0]?.address);
                            setFieldValue("nickName", newValue?.location[0]?.nickName);
                            setFieldValue("apartmentNo", newValue?.location[0]?.apartmentNo);
                            setFieldValue("zipCode", newValue?.location[0]?.zipCode);
                            setFieldValue("location", newValue?.location[0]?.location);
                          }}
                          options={options}
                          getOptionLabel={(option) => option?.fullName}
                          renderInput={(params) => (
                            <TextBox fullWidth {...params} label="Select a User" variant="outlined" />
                          )}
                        />) : (<CircularProgress />)}
                      </Grid>

                      {selectedOption && <Form style={{ width: "35rem" }}>
                        <Grid item lg={12} md={12} sm={12} xs={12}  >
                          <Typography variant="h6" gutterBottom style={{ fontSize: "1rem" }} > Edit User Data </Typography>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12} >
                          <Field name="firstName" label="First Name" component={TextField}
                            variant="outlined" fullWidth margin="dense" />
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12} >
                          <Field name="lastName" label="Last Name" component={TextField}
                            variant="outlined" fullWidth margin="dense" />
                        </Grid>

                        <Grid item xs={12}>
                          <Field
                            name="phoneNumber"
                            label="Phone Number"
                            component={TextField}
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            InputProps={{
                              inputComponent: TextMaskCustom,
                            }} />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name="email" label="Email" component={TextField}
                            variant="outlined" fullWidth margin="dense" />
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Field name="nickName" label="Nickname" component={TextField}
                            variant="outlined" fullWidth margin="dense" ></Field>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <SearchLocation setFieldValue={setFieldValue} values={values} setZipCode={setZipCode} dirty={dirty} isValid={isValid} />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "0.4rem" }} >
                          {zipCode ? (<Field name="zipCode" placeholder="Zip Code" label="Zip Code" component={TextField}
                            value={zipCode} variant="outlined" fullWidth margin="dense" disabled ></Field>
                          ) : (
                            <Field name="zipCode" placeholder="Zip Code" label="Zip Code" component={TextField} variant="outlined" fullWidth margin="dense" disabled ></Field>
                          )
                          }
                        </Grid>

                        <DialogActions>
                          <Button type="button" onClick={() => {
                            setZipCode("")
                            props.onClose();
                            resetForm();
                          }}>Cancel</Button>
                          <LoadingButton loading={backendCall} variant="contained"
                            disabled={!dirty || !isValid} type="submit" size="large" > Submit </LoadingButton>
                        </DialogActions>
                      </Form>}
                    </Grid>
                  );
                }}
              </Formik>
            </Grid>
            <Grid container alignItems="center" justifyContent="center" item lg={12} md={12} sm={12} style={{ marginTop: "1rem" }} >
              {alert.showAlert && (
                <DialogAlertCustomOrder setAlert={setAlert} alert={alert} onClose={props.onClose} setZipCode={setZipCode} />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>)}
    </>
  );
}

export default EditUserDialog;
