import React, { useState, useEffect } from "react";
import { Grid, TextField, Button } from "@mui/material/";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import AlertInlineMain from "components/common/AlertInlineMain";
export default function UnattendedTime({ setConfirm ,setConfirmTime}) {
  const axios = useAxiosPrivate()
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [disab, setDisabled] = useState(true);
  const [initialValues, setInitialValues] = useState({ startTime: null, endTime: null, });
  const [valueNotSet, setValueNotSet] = useState(false);

  const timeSetter = (d) => {
    const hour = d?.split(":")[0];
    const minute = d?.split(":")[1];
    const now = new Date();
    now.setHours(hour);
    now.setMinutes(minute);
    now.setSeconds(0);
    now.setMilliseconds(0);
    return now;
  };

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const convertTo24Hour = (e) => {
    return e?.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      hourCycle: "h23",
    });
  };

  // end time should be greater than starttime
  const timeChecker = (startT, endT) => {
    if (endT !== null && startT !== null) {
      if (startT.getTime() >= endT.getTime()) {
        setDisabled(true);
        setAlert({
          showAlert: true,
          severity: "error",
          message: "End time should be greater than start time!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 3000);
      } else {
        setDisabled(false);
      }
    }
  };

  const isValidTime = (time) => {
    const parsedDate = new Date(time);
    return !isNaN(parsedDate);
  }


  const handleStartTimeChange = (t, setFieldValue) => {
    const time = convertTo24Hour(t);
    timeChecker(t, endTime);
    setStartTime(t);
    setFieldValue("startTime", time);
    setValueNotSet(true);
  };

  const handleEndTimeChange = (t, setFieldValue) => {
    const time = convertTo24Hour(t);
    timeChecker(startTime, t);
    setEndTime(t);
    setFieldValue("endTime", time);
    setValueNotSet(true);
  };

  const hanldleClose = () => {
    setStartTime(null);
    setEndTime(null);
    setAlert({
      showAlert: false,
      severity: "error",
      message: "",
    });
  };

  const onSubmit = async (e) => {
    setValueNotSet(false);
    const data = { startTime: e.startTime, endTime: e.endTime, };
    if ( data.startTime === initialValues.startTime && data.endTime === initialValues.endTime ) { hanldleClose(); return; }
    try {
      await axios.put("/user/unattended-times", data);
      if (setConfirmTime) { setConfirmTime(false) }
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Time slot updated!",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "",
          message: "",
        });
      }, 1000);
    } catch (err) {
      if (err.response?.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: err.response.data.message,
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Request Failed!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Request Failed!",
          });
        }, 3000);
      }
    }
  };

  const getUnattendedTime = async () => {
    try {
      const { data } = await axios.get("/user/unattended");
      setInitialValues({ startTime: data?.startTime, endTime: data?.endTime, });
      if (data?.startTime && data?.endTime) {
        if (setConfirmTime) { setConfirmTime(false) }
      }
      setStartTime(timeSetter(data?.startTime));
      setEndTime(timeSetter(data?.endTime));
      setDisabled(false);
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Data fetched!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "Data fetched!",
          message: "",
        });
      }, 1000);
    } catch (err) {
      if (err.response?.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: err.response.data.message,
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Request Failed!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Request Failed!",
          });
        }, 3000);
      }
    }
  };
  useEffect(() => {
    getUnattendedTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup.object().shape({
    startTime: Yup.string().required("Start Time is required!"),
    endTime: Yup.string().required("End Time is required!"),
  });

  return (
    <>
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} >
        {({
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form>
              <Grid container columnSpacing={2} >
                <Grid item mt={2} lg = {6} md = {6} sm = {6} xs = {12} >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker label="Start Time" name="startTime" value={startTime} placeholder="Start Time" data-testid="start_time"
                      onChange={(e) => handleStartTimeChange(e, setFieldValue)}
                      renderInput={(params) => ( <TextField {...params} fullWidth /> )} />
                  </LocalizationProvider>
                </Grid>
                <Grid item mt={2} lg = {6} md = {6} sm = {6} xs = {12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker label="End Time" name="endTime" value={endTime} placeholder="End Time" data-testid="end_time"
                      onChange={(e) => handleEndTimeChange(e, setFieldValue)}
                      renderInput={(params) => ( <TextField {...params} fullWidth /> )} />
                  </LocalizationProvider>
                </Grid>
                </Grid>
                <Grid container mt={2}>
                  <Grid item lg = {"auto"} md = {"auto"} sm = {"auto"} xs = {"auto"} >
                    <Button variant="contained" color="primary" onSubmit={onSubmit} type="submit" disabled={(!isValid || disab || !dirty || !valueNotSet) || (!isValidTime(startTime) || !isValidTime(endTime))} data-testid="save_time">
                      Save </Button>
                  </Grid>
              </Grid> 
                {alert.showAlert && ( <Grid item md={12} m={2}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid> )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
}