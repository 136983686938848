import React, { createRef } from 'react';
import { Grid, Typography, Card, CardContent, Button } from '@mui/material';
import styles from "./dashboard.module.css";
import ScreenshotIcon from "@mui/icons-material/Screenshot";
import { useScreenshot, createFileName } from "use-react-screenshot";

// reports
import Line from './reports/franchisee/LineChart';
import NumberDetails from './reports/franchisee/NumberDetails';
import Pie from './reports/franchisee/PieChart';



export default function Dashboard() {
  const ref = createRef(null);
  // eslint-disable-next-line
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);
  return (
    <Card className={styles.dashboradStyle}>
      <CardContent>

        <Grid container ref={ref}
          rowSpacing={8}
        >
          <Grid container item>
            <Grid item
              lg={11}
              md={11}
              sm={10}
              xs={9}
            >
              <Typography
                gutterBottom
                variant='h5'
                component='div'
                style={{ font: 'bold' }}
              >
                Dashboard
              </Typography>
            </Grid>

            <Grid item
              lg={"auto"}
              md={"auto"}
              sm={"auto"}
              xs={"auto"} >
              <Button variant='contained' onClick={downloadScreenshot}>
                {' '}
                <ScreenshotIcon />
              </Button>
            </Grid>
          </Grid>

          <Grid container item>
            <Grid item
              lg={6}
              md={12}
              sm={12}
              xs={12}
              pl={{
                lg:5,                
                md:5,                
                sm:5,                
                xs:0,
              }}

              pr={{
                lg:5,                
                md:5,                
                sm:5,                
                xs:0,
              }}

            >
              <Line />
            </Grid>

            <Grid item
              lg={6}
              md={12}
              sm={12}
              xs={12}

              mt={8}>
              <NumberDetails />
            </Grid>
          </Grid>

          
          <Grid container item
            justifyContent={{
              lg: "flex-start",
              md: "center",
              sm: "center",
              xs: "center",
            }} 
            alignItems="center"
          >

            <Grid item
              lg={4.3}
              md={6}
              sm={"auto"}
              xs={"auto"}

              justifyContent='flex-start'
            >
              <div sx={{
                width:
                {
                  lg: "100vw",
                  md: "100vw",
                  sm: "100vw",
                  xs: "100vw",
                },
                
              }}>
                <h2 style={{textAlign:"center", padding:"2rem",}}>Sales By Service</h2>
                <Pie />
              </div>
            </Grid>

          </Grid>

        </Grid>
      </CardContent>
    </Card>
  );
}
