import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, Typography } from '@material-ui/core';
import { IconButton } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import UnattendedTime from './scheduling/UnattendedTime';

export default function Scheduling() {
  const [openEditor, setOpenEditor] = useState(false);
  let navigate = useNavigate();
  // const franchiseeTimeZone = sessionStorage.getItem("franchiseeTimeZone")

  return (
    <>

      <h1 style={{
        marginTop: "1rem",
        marginBottom: "4rem",
        padding: "1rem",
      }}>
        Schedule Settings
      </h1>

      <Grid container>

        <Grid
          container item
          alignItems='center'
          justifyContent='center'
          spacing={2}
          direction='row'>

          <Grid item
            lg={5}
            md={5}
            sm={12}
            xs={12}
          >
            <Button
              fullWidth
              variant='contained'
              color='primary'
              onClick={() => {
                navigate('/get-pick-up');
              }}
            >
              <h3>Get Pickups </h3>
            </Button>
          </Grid>


          <Grid item
            lg={5}
            md={5}
            sm={12}
            xs={12}
          >
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={() => {
                navigate('/get-delivery');
              }}>
              <h3>Get Deliveries </h3>
            </Button>
          </Grid>


          {/* <Grid item
            lg={5}
            md={5}
            sm={12}
            xs={12}>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={() => {
                navigate('/delivery-schedules');
              }}
            >
              <h3>Delivery schedules</h3>
            </Button>
          </Grid> */}
        </Grid>

        <Grid
          container item
          alignItems='center'
          justifyContent='center' >

          <Grid item
            lg={"auto"}
            md={"auto"}
            sm={"auto"}
            xs={"auto"}>

            <IconButton
              size='large'
              variant='contained'
              color='primary'
              onClick={() => {
                setOpenEditor(true);
              }}

              sx={{
                marginTop: "2rem",
                backgroundColor: "#3F51B5",
              }}
            >
              <AccessTimeIcon sx={{
                fontSize: "5rem",
                color: "white",
                "&:hover": {
                  color: "#3F51B5",
                }

              }} />
            </IconButton>
          </Grid>

          <Grid item
            lg={12}
            md={12}
            sm={12}
            xs={12}>

            <Typography
              style={{
                textAlign: "center",
                fontSize: "1.5rem",
                fontWeight: 500,
              }}>Change unattended time</Typography>

          </Grid>

        </Grid>

        <UnattendedTime
          openEditor={openEditor}
          setOpenEditor={setOpenEditor}
        />

      </Grid>
    </>
  );
}
