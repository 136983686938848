import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, FormControl, MenuItem, Select, InputLabel, Typography} from "@material-ui/core";
import { logged } from "../../store/actions/authActions";
import Button from "../../components/UI/ReusableButton";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { TextField as TF } from "formik-material-ui";
import ChipInput from "material-ui-chip-input";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import AlertInlineMain from "components/common/AlertInlineMain";

export default function AccountConfirm() {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const axios = useAxiosPrivate()
  let { state } = useLocation();
  const [royaltySchemes, setRoyaltySchemes] = useState([]);
  const [role, setRole] = useState("");
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [initialValues, setInitialValues] = useState({
    fID: "",
    firstName: "",
    ownerName: "",
    email: "",
    suitNo: "",
    address: "",
    serviceRegions: [],
    royaltyScheme: "",
    pricing: { serviceOne: 450, serviceTwo: 1200, serviceThree: 850 },
    note: "",
  });

  const handleLogged = () => {
    sessionStorage.setItem("isLogged", true);
    dispatch(logged());
  };

  const onSubmit = async (e, { resetForm }) => {
    try {
      await axios.put(`/user/confirmation/${state.id}`, {
        status: "sales",
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Confirmation Success!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
        handleLogged();
        navigate("/sales-config", { state: { id: state.id , role: role} });
      }, 3000);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Confirmation failed!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 3000);
    }
  };

  // get Company
  const get = async (id) => {
    try {
      const { data } = await axios.get(`/user/open/${state.id}`);
      setRole(data.role);
      setInitialValues({
        fID: data.roleID,
        firstName: data.firstName,
        email: data.email,
        address: data.address,
        ownerName: data.ownerName,
        serviceRegions: data.serviceRegions,
        royaltyScheme: data.royaltyScheme,
      });
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
    }
  };

  const getRoyalty = async () => {
    try {
      const { data } = await axios.get("/royalty/open");
      setRoyaltySchemes(data);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Data loading failed!",
      });
    }
  };

  useEffect(() => {
    get();
    getRoyalty();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    get();
    getRoyalty();
    // eslint-disable-next-line
  }, [state?.id]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required!")
      .trim()
      .matches(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter a valid email"
      )
      .max(320, "Must be less than 320 digits"),
    ownerName: Yup.string()
      .max(100, "Maximum length for the owner name is 100!")
      .required("Owner name required"),
  });

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent={"center"}>
          <Grid item>
            <Typography gutterBottom variant="h5" component="div" style={{ font: "bold" }}> Account Details </Typography>
          </Grid>
        </Grid>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize >
          {({values,handleChange}) => {
            return (
              <Form noValidate autoComplete="off" encType="multipart/form-data">
                <Grid container item spacing={2} xs={12} md={12} xl={12} justifyContent={"flex-start"} >
                  <Grid item xs={12} md={12}>
                    <Grid item xs={12} >
                      <Field name="fID" label={ (role === "franchisee" || (sessionStorage.getItem("managerType") === "franchisee" && role === "manager")) ? "Franchisee ID" : "Corporate ID"} variant="outlined" component={TF} fullWidth disabled ></Field>
                    </Grid> </Grid>
                  <Grid item xs={12} md={6} xl={6}> <Field component={TF} name="firstName" label={role === "franchisee"? "Franchisee Business Name" : "Corporate Business Name"}  variant="outlined"
                      onChange={handleChange} fullWidth disabled /> </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <Field name="ownerName" component={TF} label={(role === "franchisee" || (sessionStorage.getItem("managerType") === "franchisee" && role === "manager")) ?  "Franchisee owner name" : "Corporate owner name"}  variant="outlined"
                      onChange={handleChange} fullWidth disabled required ></Field>
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}> <Field  name="email" component={TF} label="Email Address"
                      variant="outlined" fullWidth required disabled onChange={handleChange} ></Field> </Grid>
                  <Grid item xs={12} md={12} xl={12}>
                    <ChipInput  label={(role === 'corporate' || (sessionStorage.getItem("managerType") === "corporate" && role === "manager")) ? "Approved Zip Codes for Corporate / Zip Codes" : "Approved Zip Codes for Franchisee / Zip Codes" }  value={initialValues.serviceRegions} variant="outlined" fullWidth disabled />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Field component={TF} name="address" label="Address" variant="outlined" fullWidth disabled onChange={handleChange} ></Field>
                  </Grid>
               {role === "franchisee" &&
                  <Grid item xs={12} md={12} xl={12}>
                  <InputLabel id="demo-simple-select-label" InputLabelProps={{ shrink: true }}
                        style={{ marginBottom: "-0.3rem", marginLeft: "0.8rem", fontSize: "0.75rem" }}>
                        Royalty Program </InputLabel>
                  <FormControl fullWidth>
                      <Select labelId="demo-simple-select-label" id="demo-simple-select" name="royaltyScheme"
                        value={values.royaltyScheme} label="Royalty Program" onChange={handleChange} disabled variant="outlined" >
                        {royaltySchemes.map((option) => (
                            <MenuItem name="royaltyScheme" required key={option.id} value={option.id}
                            style={{ display: "flex", justifyContent: "space-between", }} >
                            <Typography>{option.scheme_Name}</Typography>
                            <Typography> {option.royalty_Percentage}% </Typography>
                          </MenuItem> ))}
                      </Select>
                    </FormControl>
                  </Grid>
          }
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography style={{ color: "#0522fc", fontWeight: "bold", marginTop: "1rem", }} > Payment Notes </Typography>
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Field name="payment" component={TF} label="Payment type / Reference" variant="outlined" fullWidth disabled onChange={handleChange} ></Field>
                  </Grid>
                </Grid>
                <Grid item xs={4} md={4} style={{ marginTop: "1rem" }}>
                  <Button style={{ width: "8rem" }} variant="contained" color="secondary" onClick={() => { navigate("/view-zone"); }} testid="zones_btn"> Zones </Button>
                </Grid>
                <Grid style={{ marginTop: "1rem" }}>
                  <Button style={{ width: "8rem" }} variant="contained" color="primary" type="submit" testid="confirm_btn"> Confirm </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
        {alert.showAlert && ( <Grid item md={12}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid> )}
      </CardContent>
    </Card>
  );
}
