import React, { useState, useEffect } from 'react';
import { Grid, Typography, Card, CardContent } from '@mui/material';
import styles from './styles.module.css';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";

const numberWithCommas = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else { 
    return "N/A";
  }
};

const MINUTE_MS = 60000; // 30 minutes in milliseconds

export default function NumberDetails() {
  const axios = useAxiosPrivate()
  const [value, setValue] = useState({
    customers: '',
    sales: '',
    orders: 'N/A',
    franchisee: 'N/A',
  });

  const getNumberDetails = async () => {
    try {
      const { data } = await axios.get(
        '/reports/franchiser-sales/number-details'
      );
      setValue({
        customers: data?.customers,
        sales: data?.sales,
        orders: data?.orders,
        franchisee: data?.franchisee.find((f) => f._id === 'completed')?.count || 0,
      });
    } catch (err) {
      setValue({
        customers: '',
        sales: '',
        orders: '',
        franchisee: '',
      });
    }
  };
  useEffect(() => {
    getNumberDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getNumberDetails();
    }, MINUTE_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item spacing={1}>
      <Grid item
        lg={6}
        md={6}
        sm={6}
        xs={12}>
        <Card className={styles.cardStyle}>
          <CardContent>
            {' '}
            <Typography
              gutterBottom
              variant='h3'
              component='div'
              sx={{
                font: 'bold',
                fontSize: {
                  lg:"2.6rem",
                  md:"2.6rem",
                  sm:"2.1rem",
                  xs:"2rem",
                },
              }}
            >
              {numberWithCommas(value?.customers)}
            </Typography>
            <Typography
              gutterBottom
              variant='h6'
              component='div'
              style={{ font: 'bold' }}
            >
              Customers
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item
      lg={6}
      md={6}
      sm={6}
      xs={12}>
        <Card className={styles.cardStyle}>
          <CardContent>
            <Typography
              gutterBottom
              variant='h3'
              component='div'
              sx={{
                font: 'bold',
                fontSize: {
                  lg:"2.6rem",
                  md:"2.6rem",
                  sm:"2.1rem",
                  xs:"2rem",
                },
              }}
            >
              {numberWithCommas(Number(value?.sales)?.toFixed(2))}
            </Typography>{' '}
            <Typography
              gutterBottom
              variant='h6'
              component='div'
              style={{ font: 'bold' }}
            >
              Revenue Figure ($)
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item
       lg={6}
       md={6}
       sm={6}
       xs={12}>
        <Card className={styles.cardStyle}>
          <CardContent>
            {' '}
            <Typography
              gutterBottom
              variant='h3'
              component='div'
              sx={{
                font: 'bold',
                fontSize: {
                  lg:"2.6rem",
                  md:"2.6rem",
                  sm:"2.1rem",
                  xs:"2rem",
                },
              }}
            >
              {numberWithCommas(value?.orders)}
            </Typography>
            <Typography
              gutterBottom
              variant='h6'
              component='div'
              style={{ font: 'bold' }}
            >
             Avg. Daily Orders
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item
       lg={6}
       md={6}
       sm={6}
       xs={12}>
        <Card className={styles.cardStyle}>
          <CardContent>
            <Typography
              gutterBottom
              variant='h3'
              component='div'
              sx={{
                font: 'bold',
                fontSize: {
                  lg:"2.6rem",
                  md:"2.6rem",
                  sm:"2.1rem",
                  xs:"2rem",
                },
              }}
            >
              {numberWithCommas(value?.franchisee)}
            </Typography>
            <Typography
              gutterBottom
              variant='h6'
              component='div'
              style={{ font: 'bold' }}
            >
              Locations Count
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
