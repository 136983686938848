// Auth actions
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const TOGGLE = "TOGGLE";
export const ONBOARDING = "ONBOARDING";
export const TIMEZONE = "TIMEZONE";

//Other actions
export const ADD_SELECTED_ID = "ADD_SELECTED_ID";
export const ROLE_TYPE_CREATE = "ROLE_TYPE_CREATE";

//Header actions
export const SHOW_HEADER_ITEMS = "SHOW_HEADER_ITEMS";
export const HIDE_HEADER_ITEMS = "HIDE_HEADER_ITEMS";
export const HIDE_NAV = "HIDE_NAV";
export const SHOW_NAV = "SHOW_NAV";

//Route actions
export const SET_VAL = "SET_VAL";
export const SET_OPEN = "SET_OPEN";
export const SET_DEPOT = "SET_DEPOT";
export const SET_VEHICLE = "SET_VEHICLE";
export const SET_ONE_VEHICLE="SET_ONE_VEHICLE";
export const REMOVE_ONE_VEHICLE="REMOVE_ONE_VEHICLE";
export const SET_DRIVER = "SET_DRIVER";
export const SET_ONE_DRIVER="SET_ONE_DRIVER";
export const REMOVE_ONE_DRIVER="REMOVE_ONE_DRIVER";
export const SET_ASSIGNMENT = "SET_ASSIGNMENT";
export const SET_JOBS = "SET_JOBS";
export const SET_TIMELINE_SELECTED = "SET_TIMELINE_SELECTED";
export const SET_SERVICE_TIME = "SET_SERVICE_TIME";
export const PROGRESS_DATA_GRID_FILTER = "PROGRESS_DATA_GRID_FILTER";
export const SET_CORPORATE_CUSTOMERS = "SET_CORPORATE_CUSTOMERS";