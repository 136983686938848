import React, { useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { TextField as TF } from "formik-material-ui";
import { useSelector } from "react-redux";
import AlertInlineMain from "components/common/AlertInlineMain";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Typography } from "@material-ui/core";

export default function ServiceOne({ franchiseeId }) {
  const axios = useAxiosPrivate()
  const role = useSelector((state) => state.auth.role);
  const roleType = sessionStorage.getItem("role") === "manager" ?  sessionStorage.getItem("managerType") : sessionStorage.getItem("role") ;

  const franchiseeName = sessionStorage.getItem("ownerName");

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const [status, setStatus] = useState(false);
  const [disable, setDisable] = useState(false);

  const [initialValues, setInitialValues] = useState({
    price: "",
    serviceId: "",
    service: {},
  });

  const [message, setMessage] = useState("");

  const validationSchema = Yup.object().shape({
    price: Yup.number()
      .required("Price is required")
      .min(1, "The price must be greater than 0!")
      .test(
        "Is positive?",
        "The price must be to the 2 decimal points !",
        (value) => (value + "").match(/^[0-9]*(\.[0-9]{0,2})?$/),
      ),
  });

  const getData = async () => {
    try {
      const { data } = await axios.get("/service");

      setInitialValues({ 
        price: data?.price.toFixed(2), 
        serviceId: data?.id,
        priceChanged:false
      });
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Something went wrong!",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "Something went wrong!",
        });
      }, 3000);
    }
  };

  const getFranchiseeData = async () => {
    try {
      const { data } = await axios.get("/franchiseeService");

      if (data) {
        setInitialValues({
          price: data?.price.toFixed(2),
          serviceId: data?.serviceId,
          priceChanged:true
        });
      } else {
        getData();
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Something went wrong!",
      });
    }
  };

  const getFranchiseeDataByID = async () => {
    try {
      const { data } = await axios.get(`/franchiseeService/${franchiseeId}`);
      if (data) {
        setInitialValues({ price: data?.price.toFixed(2), priceChanged:true});
      } else {
        getData();
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Something went wrong!",
      });
    }
  };

  const onSubmit = async (e, { resetForm }) => {
    try {
      let data;

      if (role === "franchisor") {
        data = {
          price: Number(e.price.toFixed(2)),
        };
        await axios.post("/service", data);
        getData();
      }
      if (role === "franchisee" || role === 'corporate' || role === 'manager') {
        // data = {
        //   price: e.price,
        //   serviceId: e.serviceId,
        // };
        // await axios.post('/franchiseeService', data);
        data = {
          requestedPrice: e.price,
          originalPrice: Number(initialValues?.price).toFixed(2),
          serviceType: "WashDryFold",
          serviceId: initialValues?.serviceId,
          subject: "Price Change : Wash/Dry/Fold ",
        };

        await axios.post(`/request/price-change`, data);

        priceChangeRequest();
      }

      resetForm();
      setInitialValues({ price: "" });

      setStatus(!status);

      setAlert({
        showAlert: true,
        severity: "success",
        message: `${role === "franchisor" ? "Price Saved Successfully" : "Request Created Successfully"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: `${role === "franchisor" ? "Price Saved Successfully" : "Request Created Successfully"}`,
        });
      }, 3000);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${role === "franchisor" ? "Price update failed!" : "Request failed!"}`,
      });
    }
  };

  const priceChangeRequest = async () => {
    try {
      const { data } = await axios.get(
        `/request/price-change/${initialValues?.serviceId}`
      );
      if (data) {
        setMessage("Requested Price: $" + data?.requestedPrice.toFixed(2) + "");
        setDisable(role !== "franchisor" ? true : false);
        getFranchiseeData()
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    if(initialValues.serviceId && role !== "franchisor"){
    priceChangeRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.serviceId]);

  useEffect(() => {
    if (role === "franchisor" && !franchiseeId) {
      getData();
    }
    if (role === "franchisor" && franchiseeId) {
      getFranchiseeDataByID();
    }
    if (role === "franchisee"  || role === 'corporate' || role === 'manager') {
      getFranchiseeData();
    }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (role === 'franchisor') {
  //     // getData();
  //     getFranchiseeDataByID();
  //   }
  //   if (role === 'franchisee') {
  //     getFranchiseeData();
  //   }
  //   // eslint-disable-next-line
  // }, [status]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isValid, values, setFieldValue }) => {
        return (
          <Form noValidate autoComplete="off">
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              alignItems="center"
              justifyContent="center"
              marginTop={5}
              paddingLeft={5}
              paddingRight={5}
            >
              <Grid item xs={12} md={6}>
                <Field
                  name="price"
                  label={initialValues?.priceChanged ? `${roleType} Priced ($ per lbs)` : "Franchisor priced ($ per lbs)" }
                  component={TF}
                  variant="outlined"
                  type="number"
                  fullWidth
                  //value={values.price}
                  required
                ></Field>
              </Grid>

              <Grid style={{ margin: "1.5rem 1rem" }}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!isValid || disable}
                >
                  {role === "franchisor" ? "save" : "Request"}

                </Button>
              </Grid>

              {role !== "franchisor" && <Grid style={{ margin: "1.5rem 1rem" }}>
                <Typography variant="h6"> {message}</Typography>
              </Grid>}

            </Grid>
            {alert.showAlert && (
              <Grid item md={12} m={2}>
                <AlertInlineMain setAlert={setAlert} alert={alert} />
              </Grid>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
