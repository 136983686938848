const sortSpecialCareServices = (array) => {

    const hasSpecialCare = array.includes('Special Care');
    const hasWashDryFold = array.includes('Wash/ Dry/ Fold');
  
    const serviceName = [];
    const mainServiceName = [];
  
    if (hasWashDryFold) {
      serviceName.push('Wash/ Dry/ Fold');
      mainServiceName.push('Wash/ Dry/ Fold');
    }
  
    if (hasSpecialCare) {
      serviceName.push('Dry Cleaning', 'Tailoring & Alterations');
      mainServiceName.push('Special Care');
    }

    return { serviceName, mainServiceName }

}

export default sortSpecialCareServices;