import addZeroToNumbers from "./AddZeroToNumbers"

export default function DateSlashToDashReformat( date ) {
  
  const localDate = date?.toLocaleDateString('en-CA')

  if (localDate.includes("/")) {
    
    const reFormatDate = localDate.split("/")
    const reArrageDate = `${reFormatDate[2]}-${addZeroToNumbers(reFormatDate[0])}-${addZeroToNumbers(reFormatDate[1])}`
    return reArrageDate

  } else { 
    return localDate
  }

}
