import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { setVehicle, setOneVehicle } from "../../../../store/actions/RouteAction";
import { useSelector, useDispatch } from "react-redux";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";

export default function CreateVehicle() {
  let dispatch = useDispatch();
  const axios = useAxiosPrivate()
  const oneVehicle = useSelector((state) => state.route.oneVehicle);
  const [alignment, setAlignment] = useState(oneVehicle?.routeSettings?.availability);
  const [breakStart, setBreakStart] = useState("00:00");
  const [breakEnd, setBreakEnd] = useState("00:00");
  const [breaks, setBreaks] = useState([]);
  const [chips, setChips] = useState([]);
  const [backendCall, setBackendCall] = useState(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [initialValues, setInitialValues] = React.useState({
    availability: oneVehicle?.routeSettings?.availability,
    startAddress: "",
    endAddress: "",
    startLocation: {
      lat: 0,
      lng: 0,
    },
    endLocation: {
      lat: 0,
      lng: 0,
    },
    workingEndTime: null,
    workingStartTime: null,
    breakSet: [],
    pickups: 0,
    deliveries: 0,
    speed: 1,
    tags: [],
    fixed: 0,
    serviceTime: 0,
    drivingTime: 0,
    idleTime: 0,
    breakTime: 0,
    perMile: 0,
    perStop: 0,
    notes: "",
  });

  const [value, setValue] = React.useState(10);

  const marks = [
    { label: "Half", value: 0.5, },
    { label: "Normal", value: 1, },
    { label: "2x", value: 2, },];

  const addBreaks = (setFieldValue, values) => {
    let obj = { start: breakStart, end: breakEnd, };

    setFieldValue(`breakSet[${values.breakSet.length}]`, obj);
    if (breaks.length > 0) {
      setBreaks([...breaks, obj]);
    } else {
      let newBreaks = [obj];
      setBreaks(newBreaks);
    }
  };

  const handleAvailability = async (newAlignment, setFieldValue) => {
    setAlignment(newAlignment);
    setFieldValue("availability", newAlignment);
    setBackendCall(true);
    try {
      if (oneVehicle._id) {
        await axios.put(`/vehicle/availability/${oneVehicle._id}`, { availability: `${newAlignment}` });
        dispatch(setOneVehicle(oneVehicle));
        dispatch(setVehicle());
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Updated successfully!",
        });
        setBackendCall(false);
      }
    } catch (err) {
      setBackendCall(false);
      setAlert({
        showAlert: true,
        severity: "error",
        message: err?.response?.data ? err?.response?.data : "Update Failed!",
      });
    }
  };

  const handleSlider = (newValue, setFieldValue) => {

    if (typeof newValue.target.value === "number") {
      setValue(newValue.target.value);
      setFieldValue("speed", newValue.target.value);
    }
  };
  // Add Chips
  const handleAddChip = (chip, setFieldValue) => {
    if (chips.length > 0) {
      setChips([...chips, chip]);
      setFieldValue("tags", [...chips, chip]);
    } else {
      let newChips = [chip];
      setChips(newChips);
      setFieldValue("tags", newChips);
    }
  };
  // Delete Chips
  const handleDeleteChip = (chip, setFieldValue) => {
    let newChips = chips.filter((item) => item !== chip);
    setChips(newChips);
    setFieldValue("tags", newChips);
  };

  const onSubmit = async (e, { resetForm }) => {
    const fromData = {
      startLocation: e?.startLocation,
      endLocation: e?.endLocation,
      tags: e?.tags,
      availability: alignment,
      startAddress: e?.startAddress,
      endAddress: e?.endAddress,
      workingEndTime: e?.workingEndTime,
      workingStartTime: e?.workingStartTime,
      newWorkingEndTime: e?.newWorkingEndTime,
      newWorkingStartTime: e?.newWorkingStartTime,
      breakSet: e?.breakSet,
      pickups: e?.pickups,
      deliveries: e?.deliveries,
      speed: e?.speed ? e?.speed : 1,
      fixed: e?.fixed,
      serviceTime: e?.serviceTime,
      drivingTime: e?.drivingTime,
      idleTime: e?.idleTime,
      breakTime: e?.breakTime,
      perMile: e?.perMile,
      perStop: e?.perStop,
      notes: e?.notes
    }

    try {
      setBackendCall(true);
      if (oneVehicle._id) {
        await axios.put(`/vehicle/route-settings/${oneVehicle._id}`, fromData);
        const { data } = await axios.get("/vehicle");
        const getData = data.filter(item => item.id === oneVehicle._id)
        dispatch(setOneVehicle(getData[0]));
        dispatch(setVehicle());
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Details saved successfully!",
        });
        setBackendCall(false);
      }
    } catch (err) {
      setBackendCall(false);
      setAlert({
        showAlert: true,
        severity: "error",
        message: err?.response?.data ? err?.response?.data : "Create Failed!",
      });
    }
  };

  useEffect(() => {
    if (oneVehicle?.routeSettings?.availability) {
      if (oneVehicle?.routeSettings?.startAddress) {
        setInitialValues(oneVehicle?.routeSettings);
      } else {
        setInitialValues({
          ...oneVehicle?.routeSettings,
          availability: oneVehicle?.routeSettings?.availability,
          startAddress: "",
          endAddress: "",
          startLocation: {
            lat: 0,
            lng: 0,
          },
          endLocation: {
            lat: 0,
            lng: 0,
            address: "",
          },
          workingStartTime: "08:30",
          workingEndTime: "17:30",
          breakSet: [],
          pickups: 0,
          deliveries: 0,
          speed: 1,
          tags: [],
          fixed: 0,
          serviceTime: 0,
          drivingTime: 0,
          idleTime: 0,
          breakTime: 0,
          perMile: 0,
          perStop: 0,
          notes: "",
        });
      }
      setBreaks(oneVehicle?.routeSettings?.breakSet);
      setChips(oneVehicle?.routeSettings?.tags);
      setAlignment(oneVehicle?.routeSettings?.availability);
      setValue(oneVehicle?.routeSettings?.speed);
    } else {
      setInitialValues({
        availability: oneVehicle?.routeSettings?.availability,
        startAddress: "",
        endAddress: "",
        startLocation: {
          lat: 0,
          lng: 0,
        },
        endLocation: {
          lat: 0,
          lng: 0,
          address: "",
        },
        workingEndTime: null,
        workingStartTime: null,
        breakSet: [],
        pickups: 0,
        deliveries: 0,
        speed: 1,
        tags: [],
        fixed: 0,
        serviceTime: 0,
        drivingTime: 0,
        idleTime: 0,
        breakTime: 0,
        perMile: 0,
        perStop: 0,
        notes: "",
      });
      setBreaks([]);
      setChips([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneVehicle]);

  const validationSchema = Yup.object().shape({
    availability: Yup.string().required("Availability is required"),
    startAddress: Yup.string().required("Start Location is required"),
    endAddress: Yup.string().required("End Location is required"),
    workingStartTime: Yup.string().required("Start Time is required"),
    workingEndTime: Yup.string().required("End Time is required")
      .test("is-greater", "End Time must be greater than Start Time", function (value) {
        const startTime = this.resolve(Yup.ref('workingStartTime')); // Get the value of workingStartTime
        return !startTime || !value || value > startTime;
      }),
    pickups: Yup.number().required("Pickups is required")
      .test(
        "Is positive?",
        "The pickups value must not contain decimal points !",
        (value) => (value + "").match(/^[0-9]*(\.[0-9]{0,0})?$/),
      ),
    deliveries: Yup.number().required("Deliveries is required")
      .test(
        "Is positive?",
        "The pickups value must not contain decimal points !",
        (value) => (value + "").match(/^[0-9]*(\.[0-9]{0,0})?$/),
      ),
    // speed: Yup.number().required("Speed is required")
    //   .test( "Is positive?", "The value must be greater than or equal to 0!", (value) => value >= 0 ),
    fixed: Yup.number().required("Fixed is required")
      .test(
        "Is positive?",
        "The price must be to the 2 decimal points !",
        (value) => (value + "").match(/^[0-9]*(\.[0-9]{0,2})?$/),
      ),
    serviceTime: Yup.number().required("Service Time is required")
      .test(
        "Is positive?",
        "The Value must be to the 2 decimal points !",
        (value) => (value + "").match(/^[0-9]*(\.[0-9]{0,2})?$/),
      ),
    drivingTime: Yup.number().required("Driving Time is required")
      .test(
        "Is positive?",
        "The driving time must be to the 2 decimal points !",
        (value) => (value + "").match(/^[0-9]*(\.[0-9]{0,2})?$/),
      ),
    idleTime: Yup.number().required("Idle Time is required")
      .test(
        "Is positive?",
        "The idle time must be to the 2 decimal points !",
        (value) => (value + "").match(/^[0-9]*(\.[0-9]{0,2})?$/),
      ),
    breakTime: Yup.number().required("Break Time is required")
      .test(
        "Is positive?",
        "The break time must be to the 2 decimal points !",
        (value) => (value + "").match(/^[0-9]*(\.[0-9]{0,2})?$/),
      ),
    perMile: Yup.number().required("Per Mile is required")
      .test(
        "Is positive?",
        "The per mile must be to the 2 decimal points !",
        (value) => (value + "").match(/^[0-9]*(\.[0-9]{0,2})?$/),
      ),
    perStop: Yup.number().required("Per Stop is required")
      .test(
        "Is positive?",
        "The per stop must be to the 2 decimal points !",
        (value) => (value + "").match(/^[0-9]*(\.[0-9]{0,2})?$/),
      ),
    notes: Yup.string().max(500, "Notes must be less than 500 characters"),
  });

  return {
    setBreakStart, setBreakEnd, backendCall, alert, initialValues, value
    , marks, addBreaks, handleAvailability, handleSlider, handleAddChip, handleDeleteChip
    , onSubmit, validationSchema, alignment, setInitialValues, oneVehicle, breakStart, breakEnd, chips, setAlert, breaks
  };
}
