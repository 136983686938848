import React, { useState } from "react";
import { Grid, Card, CardContent, Typography, Button } from "@material-ui/core";
import ServiceTime from "components/franchiseeManagement/ServiceTIme";
import UnattendedTime from "components/franchiseeManagement/UnattendentTime";
import CreateZone from "components/franchiseeManagement/CreateZoneOpen";
import SalesDays from "components/franchiseeManagement/SalesDays";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import AlertMain from "components/common/AlertMain";

const SalesConfig = () => {
  const axios = useAxiosPrivate()
  const [zone, setZone] = useState(true);
  let navigate = useNavigate();

  let { state } = useLocation();
  const [confirmZone, setConfirmZone] = useState(true);
  const [confirmDays, setConfirmDays] = useState(false);
  const [confirmTime, setConfirmTime] = useState(true);
  const [submitConfigData, setSubmitConfigData] = useState(false);
  const [confirmServiceTime, setConfirmServiceTime] = useState(false);

  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "" });

  const completeSalesConfig = async () => {
    //franchisee-onboarding
    try {
    setSubmitConfigData(true);
    if(state.role === "franchisee") {
      if (state.id) {
      const refreshUrl = `${window.location.origin}/fail`;
      const returnUrl = `${window.location.origin}/success/${state.id}`;
      const { data } = await axios.post("/user/franchisee-onboarding", {
        refreshUrl,
        returnUrl,
      }); 

      await axios.put(`/user/confirmation/${state.id}`, {
        status: "payment",
      });

      setAlert({
        showAlert: true,
        severity: "success",
        message: "Successfully Updated!",
      })

        window.location.replace(data.url);
      } 
    }else{
      if (state.id) {
        await axios.put(`/user/confirmation/${state.id}`, {
          status: "completed",
        });
  
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Successfully Updated!",
        })
        sessionStorage.setItem('onboardingStatus', 'completed');
        sessionStorage.setItem('managerType', '');
        navigate("/account-management")
        } 
    }


    } catch (error) { 

      setSubmitConfigData(false);
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Error",
      })

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "Error",
        })
      }, 3000);

      console.log(error);
    }
  };

  return (
    <Card >

      <AlertMain setAlert={setAlert} alert={alert} />

      <CardContent >
        <Grid container justifyContent="center" >
          <Grid container item lg={5} md={12} sm={12} xs={12} >
            <Grid container item justifyContent="center" lg={12} md={12} sm={12} xs={12} >
              <Typography variant="h4" align="center" style={{ fontWeight: "bold" }} > Configuration </Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "2rem" }}>
              <h4>Service Time</h4>
              <Grid item lg={12} md={12} sm={12} xs={12} > <ServiceTime submitConfigData={submitConfigData} setConfirmServiceTime={setConfirmServiceTime} /> </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "2rem" }}>
              <h4>Unattended Time</h4>
              <Grid item lg={12} md={12} sm={12} xs={12} > <UnattendedTime setConfirmTime={setConfirmTime} /> </Grid>
            </Grid>
            {zone && (<Grid container item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "1rem" }}>
              <CreateZone setCreateZone={() => setZone(false)} setConfirmZone={setConfirmZone} />
            </Grid>)}
            <Grid container item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "2rem" }}>
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <div style={{ marginBottom: "1rem" }}>
                  <SalesDays setConfirmDays={setConfirmDays} submitConfigData={submitConfigData} />
                </div>
              </Grid>
             {state?.id && <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={12} >
                <Button variant="contained" color="primary" onClick={completeSalesConfig} disabled={confirmZone || confirmDays || confirmTime || confirmServiceTime} data-testid="save_sales_config"> Confirm  </Button>
              </Grid>}
            </Grid>
          </Grid>
        </Grid>

      </CardContent>
    </Card >
  );
};

export default SalesConfig;
