import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import AlertInlineMain from "components/common/AlertInlineMain";
import Grid from "@mui/material/Grid";

import { TextField as TF } from "formik-material-ui";

function EditPopup(props) {
  const axios = useAxiosPrivate()
  const { setOpen, open, editData, setStatus, status } = props;

  const handleClose = () => {
    setOpen(false);
    setStatus(!status);
  };

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const onSubmit = async (e, { resetForm }) => {
    try {
      const data = {
        id: e.id,
        scheme_Name: e.scheme_Name,
        royalty_Percentage: e.royalty_Percentage,
      };
      await axios.put(`/royalty/${data.id}`, {
        scheme_Name: data.scheme_Name,
        royalty_Percentage: data.royalty_Percentage,
      });

      resetForm();
      setStatus(!status);

      setAlert({
        showAlert: true,
        severity: "success",
        message: "The scheme was successfully updated!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "Request Success!",
        });
        setOpen(false);
      }, 3000);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Request failed!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "Request failed!",
        });
      }, 3000);
    }
  };

  const validationSchema = Yup.object().shape({
    scheme_Name: Yup.string()
      .max(100, "Maximum length is 100")
      .required("Required"),
    royalty_Percentage: Yup.number()
      .typeError("Please enter a valid number")
      .required("Royality Percentage % is required")
      .min(0, "Minimum atleast 0")
      .max(100, "Allowed maximum is 100")
      .test('is-decimal', 'invalid decimal', (value) =>
      (value + '').match(/^[0-9]\d?(?:\.\d{0,2})?$/)
    )
  });

  const initialValues = {
    id: editData?.id,
    schemeID: editData?.schemeID,
    scheme_Name: editData?.scheme_Name,
    royalty_Percentage: editData?.royalty_Percentage,
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({
            touched,
            errors,
            values,
            isValid,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form noValidate autoComplete="off" encType="multipart/form-data">
                <DialogTitle>Create Scheme</DialogTitle>
                <DialogContent>
                  {/* <Box
                    component="form"
                    xs={{
                      display: "flex",
                      flexDirection: "column",
                      m: "auto",
                      width: "fit-content",
                    }}
                  > */}
                    <FormControl sx={{ mt: 1 }}>
                      <Grid container spacing={1} justifyContent={"center"}>
                        <Grid item 
                        lg={4}                        
                        md={4}                        
                        sm={12}   
                        xs={12}>
                          <Field
                            name="schemeID"
                            disabled={true}
                            component={TF}
                            label="Scheme ID"
                            style={{
                              display: "flex",
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item 
                        lg={4}                        
                        md={4}                        
                        sm={12}   
                        xs={12}>
                          <Field
                            name="scheme_Name"
                            label="Scheme Name"
                            component={TF}
                            style={{
                              display: "flex",
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item 
                        lg={4}                        
                        md={4}                        
                        sm={12}   
                        xs={12}>
                          <Field
                            name="royalty_Percentage"
                            label="Royalty Percentage %"
                            component={TF}
                            style={{
                              display: "flex",
                            }}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  {/* </Box> */}

                  {alert.showAlert && (
                    <Grid item md={12} m={2}>
                      <AlertInlineMain setAlert={setAlert} alert={alert} />
                    </Grid>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    color="success"
                    onClick={onSubmit}
                    type="submit"
                    disabled={!isValid}
                  >
                    Save
                  </Button>
                  <Button color="error" onClick={handleClose}>
                    Close
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
}

export default EditPopup;
