import React, { useState, useEffect, useMemo } from "react";
import { GoogleMap, useJsApiLoader, Polyline, Marker, InfoWindow } from "@react-google-maps/api";
import { Skeleton, Grid, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setDepot } from "../../../src/store/actions/RouteAction";
import JobDetailsModal from "../common/JobPopUp/jobDetailsPopUp";

const mapStyles = {
  height: "100vh",
  width: "100%",
};

const pointSvg = {
  path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
  fillColor: '#FF0000',
  fillOpacity: .6,
  strokeWeight: 0,
  scale: 1
}
const zoom = 10;

const options = {
  zoomControl: true,
  streetViewControl: true,
  mapTypeControl: true,
  fullscreenControl: true,
};

const mapMarker = () => { };

const MapContainer = ({ locationData, center, dotsLineDataArray }) => {
  let dispatch = useDispatch();
  const [rowDetails, setRowDetails] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);

  const returnStatusColor = (type, status) => {

    // DELIVERY
    if (type === "DELIVERY" && status === "SET_TO_DELIVER") {
      return "#65b3f7" 
    } else if (type === "DELIVERY" && status === "DELIVERED") {
      return "#65f796" 
    } else if (type === "DELIVERY" && status === "DELIVERY_CANCELED") {
      return "#f76565" 
    }

    // COLLECTION
    else if (type === "COLLECTION" && status === "SET_TO_PICKUP") {
      return "#65b3f7" 
    } else if (type === "COLLECTION" && status === "PICKED_UP") {
      return "#65f796" 
    } else if (type === "COLLECTION" && status === "PICKUP_CANCELED") {
      return "#f76565" 
    } else { 
      return "#000000" 
    }

  } 

  const depot = useSelector((state) => state.route.depot);

  const normalCenter = useMemo(() => { return { lat: Number(depot?.location?.latitude), lng: Number(depot?.location?.longitude) } }, [depot]);
  const jobs = useSelector((state) => state.route.jobs);
  //const depot = useSelector((state) => state.route.depot);

  mapMarker(jobs);

  useEffect(() => {
    dispatch(setDepot());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_KEY,
    libraries: ["places"],
  });

  const [map, setMap] = useState(null);

  const lineSymbol = {
    path: 'M 1.5 1 L 1 0 L 1 2 M 0.5 1 L 1 0',
    fillColor: 'black',
    strokeColor: 'black',
    strokeWeight: 2,
    strokeOpacity: 1
  };

  const dotLineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    scale: 4,
  };

  if (!isLoaded) {
    return (
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        variant="rectangular"
        width={"100%"}
        height={"100vh"}
      />
    );
  }



  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            map.panTo(center ?? normalCenter);
            map.setZoom(zoom);
          }}
        >
          Center Map
        </Button>
      </Grid>

      {/* Google Map Box */}
      <Grid item xs={12} md={12} lg={12}>
        <GoogleMap
          center={center ?? normalCenter}
          zoom={zoom}
          mapContainerStyle={mapStyles}
          options={options}
          onLoad={(map) => setMap(map)}
          defaultCenter={center ?? normalCenter}

        >
          {locationData?.locations?.length > 0 && locationData?.locations?.map((location) => {
            return (
              <Polyline
                path={location}
                options={{
                  strokeColor: 'green', strokeWeight: 6,
                  icons: [{
                    icon: lineSymbol,
                    offset: '25px',
                    repeat: '100px'
                  }]
                }}
              />
            )
          })}
          {locationData?.orders?.map((item) => {
            return (
              item?.location &&
              <Marker
                position={item?.location}
                label={item?.orderNumber?.toString()}
                onClick={() => {
                  setActiveMarker(item?.orderNumber)
                  setRowDetails(item?.jobDetails)
                }}
                // onMouseOut={() => setActiveMarker(null)}
                icon={{
                  path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0", // svg here
                  fillColor: returnStatusColor(item?.jobDetails?.type, item?.jobDetails?.status) ?? "#000000",
                  fillOpacity: 0.8,
                  strokeWeight: 1,
                  strokeColor: "#000000",
                  scale: 0.6,
                  anchor: null,
                }}
              >

                {(activeMarker === item?.orderNumber && rowDetails) ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                      <div style={{
                        width: "fit-content",
                        overflowX: "auto",
                      }}>
                    <JobDetailsModal openDetailsView={activeMarker} popUpClose={() => setActiveMarker(false)} setOpenDetailsView={setActiveMarker} rowDetails={rowDetails} />
                    </div>
                    </InfoWindow>
                ) : null}

              </Marker>
            )
          })}
          <Marker
            position={center ?? normalCenter}
            icon={pointSvg}
          />
          {dotsLineDataArray?.map((location) => {
            return (
              <Polyline
                path={location}
                options={{
                  strokeOpacity: 0,
                  icons: [{
                    icon: dotLineSymbol,
                    offset: "0",
                    repeat: "20px",
                  }]
                }}
              />
            )
          })}
        </GoogleMap>
      </Grid>
    </Grid>
  );
};
export default MapContainer;
