

export default function TwentyFourToAmPm(time24) {

  let [hours, minutes] = time24.split(':');
  let suffix = hours >= 12 ? 'PM' : 'AM';
  
  switch (hours) { 
    case '13':
      return '01:' + minutes + ' ' + suffix;
    case '14':
      return '02:' + minutes + ' ' + suffix;
    case '15':
      return '03:' + minutes + ' ' + suffix;
    case '16':
      return '04:' + minutes + ' ' + suffix;
    case '17':
      return '05:' + minutes + ' ' + suffix;
    case '18':
      return '06:' + minutes + ' ' + suffix;
    case '19':
      return '07:' + minutes + ' ' + suffix;
    case '20':
      return '08:' + minutes + ' ' + suffix;
    case '21':
      return '09:' + minutes + ' ' + suffix;
    case '22':
      return '10:' + minutes + ' ' + suffix;
    case '23':
      return '11:' + minutes + ' ' + suffix;
    case '00':
      return '12:' + minutes + ' ' + suffix;
    default:
      return time24 + ' ' + suffix;;

  } 
}
