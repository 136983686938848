import React, { useState } from 'react';

import { Grid, Button } from '@mui/material';
import Datagrid from '../../components/layouts/DataGrid';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import DeleteRecord from './DeleteRecord';
import moment from "moment";
import CreatePromocode from './Create-promocode';
import AlertInlineMain from "components/common/AlertInlineMain";
import ViewPromocode from './ViewPromocode';
import VisibilityIcon from "@mui/icons-material/Visibility";
import sortServiceNameArray from 'components/common/SortServiceNames';
import sortSpecialCareServices from 'components/common/SortSpecialCareServices';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = { PaperProps: { style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250, }, }, };

const generatePromoCode = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const services = [
  { name: 'Wash/ Dry/ Fold', types: ['1', '5', '6', '7', '8', '9', '10'], },
  { name: 'Dry Cleaning', types: ['2', '4', '7', '8', '9', '10'], },
  { name: 'Tailoring & Alterations', types: ['3', '4', '5', '6', '9', '10'], },
];

const mainServices = [
  { name: 'Wash/ Dry/ Fold' },
  { name: 'Special Care' },
];

const uniqueArray = (serviceName) => {
  const unique = [];
  for (const val of serviceName) {
    for (const type of services) {
      if (!unique.includes(val) && type.name === val) { unique.push(type.types); }
    }
  }
  // make unique
  return [...new Set(unique.flat())];
};

export default function Promocodes() {
  const axios = useAxiosPrivate();
  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [openViewPromoCode, setOpenViewPromoCode] = useState(false);
  const [serviceName, setServiceName] = useState([]);
  const [mainServiceName, setMainServiceName] = useState([]);
  const [alert, setAlert] = useState({ showAlert: false, severity: 'success', message: '', });
  const franchiseeTimeZone = sessionStorage.getItem("franchiseeTimeZone")
  const [viewPromoData, setViewPromoData] = useState(null);

  const handleServiceName = (array) => {

    const { serviceName, mainServiceName } = sortSpecialCareServices(array);

    setServiceName(serviceName);
    setMainServiceName(mainServiceName);

  }

  const alertSetter = (showAlert, severity, message, time) => {
    if (time) {
      alertSetter(showAlert, severity, message);
      setTimeout(() => { alertSetter(false, severity, ''); }, time);
    } else {
      setAlert({ showAlert: showAlert, severity: severity, message: message, });
    }
  };

  const handleClose = () => {
    setServiceName([]);
    setStatus(!status);
    setOpen(false);
    setMainServiceName([]);
  };

  const handleCloseViewPromocode = () => {
    setOpenViewPromoCode(false);
  };

  const handleViewPromo = (cellValues) => {
    setOpenViewPromoCode(true);
    setViewPromoData(cellValues?.row);
  };

  const deleteRecord = async (id) => {
    try {
      await axios.delete(`/rewards/promocodes/${id}`);
      setStatus(!status);
      alertSetter(true, 'success', 'Reward Delete Successfully', 1000);
    } catch (error) {
      alertSetter(true, 'success', 'Error in Deleting Reward', 3000);
      setStatus(!status);
    }
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 0.7, minWidth: 250 },
    { field: 'description', headerName: 'Description', flex: 0.7, minWidth: 500, },
    {
      field: 'serviceName', headerName: 'Applicable Services', flex: 0.3, minWidth: 180,
      valueGetter: (params) => {
        return `${sortServiceNameArray(params?.row?.serviceName)}`;
      },
    },
    { field: 'promocode', headerName: 'Promo Code', flex: 0.2, minWidth: 100, },
    {
      field: 'date', headerName: 'Date', flex: 0.3, minWidth: 170,
      valueGetter: (params) => { return `${moment.tz(params.row.date, `${franchiseeTimeZone}`).format("MMMM Do YYYY")}`; },
    },
    {
      field: 'action', headerName: 'Action', minWidth: 60, flex: 0.2, disableExport: true,
      renderCell: (cellValues) => {
        return (
          <Grid container spacing={2} ml={1}>
            <Grid item xs={2}>
              <DeleteRecord deleteRecord={() => deleteRecord(cellValues.row.id)} alert={alert} setAlert={setAlert} title={'Promocode Deletion?'}
                message={'This Promocode will be permanently deleted and cannot be recovered. Are you sure you want to delete this Promocode?'} />
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'view', headerName: 'View', flex: 0.2, minWidth: 60,
      renderCell: (cellValues) => {
        return (
          <Button onClick={() => handleViewPromo(cellValues)}>
            <VisibilityIcon />
          </Button>
        );
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent={"flex-end"} marginTop={{ lg: 0, md: 0, sm: 5, xs: 5, }} >
        <Button sx={{ width: { lg: "auto", md: "auto", sm: "auto", xs: "100%", } }} variant="contained" onClick={() => setOpen(true)}> Create Promocode </Button>

        <CreatePromocode open={open} handleClose={handleClose} alertSetter={alertSetter} serviceName={serviceName}
          setServiceName={setServiceName} setStatus={() => setStatus(!status)} generatePromoCode={generatePromoCode}
          services={services} uniqueArray={uniqueArray} MenuProps={MenuProps} handleServiceName={handleServiceName} mainServices={mainServices} mainServiceName={mainServiceName} />

        <ViewPromocode open={openViewPromoCode} handleClose={handleCloseViewPromocode} alertSetter={alertSetter} serviceName={serviceName}
          setServiceName={setServiceName} setStatus={() => setStatus(!status)} generatePromoCode={generatePromoCode}
          services={services} uniqueArray={uniqueArray} MenuProps={MenuProps} viewPromoData={viewPromoData} />

      </Grid>
      <Grid item xs={12}> <Datagrid fetchDataUrl={`/rewards/promocodes`} columns={columns} status={status} title='Promocodes' /> </Grid>
      {alert.showAlert && (<Grid item md={12}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid>)} </Grid>);
}
