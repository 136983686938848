import React, { Fragment } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Chip,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import FaceIcon from "@material-ui/icons/Face";
import { makeStyles } from "@material-ui/core/styles";
import { logout, toggleDrawer } from "../../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../utils/assets/logo.png";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DateTime from "../services/currentTime"
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    // // flexGrow: 1,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  self: {
    backgroundColor: "blue",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  flexGrow: {
    marginRight: "auto",
  },
  chipStyles: {
    marginLeft: "1rem",
    marginRight: "1rem",
    marginTop: "5px",
    backgroundColor: "white",
  },
  mobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  button: {
    display: "flex",
    flexDirection: "row-reverse",
    flexGrow: 1,
  },

  link: {
    textDecoration: "none",
  },
}));

function NavBar(props) {
  const classes = useStyles();

  const timeZoneSession = sessionStorage.getItem("franchiseeTimeZone")

  let navigate = useNavigate();

  let dispatch = useDispatch();

  const token = sessionStorage.getItem('accessToken');
  // let token = useSelector((state) => state.auth.accessToken);

  // const showNav = useSelector((state) => state.header.showNav);

  // let onboardingStatus = useSelector((state) => state.auth.onboardingStatus);
  const onboardingStatus = sessionStorage.getItem('onboardingStatus');

  let name = useSelector((state) => state.auth.fullName?.split("undefined")[0]);
  const [open, setOpen] = React.useState(false);

  const role = sessionStorage.getItem("role");
  if (role === "franchisor") {
  }
  const onLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const handlePassword = () => {
    navigate("/change-password");
    setOpen(!open);
  };

  const handleMenu = (event) => {
    setOpen(!open);
  };
  const toggle = () => {
    dispatch(toggleDrawer());
  };
  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <div>
      {true && (
        <AppBar position="fixed" style={{ backgroundColor: "#101d27" }}>
          <Toolbar>
            {token && onboardingStatus === "completed" && (role === "franchisee" ? timeZoneSession : true) && (
              <IconButton
                onClick={toggle}
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
            )}
            <img
              width="55"
              height="55"
              src={logo}
              alt="LWL logo"
              style={{ objectFit: "contain" }}
            />
            <Typography variant="h6" className={classes.mobile}>
              {role === "franchisor"
                ? "Franchisor Panel"
                : role === "franchisee"
                  ? "Franchisee Panel"
                  : "LWL Admin Panel"}
            </Typography>

            {token && onboardingStatus === "completed" && (
              <Fragment>
                {(role === "franchisee" || role === "corporate" ||  role === "manager")&& sessionStorage.getItem("franchiseeTimeZone") ?
                  <div style={{ marginLeft: "auto" }}>
                    <DateTime />
                  </div> : null
                }
                <div style={{ marginLeft: "auto" }}>
                  <IconButton
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    data-testid="user_button"
                  >
                    <Chip
                      icon={<FaceIcon />}
                      label={name.replace("Mr", "")}
                      variant="outlined"
                      className={classes.chipStyles}
                    />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    getContentAnchorEl={null}
                    disableAutoFocusItem
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    PaperProps={{
                      style: {
                        marginTop: "2rem",
                        maxHeight: 40 * 4,
                        width: "10rem",
                      },
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handlePassword}>
                      Change Password
                    </MenuItem>
                    <MenuItem onClick={onLogout} data-testid="logout_user_button_dropdown">Logout</MenuItem>
                  </Menu>
                </div>{" "}
              </Fragment>
            )}
          </Toolbar>
        </AppBar>
      )}
    </div>
  );
}

export default NavBar;
