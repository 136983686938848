import React, { useState } from "react";
import { Grid, Card, Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
// validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DialogAlertMain from "components/common/DialogAlertMain";
import { Typography } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     marginTop: "2rem",
//     height: "100%",
//   },
//   center: {
//     textAlign: "center",
//   },
//   padding: {
//     padding: "3rem",
//     [theme.breakpoints.down("md")]: { padding: "3rem", },
//     [theme.breakpoints.down("sm")]: { padding: "3rem", },
//     [theme.breakpoints.down("xs")]: { padding: "0rem", },
//   },
//   formPara: {
//     textAlign: "center"
//   },
//   cancelButton: {
//     fontSize: "1rem",
//     border: "0",
//     color: "black",
//     fontWeight: "600",
//     fontFamily: "Lato,sans-serif",
//     textTransform: 'none',
//     "&:hover": {
//       backgroundColor: "#E5F4F9",
//     },
//   },
//   emailIn: {
//     fontSize: "1.7rem",
//     maxWidth: "100%",
//     minHeight: "4rem",
//     backgroundColor: "#eee",
//     border: "1px solid #ddd",
//     borderRadius: "0.3rem",
//     marginBottom: "10px",
//     color: "#101d27",
//   },

// }));



export default function Login() {

  const marginsSm = useMediaQuery("(max-width: 899px)");

  const navigate = useNavigate();
  // const classes = useStyles();
  const [backendCall, setBackendCall] = useState(false);
  const axios = useAxiosPrivate();
  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({ email: "" });
  let ForgotPassSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required!"),
  });

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e) => {
    setBackendCall(true)
    const email = {
      email: e.email,
    }
    try {
      await axios.post("/auth/user/forgot-password", email)
      setBackendCall(false)
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Password Reset Email Sent, Please Check Your Email",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "",
          message: "",
        });
        navigate("/");
      }, 4000);
    } catch (err) {
      setBackendCall(false)
      if (err.response.status === 403) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: err.response.data.message,
        });
      }
    }
  }

  return (
    <Grid container>
      <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent="center" alignItems="center" >

        <Grid item lg={6} md={8} sm={10} xs={11}>
        <Card style={{marginTop:"10rem", marginBottom:"10rem", padding:"3rem"}}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={{ fontSize: "1.5rem", textAlign: "center" }}>Password Reset</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography style={{marginBottom:"0.1rem", marginTop:"0.5rem", textAlign:"center"}}>Enter the email address that you signed up
            with and we'll email you a link
            to reset your password.</Typography>
        </Grid>
 

      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={ForgotPassSchema}
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid container item lg={12} md={12} sm={12} xs={12}
                justifyContent="center"
                alignItems="center" >

                <Grid item lg={8} md={12} sm={12} xs={12} style={{marginBottom:"1rem", marginTop:"1rem"}}>
                  <Field
                    fullWidth
                    name="email"
                    type="email"
                    label="Email address"
                    style={{
                      fontSize: marginsSm ? "1.25rem" : "1.7rem",
                      width: "100%",
                      minHeight: marginsSm ? "3rem" : "4rem",
                      backgroundColor: "#eee",
                      border: "1px solid #ddd",
                      borderRadius: "0.3rem",
                      marginBottom: "10px",
                      color: "#101d27",
                      paddingLeft:"0.5rem"
                    }}
                  ></Field>
                </Grid>

                <Grid item lg={8} md={12} sm={12} xs={12} style={{marginBottom:"0.5rem"}}>
                  <LoadingButton
                    loading={backendCall}
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    disabled={!dirty || !isValid}
                  >
                    submit
                  </LoadingButton>
                </Grid>

              </Grid>
            </Form>

          );
        }}

      </Formik>

      <Grid container>

        <Grid container item
          alignItems="center"
          justifyContent="center"
        >

          <Grid item
            lg={'auto'}
            md={'auto'}
            sm={'auto'}
            xs={'auto'}>
            <Button
              color="primary"
              variant="text"
              onClick={() => navigate("/")}
              style={{
                fontSize: "1rem",
                border: "0",
                color: "black",
                fontWeight: "600",
                fontFamily: "Lato,sans-serif",
                textTransform: 'none',
                "&:hover": { backgroundColor: "#E5F4F9", }
              }} >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
        </Card>
        
    </Grid >
      </Grid >
      <DialogAlertMain setAlert={setAlert} alert={alert} /> 
    </Grid >
  );
}
