

export default function addZeroToNumbers(val) {
  
  const checkFirstVal = String(val).split("")[0]
  
  if (val < 10 && checkFirstVal!=="0") {
    return `0${val}`
  } else { 
    return `${(val)}`
  }

}
