import React, { useMemo, useState } from "react";
import { Grid, Dialog, DialogTitle, DialogContent, Button, DialogActions, InputLabel, Select, FormControlLabel } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogContentText from '@mui/material/DialogContentText';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
// validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import SearchLocation from "./GoogleMapsAutocomplete";
// import { setCorporateCustomers } from "store/actions/RouteAction";

import { FormControl, MenuItem, FormGroup, Checkbox } from "@mui/material";
import DateSlashToDashReformat from "components/common/Functions/DateSlashToDashReformat";
import { IMaskInput } from 'react-imask';

let SignUpSchema = Yup.object().shape({
  firstName: Yup.string().required('FirstName is required'),
  lastName: Yup.string().required('LastName is required'),
  // email: Yup.string().email('Invalid email').required('Required'),
  address: Yup.string().required("Email is required!"),
  // apartmentNo: Yup.string(),
  phoneNumber: Yup.string().matches(
    /^\+[0-9]{1}\s[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
    "Please enter a valid Phone Number with country code"
  ).required("Phone Number is required"),
  zipCode: Yup.string().required("Zip Code is required"),
  time: Yup.string().required('Time is Required'),
  // serviceType: Yup.string().required('Service type is required'),
  instruction: Yup.string().required('Instruction is required'),
});

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+0 000-000-0000"
      definitions={{
        '0': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

function AddNewJobDialog(props) {

  const getServiceType = () => {
    if (props?.data?.orderDetail?.isManualOrder) {
      return "Manual Order"
    } else if (props?.data?.orderDetail?.orderType) {
      switch (props?.data?.orderDetail?.orderType) {
        case '1':
          return 'Wash/Dry/Fold';
        case '2':
          return 'Dry Cleaning';
        case '3':
          return 'Tailoring';
        case '4':
          return 'Dry Cleaning & Tailoring';
        case '5':
          return 'Wash/Dry/Fold , Tailoring';
        case '6':
          return 'Wash/Dry/Fold , Tailoring';
        case '7':
          return 'Wash/Dry/Fold , Dry Cleaning';
        case '8':
          return 'Wash/Dry/Fold , Dry Cleaning';
        case '9':
          return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
        case '10':
          return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
        default:
          return 'Enter a service t';
      }
    } else {
      return ""
    }
  }

  const axios = useAxiosPrivate();
  const [zipCode, setZipCode] = useState(props?.data?.orderDetail?.zipCode || "");
  //eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({
    firstName: props?.data?.customerDetail.firstName || "",
    lastName: props?.data?.customerDetail.lastName || "",
    phoneNumber: props?.data?.phoneNumber || "",
    email: props?.data?.customerDetail.email || "",
    address: props?.data?.address || "",
    nickName: "",
    apartmentNo: props?.data?.orderDetail.apartmentNo || "",
    zipCode: props?.data?.orderDetail.zipCode || "",
    location: props?.data?.location,
    time: props.data
      ? new Date(
        props?.data?.type == "COLLECTION"
          ? props?.data?.orderDetail.pickupDate
          : props?.data?.orderDetail.diliverDate
      )
      : "",
    notes: props?.data?.notes,
    instruction: props?.data?.instruction,
    serviceType: getServiceType(),
  });

  const [backendCall, setBackendCall] = useState(false);
  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });
  const [driverDetails, setDriverDetails] = useState(props?.vehiclesInfo?.filter(item => item._id == props?.data?.driverDetail?.vehicle)[0]);

  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);

  const handleChange1 = (event) => { setChecked1(event.target.checked); };
  const handleChange2 = (event) => { setChecked2(event.target.checked); };
  const handleChange3 = (event) => { setChecked3(event.target.checked); };

  function handleOrderType() {
    if (checked1 && !checked2 && !checked3) return 1;
    else if (!checked1 && checked2 && !checked3) return 2;
    else if (!checked1 && !checked2 && checked3) return 3;
    else if (!checked1 && checked2 && checked3) return 4;
    else if (checked1 && !checked2 && checked3) return 5;
    else if (checked1 && checked2 && !checked3) return 7;
    else if (checked1 && checked2 && checked3) return 9;
  };

  const fomratedPayloadDate = useMemo(() => {
    const returnFormatedDate = DateSlashToDashReformat(props?.date || new Date(props?.data?.type == "COLLECTION" ? props?.data?.orderDetail.pickupDate : props?.data?.orderDetail.diliverDate));
    return returnFormatedDate
  }, [props?.date]);

  const handleSelectDriverChange = (event) => {

    let vehicleData = null;
    vehicleData = props?.vehiclesInfo && props?.vehiclesInfo?.filter((e) =>
      e.driver._id === event.target.value
    )

    setDriverDetails(vehicleData[0])
  };

  function alertHandleClick() {
    props?.onClose();
    setZipCode('');
    setAlert({
      ...alert,
      showAlert: false,
    });
  }

  // const checkZipCode = async (zipCode) => { await axios.post(`/user/serviceRegions/`, { zipcode: zipCode, }); };

  const formatDateString = (date) => {
    const formattedDate = new Date(date).toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });

    return formattedDate;
  }

  const onSubmit = async (e, { resetForm, setSubmitting }) => {
    try {

      const orderType = handleOrderType();

      const data = {
        First: e.firstName,
        Last: e.lastName,
        Notes: "",
        Phone: '+' + e.phoneNumber.replace(/[^\w]/g, ""),
        Zip: `Zip: ${e.zipCode}`,
        Zone: "",
        deliveries: props?.type === 1 || props?.data?.type == "COLLECTION" ? "0" : "1", // 0 is pickup & 1 is delivery
        diliverDate: formatDateString(fomratedPayloadDate),
        diliverTimeSlot: e.time,
        pickupDate: formatDateString(fomratedPayloadDate),
        pickupTimeSlot: e.time,
        address: e.address,
        location: e.location,
        instruction: e.instruction,
        notes: e.notes,
        driverID: driverDetails?.driver?._id,
        vehicleID: driverDetails?._id,
        type: props?.type === 1 || props?.data?.type == "COLLECTION" ? "COLLECTION" : "DELIVERY",
        dateActual: fomratedPayloadDate,
        originalDate: fomratedPayloadDate,
        orderType: orderType,
      }

      setBackendCall(true);
      await axios.post(`/job/custom/create`, data);
      resetForm();
      setSubmitting(false);
      setAlert({ showAlert: true, severity: "success", message: "Job creation successful!", });
    } catch (error) {
      if (error.response.status === 500) {
        setAlert({ showAlert: true, severity: "error", message: "Job creation failed!", });
      } else { setAlert({ showAlert: true, severity: "error", message: error?.response?.data ?? "Something went wrong!", }); }

      resetForm();
      setSubmitting(false);
      setZipCode("");
    }
    setBackendCall(false);
  };

  return (
    <>
      {alert.showAlert ? (<Dialog
        open={true}
        onClose={alertHandleClick}
        fullWidth={'sm'}
        maxWidth={'sm'}
      >
        <DialogTitle id='alert-dialog-title'
          sx={{
            textTransform: "uppercase",
            color: alert?.severity === "success" ? "green" : "red"
          }}>{alert?.severity}</DialogTitle>

        <DialogContent>
          <DialogContentText
            sx={{
              fontSize: "1rem",
            }}
            id='alert-dialog-description'>
            {alert.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={alertHandleClick}
            variant='contained'
            // color={alert?.severity === "success" ? 'success' : 'error'}
            style={{
              backgroundColor: alert?.severity === "success" ? "#2e7d32" : "#d32f2f",
              color: "white"
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>) : (<Dialog open={props?.open} onClose={props?.onClose}>
        <DialogTitle>Create New Job</DialogTitle>
        <DialogContent>
          <Grid container lg={12} md={12} sm={12} xs={12} justifyContent="center" alignItems="center" >
            <Grid container item lg={12} md={12} sm={12} xs={12} alignItems="center" justifyContent="center" >
              <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={SignUpSchema} enableReinitialize >
                {({ dirty, isValid, values, setFieldValue, resetForm }) => {
                  return (
                    <Grid container item justifyContent="center" alignItems="center" lg={12} md={12} sm={12} xs={12} >
                      <Form style={{ width: "35rem" }}>

                        <Grid item xs={12} sm={6}>
                          <Field name="firstName" label="First Name" component={TextField}
                            variant="outlined" fullWidth margin="dense" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field name="lastName" label="Last Name" component={TextField}
                            variant="outlined" fullWidth margin="dense" />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name="phoneNumber" label="Phone Number" component={TextField}
                            variant="outlined" fullWidth margin="dense"
                            InputProps={{
                              inputComponent: TextMaskCustom,
                            }} />
                        </Grid>

                        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Field name="date" label="Pickup / Delivery date" component={TextField}
                            variant="outlined" fullWidth margin="dense" type="date" disabled={true}></Field>
                        </Grid> */}

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Field name="time" label="Pickup / delivery time" component={TextField}
                            variant="outlined" fullWidth margin="dense" placeholder="9:00 PM - 10:00PM" ></Field>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12} style={{ margin: "8px 0px 8px 0px" }}>
                          {/* <Field name="driver" label="Driver" component={TextField}
                            variant="outlined" fullWidth margin="dense" ></Field> */}
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Driver</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={driverDetails?.driver?._id || ""}
                              label="Driver"
                              onChange={handleSelectDriverChange}
                            >
                              {props?.vehiclesInfo && props?.vehiclesInfo.map((e) =>
                                <MenuItem key={e.driver._id} value={e.driver._id}>{e?.driver?.name}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </Grid>

                        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Field name="serviceType" label="Service Type" component={TextField}
                            variant="outlined" fullWidth margin="dense" ></Field>
                        </Grid> */}

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <label style={{
                            color: "rgba(0, 0, 0, 0.54)",
                            padding: "0",
                            fontSize: "1rem",
                            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                            fontWeight: "400",
                            lineHeight: "1",
                            letterSpacing: "0.00938em",
                          }}>Service Type</label>
                          <FormGroup>
                            <FormControlLabel control={<Checkbox checked={checked1} onChange={handleChange1} />} label="Wash/Dry/Fold Service" />
                            <FormControlLabel control={<Checkbox checked={checked2} onChange={handleChange2} />} label="Dry Cleaning" />
                            <FormControlLabel control={<Checkbox checked={checked3} onChange={handleChange3} />} label="Tailoring & Alterations" />
                          </FormGroup>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Field name="instruction" label="Instructions" component={TextField}
                            variant="outlined" fullWidth margin="dense" ></Field>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Field name="notes" label="Notes" component={TextField}
                            variant="outlined" fullWidth margin="dense" ></Field>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <SearchLocation setFieldValue={setFieldValue} values={values} setZipCode={setZipCode} dirty={dirty} isValid={isValid} />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "0.4rem" }} >
                          <Field name="zipCode" placeholder="Zip Code" label="Zip Code" component={TextField}
                            value={zipCode} variant="outlined" fullWidth margin="dense" disabled ></Field>
                        </Grid>

                        <DialogActions>
                          <Button type="button" onClick={() => {
                            setZipCode("")
                            props?.onClose();
                            resetForm();
                          }}>Cancel</Button>
                          <LoadingButton loading={backendCall} variant="contained"
                            disabled={!dirty || !isValid} type="submit" size="large" > Submit </LoadingButton>
                        </DialogActions>
                      </Form>
                    </Grid>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>)}
    </>

  );
}

export default AddNewJobDialog;