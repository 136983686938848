import React, { useState, useEffect } from "react";
import { ChromePicker } from "react-color";

export default function Color({ setFieldValue, color }) {
  const [colors, setColors] = useState(null);
  const colorPicker = (e) => {
    const newColor = {
      hex: e.hex,
      rgb: "(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")",
    };
    setColors(newColor);
    setFieldValue("color", newColor.hex);
  };

  useEffect(() => {
    if (color?.color) {
      setColors({
        hex: color.color,
        rgb: "(" + color.color + ")",
      });
    }else{
      setColors({
        hex: "#000000",
        rgb: `(#000000)`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  return (
    <div>
      <div style={{ wodth: "50%", float: "left" }}>
        <ChromePicker
          color={colors !== null && colors.hex}
          onChange={(e) => colorPicker(e)}
          disableAlpha
          renderers={false}
        />
      </div>
    </div>
  );
}
