import React, { useState } from "react";
import { Grid, Chip, Button} from "@mui/material";
import PopupCreate from "./CreatePopup";
import PopupEdit from "./EditPopup";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import DataGridTable from "../layouts/DataGrid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import { useNavigate } from "react-router-dom";
import FranchiseeDialog from "./FranchiseeListDialog";
// import LoadingButton from "@mui/lab/LoadingButton";
import AlertMain from "components/common/AlertMain";

export default function RoyaltySchems() {
  const axios = useAxiosPrivate()
  const navigate = useNavigate();
  const [editData, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);

  const [buttonDisabled, setbuttonDisabled] = useState(false);

  const [franchiseeList, setFranchiseeList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const handleStatus = (status, id) => {
    updateStatus(id, status);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenDialog = (arr) => {
    setFranchiseeList(arr);
    setOpenDialog(true);
  };



  const updateStatus = async (id, status) => {
    setbuttonDisabled(true)
    try {
      const res = await axios.put(`/royalty/update-status`, {
        royaltyId: id,
        status: status,
      });

      if (res.status === 200) {
        setbuttonDisabled(false);
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Status Updated!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 2000);
      }
    } catch (err) {
      setbuttonDisabled(false)
      if (err.response.status === 422) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "The scheme has an active franchisee. Remove the scheme from the franchisee before deactivating the scheme.",
        });
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Royalty update failed!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 2000);
      }
    }
  };


  const columns = [
    { field: "schemeID", headerName: "SCHEME ID", minWidth: 100 },
    { field: "scheme_Name", headerName: "SCHEME NAME", minWidth: 150 },
    {
      field: "royalty_Percentage",
      headerName: "ROYALTY PERCENTAGE",
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "STATUS",
      minWidth: 100,
      renderCell: (params) => {
        const onClickStatus = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          await handleStatus(!params.row.status, params.row.id);
          setStatus(params.row.status);
        };
        return (
          <Chip
            style={{ width: "100%" }}
            disabled={buttonDisabled}
            label={params.row.status ? "Active" : "Inactive"}
            color={params.row.status ? "success" : "secondary"}
            variant="outlined"
            onClick={onClickStatus}
          />

        )
      },
      valueGetter: (params) => { 
        return params.row.status ? "Active" : "Inactive"
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      minWidth: 130,
      disableExport: true,
      renderCell: (cellValues) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setData(cellValues.row);
          handleClickOpen();
          setStatus(!status);
        };

        return <Button onClick={onClick}>Edit</Button>;
      },
    },
    {
      field: "franchisee_List",
      headerName: "FRANCHISEE LIST",
      flex: 2,
      minWidth: 100,

      renderCell: (params) => {
        const arr = [];
        // eslint-disable-next-line
        params.row.franchisee_List.map((franchisee) => {
          arr.push(franchisee.franchiseeName);
        });

        return (
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleClickOpenDialog(arr)}
          >
            see list
          </Button>
        );
      },
      valueGetter: (params) => { 
         const arr = [];
        // eslint-disable-next-line
        params?.row?.franchisee_List?.map((franchisee) => {
          arr.push(franchisee?.franchiseeName);
        });

        return `${arr}`
      }
    },
  ];

  const handleNavigation = () => {
    navigate("/view-franchisee", { state: { value: 2 } });
  };
  return (
    <>
      <Grid ml={1} item>
        <IconButton
          onClick={handleNavigation}
          color="primary"
          aria-label="goback"
        >
          <ArrowBackIcon />
        </IconButton>
      </Grid>

      <AlertMain setAlert={setAlert} alert={alert} />

      <Grid container spacing={1}>
        <Grid
          container
          item
          justifyContent="flex-end"
          ml={6}
          xs={12}
          md={12}
          pt={5}
        >
          <PopupCreate setStatus={setStatus} status={status} />
        </Grid>
        <Grid item style={{ height: 350, width: "100%" }} xs={12} md={12}>
          <DataGridTable
            fetchDataUrl="/royalty"
            columns={columns}
            status={status}
            buttonDisabled={buttonDisabled}
            title="Royalty View"
          />

        </Grid>
        <FranchiseeDialog
          setOpenDialog={setOpenDialog}
          openDialog={openDialog}
          franchiseeList={franchiseeList}
        />
        <PopupEdit
          setOpen={setOpen}
          open={open}
          editData={editData}
          setStatus={setStatus}
          status={status}
        />
      </Grid>
    </>
  );
}
