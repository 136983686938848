import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, FormControl, TextField, Chip, Typography, FormControlLabel, Checkbox, } from '@material-ui/core';
import Button from '../../components/UI/ReusableButton';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { TextField as TF } from 'formik-material-ui';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import AlertMain from 'components/common/AlertMain';
import AlertInlineMain from 'components/common/AlertInlineMain';
import containsInvalidZip from './commonFunctions/InvalidZipValidation';
import { useSearchParams } from 'react-router-dom';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';

export default function CreateZone() {
  const axios = useAxiosPrivate()
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector((state) => state);
  const [er, setEr] = useState(true);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });
  const [zipAlert, setZipAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const [initialValues, setInitialValues] = useState({
    zoneName: searchParams.get("name") ? searchParams.get("name") : '',
    zipCodes: [],
    zoneId:  searchParams.get("id") ? searchParams.get("id") : ''
  });
  const [zipCodeList, setZipCodeList] = useState([]);
  const [currentZips, setCurrentZips] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (event, setFieldValue) => {
    setSelectAll(event.target.checked);
    if(!event.target.checked)
      setInitialValues({...initialValues, zipCodes: []})
      setFieldValue('zipCodes', []); // Update the zipCodes field
  };

  const renderOption = (value, {selected}) => (
    <React.Fragment key={value}>
      <FormControlLabel
        control={
          <Checkbox
            icon={<CheckBoxOutlineBlank fontSize='small' />}
            checkedIcon={<CheckBox fontSize='small' />}
            checked={selected}
          />
        }
        label={value}
      />
    </React.Fragment>
  );

  const onSubmit = async (e, { resetForm }) => {
    try {
      setEr(true);
      const data = {
        zoneName: e.zoneName,
        zipCodes: selectAll ? zipCodeList : e.zipCodes,
        zoneId:initialValues?.zoneId
      };
      if ((e.zipCodes.length === 0 && !selectAll) &&  e.zoneName === searchParams.get("name") ) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Please enter at least one zip code or edit the name',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: '',
          });
        }, 3000);
      } else {
        await axios.post('/createZone', data);
        setInitialValues({ zoneName: '', zipCodes: [], zoneId:'' });
        get();
        getCurrentZips();
        setAlert({
          showAlert: true,
          severity: 'success',
          message: 'Zone Created/updated Successfully!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'success',
            message: '',
          });
        }, 3000);
        resetForm();
        setTimeout(() => {
        navigate('/view-zone')
      }, 1000);
      }
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: error.response.data.message,
        });
      } else {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Zone Created failed!',
        });
      }
    }
  };

  // get Company
  const get = async (id) => {
    try {
      const { data } = await axios.get(`/user/${user.auth.id}`);
      const currentZipCode = await axios.get('/createZone');
      if (currentZipCode.data.length > 0) {
        const zipsArray = currentZipCode.data?.map((item) => item.zipCodes);
        const newArr = Array.prototype.concat(...zipsArray);
        setCurrentZips(newArr);
        const unusedZipCodes = data.serviceRegions.filter(zipCode => !newArr.includes(zipCode));
        setZipCodeList(unusedZipCodes);
      } else {
        setZipCodeList(data.serviceRegions);
      }

    } catch (error) {
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Data loading failed!',
      });
    }
  };

  const getCurrentZips = async (id) => {
    try {
      const { data } = await axios.get('/createZone');
      const zipsArray = data?.map((item) => item.zipCodes);
      const newArr = Array.prototype.concat(...zipsArray);
      setCurrentZips(newArr);
    } catch (err) {
      if (err.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: err.response.data.message,
        });
      } else {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Data Loading Failed!',
        });
      }
    };
  }
  const checkFranchiseeZips = (newValue, setFieldError, setFieldValue) => {

    if (!containsInvalidZip(newValue, zipCodeList)) {
      let duplicates = [];
      let uniqueArray = [];
      duplicates = currentZips?.filter((val) => newValue?.indexOf(val) !== -1);
      uniqueArray = duplicates?.filter((item, pos) => duplicates?.indexOf(item) === pos);
      setFieldValue('zipCodes', newValue, false);
      if (uniqueArray.length > 0) {
        setEr(true);
        setZipAlert({
          showAlert: true,
          severity: 'error',
          message: `Zip code is already exists ${uniqueArray}`,
        });
        setTimeout(() => {
          setZipAlert({
            showAlert: false,
            severity: 'error',
            message: '',
          });
        }, 3000);
        setFieldError('zipCodes', `Zip code is already exists ${uniqueArray}`);
      } else {
        setEr(false);
        setFieldError('zipCodes', '');
      }
    }
  }

  useEffect(() => {
    get();
    setEr(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialValues.zipCodes.length > 0) {
      checkFranchiseeZips();
    }
    // eslint-disable-next-line
  }, [initialValues.zipCodes]);

  const validationSchema = Yup.object().shape({
    zoneName: Yup.string()
      .max(300, 'Maximum length for the zone name is 15!')
      .required('Zone name required'),
  });

  return (
    <Grid container >
      <Grid item style={{ marginLeft: "2rem", marginTop: "2rem", }} >
        <IconButton onClick={() => navigate('/view-zone')} color='primary' data-testid="back_btn">
          <ArrowBackIcon />
        </IconButton>
      </Grid>
      <Grid container item justifyContent='center' alignItems='center' style={{ marginTop: "5rem" }} >
        <Card style={{ padding: "1rem" }}>
          <CardContent>
            <Grid container justifyContent={'center'}>
              <Grid item>
                <Typography gutterBottom variant='h5' component='div' style={{ font: 'bold' }}>
                  Zones </Typography>
              </Grid>
            </Grid>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize >
              {({ values, isValid, handleChange, setFieldValue, setFieldError, }) => {
                return (<Grid container style={{ marginTop: "1rem" }} >
                  <Form noValidate autoComplete='off' encType='multipart/form-data'>
                    <Grid container item alignItems='center' justifyContent='center' >
                      <Grid item lg={12} md={12} sm={12} xs={12} >
                        <Field data-testid="zone_name" name='zoneName' component={TF} label='Zone Name' variant='outlined'
                          fullWidth required onChange={handleChange} 
                          // disabled={searchParams.get("name")} 
                          ></Field>
                      </Grid>
                      <Grid item columnSpacing={5} lg={12} md={12} sm={12} xs={12} >
                        <FormControl component='fieldset' data-testid='zone_zip_select'>
                          <Autocomplete
                            multiple
                            freeSolo
                            autoHighlight
                            id='zipCodes'
                            name='zipCodes'
                            options={zipCodeList}
                            value={selectAll ? zipCodeList : values.zipCodes}
                            onChange={(event, newValue) => {
                              setSelectAll(false);
                              checkFranchiseeZips(newValue, setFieldError, setFieldValue);
                            }}
                            renderOption={renderOption}
                            getOptionLabel={(option) => option}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  key={index}
                                  size='small'
                                  label={option ? option : ''}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                style={{ maxWidth: '25rem', minWidth: '18rem', marginTop: '1rem' }}
                                label='Zip Codes'
                                {...params}
                                variant='outlined'
                              />
                            )}
                            disableCloseOnSelect
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectAll}
                                onChange={(event) => handleSelectAll(event, setFieldValue)}
                                disabled={zipCodeList?.length === 0}
                              />
                            }
                            label='Select All'
                          />
                        </FormControl>
                      </Grid>
                      </Grid>
                    {zipAlert.showAlert && (<Grid item md={12} m={2}>
                      <AlertInlineMain setAlert={setZipAlert} alert={zipAlert} /> </Grid>)}
                    <Grid style={{ marginTop: '1rem' }}>
                      <Button style={{ width: '100%' }} variant='contained' color='primary' type='submit' disabled={!isValid && er} testid="zone_zip_code_save_btn">
                        Save </Button>
                    </Grid>
                  </Form>
                </Grid>
                );
              }}
            </Formik>
            <AlertMain setAlert={setAlert} alert={alert} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
