import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, CardActions, Button, Typography, Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import AlertInlineMain from "components/common/AlertInlineMain";
import AccountFunctions from "../../components/franchiseeManagement/commonFunctions/AccountFunctions"
// validation
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { ChevronLeft } from '@material-ui/icons';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  center: {
    textAlign: 'center',
  },
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const theme = createTheme({
  palette: {
    primary: green,
    secondary: yellow,
  },
});

export default function User(props) {
  const axios = useAxiosPrivate()
  const navigate = useNavigate();
  const classes = useStyles();

  const { initialValues : initialUserValues } = AccountFunctions();
  
  const componentType = 'create';
  const [callingBackend, setCallingBackend] = useState(false);

  const [email, setEmail] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);

  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(oldVal=>!oldVal);
  };
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const submit = async (e, { resetForm }) => {
    try {
      setCallingBackend(true);
      await axios.put('/auth/admin/change-password', {
        email: sessionStorage.getItem("role")  === "manager" ? sessionStorage.getItem("email") : initialUserValues?.email,
        currentPassword: e.currentPassword,
        newPassword: e.newPassword,
      });
      resetForm();
      setAlert({
        showAlert: true,
        severity: 'success',
        message: 'Password changed successfully!',
      });
    } catch (error) {
      if (error.response?.status === 401) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'User not found or Inactive',
        });
      } else if (error.response?.status === 403) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Current Password invalid!',
        });
      } else {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Password change failed!',
        });
      }
    } finally {
      setCallingBackend(false);
    }
  };

  // validation schema
  let validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('Current Password is required!')
      .min(8, 'Min length for the Password is 8'),
    newPassword: Yup.string()
      .required('New Password is required!')
      .matches(/(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
        "One Uppercase, One Lowercase, One Number,one special case Character and Must Contain 8 Characters")
      .min(8, "Must Contain 8 Characters"),
    confirmPassword: Yup.string()
      .required('Confirm Password is required!')
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  const loadData = async () => {
    try {
      const data = await axios.get('/auth/me');

      setEmail(data.data.email);
    } catch (error) {
      if (error.response?.status === 401) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'User not found or Inactive',
        });
      } else if (error.response?.status === 403) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'error!',
        });
      } else {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'User loading failed!',
        });
      }
    }
  };

  useEffect(() => {
    loadData();
    //eslint-disable-next-line
  }, []);

  return (
    <Grid container className={classes.root} spacing={1}>
      <ChevronLeft onClick={() => navigate(sessionStorage.getItem("role") != 'franchisor' ? '/franchisee-dashboard' : '/dashboard')} />
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isValid, dirty }) => {
            return (
              <Form>
                <Card>
                  <Typography
                    style={{
                      fontFamily: 'holiday',
                      fontSize: '2rem',
                      marginLeft: 15,
                      marginTop: 15,
                    }}
                  >
                    {' '}
                    Change Password
                  </Typography>
                  <CardContent>
                    <Grid container item spacing={2} xs={12} sm={12} md={12}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          name='currentPassword'
                          label='Current Password'
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          type={passwordShown ? 'text' : 'password'}
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          name='newPassword'
                          label='New Password'
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          type={passwordShown ? 'text' : 'password'}
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          required
                        ></Field>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          name='confirmPassword'
                          label='Confirm Password'
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          type={passwordShown ? 'text' : 'password'}
                          inputProps={{
                            style: { fontFamily: 'Overpass, sans-serif' },
                          }}
                          required
                        ></Field>
                      </Grid>

                      <FormControlLabel
                        value={passwordShown}
                        control={<Checkbox onClick={togglePassword} color='primary' />}
                        label='Show Passwords'
                        labelPlacement='end'
                      />
                    </Grid>
                  </CardContent>
                  <CardActions>
                    {callingBackend ? (
                      'Please Wait...'
                    ) : (
                      <ThemeProvider theme={theme}>
                        <Button
                          variant='contained'
                          style={{ fontFamily: 'Overpass, sans-serif' }}
                          className={classes.button}
                          color={
                            componentType === 'create' ? 'primary' : 'secondary'
                          }
                          type='submit'
                          disabled={!dirty || !isValid}
                        >
                          {componentType === 'create' ? 'Change' : 'update'}
                        </Button>
                      </ThemeProvider>
                    )}
                  </CardActions>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      {alert.showAlert && (
        <Grid item sm={12} md={12} xs={12}>
          <AlertInlineMain setAlert={setAlert} alert={alert} />
        </Grid>
      )}
    </Grid>
  );
}
