import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AlertMain from 'components/common/AlertMain';
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";

function ccyFormat(num) {
  return `${num?.toFixed(2)}`;
}

export default function SpanningTable({ setBalance, accBalance }) {
  const axios = useAxiosPrivate()
  const d = new Date();
  let monthNumber = d.getMonth() - 1;

  const [rowData, setRowData] = useState([]);
  const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
  const [royaltyPaidAmount, setRoyaltyPaidAmount] = useState(0);
  const [balanceTobePaid, setBalanceTobePaid] = useState(0);
  const [month, setMonth] = useState('');
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  function GetMonthName(monthNumber) {
    monthNumber = monthNumber < 0 ? 11 : monthNumber;
    var months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    setMonth(months[monthNumber]);
  }

  function subtotal(items) {
    const invoice = items
      .map(({ totalRoyalty }) => totalRoyalty)
      .reduce((sum, i) => sum + i, 0);

    setInvoiceSubtotal(invoice);
  }

  function getRoyaltyPaidAmount(items) {
    const royaltyPaid = items
      .map(({ totalRoyaltyPaid }) => totalRoyaltyPaid)
      .reduce((sum, i) => sum + i, 0);

      setRoyaltyPaidAmount(royaltyPaid);
  }

  const calculateRoyaltyBalance = (payments, balance) => {
    let royaltyBalance = 0;
    payments?.forEach((element) => {
      royaltyBalance += element?.royaltyBalance;
    });
    royaltyBalance = royaltyBalance - balance 
    return royaltyBalance.toFixed(2);
  }

  const getBalanceToBePaid = async () => {
    try {
      
    const accBalance = await axios.get('/royalty/account-balance');
    const royaltyPaymentHistory = await axios.get('/royalty/royalty-payment-history/');
    const royaltyBalance = calculateRoyaltyBalance(royaltyPaymentHistory?.data, accBalance?.data?.balance);

    return royaltyBalance ? royaltyBalance : 0; 

    } catch (error) {
       console.log("Error fetching balance: ", error)
       return 0; 
    }

  }

  const getData = async () => {
    try {

      const { data } = await axios.get('/orders/salesByDate');

      const royaltyBalanceToBePaid = await getBalanceToBePaid();

      setRowData(data);
      setBalanceTobePaid(Number(royaltyBalanceToBePaid));
      subtotal(data);
      getRoyaltyPaidAmount(data);

    } catch (e) {
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Something Went wrong!',
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'error',
          message: '',
        });
      }, 3000);
    }
  };

  useEffect(() => {
    getData();
    GetMonthName(monthNumber);
    // eslint-disable-next-line
  }, [accBalance]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 350 }} aria-label='spanning table'>
            <TableHead>
              <TableRow>
                <TableCell align='center' colSpan={3}>
                  {month}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align='right'>Sales</TableCell>
                <TableCell align='right'>Royalty Fee</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row._id}</TableCell>
                  <TableCell align='right'>
                    {row.totalSaleAmount?.toFixed(2)}
                  </TableCell>
                  <TableCell align='right'>
                    {row.totalRoyalty?.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell rowSpan={2} />
                <TableCell colSpan={1}>Total</TableCell>
                <TableCell align='right'>
                  {ccyFormat(invoiceSubtotal)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={1}>Balance to Be Paid</TableCell>
                <TableCell align='right'>
                  {ccyFormat(invoiceSubtotal && royaltyPaidAmount ? invoiceSubtotal - royaltyPaidAmount : 0)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <AlertMain setAlert={setAlert} alert={alert} />
      </div>
    </>
  );
}
