import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Franchisee from "components/franchiseeManagement/FranchiseeView";
import Request from "components/manageRequest/RequestView";
import RoyaltyManagement from "components/royalty/RoyaltyManagement";
import useAxiosPrivate from "../utils/hooks/useAxiosPrivate";
import Badge from "@mui/material/Badge";
import { useLocation } from "react-router-dom";
import PriceChangeRequest from 'components/manageRequest/PriceChangeRequest';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const axios = useAxiosPrivate()
  const location = useLocation();

  const [value, setValue] = useState(0);
  const [requests, setRequests] = useState(0);
  const [priceChangeReq, setPriceChangeReq] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setReqNumber = async () => {
      const req = await axios.get("/request");
      const filteredData = req.data.filter((e) => e.status === "pending");
      setRequests(filteredData?.length);
  };

  const setPriceChangeReqNumber = async () => {
    const req = await axios.get("/request/price-change");
    const filteredData = req.data.filter((e) => e.status === "pending");
    setPriceChangeReq(filteredData?.length);
  };

  useEffect(() => {
    setReqNumber();
    setPriceChangeReqNumber();
    if (location.state?.value) {
      setValue(location.state?.value);
    } else {
      setValue(0);
    }
    //eslint-disable-next-line
  }, [location.state?.value]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="LOCATIONS" {...a11yProps(0)} />
          <Tab
            label={
              <Badge badgeContent={requests} color="secondary">
                Request
              </Badge>
            }
            {...a11yProps(1)}
          />
          <Tab label="ROYALTY MANAGEMENT" {...a11yProps(2)} />
          <Tab label={
            <Badge badgeContent={priceChangeReq} color="secondary">
              PRICE CHANGE REQUEST
            </Badge>
          } {...a11yProps(3)} />
        </Tabs>
      </Box>
      {value === 0 ? (
        <TabPanel value={value} index={0}>
          <Franchisee />
        </TabPanel>
      ) : value === 1 ? (
        <TabPanel value={value} index={1}>
          <Request setReqNumber={setReqNumber} />
        </TabPanel>
      ) : value === 2 ? (
        <TabPanel value={value} index={2}>
          <RoyaltyManagement />
        </TabPanel>
      ) : value === 3 ? (
        <TabPanel value={value} index={3}>
          <PriceChangeRequest setPriceChangeReqNumber={setPriceChangeReqNumber} />
        </TabPanel>
      ) : (
        ""
      )}
    </Box>
  );
}
