import React, { useEffect, useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { TextField as TF } from "formik-material-ui";
import { useSelector } from "react-redux";
import AlertInlineMain from "components/common/AlertInlineMain";
import axios from "../../utils/lib/axios";

export default function ServiceOneFranchisorEdit({ franchiseeId, ownerName, selectedTypeOption}) {
  const role = useSelector((state) => state.auth.role);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const [status, setStatus] = useState(false);
  const [dataIsnull, setDataIsnull] = useState(true);
  const [disable, setDisable] = useState(false);

  const [initialValues, setInitialValues] = useState({
    price: "",
    serviceId: "",
    service: {},
  });

  const validationSchema = Yup.object().shape({
    price: Yup.number()
      .required("Price is required")
      .min(1, "The price must be greater than 0!")
      .test(
        "Is positive?",
        "The price must be to the 2 decimal points !",
        (value) => (value + "").match(/^[0-9]*(\.[0-9]{0,2})?$/),
      ),
  });


  const getData = async () => {
    try {
      const { data } = await axios.get("/service");

      setInitialValues({ 
        price: data?.price.toFixed(2), 
        serviceId: data?.id,
        priceChanged:false
      });
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Something went wrong!",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "Something went wrong!",
        });
      }, 3000);
    }
  };

  const getFranchiseeData = async () => {
    try {
      const { data } = await axios.get(`/franchiseeService/getWashDryFold/${franchiseeId}`);

      if (data !== null) {
        setInitialValues({
          price: data?.price.toFixed(2),
          serviceId: data?.serviceId,
          priceChanged:true
        });
      } else {
        setDataIsnull(data);
        getData();
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Something went wrong!",
      });
    }
  };

  const onSubmit = async (e, { resetForm }) => {

    setDisable(true);

    if (dataIsnull === null) {
      setDisable(false);
      return
    } else {
      try {
        let data;
        if (role === "franchisor") {
          data = {
            price: Number(e.price.toFixed(2)),
          };

          await axios.put(`/franchiseeService/updateWashDryFold/${franchiseeId}`, data);
          // getFranchiseeDataByID();
          getFranchiseeData();
        }
        setDisable(false);
        resetForm();
        setInitialValues({ price: "" });

        setStatus(!status);

        setAlert({
          showAlert: true,
          severity: "success",
          message: `${role === "franchisor" ? "Price Saved Successfully" : "Request Created Successfully"}`,
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: `${role === "franchisor" ? "Price Saved Successfully" : "Request Created Successfully"}`,
          });
        }, 3000);
      } catch (error) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: `${role === "franchisor" ? "Price update failed!" : "Request failed!"}`,
        });
      }
    }
  };

  useEffect(() => {
    // getFranchiseeDataByID();
    getFranchiseeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isValid, values, setFieldValue }) => {
        return (
          <Form noValidate autoComplete="off">
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              alignItems="center"
              justifyContent="center"
              marginTop={5}
              paddingLeft={5}
              paddingRight={5}
            >
              <Grid item xs={12} md={6}>

                <Field
                  name="price"
                  label={initialValues?.priceChanged ? `${ownerName} Priced ($ per lbs)` : "Franchisor priced ($ per lbs)" }
                  component={TF}
                  variant="outlined"
                  type="number"
                  fullWidth
                  //value={values.price}
                  required
                  disabled={dataIsnull === null}
                ></Field>
              </Grid>

              <Grid style={{ margin: "1.5rem 1rem" }}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!isValid || disable || dataIsnull === null}
                >
                  {role === "franchisor" ? "save" : "Request"}

                </Button>
              </Grid>

            </Grid>
            {alert.showAlert && (
              <Grid item md={12} m={2}>
                <AlertInlineMain setAlert={setAlert} alert={alert} />
              </Grid>
            )}

            {dataIsnull === null &&
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  color: "red",
                  textAlign: "center",
                  marginTop:"0.5rem",
                  marginBottom:"1rem",
                }}
              >
                {`Can't update ${selectedTypeOption === `corporate` ? `Corporate`:`franchisee`}'s Wash/Dry/Fold price until
                ${selectedTypeOption === `corporate` ? `Corporate`:`franchisee`} have at least one approved Wash/Dry/Fold Price change.`}
              </Typography>
            }
          </Form>
        );
      }}
    </Formik>
  );
}
