import React, { useState } from "react";
import DataGrid from "../../components/layouts/DataGrid";
import { Button, Grid, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText } from "@mui/material";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Typography } from "@material-ui/core";
import AlertMain from "components/common/AlertMain";

export default function Test() {
  const axios = useAxiosPrivate()
  let navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [idForOpenDialog, setidForOpenDialog] = useState("");
 
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });



  const onDelete = async (id) => {
    try {
      await axios.delete(`/user/deletemanager/${id}`);
  
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Manager deleted successfully!",
      });
      setOpen(false)
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 2000);
      setStatus(!status);
    } catch (error) {
      setOpen(false)
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Manager delete failed!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "",
        });
      }, 2000);
    }
  };

  const columns = [
    { field: "roleID", headerName: "User ID", width: 150 },
    { field: "firstName", headerName: "User Name", width: 220,
      valueGetter: (params) => { return `${params.row.firstName}`; }, },
    { field: "createdAt", headerName: "Established Date", width: 280,
      valueGetter: (params) => { return `${moment(params.row.createdAt).format("MMMM Do YYYY")}`; }, },
      { field: "status", headerName: "Status", width: 150,
      valueGetter: (params) => { return `${params.row.status ? params.row.status : ""}`; }, },
    { field: "edit", headerName: "Edit", sortable: false, disableExport: true,
      renderCell: (params) => {
        const onClick = () => {
          const id = params.row._id;
          navigate("/update-manager", { state: { id, update: true, data: params.row } });
        };
        return ( <Button onClick={onClick}> <EditIcon /> </Button> ); }, },
    { field: "delete", headerName: "Delete", sortable: false, disableExport: true,
      renderCell: (params) => {
        const openDialog = () => {
          const id = params.row._id;
          setidForOpenDialog(id);
          setOpen(true)
        }
      
        return (
          <>
            <Dialog open={open} onClose={() => setOpen(false)} BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.21)' } }} >
              <DialogTitle id='alert-dialog-title'> Delete Manager </DialogTitle>
              <DialogContent sx={{ width: "20rem", }} >
                <DialogContentText id='alert-dialog-description'>
                  <Typography> Are you Sure? </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button sx={{ color: "red", }} onClick={() => onDelete(idForOpenDialog)}> Yes </Button>
                <Button onClick={() => setOpen(false)} variant='contained' style={{ backgroundColor: 'grey' }} autoFocus > No </Button>
              </DialogActions>
            </Dialog>
            <Button onClick={openDialog} > <DeleteIcon /> </Button>
          </>
        );
      },
    },
 
  ];
  return (
    <Grid container>
      <Grid container item xs={12} md={12} justifyContent="flex-end" mr={4} mt={4}>
        <Button variant="contained" component={Link} to="/create-manager">+ Create User</Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <DataGrid autoHeight columns={columns} fetchDataUrl="/user/getManagers" status={status} title="Users Management" updatePath="/update-franchisee/" />
      </Grid>
      <AlertMain setAlert={setAlert} alert={alert} />
    </Grid>
  );
}
