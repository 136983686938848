import React, { useState } from "react";
import Bar from 'components/layouts/AdminBarChart-report';
import { useNavigate } from "react-router-dom";

import { Grid, Button } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterDialog from "components/dashboard/FilterDialog";
import ScreenshotIcon from "@mui/icons-material/Screenshot";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

export default function LineChart({ dataset, downloadScreenshot }) {
  let navigate = useNavigate();

  const onGoback = () => {
    navigate('/franchisee-reports')
  }
  const [selected, setSelected] = useState({});

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} md={12} spacing={1}>

        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
        <div style={{display:'flex', width:'100%'}}>
          <Grid item >
            <IconButton onClick={() => onGoback()}>
              <ArrowBackIcon sx={{ color: "#343434", }} />
            </IconButton>
          </Grid>
          </div>
          <div style={{display:'flex', width:'100%', justifyContent:'flex-end'}}>
        <Grid item xs={10} md={1}>
          <Button variant="contained" onClick={downloadScreenshot}>
            {" "}
            <ScreenshotIcon />
          </Button>
        </Grid>


        <Grid item xs={2} md={1}>
          <Button variant="contained" onClick={handleClickOpen}>
            {" "}
            <FilterAltIcon />
          </Button>
        </Grid>
        </div>
        </div>

      </Grid>
      <Grid item xs={12} md={12} >
        {" "}
        <Bar selected={selected} />
      </Grid>

      <FilterDialog
        setOpen={setOpen}
        open={open}
        dataset={dataset}
        setSelected={setSelected}
      />
    </Grid>
  );
}
