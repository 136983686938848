import React, { useState, useEffect } from "react";
import { Grid, Card, Stack, CardContent, Typography, IconButton } from "@mui/material";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { TextField as TF } from "formik-material-ui";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Color from "../Color";
import SearchLocation from "../SearchLocation";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { setDepot, setOpen } from "../../../store/actions/RouteAction";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogAlertMain from "components/common/DialogAlertMain";

export default function CreateDepot() {
  const axios = useAxiosPrivate()
  const dispatch = useDispatch();
  const depot = useSelector((state) => state.route.depot);
  const state = useSelector((state) => state.route.open);
  const [backendCall, setBackendCall] = useState(false);
  const [open, setOpenDepot] = React.useState(state);

  const setDetails = () => {
    dispatch(setOpen(!open));
    setOpenDepot(!open);
  };

  const [initialValues, setInitialValues] = React.useState({
    name: "",
    color: "#000000",
    location: {
      latitude: "",
      longitude: "",
    },
    time: "",
    // cost: "",
    address: "",
  });

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const onSubmit = async (e, { resetForm }) => {
    try {
      setBackendCall(true);
      const data = {
        name: e.name,
        color: e.color==="" ? "#000000" : e.color,
        location: e.location,
        time: e.time,
        // cost: e.cost,
        address: initialValues.address,
      };
      if (depot) {
        await axios.put(`/depot/${depot._id}`, data);
        dispatch(setDepot());
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Update Success!",
        });
      } else {
        await axios.post("/depot", data);
        dispatch(setDepot());
        resetForm();
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Create Success!",
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: error.response.data,
        });
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Create Failed!",
        });
      }
    } finally {
      setBackendCall(false);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    color: Yup.string(),
    address: Yup.string().required("Business location is required"),
    time: Yup.string().required("Time is required")
      .test( "Is positive?", "The setup time must be greater than 0!", (value) => value > 0 ),
    // cost: Yup.string().required("Cost is required")
    //   .test( "Is positive?", "The price must be greater than 0!", (value) => value > 0 ),
  });

  useEffect(() => {
    if (depot) {
      setInitialValues({
        name: depot.name,
        color: depot.color,
        location: { latitude: depot.location.latitude, longitude: depot.location.longitude, },
        time: depot.time,
        // cost: depot.cost,
        address: depot.address,
      });
    }
  }, [depot]);

  return (
    <>
      <Grid container pl={2} pr={2} mt={2}
        sx={{ backgroundColor: "#E4E4E4", borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem" }}>
        <Grid container item lg={12} md={12} sm={12} xs={12}
          mt={2} mb={1} alignItems="center" justifyContent="center">
          <IconButton size="medium" onClick={setDetails}>
            <ArrowCircleLeftIcon sx={{ fontSize: "2.5rem", color: '#1976D2' }} />
          </IconButton>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} alignItems="center" justifyContent="center">
          <Stack direction="row" mt={2} alignItems="center" justifyContent="center">
            <Typography
              size="small"
              sx={{ color: "#1976D2", fontSize: "1.034rem", textTransform: "capitalize", backgroundColor: "white",
                fontWeight: "500", padding: "0.32rem", borderTopLeftRadius:"0.3rem", borderTopRightRadius:"0.3rem", }}>
              General Settings </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Card style={{ width: "100%", marginTop: "1rem", marginBottom: "2rem" }}>
        <CardContent>
          <Grid item container xs={12} md={12}>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize >
              {({ values, isValid, dirty, handleChange, setFieldValue, }) => {
                return (
                  <Form>
                    <Grid container item lg={12} md={12} sm={12} xs={12} alignItems="center" justifyContent="center">
                      <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"}>
                        {" "}
                        <Field data-testid="depot-name" name="name" label="Depot Name" variant="outlined" component={TF} onChange={handleChange} fullWidth></Field>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                        <Typography sx={{ textAlign: "center", }} > Depot Color </Typography>
                      </Grid>
                      <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"}>
                        <Color setFieldValue={setFieldValue} color={depot} />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <Typography variant="h6" sx={{ fontSize: "0.8rem", textAlign: "center", }}>
                          We recommend using a different color for each Depot *{" "} </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} mt={2}> Business Location  </Grid>
                      <Grid item xs={12} md={12} mt={2}>
                        <SearchLocation setFieldValue={setFieldValue} setInitialValues={setInitialValues} values={values} />
                      </Grid>
                      <Grid item xs={12} md={12} mt={2}>
                        <Typography variant="h6" style={{ fontSize: "1rem" }}> {values.address || depot?.address} </Typography>
                      </Grid>
                      {/* <Grid item xs={12} md={12} mt={2}>
                        {" "}
                        <Field name="cost" label="Setup Cost" variant="outlined" type="number" onChange={handleChange} component={TF} fullWidth ></Field>
                      </Grid> */}
                      <Grid item xs={12} md={12} mt={2}>
                        {" "}
                        <Field name="time" label="Setup Time(minutes)" variant="outlined" type="number" onChange={handleChange} component={TF} fullWidth ></Field>
                      </Grid>
                      <Grid item xs={12} md={12} mt={2} mb={2}></Grid>
                    </Grid>
                    <LoadingButton data-testid="create-depo-btn" loading={backendCall} type="submit" variant="contained" size="small" disabled={!isValid || !dirty}>
                      {depot ? "Update" : "Create"} </LoadingButton>
                  </Form>
                );}}
            </Formik>
          </Grid>
        </CardContent>
        <DialogAlertMain alert={alert} setAlert={setAlert} />
      </Card>
    </>
  );
}
