import React, { useEffect, useState } from 'react';
import { chartColors } from './colors';
import DataGrid from "./DataGrid5";
import AlertInlineMain from "components/common/AlertInlineMain";
import { Bar } from 'react-chartjs-2';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Grid, Typography, } from '@mui/material';
import SalesOverview from 'components/reports/salesOverview';

const totalSales = (sales) => {
  const total = sales.reduce((pre, cur) => pre + cur, 0);
  const percentage = sales.map((item) => {
    return {
      ...item,
      sales: item,
      percentage: ((item / total) * 100).toFixed(),
    };
  })
  return { total, percentage };
}

const numberWithCommas = (x) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const columns = [
  { field: 'ym', headerName: 'Date Range', minWidth: 240, flex: 1, },
  { field: 'franchisee', headerName: 'Locations', minWidth: 350, flex: 1, },
  { field: '_id', headerName: 'Locations Id', minWidth: 150, flex: 1, },
  { field: 'total', headerName: 'Sales', minWidth: 150, flex: 1, },
];

export default function AdminBarChart({ selected }) {
  const axios = useAxiosPrivate()
  const [label, setLabel] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsData, setRows] = useState([]);
  const [newData, setNewData] = useState([]);
  const [salesDetails, setSalesDetails] = useState({});
  const [details, setDetails] = useState('Revenue By Frachisee ($)');
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const monthName = (number) => {
    let month = '';
    switch (number) {
      case '01':
        month = 'January';
        break;
      case '02':
        month = 'February';
        break;
      case '03':
        month = 'March';
        break;
      case '04':
        month = 'April';
        break;
      case '05':
        month = 'May';
        break;
      case '06':
        month = 'June';
        break;
      case '07':
        month = 'July';
        break;
      case '08':
        month = 'August';
        break;
      case '09':
        month = 'September';
        break;
      case '10':
        month = 'October';
        break;
      case '11':
        month = 'November';
        break;
      case '12':
        month = 'December';
        break;
      default:
        month = '';
        break;
    }
    return month;
  };

  const isEmpty = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };

  const alertSetter = (severity, message) => {
    setAlert({ showAlert: true, severity, message, });
    setTimeout(() => { setAlert({ showAlert: false, severity, message, }); }, 3000);
  };

  const getMTDSales = async () => {
    try {
      const { data } = await axios.get('/reports/franchiser-sales/mtd-bar');
      setRows(data);
      const labels = data.map((item) => item._id);
      const newData = data.map((item) => item.total);
      setLabel(labels);
      const { total, percentage } = totalSales(newData);
      setTotal(total);
      setNewData(percentage.map((item) => item.percentage));
      setDetails(`MTD Sales (%)`);
      alertSetter('success', 'Successfully fetched MTD sales');
    } catch (error) {
      alertSetter('error', 'Failed to fetch MTD sales');
    }
  };

  const getYearMonthSales = async (year, month) => {
    try {
      const ym = { year, month, };
      const { data } = await axios.post('/reports/franchiser-sales/ym-bar', ym);
      setRows(data);
      const labels = data.map((item) => item._id);
      const newData = data.map((item) => item.total);
      setLabel(labels);
      const { total, percentage } = totalSales(newData);
      setTotal(total);
      setNewData(percentage.map((item) => item.percentage));
      setDetails(`Year : ${year} Month : ${monthName(month)} (%)`);
      alertSetter('success', 'Successfully fetched Year-Month sales');
    } catch (error) {
      alertSetter('error', 'Failed to fetch Year-Month sales');
    }
  };

  const yearlySales = async (year) => {
    try {
      const { data } = await axios.post(`/reports/franchiser-sales/year-bar`, { year, });
      setRows(data);
      const labels = data.map((item) => item._id);
      const newData = data.map((item) => item.total);
      setLabel(labels);
      const { total, percentage } = totalSales(newData);
      setTotal(total);
      setNewData(percentage.map((item) => item.percentage));
      setDetails(`Year : ${year} (%)`);
      alertSetter('success', 'Successfully fetched Yearly sales');
    } catch (error) {
      alertSetter('error', 'Failed to fetch Yearly sales');
    }
  };

  const getSalesOverview = async (value) => {
    try {
      const { data } = await axios.post('/reports/franchisee-sales/sales/overview', {
        startDate: value?.startDate,
        endDate: value?.endDate,
      });
      setSalesDetails(data);
    } catch (err) {
      console.log(err)
    }
  };


  useEffect(() => {
    if (isEmpty(selected)) { getMTDSales(); }
    else { return; }
    //eslint-disable-next-line
  }, []);

  const check = async () => {
    if (selected.year && selected.month === '') {
      await yearlySales(selected.year);
      await getSalesOverview({startDate:new Date(`${selected.year}-01-01`), endDate: new Date(`${selected.year}-12-31`) })
    } else if (selected.year && selected.month) {
      await getYearMonthSales(selected.year, selected.month);
      await getSalesOverview({startDate:new Date(`${selected.year}-${selected.month}-01`), endDate: new Date(`${selected.year}-${selected.month}-30`) })
    } else if (selected.year === '' && selected.month === '') {
      await getMTDSales();
    }
  };

  useEffect(() => {
    check();
    // eslint-disable-next-line
  }, [selected]);

  const dataBar = {
    labels: label,
    datasets: [
      {
        label: details,
        backgroundColor: chartColors,
        borderColor: chartColors,
        indexAxis: "y",
        borderWidth: 0.5,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: newData,
        options: {
          scales: {
            y: {
              ticks: {
                // Include a dollar sign in the ticks
                callback: function (value, index, ticks) {
                  return `$` + this.getLabelForValue(value);
                },
              },
            },
          },
        },
      },
    ],
  };

  return (
    <Grid container spacing={3} >
      <Grid item container xs={12} md={12} lg={12} p={2}>
        <Grid item xs={12} md={12} lg={12} p={2} >
          <Typography variant='h4'>Total Sales ($)</Typography>
          <Typography variant='h4'> {numberWithCommas(total.toFixed(2))} </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={6} p={1}>
          <Bar data={dataBar} width={100} height={50} />
        </Grid>
      </Grid>
      <Grid item  lg={12} md={12}  sm={12}  xs={12} mt={2}>
      <SalesOverview salesDetails={salesDetails}/>
      </Grid>
      <Grid item  lg={12} md={12}  sm={12}  xs={12} mt={2}>
        <DataGrid autoHeight columns={columns} title="Sales By Locations" newData={rowsData} />
      </Grid>
      <Grid item md={12}>
        {alert.showAlert ? ( <AlertInlineMain setAlert={setAlert} alert={alert} /> ) : ( '' )}
      </Grid>
    </Grid>
  );
}
