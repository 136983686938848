import React, { useState /* useEffect*/ } from 'react';

import { Grid, Button, IconButton } from '@mui/material';
import Datagrid from '../../components/layouts/DataGrid';
import EditIcon from '@mui/icons-material/Edit';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import moment from "moment";
import CreateCoupon from './Create-coupon';
import EditCoupon from './Edit-coupon';
import DeleteRecord from './DeleteRecord';
import AlertInlineMain from "components/common/AlertInlineMain";
import Points from './Points';
import sortServiceNameArray from 'components/common/SortServiceNames';
import sortSpecialCareServices from 'components/common/SortSpecialCareServices';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const services = [
  {
    name: 'Wash/ Dry/ Fold',
    types: ['1', '5', '6', '7', '8', '9', '10'],
  },
  {
    name: 'Dry Cleaning',
    types: ['2', '4', '7', '8', '9', '10'],
  },
  {
    name: 'Tailoring & Alterations',
    types: ['3', '4', '5', '6', '9', '10'],
  },
];

const mainServices = [
  {
    name: 'Wash/ Dry/ Fold',
  },
  {
    name: 'Special Care',
  },
];

const uniqueArray = (serviceName) => {
  const unique = [];
  for (const val of serviceName) {
    for (const type of services) {
      if (!unique.includes(val) && type.name === val) {
        unique.push(type.types);
      }
    }
  }
  // make unique
  return [...new Set(unique.flat())];
};

export default function Coupons() {
  const axios = useAxiosPrivate()
  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [serviceName, setServiceName] = useState([]);
  const [mainServiceName, setMainServiceName] = useState([]);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const handleServiceValue = (array) => {

    const {serviceName, mainServiceName} = sortSpecialCareServices(array);
  
    setServiceName(serviceName);
    setMainServiceName(mainServiceName);

  }

  const alertSetter = (showAlert, severity, message, time) => {
    if (time) {
      alertSetter(showAlert, severity, message);
      setTimeout(() => {
        alertSetter(false, severity, '');
      }, time);
    } else {
      setAlert({
        showAlert: showAlert,
        severity: severity,
        message: message,
      });
    }
  };

  const handleClose = () => {
    setServiceName([]);
    setMainServiceName([]);
    setStatus(!status);
    setOpen(false);
  };

  const handleCloseEdit = () => {
    setServiceName([]);
    setMainServiceName([]);
    setStatus(!status);
    setEdit(false);
  };

  const deleteRecord = async (id) => {
    try {
      await axios.delete(`/rewards/coupons/${id}`);
      setStatus(!status);
      alertSetter(true, 'success', 'Reward Delete Successfully', 3000);
    } catch (error) {
      setStatus(!status);
      alertSetter(true, 'error', 'Error in Deleting Reward', 3000);
    }
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 0.7, minWidth: 250 },
    { field: 'description', headerName: 'Description', flex: 0.7, minWidth: 500, },
    { field: 'serviceName', headerName: 'Applicable Services', flex: 0.3, minWidth: 220, valueGetter: (params) => { return `${sortServiceNameArray(params.row.serviceName)}`; }, },
    { field: 'date', headerName: 'Date', flex: 0.3, minWidth: 170,
      valueGetter: (params) => { return `${moment(new Date(params.row.date).toLocaleDateString('en-US')).format( "MMMM Do YYYY" )}`; },
    },
    { field: 'points', headerName: 'Points', flex: 0.2, minWidth: 100 },
    { field: 'action', headerName: 'Action', minWidth: 80, flex: 0.2, disableExport: true,
      renderCell: (cellValues) => {
        const onEdit = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setEditData(cellValues?.row);
          setServiceName(cellValues?.row?.serviceName);
					setMainServiceName(sortServiceNameArray(cellValues?.row?.serviceName))
          setEdit(true);
        };
        return (
          <Grid container spacing={3}>
            <Grid item lg={2} md={4} sm={6} xs={6}>
              <IconButton aria-label='edit'>
                <EditIcon onClick={onEdit} style={{ color: '#00bcd4', fontSize: '20px' }} />
              </IconButton>
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={2}>
              <DeleteRecord title={'Reward Deletion?'}
                message={ 'This Reward will be permanently deleted and cannot be recovered. Are you sure you want to delete this Reward?' }
                deleteRecord={() => deleteRecord(cellValues.row.id)} alert={alert} setAlert={setAlert} />
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Grid container>
      <Grid container item xs={12} md={12} lg={12} mt={5} >
        <Grid item xs={12} md={6} lg={6}> <Points /> </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent={"flex-end"} marginTop={{ lg:0, md:0, sm:5, xs:5, }} >
      <Button sx={{ width: { lg:"auto", md:"auto", sm:"auto", xs:"100%", } }} variant="contained" onClick={() => setOpen(true)}> Create Reward </Button>
        </Grid>
      </Grid>
      <CreateCoupon open={open} handleClose={handleClose} serviceName={serviceName} setServiceName={setServiceName}
        setStatus={() => setStatus(!status)} services={services} uniqueArray={uniqueArray} MenuProps={MenuProps} mainServices={mainServices} handleServiceValue={handleServiceValue} mainServiceName={mainServiceName}/>
      <EditCoupon open={edit} handleClose={handleCloseEdit} serviceName={serviceName} setServiceName={setServiceName}
        setStatus={() => setStatus(!status)} services={services} editData={editData} uniqueArray={uniqueArray} MenuProps={MenuProps} handleServiceValue={handleServiceValue} mainServiceName={mainServiceName} mainServices={mainServices}/>
      <Grid item xs={12}>
        <Datagrid fetchDataUrl={`/rewards/coupons`} columns={columns} status={status} title='Rewards' />
      </Grid>
      {alert.showAlert && ( <Grid item md={12}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid> )}
    </Grid>
  );
}
