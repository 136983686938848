import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import SpanningTable from './SpanningTable';
import PaymentModal from './PaymentModal';
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import AlertInlineMain from "components/common/AlertInlineMain";

// franchisee
export default function RoyaltyBalance() {
  const axios = useAxiosPrivate()
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const [open, setOpen] = useState(false);
  const [balance, setBalance] = useState(0);
  const [accBalance, setAccBalance] = useState(0);

  const getBalance = async () => {
    try {
      const { data } = await axios.get('/orders/get-account-balance');
      setAccBalance(data);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Account Balance failed!',
      });
      setTimeout(() => {
        setAlert({ showAlert: false, severity: 'error', message: '' });
      }, 3000);
    }
  };

  const handleClickOpen = () => {
    getBalance();
    setOpen(true);
  };

  return (
    <>
      <Grid container spacing={1} direction='column'>
        <Grid item style={{ height: 350, width: '100%' }} xs={12} md={12}>
          <SpanningTable setBalance={setBalance} accBalance={accBalance} />
        </Grid>
        <Grid item>
          <Button variant='contained' color='primary' onClick={handleClickOpen}>
            Pay Royalty
          </Button>
        </Grid>
        {alert.showAlert && (
          <Grid item md={12} m={2}>
            <AlertInlineMain setAlert={setAlert} alert={alert} />
          </Grid>
        )}
      </Grid>
      <PaymentModal
        open={open}
        setOpen={setOpen}
        balance={balance}
        accBalance={accBalance}
        getBalance={() => getBalance()}
      />
    </>
  );
}
