import React, { useState, useEffect } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Grid, CardHeader, Card } from '@mui/material';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import LinearProgress from '@mui/material/LinearProgress';
import AlertInlineMain from "components/common/AlertInlineMain";
import { useStyles } from 'utils/styles/styles';

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className='icon' />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className='icon' />;
}

export default function CustomSortIcons({
  columns,
  fetchDataUrl,
  status,
  buttonDisabled,
  title,
  newData,
  height,
  refreshData,
  fixHeight,
  filteredItems,
  setFilteredItems
}) {
  const axios = useAxiosPrivate()
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    // eslint-disable-line
    showAlert: false,
    severity: 'success',
    message: '',
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, fetchDataUrl, refreshData]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonDisabled]);


  useEffect(() => {
    if (newData) {
      setFilteredItems(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);


  const loadData = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(fetchDataUrl);

      setFilteredItems(data);

    } catch (error) {
      if (error.response?.status === 401) {
        if (error.response?.data.message) {
          setAlert({
            showAlert: true,
            severity: 'error',
            message: `${error.response.data.message}`,
          });
          setTimeout(() => setAlert({ showAlert: false, severity: 'error', message: '' }), 3000);
        } else {
          setAlert({
            showAlert: true,
            severity: 'error',
            message: 'Authorized Access!',
          });
          setTimeout(() => setAlert({ showAlert: false, severity: 'error', message: '' }), 3000);
        }
      } else {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Something went wrong!',
        });
        setTimeout(() => setAlert({ showAlert: false, severity: 'error', message: '' }), 3000);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container className={classes.root1} spacing={1}
      alignItems="center"
      justifyContent="center"
    >
      <Grid container item
        alignItems="center"
        justifyContent="center">

        <Grid item
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >

          <Card>
            <CardHeader
              titleTypographyProps={{ variant: 'h6' }}
              title={`${title.toUpperCase()}`} />

            <Grid
              item
              xs={12}
              md={12}
              style={{ height: !height ?? '60vh', margin: '1rem', padding: 0 }}
            >
              <DataGrid
                getRowHeight={() => !fixHeight ? 'auto' : ''}
                autoHeight
                columns={columns}
                disableSelectionOnClick={true}
                disableColumnSelector={true}
                loading={loading}
                disableDensitySelector={true}
                rows={filteredItems}
                components={{
                  Toolbar: GridToolbar,
                  ColumnSortedDescendingIcon: SortedDescendingIcon,
                  ColumnSortedAscendingIcon: SortedAscendingIcon,
                  LoadingOverlay: LinearProgress,
                }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [
                        {
                          columnField: 'price',
                          operatorValue: '>',
                          value: '2.5',
                        },
                      ],
                    },
                  },
                }}
              />
            </Grid>
            {alert.showAlert && (
              <Grid item md={12} m={2}>
                <AlertInlineMain setAlert={setAlert} alert={alert} />
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
