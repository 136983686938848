import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';
import AlertInlineMain from "components/common/AlertInlineMain";
import { makeStyles } from '@material-ui/core/styles';
import Folder from '../Folder';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import HomeIcon from '@material-ui/icons/Home';
import DropZone from '../../../components/layouts/MDropZone2';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";

export default function FolderViewTable({ headers, TableB, fetchDataUrl, fetchDataUrl2, statusChangeUrl, updatePath,
  learn, level1, level2, level1n, level2n, setLevel1, setLevel2, setLevel1n, setLevel2n, title, imgs, view, ...props }) {
  const axios = useAxiosPrivate()
  const navigate = useNavigate();
  const [filteredItems, setFilteredItems] = useState([]);
  const role = sessionStorage.getItem('role');
  //alerting
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });
  const [items, setData] = useState([]);
  const [image, setImage] = useState([]);
  //use effect
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load data to the table
  const loadData = async (fetchDataUrl2, isRoot) => {
    try {
      if (fetchDataUrl2) {
        const { data } = await axios.post(fetchDataUrl2, { user: isRoot ? '' : learn });
        setFilteredItems(data);
        setData(data);
      } else {
        const { data } = await axios.post(fetchDataUrl, { user: learn });
        setFilteredItems(data);
        setData(data);
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Data loading failed!',
      });
    }
  };

  // activate and deactivate status
  const changeStatus = async (id, status, file) => {
    try {
      if (file) { await axios.put('/folder/file', { id, status: !status, });
        if (!level1 && !level2) { loadData('/folder/all'); }
        else if (level1 && !level2) { loadData(`/folder/sub/${level1}`); }
        else if (level1 && level2) { loadData(`/folder/sub/${level2}`); }
      } else { await axios.put(statusChangeUrl, { id, status: !status, });
        if (!level1 && !level2) { loadData('/folder/all'); }
        else if (level1 && !level2) { loadData(`/folder/sub/${level1}`); }
        else if (level1 && level2) { loadData(`/folder/sub/${level2}`); }
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Status changing failed!',
      });
    }
  };

  //update
  const onUpdate = (id) => {
    props.history.push(updatePath, { update: true, id });
  };
  const onView = (id) => {
    props.history.push(view, { id });
  };
  const useStyles = makeStyles((theme) => ({
    root: { flexGrow: 1, },
    marginBottom: { width: '80%', padding: '0.5rem', },
    green: { backgroundColor: 'green', color: 'white', },
    red: { backgroundColor: 'red', color: 'white', },
    yellow: { backgroundColor: 'orange', color: 'white', },
    textCenter: { textAlign: 'center', },
  }));

  const StyledBreadcrumb = withStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.grey[100],
      height: theme.spacing(3),
      color: theme.palette.grey[800],
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover, &:focus': { backgroundColor: theme.palette.grey[300], },
      '&:active': { boxShadow: theme.shadows[1], backgroundColor: emphasize(theme.palette.grey[300], 0.12), },
    },
  }))(Chip);

  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  //search
  const onSearch = (e) => {
    const text = String(e.target.value).toLowerCase();
    setSearchText(text);
    if (text) {
      // eslint-disable-next-line
      const result = items.filter((item) => {
        const str = JSON.stringify(item.name).toLowerCase();
        if (str.search(text) >= 0) return item;
      });
      setFilteredItems(result);
    } else {
      setFilteredItems(items);
    }
  };

  const updateStatus = async (id, status) => {
    try { await axios.put(`/purchaseOrder/${id}`, { status, });
      loadData();
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Status changing failed!',
      });
    }
  };

  const handleClick1 = () => {
    loadData(`/folder/all`, true);
    setLevel1n('');
    setLevel1('');
    setLevel2n('');
    setLevel2('');
    navigate("/lwl-learning")
  };

  const handleClick2 = async () => {
    loadData(`/folder/sub/${level1}`);
    setLevel2n('');
    setLevel2('');
  };
  const uploadData = async () => {
    try {
      let bodyFormData = new FormData();
      for (let i = 0; i < image.length; i++) { bodyFormData.append(`image`, image[i]); }
      if (image.length > 5) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Files upload fail! (Maximum file limit is 5)',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'error',
            message: 'Files upload fail! (Maximum file limit is 5)',
          });
        }, 3000);
        setImage([]);
      } else {
        if (!level1 && !level2) {
          await axios.post('/folder/upload', bodyFormData, { headers: { 'Content-Type': 'multipart/form-data', }, });
          loadData('/folder/all');
        } else if (level1 && !level2) {
          await axios.post(`/folder/upload/${level1}`, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data', }, });
          loadData(`/folder/sub/${level1}`);
        } else if (level1 && level2) {
          await axios.post(`/folder/upload/${level2}`, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data', }, });
          loadData(`/folder/sub/${level2}`);
        }

        setImage([]);
        setAlert({
          showAlert: true,
          severity: 'success',
          message: 'Files upload successfully!',
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: 'success',
            message: 'Files upload successfully!',
          });
        }, 3000);
      }
    } catch {
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Files upload fail!',
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'error',
          message: 'Files upload fail!',
        });
      }, 3000);
    }
  };
  useEffect(() => {
    if (image.length !== 0) {
      uploadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  const backNav = ()=>{
    if(level1n && !level2n){
      loadData(`/folder/all`);
      setLevel1('');
      setLevel1n('')
    }else if(level2n){
      loadData(`/folder/sub/${level1}`);
      setLevel2n('');
      setLevel2('');
    }else{
      if(role === 'franchisor'){
        navigate("/FranchiseeFolderView")
          }else{
      navigate("/FranchiseeFolderView")
    }
    }
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  }} >
        <Breadcrumbs aria-label='breadcrumb' style={{ marginBottom: '0.5rem' }}>
          <StyledBreadcrumb component='a' label='Root' icon={<HomeIcon fontSize='small' />} onClick={handleClick1} />
          {level1n && ( <StyledBreadcrumb component='a' label={level1n} onClick={handleClick2} /> )}
          {level2n && <StyledBreadcrumb component='a' label={level2n} />}
        </Breadcrumbs>
        <Button variant='contained' onClick={() => backNav()}>
          {' '}
          Back
        </Button>
      </div> 
      <Grid container>
        <Grid item className={classes.root}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField label='Search...' variant='outlined' className={classes.marginBottom} onChange={onSearch} value={searchText} size='small' />
          </div>
          {!level2n &&
            ((role === 'franchisor' && learn?.length !== 24) || learn === 'user') && (
              <Folder level1={level1} level2={level2} loadData={loadData} /> )}
          {((role === 'franchisor' && learn?.length !== 24) ||
            learn === 'user') && <DropZone setImage={setImage} image={image} />}
          {filteredItems.map((item, i) => (
            <div key={i}>
              <TableB item={item} changeStatus={changeStatus} loadData={loadData} classes={classes} onUpdate={onUpdate} onView={onView}
                updateStatus={updateStatus} setAlert={setAlert} />
            </div>
          ))}
          {alert.showAlert && ( <Grid item sm={12} md={12} xs={12}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid> )}
        </Grid>
      </Grid>
    </>
  );
}
