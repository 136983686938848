import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { TextField as TF } from "formik-material-ui";
import DateDashToSlashReformat from 'components/common/Functions/DateDashToSlashReformat';

function UpdateDelivery({ setStatus, status, editData, open, setOpen }) {
  const axios = useAxiosPrivate()
  const [limtValidated, setLimitValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  // const franchiseeTimeZone = sessionStorage.getItem("franchiseeTimeZone")
  const [timeSlots, setTimeSlots] = useState([]);

  const initial = {
    id: "",
    zone: "",
    unLimit: "",
    startDate: "",
    endDate: "",
    isPublished: "",
    date: "",
  };
  const [initialValues, setInitialValues] = useState(initial);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [alert2, setAlert2] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  useEffect(() => {
    getTimeSlots(editData?.id);
    setInitialValues({
      id: editData?.id,
      zone: editData?.zoneName,
      unLimit: editData?.unattendedLimit,
      startDate: DateDashToSlashReformat(editData?.startDate),
      endDate: editData?.endDate
      ? DateDashToSlashReformat(editData?.endDate)
      : '',
      isPublished: editData?.isPublished,
      date: editData?.date,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const handleClose = () => {
    setTimeSlots([]);
    setOpen(false);
    setStatus(!status);
  };
  // get time slots
  const getTimeSlots = async (id) => {
    if (id) {
      try {
        const { data } = await axios.get(`/delivery/edit/${id}`);
        let array = [];
        // return data to timeslots
        let countChecker = false;
        for (let d of data.data) {
          array.push({ id: d.id, timeSlot: d.timeSlot, limit: Number(d.limit), });
          countChecker = d.count > 0 ? true : false;
        }
        if (data.unAttended || countChecker) {
          setAlert2({
            showAlert: true,
            severity: "error",
            message: "Orders are currently scheduled for this time frame.",
          });
        }
        setTimeSlots(array);
      } catch (error) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Delivery Schedule Loading Failed!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Delivery Schedule Loading Failed!",
          });
          setOpen(false);
        }, 8000);
      }
    }
  };

  // insert data to database
  const onSubmit = async (e, { resetForm }) => {
    setLoading(true);
    const array = timeSlots?.map((t) => { return { id: t.id, limit: t.limit, }; });
    try {
      const data = {
        timeSlots: array, unattendedLimit: e.unLimit,
        isPublished: e.isPublished === "true" || e.isPublished === true ? true : false,
      };
      resetForm();
      setInitialValues(initial);
      setTimeSlots([]);
      await axios.put(`/delivery/${e.id}`, {
        timeSlots: data.timeSlots,
        unattendedLimit: data.unattendedLimit,
        isPublished: data.isPublished,
      });
      setStatus(!status);
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Delivery Schedule updated!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "Delivery Schedule updated!",
          message: "",
        });
        setLoading(false);
        handleClose();
      }, 1000);
    } catch (err) {
      setLoading(false);
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Request Failed!",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "Request Failed!",
        });
        handleClose();
      }, 3000);
    }
  };

  const validationSchema = Yup.object().shape({
    unLimit: Yup.number()
      .typeError("Please enter a valid number")
      .required("Limit is required")
      .min(0, "Minimum atleast 0")
      .test("is-decimal", "invalid decimal", (value) =>
        (value + "").match(/^\d*$/)
      ),
  });

  const handleLimitChange = (e, id) => {
    const vala = e.target.value;
    const err = Yup.number()
      .typeError("Please enter a valid number")
      .required("Limit is required")
      .min(0, "Minimum atleast 0")
      .test("is-decimal", "invalid decimal", (value) =>
        (value + "").match(/^\d*$/)
      )
      .isValidSync(vala);
    setLimitValidated(!err);
    const timeSlot = timeSlots.map((slot) => {
      if (slot.id === id) {
        return {
          ...slot,
          limit: vala,
          error: !err,
        };
      }
      return slot;
    });
    setTimeSlots(timeSlot);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Dialog fullWidth maxWidth={"md"} open={open} onClose={handleClose} >
            <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} >
              {({ values, isValid, handleChange }) => {
                return (
                  <Form noValidate autoComplete="off" encType="multipart/form-data" >
                    <DialogTitle style={{ cursor: "move" }}> Edit Schedule{" "}
                      {/* {new Date(values?.date).toLocaleDateString('en-US', { timeZone: `${franchiseeTimeZone}` })} */}
                      {JSON.stringify(values?.date).split("T")[0]}"
                    </DialogTitle>
                    <DialogContent>
                      <Grid container rowSpacing={5} >
                        <Grid container item columnSpacing={1} rowSpacing={2} lg={12} md={12} sm={12} xs={12} >
                          <Grid item lg={12} md={12} xs={12} sm={12} mt={1} >
                            <Field fullWidth component={TF} name="zone" label="Zone" variant="outlined" disabled ></Field>
                          </Grid>
                          <Grid item lg={6} md={12} xs={12} sm={12} >
                            <Field fullWidth component={TF} name="startDate" label="Start Date" variant="outlined" disabled ></Field>
                          </Grid>
                          <Grid item lg={6} md={12} sm={12} xs={12} >
                            <Field fullWidth component={TF} name="endDate" label="End Date" variant="outlined" disabled={true} ></Field>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12} >
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                              <Field fullWidth name="unLimit" label="Unattended Limit" variant="outlined" value={values.unLimit} onChange={handleChange}
                                style={{ display: 'flex', }} component={TF} ></Field>
                            </Grid>
                            {alert2.showAlert && (
                              <Grid item xs={12} md={12}>
                                <Alert severity={alert2.severity} onClose={() => setAlert2({ ...alert2, showAlert: false, })} >
                                  {alert2.message}
                                </Alert>
                              </Grid>
                            )}
                          </Grid>
                          <Grid container item >
                            <Grid item mb={2}>
                              <h3>Daily Time Limits</h3>
                            </Grid>
                            {timeSlots?.map((t) => {
                              return (
                                <Grid container key={t.timeSlot}>
                                  <Grid container item alignItems="center" justifyContent="center" >
                                    <Grid item mb={2} lg={12} md={12} sm={12} xs={12} >
                                      <p style={{ marginBottom: "0.5rem" }} >{t.timeSlot}</p>
                                      <TextField name="timeSlotLimit" label="Limit" defaultValue={t.limit} error={t.error}
                                        helperText={t.error ? "Enter Valid Number " : ""} variant="outlined"
                                        onChange={(e) => { handleLimitChange(e, t.id); }} />
                                    </Grid>
                                  </Grid>
                                </Grid>);
                            })}
                          </Grid>
                          <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"} >
                            <FormControl>
                              <FormLabel id="time-slots-radio-buttons"> Publish Time Slot </FormLabel>
                              <RadioGroup name="isPublished" value={values.isPublished} onChange={handleChange} defaultValue={false} >
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          {alert.showAlert && (
                            <Grid item md={12} m={2}>
                              <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, showAlert: false, })} >
                                {alert.message}
                              </Alert>
                            </Grid>)}
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <LoadingButton loading={loading} color="success" onClick={onSubmit} type="submit" disabled={!isValid && limtValidated} >
                        Save </LoadingButton>
                      <Button color="error" onClick={handleClose}> Close </Button>
                    </DialogActions>
                  </Form>);
              }}
            </Formik>
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
}
export default UpdateDelivery;
