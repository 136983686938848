import React, { useState } from 'react';
import Line from 'components/layouts/SalesLineChart';

import { Grid, Button, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterDialog from 'components/dashboard/FilterDialog';

export default function LineChart() {
  const [selected, setSelected] = useState({});

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <Grid container >

      <Grid container item
        alignItems="center"
        justifyContent={{
          lg:"flex-start",
          md:"flex-start",
          sm:"center",
          xs:"center",
        }}
      >

        <Grid item
          lg={"auto"}
          md={"auto"}
          sm={"auto"}
          xs={"auto"}
        >
          <Typography sx={{
            fontSize: "2rem",
            fontWeight: 400,
            marginRight: "1rem"
          }}
            variant='h4' >
            Sales ($)
          </Typography>
        </Grid>

        <Grid item
          lg={"auto"}
          md={"auto"}
          sm={"auto"}
          xs={"auto"}
        >
          <Button variant='contained' onClick={handleClickOpen}>
            <FilterAltIcon />
          </Button>
        </Grid>

        <Grid item
          lg={12}
          md={12}
          sm={12}
          xs={12}

          mt={3}
        >
          <Line selected={selected} />
          <FilterDialog setOpen={setOpen} open={open} setSelected={setSelected} />
        </Grid>

      </Grid>

    </Grid>
  );
}
