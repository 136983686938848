import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { Form, Formik, Field } from "formik";
import Color from "../Color";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField as TF } from "formik-material-ui";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import { setVehicle } from "../../../store/actions/RouteAction";
import { useDispatch, useSelector } from "react-redux";

import DialogAlertMain from "components/common/DialogAlertMain";

export default function CreateVehicle() {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate()
  const oneVehicle = useSelector((state) => state.route.oneVehicle);

  const [backendCall, setBackendCall] = useState(false);
  const [initialValues, setInitialValues] = React.useState({
    name: "",
    color: "",
    id: "",
  });

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  useEffect(() => {
    if (oneVehicle) {
      setInitialValues({
        name: oneVehicle.name,
        color: oneVehicle.color,
        id: oneVehicle._id,
      });
    } else {
      setInitialValues({
        name: "",
        color: "",
        id: "",
      });
    }
  }, [oneVehicle]);

  const onSubmit = async (e, { resetForm }) => {

    let payload

    if (e.name === oneVehicle.name && e.color === oneVehicle.color) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Can't update the same value!",
      });

    } else if (e.name === oneVehicle.name) {
      payload = { color: e.color, id: e.id }
    } else if (e.color === oneVehicle.color) {
      payload = { name: e.name, id: e.id }
    } else if (e.name !== oneVehicle.name && e.color !== oneVehicle.color) {
      payload = e
    }

    try {
      setBackendCall(true);

      if (oneVehicle.color) {
        if (payload) {
          await axios.put(`/vehicle/${oneVehicle?._id}`, payload);
          dispatch(setVehicle());
          setAlert({
            showAlert: true,
            severity: "success",
            message: "Update Success!",
          });
        }

      } else {
        await axios.post("/vehicle", e);
        resetForm();
        dispatch(setVehicle());
        setInitialValues({
          name: "",
          color: "",
          id: "",
        })
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Create Success!",
        });

      }
    } catch (e) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${e.response.data.message}`,
      });

    } finally {
      setBackendCall(false);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    color: Yup.string().required("Color is required"),
  });

  return (
    <Grid container justifyContent={"flex-start"} alignContent="center"
      mt={2}
      sx={{
        height: "100%"
      }}
    >
      <Grid item container
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            isValid,
            dirty,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Grid container item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item
                    lg={"auto"}
                    md={"auto"}
                    sm={"auto"}
                    xs={"auto"}
                  >
                    {" "}
                    <Field
                      name="name"
                      label="Vehicle Name"
                      variant="outlined"
                      component={TF}
                      onChange={handleChange}
                      fullWidth
                    ></Field>
                  </Grid>
                  <Grid item

                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}

                    mt={3}>
                    <Typography
                      sx={{
                        textAlign: "center",
                      }}
                    > Vehicle Color </Typography>
                  </Grid>
                  <Grid item

                    lg={"auto"}
                    md={"auto"}
                    sm={"auto"}
                    xs={"auto"}>
                    <Color setFieldValue={setFieldValue} color={oneVehicle} />
                  </Grid>
                  <Grid item

                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}

                    mt={2}>
                    <Typography variant="h6" sx={{ 
                      fontSize: "0.8rem",
                      textAlign: "center",
                   }}>
                      We recommend using a different color for each Vehicle *{" "}
                    </Typography>
                  </Grid>
               

                <Grid item
                lg={"auto"}
                md={"auto"}
                sm={"auto"}
                xs={"auto"}
                  mt={2}>
                  <LoadingButton
                    data-testid="vehicle-create-btn"
                    loading={backendCall}
                    type="submit"
                    variant="contained"
                    size="small"
                    disabled={!isValid || !dirty}
                  >
                    {oneVehicle?.color ? "Update" : "Create"}
                  </LoadingButton>
                  </Grid>
                  
                  </Grid>
              </Form>
            );
          }}
        </Formik>
        <DialogAlertMain alert={alert} setAlert={setAlert} />
      </Grid>
    </Grid>
  );
}
