import React, { useState, useEffect } from 'react';
import { Grid } from "@mui/material/";
import Header from 'components/routing/Header';
import { setDepot } from "../../store/actions/RouteAction";
import { useDispatch } from "react-redux";
import SearchJobsForm from 'components/jobs/JobsSearchForm';
import DataGrid from "components/layouts/DataGridForJobSearch";
import { orderStatusGetter } from 'utils/commonFunctions';
import LocationOnIcon from '@mui/icons-material/LocationOnOutlined'

export default function JobSearch() {
  let dispatch = useDispatch();
  const franchiseeTimeZone = sessionStorage.getItem("franchiseeTimeZone");
  const today = new Date();
  const todayDateFromTimezone = new Date(today.toUTCString('en-US', { timeZone: `${franchiseeTimeZone}` }).slice(0, -4))
  todayDateFromTimezone.setHours(0, 0, 0)
  const [filteredItems, setFilteredItems] = useState([]);
  const [displayItems, setdisplayItems] = useState(filteredItems);

  useEffect(() => {
    dispatch(setDepot());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    setdisplayItems(filteredItems)
  }, [filteredItems])

  const MapMarkerCell = () => {
    return <LocationOnIcon />;
  };

  const columns = [
    {
      field: "map",
      headerName: "Map",
      width: 120,
      renderCell: (params) => {
        return (
          <a href={`https://www.google.com/maps/search/?api=1&query=${params.row?.orderDetail?.orderLocation?.lat},${params.row?.orderDetail?.orderLocation?.lng}`} target="_blank" rel="noopener noreferrer">
            <LocationOnIcon />
          </a>
        );
      },
    },
    {
      field: "jobType",
      headerName: "Job type",
      width: 120,
      valueGetter: (params) => {
        return `${params?.row?.type}`;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      valueGetter: (params) => {
        return `${params?.row?.type == "DELIVERY" ? params?.row?.orderDetail?.diliverDate : params?.row?.orderDetail?.pickupDate}`; // check date
      },
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 480,
      valueGetter: (params) => {
        return `${params?.row?.address}`;
      },
    },
    {
      field: "first",
      headerName: "First name",
      width: 150,
      valueGetter: (params) => {
        return `${params?.row?.customerDetail?.firstName}`;
      },
    },

    {
      field: "last",
      headerName: "Last name",
      width: 150,
      valueGetter: (params) => {
        return `${params?.row?.customerDetail?.lastName}`;
      },
    },

    {
      field: "driver",
      headerName: "Driver",
      maxWidth: 100,
      valueGetter: (params) => {
        return `${params?.row?.driverDetail?.name ?? "Unassigned"}`;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      valueGetter: (params) => orderStatusGetter(params.row)
    },
    {
      field: 'orderType',
      headerName: 'Services',
      minWidth: 180,
      valueGetter: (params) => {
        if (params.row.isManualOrder) {
          return "Manual Order"
        } else {
          switch (params.row.orderDetail?.orderType) {
            case '1':
              return 'Wash/Dry/Fold';
            case '2':
              return 'Dry Cleaning';
            case '3':
              return 'Tailoring';
            case '4':
              return 'Dry Cleaning & Tailoring';
            case '5':
              return 'Wash/Dry/Fold , Tailoring';
            case '6':
              return 'Wash/Dry/Fold , Tailoring';
            case '7':
              return 'Wash/Dry/Fold , Dry Cleaning';
            case '8':
              return 'Wash/Dry/Fold , Dry Cleaning';
            case '9':
              return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
            case '10':
              return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
            default:
              return '-';
          }
        }
      }
    }
  ];

  return (
    <Grid container >
      <Grid item
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Header navFrom={"jobs search"} />
      </Grid>
      <Grid item
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <SearchJobsForm filteredItems={filteredItems} setFilteredItems={setdisplayItems} />
        <DataGrid
          autoHeight
          columns={columns}
          status={true}
          fetchDataUrl={'/job/job-franchisee/all'}
          title={"Searched Jobs"}
          filteredItems={displayItems}
          setFilteredItems={setFilteredItems}
        />
      </Grid>
    </Grid>
  );
}