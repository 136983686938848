import React, {  useEffect, useState } from 'react';
import { Typography, Box} from '@mui/material';
import BasicDateRangePicker from 'components/common/BasicDateRangePicker';
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";

export default function SalesFigure({setSalesDetails}) {
  const axios = useAxiosPrivate()
  const [value, setValue] = useState([]);
  const [sales, setSales] = useState('');

  const Today = new Date();
  const firstDay = new Date(Today.getFullYear(), Today.getMonth(), 1);
  const lastDay = new Date(Today.getFullYear(), Today.getMonth() + 1, 0);



  const getSales = async (value) => {
    try {
      const { data } = await axios.post('/reports/franchisee-sales/sales', {
        startDate: value?.startDate,
        endDate: value?.endDate,
      });
      setSales(data);
    } catch (err) {
      setSales('Error');
    }
  };

  const getSalesOverview = async (value) => {
    try {
      const { data } = await axios.post('/reports/franchisee-sales/sales/overview', {
        startDate: value?.startDate,
        endDate: value?.endDate,
      });
      setSalesDetails(data);
    } catch (err) {
      console.log(err)
    }
  };


  useEffect(() => {
   const sendVal = {
      startDate: firstDay,
      endDate: lastDay
    }
    getSales(sendVal);
// eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      <Box sx={{ bgcolor: 'text.disabled', color: 'background.paper', p: 2 }}>
        <Typography variant='h4' component='h4'>
          Sales: $ {Number(sales).toFixed(2)}
        </Typography>
        <BasicDateRangePicker
          value={value}
          setValue={setValue}
          trigger={() => {getSales(value); getSalesOverview(value)}}
        />
      </Box>
    </>
  );
}
