import React, { useState, useEffect } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Grid, TextField, CardHeader, Card } from '@mui/material';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import LinearProgress from '@mui/material/LinearProgress';
import AlertInlineMain from "components/common/AlertInlineMain";
import { useStyles } from 'utils/styles/styles';
import BasicDateRangePicker from 'components/common/BasicDateRangePicker';
import { Box, Typography } from '@material-ui/core';
import DateSlashToDashReformat from 'components/common/Functions/DateSlashToDashReformat';

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className='icon' />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className='icon' />;
}

export default function DataGridForDeliveryReport({
  columns,
  fetchDataUrl,
  status,
  buttonDisabled,
  title,
  newData,
  role,
  height
}) {
  const axios = useAxiosPrivate()
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [totalDistance, setTotalDistance] = useState(null);
  const [rows, setRows] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState([]);
  const Today = new Date();
  const firstDay = DateSlashToDashReformat(new Date(Today.getFullYear(), Today.getMonth(), 1));
  const lastDay = DateSlashToDashReformat(new Date(Today.getFullYear(), Today.getMonth() + 1, 0));

  const [payload, setPayload] = useState( { startDate: firstDay, endDate: lastDay, } );


  const [alert, setAlert] = useState({
    // eslint-disable-line
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const onSearch = (e) => {
    const text = String(e.target.value).toLowerCase();
    setSearchText(text);

    if (text) {
      // eslint-disable-next-line
      const result = rows.filter((item) => {
        const str = JSON.stringify(item).toLowerCase();

        if (str.search(text) >= 0) return item;
      });
      // setPage(0);
      setFilteredItems(result);
    } else {
      setFilteredItems(rows);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, fetchDataUrl, payload]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonDisabled]);


  useEffect(() => {
    if (newData) {
      setFilteredItems(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);


  const loadData = async () => {
    try {
      setLoading(true);
      setRows([]);
      const { data } = await axios.post(fetchDataUrl, payload);

      const total = data.reduce((sum, row) => sum + Number(row.distance), 0)
      if (total) {
        setTotalDistance(`${total} miles`)
      } else { 
        setTotalDistance(`${0} miles`)
      }

      //set hardcode value for customer report
      const url = window.location.pathname.split('/');
      var path = url[1];

      if (path === 'customer-reports') {
        // const cdata = [
        //   {
        //     id: '',
        //     fID: 'C0001',
        //     customerName: 'Chirath Angekumbura',
        //     address: '14, GalkandaraRd, Anniwatta, Kandy',
        //     email: 'chirath.ang@gmail.com',
        //     franchisorId: 'FR000120',
        //     franchisorName: 'Kandy',
        //   },
        // ];
        setFilteredItems(data);
        setRows(data);
      } else {

        if (role === 'franchisee') {
          const statusTrueRows = data.filter(item => item?.item ? item?.item?.status === true : !item?.item)
          setFilteredItems(statusTrueRows);
          setRows(statusTrueRows);
        } else {
          setFilteredItems(data);
          setRows(data);
        }
      }
    } catch (error) {
      if (error.response?.status === 401) {
        if (error.response?.data.message) {
          setAlert({
            showAlert: true,
            severity: 'error',
            message: `${error.response.data.message}`,
          });
          setTimeout(() => setAlert({ showAlert: false, severity: 'error', message: '' }), 3000);
        } else {
          setAlert({
            showAlert: true,
            severity: 'error',
            message: 'Authorized Access!',
          });
          setTimeout(() => setAlert({ showAlert: false, severity: 'error', message: '' }), 3000);
        }
      } else {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Something went wrong!',
        });
        setTimeout(() => setAlert({ showAlert: false, severity: 'error', message: '' }), 3000);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container className={classes.root1} spacing={1}
      alignItems="center"
      justifyContent="center"
    > 
      <Box sx={{ bgcolor: 'text.disabled', color: 'background.paper', p: 2, marginBottom:"5rem" }}>
        <Grid container item alignItems={"center"} justifyContent={"center"}>
          <Grid item 
          lg={12}
          md={12}
          sm={12}
          xs={12} 
          >
            <Typography style={{textAlign:"center", color:"white", marginBottom:"1rem"}} variant='h4' component='h4'>
        Total Distance: {totalDistance ? parseFloat(totalDistance).toFixed(2) : "Loading..."}
            </Typography>
          </Grid>
          <Grid item 
          lg={"auto"}
          md={"auto"}
          sm={"auto"}
          xs={"auto"} 
          >
        <BasicDateRangePicker
          value={value}
          setValue={setValue}
          trigger={()=>{setPayload({
            startDate: DateSlashToDashReformat(value?.startDate),
            endDate: DateSlashToDashReformat(value?.endDate),
          })}}
            />
            </Grid>
          </Grid>
      </Box>
      <Grid container item
        alignItems="center"
        justifyContent="center">

        <Grid item
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >

          <Card>
            <CardHeader
              titleTypographyProps={{ variant: 'h6' }}
              title={`${title.toUpperCase()}`} />
            <Grid container spacing={2}>
              {' '}
              <Grid item xs={6} sm={6} md={6}>
                <TextField
                  label='Search'
                  fullWidth
                  variant='outlined'
                  style={{ margin: '1rem' }}
                  onChange={onSearch}
                  value={searchText}
                />
              </Grid>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ height: height ?? '60vh', margin: '1rem', padding: 0 }}
            >
              <DataGrid
                autoHeight
                columns={columns}
                disableSelectionOnClick={true}
                disableColumnSelector={true}
                loading={loading}
                disableDensitySelector={true}
                rows={filteredItems}
                components={{
                  Toolbar: GridToolbar,
                  ColumnSortedDescendingIcon: SortedDescendingIcon,
                  ColumnSortedAscendingIcon: SortedAscendingIcon,
                  LoadingOverlay: LinearProgress,
                }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [
                        {
                          columnField: 'price',
                          operatorValue: '>',
                          value: '2.5',
                        },
                      ],
                    },
                  },
                }}
              />
            </Grid>
            {alert.showAlert && (
              <Grid item md={12} m={2}>
                <AlertInlineMain setAlert={setAlert} alert={alert} />
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
