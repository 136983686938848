import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import DataGridTable from 'components/layouts/DataGrid';
import Chip from '@mui/material/Chip';
import OrderView from '../../screens/orders/OrderView';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import AlertMain from "components/common/AlertMain";

import moment from "moment";
export default function OrderManagement({ dateVal, setUpdateOrders, setReqNumber, onDeleteSuccess }) {
  const [status, setStatus] = useState(false);
  //eslint-disable-next-line
  const [type, setType] = useState('pending');
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const axios = useAxiosPrivate();
  const [refreshData, setRefreshData] = useState(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const deleteOrder = async (id) => {
    try {
      await axios.delete(`order/manualorder/${id}`)
      setReqNumber();
      onDeleteSuccess()
      setAlert({
        showAlert: true,
        severity: 'success',
        message: 'Order Deleted Successfully',
      });
      setRefreshData(!refreshData)
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'success',
          message: '',
        });
      }, 3000);
    } catch (error) {
      if (error.response.data) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: error.response.data.message,
        });
      }
      setRefreshData(!refreshData)
    }
  }

  useEffect(() => {
    setStatus(!status);
    //eslint-disable-next-line
  }, [dateVal]);

  const columns = [
    { field: 'orderId', headerName: 'Order ID', minWidth: 100, flex: 0.2 },
    {
      field: 'customer',
      headerName: 'Customer Name',
      minWidth: 150,
      flex: 0.5,
      valueGetter: (params) => {
        return `${params.row.customer?.firstName || ''} ${params.row.customer?.lastName || ''}`;
      },
    },
    {
      field: 'orderStatus',
      headerName: 'Order Status',
      minWidth: 100,
      flex: 0.2,
      renderCell: (params) => {
        switch (params.row.orderStatus) {
          case 'pending':
            return <Chip label='Pending' color='primary' />;
          case 'process':
            return <Chip label='Processing' color='secondary' />;
          case 'completed':
            return <Chip label='Completed' color='success' />;
          default:
            return <Chip label='Pending' color='primary' />;
        }
      },
    },

    {
      field: 'orderType',
      headerName: 'Services',
      minWidth: 290,
      flex: 0.6,
      valueGetter: (params) => {
        if (params.row.isManualOrder) {
          return "Manual Order"
        } else {
          switch (params.row.orderType) {
            case '1':
              return 'Wash/Dry/Fold';
            case '2':
              return 'Dry Cleaning';
            case '3':
              return 'Tailoring';
            case '4':
              return 'Dry Cleaning & Tailoring';
            case '5':
              return 'Wash/Dry/Fold , Tailoring';
            case '6':
              return 'Wash/Dry/Fold , Tailoring';
            case '7':
              return 'Wash/Dry/Fold , Dry Cleaning';
            case '8':
              return 'Wash/Dry/Fold , Dry Cleaning';
            case '9':
              return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
            case '10':
              return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
            default:
              return '-';
          }
        }
      },
    },
    {
      field: 'pickupDate',
      headerName: 'Pickup Date',
      minWidth: 250,
      flex: 0.3,
      valueGetter: (params) => {
        let pickupDate = params?.row?.pickupDate
        if (pickupDate?.includes("Z")) {
          pickupDate = (pickupDate?.split("T")[0])
        } else {
          pickupDate = params?.row?.pickupDate
        }
        return `${params?.row?.pickupDate ? moment(pickupDate).format("MMMM Do dddd YYYY") : "Not applicable"}`;
      },
    },
    {
      field: 'pickupTimeSlot',
      headerName: 'Pickup TimeSlot',
      minWidth: 200,
      flex: 0.3,
      valueGetter: (params) => {
        return `${params?.row?.pickupTimeSlot ? params?.row?.pickupTimeSlot : "Not applicable"}`;
      },
    },
    {
      field: 'diliverDate',
      headerName: 'Delivery Date',
      minWidth: 250,
      flex: 0.3,
      valueGetter: (params) => {
        let diliverDate = params?.row?.diliverDate
        if (diliverDate?.includes("Z")) {
          diliverDate = (diliverDate?.split("T")[0])
        } else {
          diliverDate = params?.row?.diliverDate
        }
        return `${params?.row?.diliverDate ? moment(diliverDate).format("MMMM Do dddd YYYY") : "Not applicable"}`;
      },
    },
    {
      field: 'diliverTimeSlot',
      headerName: 'Delivery TimeSlot',
      minWidth: 200,
      flex: 0.3,
      valueGetter: (params) => {
        return `${params?.row?.diliverTimeSlot ? params?.row?.diliverTimeSlot : "Not applicable"}`;
      },
    },
    {
      field: 'specialDate',
      headerName: 'Special Care Delivery Date',
      minWidth: 250,
      flex: 0.3,
      valueGetter: (params) => {
        let specialDate = params.row.specialDate
        if (specialDate?.includes("Z")) {
          specialDate = (specialDate?.split("T")[0])
        } else {
          specialDate = params.row.specialDate
        }
        return `${params?.row?.specialDate ? moment(specialDate).format("MMMM Do dddd YYYY") : "Not applicable"}`;
      },
    },
    {
      field: 'view',
      headerName: 'View',
      minWidth: 100,
      flex: 0.2,
      disableExport: true,
      renderCell: (params) => {
        const onClickStatus = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setEditData(params.row);
          setOpen(true);
          setStatus(!status);
        };

        return <Button onClick={onClickStatus}>View</Button>;
      },
    },
    {
      field: 'delete',
      headerName: 'Delete',
      minWidth: 100,
      flex: 0.2,
      disableExport: true,
      renderCell: (params) => {
        const onClickStatus = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          await deleteOrder(params.row.id);
        };
        return <Button onClick={onClickStatus}>Delete</Button>;
      }
    }
  ];

  return (

    <Grid container
    // spacing={1}
    // md={12} xl={12}
    >
      {/* <Grid container item 
            // spacing={1}
             xs={6} md={6} xl={6}></Grid> */}
      <Grid item xs={12} md={12} xl={12}>
        <DataGridTable
          // title={`${type.toUpperCase()} Orders`}
          height={"35rem"}
          title={`Orders`}
          fetchDataUrl={`/job/frontend-orders-date/${dateVal}`}
          // fetchDataUrl={`/orders/orders-date/${dateVal}`}
          columns={columns}
          status={status}
          refreshData={refreshData}
        />
      </Grid>
      <OrderView
        setUpdateOrders={setUpdateOrders}
        editData={editData}
        setStatus={setStatus}
        status={status}
        open={open}
        setOpen={setOpen}
      />
      <AlertMain setAlert={setAlert} alert={alert} />
    </Grid>
  );
}
