import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import AlertInlineMain from "components/common/AlertInlineMain";
const validationSchema = Yup.object().shape({
  points: Yup.number()
    .min(0, 'Points % cannot be negative')
    .required('Points % is required'),
});

const Points = () => {
  const axios = useAxiosPrivate()
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    points: '',
  });

  const alertSetter = (showAlert, severity, message, time) => {
    if (time) {
      alertSetter(showAlert, severity, message);
      setTimeout(() => {
        alertSetter(false, severity, '');
      }, time);
    } else {
      setAlert({
        showAlert: showAlert,
        severity: severity,
        message: message,
      });
    }
  };

  const getPoints = async () => {
    try {
      const { data } = await axios.get('/user/point-precentage');
      setInitialValues({
        points: data.points,
      });

      alertSetter(
        true,
        'success',
        `Point percentage fetched Succesfully`,
        3000
      );
    } catch (err) {
      alertSetter(true, 'error', err.response.data.message, 3000);
    }
  };

  const onSubmit = async (e) => {
    try {
      setLoading(true);
      await axios.put('/user/points-precentage', {
        points: e.points,
      });
      alertSetter(
        true,
        'success',
        'Point percentage updated Succesfully',
        3000
      );
    } catch (err) {
      alertSetter(true, 'error', err.message, 3000);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, handleChange }) => {
        return (
          <Paper variant='outlined'>
            <Form>
              <Grid container direction='row' spacing={1} p={1}>
                <Grid item xs={6} mg={6} lg={6} p={1}>
                  <Field
                    name='points'
                    label='Points Percentage %'
                    component={TextField}
                    variant='outlined'
                    onChange={handleChange}
                    fullWidth
                  ></Field>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  mg={6}
                  lg={6}
                  p={1}
                  justifyContent='center'
                  alignItems='center'
                >
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    color='primary'
                    fullWidth
                    loading={loading}
                    disabled={!isValid}
                  >
                    Save
                  </LoadingButton>
                </Grid>
                {alert.showAlert ? (
                  <Grid item md={12}>
                    <AlertInlineMain setAlert={setAlert} alert={alert} />
                  </Grid>
                ) : (
                  <Grid item md={12}>
                    {''}
                  </Grid>
                )}
              </Grid>
            </Form>
          </Paper>
        );
      }}
    </Formik>
  );
};

export default Points;
