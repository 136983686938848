import * as Yup from "yup";

export default function useFranchiseeMgtValidation() {
  
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Franchisee business name required")
      .max(60, "Maximum length for the Franchisee business name is 100!"),
    ownerName: Yup.string().required("Owner name required")
      .max(100, "Maximum length for the owner name is 100!"),
    email: Yup.string().required("Email is required!")
      .email("Must be a valid email")
      .matches(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter a valid email")
      .max(320, "Must be less than 320 digits"),
    password: Yup.string()
  .matches(
    /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
    "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long"
  )
  .min(8, "Password must be at least 8 characters long")
  .required("Password is required"),
  });

  const validationSchemaUpdate = Yup.object().shape({
    firstName: Yup.string().required("Franchisee business name required")
      .max(60, "Maximum length for the Franchisee business name is 100!"),
    ownerName: Yup.string().required("Owner name required")
      .max(100, "Maximum length for the owner name is 100!"),
    email: Yup.string().required("Email is required!")
      .email("Must be a valid email")
      .matches(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter a valid email")
      .max(320, "Must be less than 320 digits")
  });

  return { validationSchema, validationSchemaUpdate };

}