import React, { useEffect, useState } from 'react';
import { chartColors } from './colors';

import { Bar } from 'react-chartjs-2';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Grid, Typography } from '@mui/material';
import AlertInlineMain from "components/common/AlertInlineMain";
export default function AdminBarChart({ selected }) {
  const axios = useAxiosPrivate()
  const [label, setLabel] = useState([]);

  const [newData, setNewData] = useState([]);

  const [details, setDetails] = useState('Revenue By Frachisee ($)');

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const monthName = (number) => {
    let month = '';
    switch (number) {
      case '01':
        month = 'Jan';
        break;
      case '02':
        month = 'Feb';
        break;
      case '03':
        month = 'March';
        break;
      case '04':
        month = 'April';
        break;
      case '05':
        month = 'May';
        break;
      case '06':
        month = 'June';
        break;
      case '07':
        month = 'July';
        break;
      case '08':
        month = 'August';
        break;
      case '09':
        month = 'September';
        break;
      case '10':
        month = 'Octomber';
        break;
      case '11':
        month = 'November';
        break;
      case '12':
        month = 'December';
        break;
      default:
        month = '';
        break;
    }
    return month;
  };

  const isEmpty = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };

  const alertSetter = (severity, message) => {
    setAlert({
      showAlert: true,
      severity,
      message,
    });
    setTimeout(() => {
      setAlert({
        showAlert: false,
        severity,
        message,
      });
    }, 3000);
  };

  const getMTDSales = async () => {
    try {
      const { data } = await axios.get('/reports/franchiser-sales/mtd-bar');
      setLabel(data.map((item) => (item?.franchisee.length > 8 ? item?.franchisee.slice(0, 8) + '...' : item?.franchisee)));
      setNewData(data.map((item) => item.total));
      setDetails(`MTD Sales ($)`);
      alertSetter('success', 'Successfully fetched MTD sales');
    } catch (error) {
      alertSetter('error', 'Failed to fetch MTD sales');
    }
  };

  const getYearMonthSales = async (year, month) => {
    try {
      const ym = {
        year,
        month,
      };
      const { data } = await axios.post('/reports/franchiser-sales/ym-bar', ym);
      setLabel(data.map((item) => (item?.franchisee.length > 8 ? item?.franchisee.slice(0, 8) + '...' : item?.franchisee)));
      setNewData(data.map((item) => item.total));
      setDetails(`Year : ${year} Month : ${monthName(month)} ($)`);
      alertSetter('success', 'Successfully fetched Year-Month sales');
    } catch (error) {
      alertSetter('error', 'Failed to fetch Year-Month sales');
    }
  };

  const yearlySales = async (year) => {
    try {
      const { data } = await axios.post(`/reports/franchiser-sales/year-bar`, {
        year,
      });
      setLabel(data.map((item) => (item?.franchisee.length > 8 ? item?.franchisee.slice(0, 8) + '...' : item?.franchisee)));
      setNewData(data.map((item) => item.total));
      setDetails(`Year : ${year} ($)`);
      alertSetter('success', 'Successfully fetched Yearly sales');
    } catch (error) {
      alertSetter('error', 'Failed to fetch Yearly sales');
    }
  };

  useEffect(() => {
    if (isEmpty(selected)) {
      getMTDSales();
    } else {
      return;
    }
    //eslint-disable-next-line
  }, []);

  const check = async () => {
    if (selected.year && selected.month === '') {
      await yearlySales(selected.year);
    } else if (selected.year && selected.month) {
      await getYearMonthSales(selected.year, selected.month);
    } else if (selected.year === '' && selected.month === '') {
      await getMTDSales();
    }
  };

  useEffect(() => {
    check();
    // eslint-disable-next-line
  }, [selected]);

  const dataBar = {
    labels: label,
    datasets: [
      {
        label: details,
        backgroundColor: chartColors,
        borderColor: chartColors,
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: newData,
        options: {
          scales: {
              y: {
                  ticks: {
                      // Include a dollar sign in the ticks
                      callback: function(value, index, ticks) {
                        return `$`+ this.getLabelForValue(value) ;
                      }
                  }
              }
          }
      }
      },
    ],
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Typography variant='h4'>Revenue By Locations ($)</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Bar data={dataBar} width={100} height={50} />
      </Grid>
      <Grid item md={12}>
        {alert.showAlert ? (
          <AlertInlineMain setAlert={setAlert} alert={alert} />
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  );
}
