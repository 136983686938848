import React from "react";
import { Pie } from "react-chartjs-2";
import { chartColors } from "./colors";
import ChartDataLabels from 'chartjs-plugin-datalabels';
// Pie.register(ChartDataLabels);

export default function PieChart({ filteredData }) {
  const pieOptions = {
    legend: {
      display: false,
      position: "right",
      legendCallback: function (chart) {
        // Return the HTML string here.
        return [
          <ul>
            <li>z</li>
            <li>zzzz</li>
            <li>ppp</li>
            <li>adasda</li>
          </ul>,
        ];
      },
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const dataArr = ctx.chart.data.datasets[0].data;
          const sum = dataArr.reduce((a, b) => a + b, 0);
          const percentage = (value * 100 / sum).toFixed(2) + "%";
          return percentage;
        },
        color: '#fff',
      }
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  const pieData = {
    maintainAspectRatio: false,
    responsive: false,
    labels: ["Wash/Dry/Fold", "Dry Cleaning", "Tailoring"],
    datasets: [
      {
        data: filteredData,
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors,
      },
    ],
  };

  return <Pie data={pieData} plugins={[ChartDataLabels]} options={pieOptions} />;
}
