import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formattedDateInOrderDetails, formattedTimeInOrderDetails } from 'utils/commonFunctions';

export default function AlertDialog({ setOpenDetailsView, openDetailsView, rowDetails, popUpClose }) {
    const handleClose = () => { setOpenDetailsView(false); };
    const [rows, setOpenMsetRow] = useState([]);
    useEffect(() => {
        setChartData(rowDetails)
        // eslint-disable-next-line
    }, [openDetailsView]);

    const loadStatus = (name) => {
        switch (name) {
            case 'placingOrder':
                return setOpenMsetRow([
                    {
                        status: "Place order", date: rowDetails?.orderDetail?.createdAt, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.createdAt, colorCode: "#089C79"
                    },
                ]);
            case 'setToPickup':
                return setOpenMsetRow([
                    {
                        status: "Place order", date: rowDetails?.orderDetail?.createdAt, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.createdAt, colorCode: "#089C79"
                    },
                    {
                        status: "Set to pickUp", date: rowDetails?.orderDetail?.setToPickupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.setToPickupTime, colorCode: "#0096FF"
                    },
                ]);
            case 'pickupCancel':
                return setOpenMsetRow([
                    {
                        status: "Place order", date: rowDetails?.orderDetail?.createdAt, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.createdAt, colorCode: "#089C79"
                    },
                    {
                        status: "Set to pickUp", date: rowDetails?.orderDetail?.setToPickupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.setToPickupTime, colorCode: "#0096FF"
                    },
                    {
                        status: "Pickup Cancelled", date: rowDetails?.orderDetail?.pickupCancelTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.pickupCancelTime, colorCode: "#EE4B2B"
                    }
                ]);
            case 'pickup':
                return setOpenMsetRow([
                    {
                        status: "Place order", date: rowDetails?.orderDetail?.createdAt, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.createdAt, colorCode: "#089C79"
                    },
                    {
                        status: "Set to pickUp", date: rowDetails?.orderDetail?.setToPickupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.setToPickupTime, colorCode: "#0096FF"
                    },
                    {
                        status: "Picked up", date: rowDetails?.orderDetail?.pickedupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.pickedupTime, colorCode: "#DA70D6"
                    },
                ]);
            case 'processing':
                return setOpenMsetRow([
                    {
                        status: "Place order", date: rowDetails?.orderDetail?.createdAt, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.createdAt, colorCode: "#089C79"
                    },
                    {
                        status: "Set to pickUp", date: rowDetails?.orderDetail?.setToPickupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.setToPickupTime, colorCode: "#0096FF"
                    },
                    {
                        status: "Picked up", date: rowDetails?.orderDetail?.pickedupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.pickedupTime, colorCode: "#DA70D6"
                    },
                    {
                        status: "Processing", date: rowDetails?.orderDetail?.processingTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.processingTime, colorCode: "#808080"
                    }
                ]);
            case 'setToDeliver':
                return setOpenMsetRow([
                    {
                        status: "Place order", date: rowDetails?.orderDetail?.createdAt, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.createdAt, colorCode: "#089C79"
                    },
                    {
                        status: "Set to pickUp", date: rowDetails?.orderDetail?.setToPickupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.setToPickupTime, colorCode: "#0096FF"
                    },
                    {
                        status: "Picked up", date: rowDetails?.orderDetail?.pickedupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.pickedupTime, colorCode: "#DA70D6"
                    },
                    {
                        status: "Processing", date: rowDetails?.orderDetail?.processingTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.processingTime, colorCode: "#808080"
                    },
                    {
                        status: "Set to delivery", date: rowDetails?.orderDetail?.setToDeliverTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.setToDeliverTime, colorCode: '#228B22'
                    },
                ]);
            case 'deliveryCancel':
                return setOpenMsetRow([
                    {
                        status: "Place order", date: rowDetails?.orderDetail?.createdAt, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.createdAt, colorCode: "#089C79"
                    },
                    {
                        status: "Set to pickUp", date: rowDetails?.orderDetail?.setToPickupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.setToPickupTime, colorCode: "#0096FF"
                    },
                    {
                        status: "Picked up", date: rowDetails?.orderDetail?.pickedupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.pickedupTime, colorCode: "#DA70D6"
                    },
                    {
                        status: "Processing", date: rowDetails?.orderDetail?.processingTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.processingTime, colorCode: "#808080"
                    },
                    {
                        status: "Set to delivery", date: rowDetails?.orderDetail?.setToDeliverTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.setToDeliverTime, colorCode: '#228B22'
                    },
                    {
                        status: "Delivery Cancelled", date: rowDetails?.orderDetail?.deliveryCancelTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.deliveryCancelTime, colorCode: "#EE4B2B"
                    }
                ]);
            case 'completed':
                return setOpenMsetRow([
                    {
                        status: "Place order", date: rowDetails?.orderDetail?.createdAt, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.createdAt, colorCode: "#089C79"
                    },
                    {
                        status: "Set to pickUp", date: rowDetails?.orderDetail?.setToPickupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.setToPickupTime, colorCode: "#0096FF"
                    },
                    {
                        status: "Picked up", date: rowDetails?.orderDetail?.pickedupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.pickedupTime, colorCode: "#DA70D6"
                    },
                    {
                        status: "Processing", date: rowDetails?.orderDetail?.processingTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.processingTime, colorCode: "#808080"
                    },
                    {
                        status: "Set to delivery", date: rowDetails?.orderDetail?.setToDeliverTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.setToDeliverTime, colorCode: '#228B22'
                    },
                    {
                        status: "Delivered", date: rowDetails?.orderDetail?.deliveredTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.deliveredTime, colorCode: "#FFC000"
                    }
                ]);
            case 'specialSetToDeliver':
                return setOpenMsetRow([
                    {
                        status: "Place order", date: rowDetails?.orderDetail?.createdAt, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.createdAt, colorCode: "#089C79"
                    },
                    {
                        status: "Set to pickUp", date: rowDetails?.orderDetail?.setToPickupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.setToPickupTime, colorCode: "#0096FF"
                    },
                    {
                        status: "Picked up", date: rowDetails?.orderDetail?.pickedupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.pickedupTime, colorCode: "#DA70D6"
                    },
                    {
                        status: "Processing", date: rowDetails?.orderDetail?.processingTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.processingTime, colorCode: "#808080"
                    },
                    {
                        status: "Special set to deliver", date: rowDetails?.orderDetail?.specialSetToDeliverTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.specialSetToDeliverTime, colorCode: "#228B22"
                    },
                ]);
            case 'specialDeliveryCancel':
                return setOpenMsetRow([
                    {
                        status: "Place order", date: rowDetails?.orderDetail?.createdAt, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.createdAt, colorCode: "#089C79"
                    },
                    {
                        status: "Set to pickUp", date: rowDetails?.orderDetail?.setToPickupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.setToPickupTime, colorCode: "#0096FF"
                    },
                    {
                        status: "Picked up", date: rowDetails?.orderDetail?.pickedupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.pickedupTime, colorCode: "#DA70D6"
                    },
                    {
                        status: "Processing", date: rowDetails?.orderDetail?.processingTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.processingTime, colorCode: "#808080"
                    },
                    {
                        status: "Special set to deliver", date: rowDetails?.orderDetail?.specialSetToDeliverTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.specialSetToDeliverTime, colorCode: "#228B22"
                    },
                    {
                        status: "Special delivery cancelled", date: rowDetails?.orderDetail?.specialDeliveryCancelTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.specialDeliveryCancelTime, colorCode: "#EE4B2B"
                    }
                ]);
            case 'specialCompleted':
                return setOpenMsetRow([
                    {
                        status: "Place order", date: rowDetails?.orderDetail?.createdAt, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.createdAt, colorCode: "#089C79"
                    },
                    {
                        status: "Set to pickUp", date: rowDetails?.orderDetail?.setToPickupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.setToPickupTime, colorCode: "#0096FF"
                    },
                    {
                        status: "Picked up", date: rowDetails?.orderDetail?.pickedupTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.pickedupTime, colorCode: "#DA70D6"
                    },
                    {
                        status: "Processing", date: rowDetails?.orderDetail?.processingTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.processingTime, colorCode: "#808080"
                    },
                    {
                        status: "Special set to deliver", date: rowDetails?.orderDetail?.specialSetToDeliverTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.specialSetToDeliverTime, colorCode: "#228B22"
                    },
                    {
                        status: "Special delivered", date: rowDetails?.orderDetail?.specialDeliveryTime, driver: rowDetails?.driverDeatils?.name, customer: rowDetails?.customerDetail?.firstName + ' ' + rowDetails?.customerDetail?.lastName, time: rowDetails?.orderDetail?.specialDeliveryTime, colorCode: "#FFC000"
                    }
                ]);
            default:
                return '-';
        }
    }

    const setChartData = () => {
        rowDetails?.type === "COLLECTION" ? loadStatus(rowDetails?.orderDetail?.statusTracker) : rowDetails?.orderDetail?.specialCareTracker !== null &&
            rowDetails?.orderDetail?.specialCareTracker !== undefined ? loadStatus(rowDetails?.orderDetail?.specialCareTracker) : loadStatus(rowDetails?.orderDetail?.statusTracker)
    }
    return (
        <div>
        <div style={{ paddingBottom: "1rem" }}>
            <h1>Order Id: {rowDetails?.orderDetail?.orderId}</h1>
            <h3>Address:{" "}{rowDetails?.address}</h3>
            <h3>Customer Name:{" "}{rowDetails?.customerDetail?.firstName} {rowDetails?.customerDetail?.lastName}</h3>
            <h3>Phone:{" "}{rowDetails?.phoneNumber}</h3>

            {openDetailsView ? (<IconButton aria-label='close' onClick={handleClose} sx={{ position: 'absolute', right: 10, top: 8, color: 'white', }} > <CloseIcon /> </IconButton>) : null}
        </div>
        <TableContainer component={Paper}>

            <Table sx={{ overflow: "auto" }} size="small" aria-label="a dense table">
                <TableHead style={{ backgroundColor: "#E5E5E5", fontWeight: "500" }}>
                    <TableRow> <TableCell>Status / Reason</TableCell>
                        <TableCell align="center">Date</TableCell>
                        <TableCell align="center">Time</TableCell>
                        <TableCell align="center">Driver</TableCell>
                        <TableCell align="center">User</TableCell> </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="th" scope="row"> <Chip label={row.status} color="primary" style={{ borderRadius: "7px", backgroundColor: `${row.colorCode}` }} /> </TableCell>
                            <TableCell align="center">{formattedDateInOrderDetails(row.date)}</TableCell>
                            <TableCell align="center">{formattedTimeInOrderDetails(row.time)}</TableCell>
                            <TableCell align="center">{row.driver}</TableCell>
                            <TableCell align="center">{row.customer}</TableCell>
                        </TableRow>))}
                </TableBody>
            </Table>
        </TableContainer>
        {/* <Grid style={{ marginTop: "2rem" }} container>
                        <BusinessCenterIcon style={{ marginRight: "0.2rem", color: "#696969" }} />
                        <Typography style={{ fontWeight: "500", fontSize: "20px", color: "#696969" }}>Job Details</Typography>
                    </Grid>
                    <Grid container style={{ marginTop: "1rem" }}> <Grid item md={12}> <hr style={{ border: '0.1px dashed #696969' }} /> </Grid> </Grid>
                    <Grid container style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                        <Grid item xs={12} md={10} container style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Grid item xs={12} md={3} sx={{ display: "flex", justifyContent: { md: "flex-end", xs: "center" }, alignItems: "center" }}>
                                <Typography style={{ fontWeight: "500", fontSize: "17px" }}>Tracking Status</Typography> </Grid> <Grid item xs={12} md={1}> </Grid>
                            <Grid item xs={12} md={8} sx={{ display: "flex", justifyContent: { md: "flex-start", xs: "center" }, alignItems: "center" }}>
                                < Chip label={rows[rows?.length - 1]?.status} color="primary" style={{ borderRadius: "7px", backgroundColor: `${rows[rows?.length - 1]?.colorCode}` }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                        <Grid item xs={12} md={10} container style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Grid item xs={12} md={3} sx={{ display: "flex", justifyContent: { md: "flex-end", xs: "center" }, alignItems: "center" }}>
                                <Typography style={{ fontWeight: "500", fontSize: "17px" }}>Order ID</Typography> </Grid> <Grid item xs={12} md={1}> </Grid>
                            <Grid item xs={12} md={8} sx={{ display: "flex", justifyContent: { md: "flex-start", xs: "center" }, alignItems: "center" }}>
                                <Typography style={{ fontWeight: "500", fontSize: "17px" }}>{rowDetails?.orderDetail?.orderId}</Typography> </Grid> </Grid> </Grid>
                    {rows[rows?.length - 1]?.time &&
                        <Grid container style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                            <Grid item xs={12} md={10} container style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Grid item xs={12} md={3} sx={{ display: "flex", justifyContent: { md: "flex-end", xs: "center" }, alignItems: "center" }}>
                                    <Typography style={{ fontWeight: "500", fontSize: "17px" }}>Time</Typography> </Grid> <Grid item xs={12} md={1}> </Grid>
                                <Grid item xs={12} md={8} sx={{ display: "flex", justifyContent: { md: "flex-start", xs: "center" }, alignItems: "center" }}>
                                    <TextField hiddenLabel disabled id="outlined-basic" defaultValue={rows[rows?.length - 1]?.time} variant="outlined"
                                        size="small" InputProps={{ endAdornment: <AccessTimeIcon /> }} /> </Grid> </Grid> </Grid> }
                    {rowDetails?.notes.split(",")[0] &&
                        <Grid container style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                            <Grid item xs={12} md={10} container style={{ display: "flex", justifyContent: "center" }}>
                                <Grid item xs={12} md={3} sx={{ display: "flex", justifyContent: { md: "flex-end", xs: "center" } }}>
                                    <Typography style={{ fontWeight: "500", fontSize: "17px" }}>Note</Typography> </Grid> <Grid item xs={12} md={1}> </Grid>
                                <Grid item xs={12} md={8}> <TextField hiddenLabel disabled id="outlined-basic" defaultValue={rowDetails?.notes.split(",")[0].toString()}
                                        multiline rows={4} variant="outlined" size="medium" maxRows={4} fullWidth /> </Grid> </Grid> </Grid>}
                    {rowDetails?.notes.split(",")[1] &&
                        <Grid container style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                            <Grid item xs={12} md={10} container style={{ display: "flex", justifyContent: "center" }}>
                                <Grid item xs={12} md={3} sx={{ display: "flex", justifyContent: { md: "flex-end", xs: "center" } }}>
                                    <Typography style={{ fontWeight: "500", fontSize: "17px" }}>Cancellation Note</Typography> </Grid> <Grid item xs={12} md={1}> </Grid>
                                <Grid item xs={12} md={8}> <TextField hiddenLabel disabled id="outlined-basic" defaultValue={rowDetails?.notes.split(",")[1].toString()}
                                        multiline rows={4} variant="outlined" size="medium" maxRows={4} fullWidth /> </Grid> </Grid> </Grid>}
                    {rowDetails?.pod?.length > 0 ? <Grid container style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                            <Grid item xs={12} md={10} container style={{ display: "flex", justifyContent: "center" }}>
                                <Grid item xs={12} md={3} sx={{ display: "flex", justifyContent: { md: "flex-end", xs: "center" } }}>
                                    <Typography style={{ fontWeight: "500", fontSize: "17px" }}>POD</Typography> </Grid> <Grid item xs={12} md={1}> </Grid>
                                <Grid item xs={12} md={8}> {rowDetails?.pod?.map((imageURL) => ( <img src={imageURL?.url} width="200" alt={"pod"} loading="lazy" /> ))} </Grid>
                            </Grid> </Grid> : null}
                    <Grid container style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                        <Grid item xs={12} md={10} container style={{ display: "flex", justifyContent: "center" }}>
                            <Grid item xs={12} md={3} sx={{ display: "flex", justifyContent: { md: "flex-end", xs: "center" } }}>
                                <Typography style={{ fontWeight: "500", fontSize: "17px" }}>POD Address</Typography> </Grid> <Grid item xs={12} md={1}> </Grid>
                            <Grid item xs={12} md={8}>
                                <TextField hiddenLabel disabled id="outlined-basic" defaultValue={rowDetails?.orderDetail?.orderAddress} variant="outlined" size="small" fullWidth
                                    InputProps={{ endAdornment: <LocationOnIcon /> }} />
                            </Grid>  </Grid>  </Grid> */}
    </div>);
}
