import * as React from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import { blue } from "@mui/material/colors";

function SimpleDialog(props) {
  const { onClose, franchiseeList, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>Franchisee List</DialogTitle>
      <List sx={{ pt: 0 }}>
        {franchiseeList.map((franchisee) => (
          <ListItem button key={franchisee}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={franchisee} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function SimpleDialogDemo({
  setOpenDialog,
  openDialog,
  franchiseeList,
}) {
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <SimpleDialog
        open={openDialog}
        onClose={handleClose}
        franchiseeList={franchiseeList}
      />
    </div>
  );
}
