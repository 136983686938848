import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent,Typography,CardHeader} from "@mui/material";
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import DataGridTable from "components/layouts/DataGrid";
import AlertInlineMain from "components/common/AlertInlineMain";
export default function RoyaltySchems() {
  const axios = useAxiosPrivate()
  const [status, setStatus] = useState(false);
  const [newData, setNewData] = useState([]);
const [totalPayable, setTotalPayable] = useState(0);
const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });

  // number with comma
const numberWithCommas = (x) => { return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); };

  useEffect(() => {
    loadData();
    setStatus(!status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    { field: "franchiseeID", headerName: "franchisee ID", flex: 0.1, minWidth: 100,},
    { field: "franchiseeName", headerName: "Franchisee Business Name", flex: 1, minWidth: 220,},
    { field: "lastPayment", headerName: "Last Payment Date", flex: 0.4, minWidth: 150,},
    { field: "paymentAge", headerName: "Days since last payment", flex: 0.3, minWidth: 100,},
    { field: "balance", headerName: "Balance", flex: 0.3, minWidth: 100 },
    { field: "precentage", headerName: "Precentage", flex: 0.3, minWidth: 100 },
  ];

  const calculetotalPayable = (data) => {
    let total = 0;
    //eslint-disable-next-line
    data.map((item) => {total += item.balance; });
    setTotalPayable(total);
    }

  const loadData = async () => {
    try {
      const { data } = await axios.get(`/royalty/payment-report`);
      calculetotalPayable(data);
      setNewData(data);
      setAlert({ showAlert: true, severity: "success", message: "Data loaded successfully", });
    } catch (err) {
      setAlert({ showAlert: true, severity: "error", message: "Request Failed!", });
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Card>
            <CardHeader  />
            <CardContent>
              <Typography sx={{ fontSize: 25, fontWeight: "bold", color: "text.primary" }} gutterBottom >
                {`Total Royalty Balance :  $${numberWithCommas(totalPayable)}`}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item style={{ height: 350, width: "100%" }} xs={12} md={12}>
          <DataGridTable fetchDataUrl="/royalty/payment-report" columns={columns} status={status} newData={newData}
            title="Balance Break down" />
          {"Perecentage = (balance for this fanchisee / total balance) * 100%"}
          {alert.showAlert && ( <Grid item md={12} m={2}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid> )}
        </Grid>
      </Grid>
    </>
  );
}
