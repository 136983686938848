import React, { useState, useEffect } from "react";
import { Grid, Typography, Card, CardContent, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import SelectDriver from "./select-driver";
import CircularProgress from "@mui/material/CircularProgress";
import DialogAlertMain from "components/common/DialogAlertMain";

export default function CreateVehicle() {
  const axios = useAxiosPrivate()
  const [backendCall, setBackendCall] = useState(false);
  const [users, setUsers] = useState([]);
  const [displayVehicle, setDisplayVehicle] = useState([]);
  const [displayNoVehicleMsg, setdisplayNoVehicleMsg] = useState(false);
  const filterDisplaVeh = displayVehicle?.filter(item => item.availability === "available")
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [initialValues, setInitialValues] = useState({
    assignment: [],
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      setBackendCall(true);
      await axios.post("/assignments", values?.assignment);
      setAlert({ showAlert: true, severity: "success", message: "Assignment Success!", });
    } catch (e) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Failed!",
      });
    } finally {
      setBackendCall(false);
    }
  };

  const validationSchema = Yup.object().shape({
    assignment: Yup.array()
      .of( Yup.object().shape({ driverId: Yup.string(), vehicleId: Yup.string(), }) )
      .min(1, "Driver assignment required"),
  });

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, []);


  const getData = async () => {
    try {
      const [{ data }, drivers] = await Promise.all([ axios.get("/vehicle"), axios.get("/driver") ]);
      setDisplayVehicle(data.map(({ name, _id, routeSettings }) => ({ name, _id, availability: routeSettings.availability })));
      setUsers(drivers.data.map(({ name, _id }) => ({ name, _id })))
      const assignment = data.filter(veh => veh?.driver && veh?.routeSettings.availability === "available").map(veh => ({
        driverId: veh?.driver?._id,
        vehicleId: veh?._id
      }))

      const dataValues = { assignment }
      setInitialValues(dataValues)
    } catch (error) {
      setAlert({ showAlert: true, severity: "error", message: "Failed!", });
    }
  }

  useEffect(() => {
    setTimeout(() => { setdisplayNoVehicleMsg(true) }, 3000);
  }, [])


  return (
    <>
      <Grid container pl={2} pr={2} pt={7} sx={{ backgroundColor: "#E4E4E4", borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem" }}>
        <Grid container item lg={12} md={12} sm={12} xs={12} alignItems="center" justifyContent="center" >
          <Stack direction="row" mt={2} alignItems="center" justifyContent="center">
           <Typography size="small"
              sx={{ color: "#1976D2", fontSize: "1.034rem", textTransform: "capitalize", backgroundColor: "white",
                fontWeight: "500", padding: "0.32rem", borderTopLeftRadius:"0.3rem", borderTopRightRadius:"0.3rem", }}>
              Vehicle Assignment </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Card style={{ marginTop: "1rem" }}>
        <CardContent>
          {displayVehicle?.length < 1 &&
            <Grid container>
              <Grid container item justifyContent="center" alignItems="center" lg={12} md={12} sm={12} xs={12} marginTop={5} >
                <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"} > {!displayNoVehicleMsg && <CircularProgress />} </Grid>
                <Grid item xs={12} md={12} mt={2}>
                  {displayNoVehicleMsg ? (<Typography sx={{ fontSize: "1.5rem", textAlign: "center" }}>
                    Need to create vehicles first!</Typography>) : ( <Typography sx={{ fontSize: "1.3rem", textAlign: "center" }}>
                    Please Wait <br></br> Checking whether vehicles are available...! </Typography>)}
                  {displayNoVehicleMsg && <Typography sx={{ fontSize: "1rem", textAlign: "center" }}> (Create vehicles in the vehicle section) </Typography>}
                </Grid>
              </Grid>
            </Grid>}
          {!displayVehicle?.length < 1 && displayVehicle?.filter(item => item.availability === "available") < 1 &&
            <Grid container>
              <Grid container item xs={12} md={12} pt={2}>
                <Grid item xs={12} md={12} mt={2}>
                  <Typography sx={{ fontSize: "1.5rem", textAlign: "center" }}> Vehicles not available! </Typography>
                  <Typography sx={{ fontSize: "1rem", textAlign: "center", width: "100%" }}>
                    (Change the vehicle availability to "Available" in the vehicle section) </Typography>
                </Grid>
              </Grid>
            </Grid>}
          <Grid container justifyContent={"flex-start"} alignContent="center" pt={2}>
            <Grid item container xs={12} md={12}>
              <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
                {({ values, setFieldValue, }) => {
                  return (
                    <Form>
                      <Grid container item xs={12} md={12} spacing={2}>
                        {values.assignment && displayVehicle?.filter(item => item.availability === "available")?.map((vehicle) => {
                          return ( <SelectDriver users={users} vehicle={vehicle} setFieldValue={setFieldValue} key={vehicle._id}
                              drivers={users} values={values} getData={getData} /> ); })}
                      </Grid>
                      {!(displayVehicle?.filter(item => item.availability === "available") < 1) &&
                        <Grid item xs={12} md={12} mt={2}>
                          <LoadingButton loading={backendCall} type="submit" variant="contained" size="small"
                            disabled={ values?.assignment === initialValues?.assignment ||
                              filterDisplaVeh?.length !== values?.assignment?.length ||
                              users?.length < 1 || displayVehicle?.filter(item => item.availability === "available")?.length < 1}>
                            save </LoadingButton>
                        </Grid>}
                    </Form>
                  );
                }}
              </Formik>
              <DialogAlertMain alert={alert} setAlert={setAlert} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
