import React, { useMemo, useState } from "react";
import DataGrid from "components/layouts/DataGrid";
import MapModal from "components/jobs/MapModal";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Button } from "@mui/material";
import AlertMain from "components/common/AlertMain";
import DateSlashToDashReformat from 'components/common/Functions/DateSlashToDashReformat';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import IconButton from '@mui/material/IconButton';

export default function Main({ date }) {
  const axios = useAxiosPrivate()
  const [openMap, setOpenMap] = useState(false);
  const status = true;

  const handleClickOpen = () => {
    setOpenMap(true);
  };

  const [locationData, setLocationData] = useState([]);
  const [center, setCenter] = useState(null)
  const [dotsLineDataArray, setDotsLineDataArray] = useState(null)
  const [alertSnack, setAlertSnack] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const fomratedPayloadDate = useMemo(() => {
    const returnFormatedDate = DateSlashToDashReformat(date);
    return returnFormatedDate
  }, [date]);

  const setLocation = async (id) => {
    try {
      const { data } = await axios.get(`/job/locationsvisit/${id}/${fomratedPayloadDate}`);
      setLocationData(data);
      currentLocation(data)
      drawDotLineArrayMethod(data)
      handleClickOpen();
    } catch (error) {
      setAlertSnack({
        showAlert: true,
        severity: "error",
        message: "No Data Available! for this data!",
      })
    }
  };

  //download csv


  // const downloadCSV = (val) => {

  //   console.log(val)

  //   const data = [
  //     ['Location', 'Time', 'Lat', 'Lng', 'Speed (mi/h)', 'Mileage (mi)'],
  //     ['New York', '10:30 AM', '40.7128', '-74.0060', '60', '100'],
  //     ['Los Angeles', '1:45 PM', '34.0522', '-118.2437', '70', '200'],
  //     ['Chicago', '4:15 PM', '41.8781', '-87.6298', '50', '150'],
  //     ['Houston', '7:00 PM', '29.7604', '-95.3698', '55', '175'],
  //     ['Miami', '9:30 PM', '25.7617', '-80.1918', '65', '225']
  //   ];

  //   const csvContent = 'Location,Time,Lat,Lng,Speed (mi/h),Mileage (mi)\r\n' +
  //     data.map(row => row.join(',')).join('\r\n');
  //   const blob = new Blob([csvContent], { type: 'text/csv' });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement('a');
  //   link.setAttribute('href', url);
  //   link.setAttribute('download', 'data.csv');
  //   document.body.appendChild(link);
  //   link.click();
  //   URL.revokeObjectURL(url);
  // };


  const currentLocation = (data) => {
    if (data?.locations.length > 0) {
      const lastLocationArray = data?.locations[data?.locations?.length - 1]
      const currentLocationObj = lastLocationArray[lastLocationArray?.length - 1]
      setCenter({ ...currentLocationObj })
    } else {
      setCenter(null)
      return null
    }
  }

  const drawDotLineArrayMethod = (data) => {
    let lineArray = []
    let finalLineArray = []
    if (data?.locations.length > 0) {
      // eslint-disable-next-line
      data?.locations?.map((location, index) => {
        if (index % 2 === 0) {
          lineArray.push(location[location?.length - 1])
          finalLineArray.push(lineArray)
        } else {
          lineArray.push(location[0])
        }
      })
    } else {
      lineArray.push(null)
    }
    setDotsLineDataArray(finalLineArray)
  }

  const columns = [
    {
      field: "map",
      headerName: "Map",
      width: 120,
      disableExport: true,
      renderCell: (params) => {
        const onClick = () => {
          setLocation(params?.row?.vehicleDetail?.driver);
        };

        return <Button onClick={onClick}>Map</Button>;
      },
    },
    {
      field: "vehicleDetail",
      headerName: "Vehicle Name",
      width: 180,
      valueGetter: (params) => {
        return `${params?.row?.vehicleDetail?.name}`;
      },
    },

    {
      field: "infoRecieved",
      headerName: "Total Jobs",
      width: 150,
      valueGetter: (params) => {
        return `${params.row?.infoReceived}`;
      },
    },
    {
      field: "inProgress",
      headerName: "In Progress",
      width: 150,
      valueGetter: (params) => {
        return `${params.row?.inProgress}`;
      },
    },

    {
      field: "Completed",
      headerName: "completed",
      width: 150,
      valueGetter: (params) => {
        return `${params.row?.completed}`;
      },
    },
    {
      field: "failed",
      headerName: "Failed",
      width: 150,
      valueGetter: (params) => {
        return `${params.row.failed}`;
      },
    },
    {
      field: "distance",
      headerName: "Miles Driven",
      width: 150,
    },

    {
      field: "route",
      headerName: "Route",
      width: 80,
      renderCell: (params) => {
        const onClickAction = () => {
          excelDownload(params?.row?.vehicleDetail?._id);
        };
        return (
          <IconButton onClick={onClickAction} size="small" color="primary" component="label" sx={{
            backgroundColor: "#1976D2", borderRadius: "0.5rem",
            "&:hover": {
              backgroundColor: "#479bed",
            }
          }}>
            <DownloadForOfflineIcon fontSize="large" sx={{ color: "white" }} />
          </IconButton>)
      },
    },
  ];

  const excelDownload = async (id) => {
    try {
      const { data } = await axios.post(`/vehicle/generate-excel/${id}`, { date: fomratedPayloadDate }, { responseType: 'blob' });
  
      // Create a Blob object containing the response data
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);
  
      const a = document.createElement('a');
      a.href = url;
      a.download = `vehicle_locations_${id}.xlsx`; // You can customize the filename here
  
      a.click();
  
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }
  };
  

  return (
    <div>
      <DataGrid
        autoHeight
        columns={columns}
        fetchDataUrl={`/job/vehicles-franchisee/${fomratedPayloadDate}`}
        status={status}
        title={"Vehicle details"}
      />
      <MapModal open={openMap} setOpen={setOpenMap} locationData={locationData} center={center} dotsLineDataArray={dotsLineDataArray} />
      <AlertMain setAlert={setAlertSnack} alert={alertSnack} />
    </div >
  );
}
