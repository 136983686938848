import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";

import DataGrid from '../../../components/layouts/DataGridForDeliveryReport';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

export default function Delivery() {
  let navigate = useNavigate();

  const onGoback = () => {
    navigate('/franchisee-reports')
  }
  const columns = [
    { field: "driver", headerName: "Driver", minWidth: 150 },
    { field: "date", headerName: "Date", minWidth: 480, },
    { field: "distance", headerName: "Distance (miles)", minWidth: 250 },
  ];

  return (
    <Grid container rowSpacing={2}
      alignItems="center"
      justifyContent="center"
    >

      <Grid container item
        lg={11}
        md={11}
        sm={11}
        xs={11}
        alignItems="center"
        justifyContent="center"
      >
        <Grid container justifyContent="space-between">
          <Grid item >
            <IconButton onClick={() => onGoback()}>
              <ArrowBackIcon sx={{ color: "#343434", }} />
            </IconButton>
          </Grid>
          <Grid item >
            <div style={{ width: '10px' }}></div>
          </Grid>
        </Grid>
        <Grid item
          mb={5}
          lg={12}
          md={12}
          sm={12}
          xs={12}>
          <DataGrid
            autoHeight
            columns={columns}
            fetchDataUrl={`/job/totaldistanceperday/reports`}
            // status={status}
            title="Delivery Report"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
