import React, { useState, useEffect } from "react";
// import Button from "@mui/material/Button";

import * as Yup from "yup";
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import { Grid } from "@mui/material";
import AlertInlineMain from "components/common/AlertInlineMain";
import { Form, Formik, Field } from "formik";
import { TextField as TF } from "formik-material-ui";
import { Typography } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import { set } from "date-fns";

export default function AlertDialog({ setConfirm, setConfirmDays, submitConfigData }) {
  const axios = useAxiosPrivate()
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [backendCall, setBackendCall] = useState(false);
  const [buttonEnable, setButtonEnable] = useState(false);

  const [initialValues, setInitialValues] = useState({
    washDryDays: 1,
    specialCareDays: 5,
  });

  const [finalValues, setFinalValues] = useState({
    washDryDays: 1,
    specialCareDays: 5,
  });

  const getData = async () => {
    try {
      const { data } = await axios.get("/salesDays");

      if (data) {
        setInitialValues(data);

        if (data?.washDryDays && data?.specialCareDays) {

          setFinalValues({
            washDryDays: data?.washDryDays,
            specialCareDays: data?.specialCareDays,
          })

          if (setConfirmDays) {
            setConfirmDays(false);
          }
        } else {
          setInitialValues({
            washDryDays: 1,
            specialCareDays: 5,
          });
          setFinalValues({
            washDryDays: 1,
            specialCareDays: 5,
          })
        } 
      } else {await axios.post("/salesDays", initialValues)}
    } catch (e) { }
  };

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, []);

  const onSubmit = async (e) => {
    setBackendCall(true);
    try {
      const data = {
        washDryDays: e.washDryDays,
        specialCareDays: e.specialCareDays,
      };
      await axios.post("/salesDays", data);

      if (setConfirmDays) {
        setConfirmDays(false);
      }
      setButtonEnable(false);
      setBackendCall(false); 
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Successfully Updated!",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "Create failed!",
        });
      }, 3000);
    } catch (error) {
    setBackendCall(true); 
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Create failed!",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "Create failed!",
        });
      }, 3000);
    }
  };

  useEffect(() => {
    if(submitConfigData){
      onSubmit({
        washDryDays: finalValues.washDryDays,
        specialCareDays: finalValues.specialCareDays
      });
    }
  },[submitConfigData])

  const validationSchema = Yup.object().shape({
    washDryDays: Yup.number()
      .required("required")
      .required("required")
      .test(
        "Is positive?",
        "The wash/dry/fold days must be greater than 0!",
        (value) => value > 0
      ),
    specialCareDays: Yup.number()
      .required("required")
      .test(
        "Is positive?",
        "The special care days must be greater than 0!",
        (value) => value > 0)
     .when("washDryDays", (washDryDays, schema) => washDryDays && schema.min(washDryDays, "Special care value must be larger than or equal to wash/dry/fold Days")),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, isValid, dirty, handleChange }) => {
          const isSameValues = values.washDryDays === initialValues.washDryDays && values.specialCareDays === initialValues.specialCareDays;
          const handleFieldChange = (e) => {

            handleChange(e);
            setButtonEnable(true);
            setConfirmDays(true);
            
            const name = e?.target?.name;
            const value = parseInt(e?.target?.value);
          
            if (name === "washDryDays" || name === "specialCareDays") {
              setFinalValues((prevValues) => ({
                ...prevValues,
                [name]: value,
              }));
            }
          };
          return (
            <Form noValidate autoComplete="off">
              <Grid container
                columnSpacing={2}
                rowSpacing={2}
                mt={3}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Grid item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12} >
                  <Typography variant="h6" gutterBottom>
                    Service Days
                  </Typography>
                </Grid>

                <Grid item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <Field
                    name="washDryDays"
                    label="Wash/Dry/Fold"
                    component={TF}
                    type="number"
                    variant="outlined"
                    fullWidth
                    required
                    data-testid="wash_dry_days"
                    onChange={handleFieldChange}
                  ></Field>
                </Grid>
                <Grid item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12} >
                  <Field
                    name="specialCareDays"
                    label="Special Care"
                    component={TF}
                    type="number"
                    variant="outlined"
                    fullWidth
                    required
                    data-testid="special_care_days"
                    onChange={handleFieldChange}
                  ></Field>
                </Grid>
              </Grid>

              <Grid item
                lg={"auto"}
                md={"auto"}
                sm={"auto"}
                xs={12}
                mt={1}> 
                <LoadingButton
                  loading={backendCall}
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="small"
                  data-testid="save_service_days"
                  disabled={!buttonEnable} 
                  >
                    save
                </LoadingButton>

              </Grid>

              {alert.showAlert && (
                <Grid item md={12} m={2}>
                  <AlertInlineMain setAlert={setAlert} alert={alert} />
                </Grid>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
}