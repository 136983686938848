import React from "react";
import { Grid, Chip } from "@mui/material/";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch } from "react-redux";
import { setProgressDataGridFilter } from "../../store/actions/RouteAction";

export default function ProgressBar({ circularBarData, filterDataObj}) {
  const dispatch = useDispatch();
  
  return (
    <Grid container>
      <Grid
        container item
        lg={12}
        md={12}
        sm={12}
        xs={12}

        alignItems="center"
        justifyContent="center"

        style={{
          marginTop: "1rem",
        }}
        columnSpacing={2}
      >
        <Grid item
          lg={1}
          md={1.5}
          sm={1.8}
          xs={2.5} >
          <CircularProgressbar value={circularBarData?.statusInPercentage?.inprogressPercentage > 0 || circularBarData?.statusInPercentage?.completedPercentage > 0 || circularBarData?.statusInPercentage?.failedPercentage ? 100 : 0} text={circularBarData?.statusInPercentage?.inprogressPercentage > 0 || circularBarData?.statusInPercentage?.completedPercentage > 0 || circularBarData?.statusInPercentage?.failedPercentage > 0 ? `${100.0}%` : `${0}%`} styles={{
            path: {
              transform: "rotate(180deg)",
              transformOrigin: "center center",
              strokeLinecap: "butt",
              stroke: "#808080"
            },
            text: {
              fontSize: 22,
              fontWeight: 800,
              animation: "fadein 2s",
              fill: "#808080"
            }
          }} />
          <Grid item style={{ display: "flex", justifyContent: "center", marginTop: "0.5rem" }}>
            <Chip
              label='Total Jobs'
              color="primary"
              onClick={()=>dispatch(setProgressDataGridFilter(""))}
              style={{ borderRadius: "5px", backgroundColor: '#808080' }}
            />
          </Grid>
        </Grid>
        <Grid item
          lg={1}
          md={1.5}
          sm={1.8}
          xs={2.5} >
          <CircularProgressbar value={circularBarData?.statusInPercentage?.inprogressPercentage} text={`${circularBarData?.statusInPercentage?.inprogressPercentage ? circularBarData?.statusInPercentage?.inprogressPercentage.toFixed(1) : 0}%`} styles={{
            path: {
              transform: "rotate(180deg)",
              transformOrigin: "center center",
              strokeLinecap: "butt",
              stroke: 73 >= 70 ? "#0096FF" : "blue"
            },
            text: {
              fontSize: 22,
              fontWeight: 800,
              animation: "fadein 2s",
              fill: 73 >= 70 ? "#0096FF" : "blue"
            }
          }} />
          <Grid item style={{ display: "flex", justifyContent: "center", marginTop: "0.5rem" }}>
            <Chip
              label='In Progress'
              color="primary"
              onClick={()=>dispatch(setProgressDataGridFilter(filterDataObj?.inProgress))}
              style={{ borderRadius: "5px", backgroundColor: '#0096FF', }}
            />
          </Grid>
        </Grid>
        <Grid item
          lg={1}
          md={1.5}
          sm={1.8}
          xs={2.5} >
          <CircularProgressbar value={circularBarData?.statusInPercentage?.completedPercentage} text={`${circularBarData?.statusInPercentage?.completedPercentage ? circularBarData?.statusInPercentage?.completedPercentage.toFixed(1) : 0}%`} styles={{
            path: {
              transform: "rotate(180deg)",
              transformOrigin: "center center",
              strokeLinecap: "butt",
              stroke: "#50C878"
            },
            text: {
              fontSize: 22,
              fontWeight: 800,
              animation: "fadein 2s",
              fill: "#50C878"
            }
          }} />
          <Grid item style={{ display: "flex", justifyContent: "center", marginTop: "0.5rem" }}>
            <Chip
              label='Completed'
              color="primary"
              onClick={()=>dispatch(setProgressDataGridFilter(filterDataObj?.completed))}
              style={{ borderRadius: "5px", backgroundColor: '#50C878', }}
            />
          </Grid>
        </Grid>
        <Grid item
          lg={1}
          md={1.5}
          sm={1.8}
          xs={2.5} >
          <CircularProgressbar value={circularBarData?.statusInPercentage?.failedPercentage} text={`${circularBarData?.statusInPercentage?.failedPercentage ? circularBarData?.statusInPercentage?.failedPercentage.toFixed(1) : 0}%`} styles={{
            path: {
              transform: "rotate(180deg)",
              transformOrigin: "center center",
              strokeLinecap: "butt",
              stroke: "#C41E3A"
            },
            text: {
              fontSize: 22,
              fontWeight: 800,
              animation: "fadein 2s",
              fill: "#C41E3A"
            }
          }} />
          <Grid item style={{ display: "flex", justifyContent: "center", marginTop: "0.5rem" }}>
            <Chip
              onClick={()=>dispatch(setProgressDataGridFilter(filterDataObj?.fail))}
              label='Fail'
              color="primary"
              style={{ borderRadius: "5px", backgroundColor: '#C41E3A', padding: "0 20px 0 20px" }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
