
const sortServiceNameArray = (array) => {

    if(array){
      const hasSpecialCare = array.includes('Dry Cleaning') || array.includes('Tailoring & Alterations');
      const hasWashDryFold = array.includes('Wash/ Dry/ Fold');
  
      let sortedArray = [];
  
      if (hasWashDryFold) {
          sortedArray.push('Wash/ Dry/ Fold');
      }
  
      if (hasSpecialCare) {
          sortedArray.push('Special Care');
      }
  
       return sortedArray;
    }else{
      return [];
    }
  
    }

    export default sortServiceNameArray;