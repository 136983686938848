import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useSelector, useDispatch} from "react-redux";
import DepotMain from "./DepotMain";
import VehicleMain from "./VehiclesMain";
import DriverMain from "./DriverMain";
// import AssignmentMain from "./VehicleAssignment";
import {
  setOpen,
  setDriver,
  // removeOneDriver,
  setVehicle
} from "../../store/actions/RouteAction";


export default function DetailMain() {
  const pageNo = useSelector((state) => state.route.depoPage);
  let dispatch = useDispatch();
  
  useEffect(() => {
    if (pageNo === 3) { 
        dispatch(setDriver());
        dispatch(setVehicle());
        dispatch(setOpen(true));
    }
 //eslint-disable-next-line 
  }, [pageNo])
  
  return (
    <Grid container justifyContent={"center"} alignContent="center" p={2}>
      {pageNo === 0 && <DepotMain />}

      {pageNo === 1 && <VehicleMain />}

      {pageNo === 2 && <DriverMain />}

      {/* {pageNo === 3 && <AssignmentMain />} */}
    </Grid>
  );
}
