import React, { useState, useEffect } from 'react';
import { Grid, Typography, Card, CardContent } from '@mui/material';
import styles from '../../../components/dashboard/styles.module.css';
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";

export default function NumberDetails() {
  const axios = useAxiosPrivate()
  const MINUTE_MS = 60000; // 30 minutes in milliseconds
  const [value, setValue] = useState({
    customers: '',
    sales: '',
    orders: 'N/A',
    deliveries: 'N/A',
  });

  const getNumberDetails = async () => {
    try {
      const { data } = await axios.get(
        '/reports/franchisee-sales/number-details'
      );
      setValue(data);
    } catch (err) {
      setValue({
        customers: '',
        sales: '',
        orders: '',
        deliveries: '',
      });
    }
  };
  useEffect(() => {
    getNumberDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getNumberDetails();
    }, MINUTE_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item spacing={1}>

      <Grid item
        lg={6}
        md={6}
        sm={6}
        xs={12}
      >
        <Card className={styles.cardStyle}>
          <CardContent>
            {' '}
            <Typography
              gutterBottom
              variant='h3'
              component='div'
              sx={{
                font: 'bold',
                fontSize: {
                  lg:"2.6rem",
                  md:"2.6rem",
                  sm:"2.1rem",
                  xs:"2rem",
                },
              }}
            >
              {value?.customers > 0 ? value?.customers : 0}
            </Typography>
            <Typography
              gutterBottom
              variant='h6'
              component='div'
              style={{ font: 'bold' }}
            >
              Customers
            </Typography>
          </CardContent>
        </Card>
      </Grid>


      <Grid item 
       lg={6}
       md={6}
       sm={6}
       xs={12}
      >
        <Card className={styles.cardStyle}>
          <CardContent>
            <Typography
              gutterBottom
              variant='h3'
              component='div'
              sx={{
                font: 'bold',
                fontSize:"2.6rem",
              }}
            >
              { value?.sales > 0 ? Number(value?.sales).toFixed(2) : 0}
            </Typography>{' '}
            <Typography
              gutterBottom
              variant='h6'
              component='div'
              style={{ font: 'bold' }}
            >
              Sales Figure ($)
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item
       lg={6}
       md={6}
       sm={6}
       xs={12}
      >
        <Card className={styles.cardStyle}>
          <CardContent>
            {' '}
            <Typography
              gutterBottom
              variant='h3'
              component='div'
              sx={{
                font: 'bold',
                fontSize: {
                  lg:"2.6rem",
                  md:"2.6rem",
                  sm:"2.1rem",
                  xs:"2rem",
                },
              }}
            >
              {value?.orders > 0 ? value?.orders : 0}
            </Typography>
            <Typography
              gutterBottom
              variant='h6'
              component='div'
              style={{ font: 'bold' }}
            >
              Daily Orders
            </Typography>
          </CardContent>
        </Card>
      </Grid>


      <Grid item
       lg={6}
       md={6}
       sm={6}
       xs={12}
      >
        <Card className={styles.cardStyle}>
          <CardContent>
            <Typography
              gutterBottom
              variant='h3'
              component='div'
              sx={{
                font: 'bold',
                fontSize: {
                  lg:"2.6rem",
                  md:"2.6rem",
                  sm:"2.1rem",
                  xs:"2rem",
                },
              }}
            >
              {value?.deliveries > 0 ? value?.deliveries : 0}
            </Typography>
            <Typography
              gutterBottom
              variant='h6'
              component='div'
              style={{ font: 'bold' }}
            >
              Deliveries Per Day
            </Typography>
          </CardContent>
        </Card>
      </Grid>


    </Grid>
  );
}
