import React, { useState } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
} from '@mui/material';
import DataGridTable from 'components/layouts/DataGrid';
// import Chip from '@mui/material/Chip';
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import AlertInlineMain from "components/common/AlertInlineMain";
import AutoModeIcon from '@mui/icons-material/AutoMode';

export default function OrderByDate({ setOpen, open, date, createJobs, loading }) {
  const [status, setStatus] = useState(false);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  let newDate = new Date(date);
  // convert date to YYYY-MM-DD
  let dateString = newDate?.toISOString().split('T')[0];

  const columns = [
    { field: 'orderId', headerName: 'Order ID', minWidth: 100, flex: 0.2 },
    {
      field: 'customer',
      headerName: 'Customer Name',
      minWidth: 150,
      flex: 0.5,
      valueGetter: (params) => {
        return `${params.row.customer?.firstName} ${params.row.customer?.lastName}`;
      },
    },
    {
      field: 'orderStatus',
      headerName: 'Order Status',
      minWidth: 100,
      flex: 0.2,
      renderCell: (params) => {
        switch (params.row.orderStatus) {
          case 'pending':
            return <Chip label='Pending' color='primary' />;
          case 'process':
            return <Chip label='Processing' color='secondary' />;
          case 'completed':
            return <Chip label='Completed' color='success' />;
          default:
            return <Chip label='Pending' color='primary' />;
        }
      },
    },
    // {
    //   field: 'mapped',
    //   headerName: 'matrix available',
    //   minWidth: 130,
    //   flex: 0.3,
    //   renderCell: (params) => {
    //     return params.row.mapped ? <Chip label='Available' color='success' /> : <Chip label='Not Available' color='secondary' />
    //   },
    // },

    {
      field: 'orderType',
      headerName: 'Services',
      minWidth: 210,
      flex: 0.9,
      valueGetter: (params) => {
        if (params.row.isManualOrder) {
          return "Manual Order"
        } else {
        switch (params.row.orderType) {
          case '1':
            return 'Wash/Dry/Fold';
          case '2':
            return 'Dry Cleaning';
          case '3':
            return 'Tailoring';
          case '4':
            return 'Dry Cleaning & Tailoring';
          case '5':
            return 'Wash/Dry/Fold , Tailoring';
          case '6':
            return 'Wash/Dry/Fold , Tailoring';
          case '7':
            return 'Wash/Dry/Fold , Dry Cleaning';
          case '8':
            return 'Wash/Dry/Fold , Dry Cleaning';
          case '9':
            return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
          case '10':
            return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
          default:
            return '-';
        }
      }
      },
    },
    {
      field: 'pickupDate',
      headerName: 'Pickup Date',
      minWidth: 250,
      flex: 0.3,
      valueGetter: (params) => {
          let pickupDate = params?.row?.pickupDate
          if (pickupDate?.includes("Z")) {
            pickupDate = (pickupDate?.split("T")[0])
          } else {
            pickupDate = params?.row?.pickupDate
          }
          return `${params?.row?.pickupDate ? moment(pickupDate).format("MMMM Do dddd YYYY") : "Not applicable"}`;
      },
    },
    {
      field: 'pickupTimeSlot',
      headerName: 'Pickup TimeSlot',
      minWidth: 200,
      flex: 0.3,
      valueGetter: (params) => {
        return `${params?.row?.pickupTimeSlot ? params?.row?.pickupTimeSlot : "Not applicable"}`;
    },
    },
    {
      field: 'diliverDate',
      headerName: 'Delivery Date',
      minWidth: 250,
      flex: 0.3,
      valueGetter: (params) => { 
          let diliverDate = params?.row?.diliverDate
          if (diliverDate?.includes("Z")) {
            diliverDate = (diliverDate?.split("T")[0])
          } else {
            diliverDate = params?.row?.diliverDate
          }
          return `${params?.row?.diliverDate ? moment(diliverDate).format("MMMM Do dddd YYYY") : "Not applicable"}`;
      },
    },
    {
      field: 'diliverTimeSlot',
      headerName: 'Delivery TimeSlot',
      minWidth: 200,
      flex: 0.3,
      valueGetter: (params) => {
        return `${params?.row?.diliverTimeSlot ? params?.row?.diliverTimeSlot : "Not applicable"}`;
    },
    }, 
    {
      field: 'specialDate',
      headerName: 'Special Care Delivery Date',
      minWidth: 250,
      flex: 0.3,
      valueGetter: (params) => {
        let specialDate = params.row.specialDate
        if (specialDate?.includes("Z")) {
          specialDate = (specialDate?.split("T")[0])
        } else { 
          specialDate = params.row.specialDate
        }
        return `${params?.row?.specialDate ? moment(specialDate).format("MMMM Do dddd YYYY") : "Not applicable"}`;
      },
    },
  ];

  const handleClose = () => {
    setOpen(false);
    setStatus(!status);
  };

  return (
    <>
      <Dialog fullWidth={true} maxWidth='md' open={open} onClose={handleClose}>
        <DialogTitle sx={{ cursor: 'move', alignSelf: 'center' }}>
          Order By Date
        </DialogTitle>
        <DialogContent>
          <Grid container item justifyContent={'flex-end'} alignItems={'flex-end'} mb={1}>
            <Grid item lg={'auto'} sm={'auto'} xs={'auto'} md={'auto'}>
              <LoadingButton sx={{ width: "100%", height: "2.2rem" }}
                startIcon={<AutoModeIcon />}
                loading={loading}
                variant="contained"
                color="info"
                onClick={createJobs}>
                Create Jobs
              </LoadingButton>
            </Grid>
            <Grid item /*style={{ height: 350, width: "100%" }}*/ xs={12} md={12}>
              <DataGridTable
                fetchDataUrl={`/job/frontend-orders-date/${dateString}`}
                columns={columns}
                status={status}
                title='Orders'/>
              {alert.showAlert && (
                <Grid item md={12} m={2}>
                  <AlertInlineMain setAlert={setAlert} alert={alert} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color='error' onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
