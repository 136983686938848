import React, { Fragment } from 'react';
import { TableCell } from '@material-ui/core';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import ReusableTable from '../../components/layouts/ReusableTable';
import { useNavigate } from 'react-router-dom';

const headers = [
  {
    text: 'Name',
    value: 'name',
  },
  {
    text: 'Owner',
    value: 'Owner',
  },
];

export default function FranchiseeListView(props) {
  const TableB = ({ item }) => {
    const history = useNavigate();

    const changeUrl = (id) => {
      history('/lwl-learning', { state: { user: id } });
    };

    return (
      <Fragment key={item.name}>
        <TableCell onClick={() => changeUrl(item.id)}>
          {' '}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            {' '}
            <FolderSpecialIcon />
            <p style={{ paddingLeft: 5 }}> {item.fullName}</p>
          </div>
        </TableCell>
        <TableCell>{item.role}</TableCell>
      </Fragment>
    );
  };
  return (
    <ReusableTable
      headers={headers}
      fetchDataUrl='/folder/corporate'
      statusChangeUrl='/folder/status'
      items={[]}
      TableB={TableB}
      {...props}
    />
  );
}
