import React, { useState, useEffect } from "react";
import { Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Divider, } from "@mui/material";
import { DialogContentText, TextField } from "@material-ui/core";
import { Form, Formik, Field } from "formik";
import { TextField as TF } from "formik-material-ui";
import LoadingButton from "@mui/lab/LoadingButton";
import AlertMain from "components/common/AlertMain";
import useOrderViewFunctions from "./commonFunctions/useOrderViewFunctions";

import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";

export default function OrderView({ editData, setStatus, status, open, setOpen, setUpdateOrders }) {

  const { todayDate, alert, extraList, handleChange,
    changeStatus, validationSchema, handleClose, extra, setpickUpDateValue, setdeliveryDateValue,
    setSpecialCareDate, cngPickupDelivery, waitPDdate, pickUpDateValue, oldPickUpDateValue, deliveryDateValue,
    oldDeliveryDateValue, specialCareDate, oldSpecialCareDate, addShowItems, showItems,
    exChargedisable, exWeightdisable, subTotal, setAlert, undefinedValueChecker, setExtraList, setSubTotal,
    setexChargedisable, setexWeightdisable, setExtra, exChargeFeesdisable, exChargeReasondisable,
  } = useOrderViewFunctions({ editData, setStatus, status, open, setOpen, setUpdateOrders });

  const axios = useAxiosPrivate();

  const [backendCallWeight, setBackendCallWeight] = useState(false);
  const [backendCall, setBackendCall] = useState(false);
  const [weightPrice, setWeightPrice] = useState('');
  const [isCustomerChargedForWeight, setIsCustomerChargedForWeight] = useState(false);
  const [openModel, setOpenModel] = useState(false);

  useEffect(() => {

    if(editData?.isCustomerChargedForWeight){
      setIsCustomerChargedForWeight(editData?.isCustomerChargedForWeight)
    }else{
      setIsCustomerChargedForWeight(false)
    }

  },[editData])

  const submitExtra = async (setFieldValue) => {
    try {
      setBackendCall(true);
      setexWeightdisable(true);
      const data = { orderId: editData._id, extra: extra.extra, reason: extra.reason, };
      const newData = await axios.put("order/extra", data);
      setBackendCall(false);
      setFieldValue("extra", "");
      setFieldValue("reason", "");
      setExtraList(newData.data?.updatedData?.extraCharges);
      setSubTotal(newData.data?.subTotal);
      setexChargedisable(true);
      setAlert({ showAlert: true, severity: "success", message: "Extra charges added Successfully!", });
      setTimeout(() => { setAlert({ showAlert: false, severity: "success", message: "Extra charges added Successfully!", }); }, 3000);
      setExtra(() => ({ ...extra, extra: "", reason: "" }));
    } catch (error) {
      setAlert({ showAlert: true, severity: "error", message: "The card is declined. Unable to add extra charges!", });
      // setTimeout(() => { setAlert({ showAlert: false, severity: "success", message: "Card payment declined. Extra charges added Fail!", }); }, 3000);
    } finally {
      setBackendCall(false);
      if (extra.extraWeight !== "") {
        setexWeightdisable(false);
      }
    }
  };

  const calculateExtraWeight = (extraWeight) => { // this function reduce 15lbs from the total value and return the rest as extra weight
    
    let weight = 0;
    
    if(extraWeight > 15){
      weight = parseFloat(extraWeight - 15).toFixed(2)
    }
    
    return weight.toString();
  }

  const submitWeightParent = () => {

    if(extra.extraWeight > 15){
      submitExtraWeight()
    }else{
      submitDraftTotalWeight()
    }
  }

  const submitDraftTotalWeight = async (setFieldValue) => {

    try {
      setOpenModel(false)
      setBackendCallWeight(true);

      const data = { orderId: editData._id, extraWeight: extra.extraWeight};

      setBackendCall(true);
      // setexWeightdisable(true);
      const result = await axios.put("order/extraDraftWeight", data);
      setBackendCall(false);
      setBackendCallWeight(false);

      setIsCustomerChargedForWeight(true);
      // setexWeightdisable(true);
      setExtra(() => ({ ...extra, extraWeight: result?.data ? result?.data : "" }));
      
    } catch (error) {
      setBackendCallWeight(false);
      setOpenModel(false)
      setBackendCall(false);
    }
  }

  
  const submitExtraWeight = async () => {
    try {
      setOpenModel(false)
      setBackendCallWeight(true);
      setexChargedisable(true);
      calculateExtraWeight(extra.extraWeight);

      const data = { orderId: editData._id, extraWeight: calculateExtraWeight(extra.extraWeight), };

      const saveDataWeight = { orderId: editData._id, extraWeight: extra.extraWeight};

      await axios.put("order/extraDraftWeight", saveDataWeight);
      const weight = await axios.put("order/extraWeight", data);

      setIsCustomerChargedForWeight(true);
      
      // setFieldValue("extraWeight", "");
      setBackendCallWeight(false);
      setExtraList(weight.data?.data?.extraCharges);
      setSubTotal(weight.data?.subTotal);
      setAlert({ showAlert: true, severity: "success", message: "Extra charges added Successfully!", });
      setexWeightdisable(true);
      setTimeout(() => { setAlert({ showAlert: false, severity: "success", message: "Extra charges added Successfully!", }); }, 3000);
      // setExtra(() => ({ ...extra, extraWeight: "" }));
    } catch (error) {
      setOpenModel(false)
      setAlert({ showAlert: true, severity: "error", message: "The card is declined. Unable to add extra charges!", });
      // setTimeout(() => { setAlert({ showAlert: false, severity: "success", message: "Card payment declined. Extra charges added Fail!", }); }, 3000);
    } finally {
      setOpenModel(false)
      setBackendCallWeight(false);
      if (extra.extra !== "") {
        setexChargedisable(false);
      }
    }
  };

  const getWeightPrice = async () => {
    try {
      const { data } = await axios.get("service/extra-weight/price");
      setWeightPrice(data.price);
      console.log(data)
    } catch (error) {
      console.log(error)
      setAlert({ showAlert: true, severity: "error", message: "Unable to get extra weight charges!", });
    }
  };


  useEffect(() => {
    getWeightPrice()
  }, []);

  function orderTypeName(serviceType) {
    switch (serviceType) {
      case '1':
        return 'Wash/Dry/Fold';
      case '2':
        return 'Dry Cleaning';
      case '3':
        return 'Tailoring';
      case '4':
        return 'Dry Cleaning & Tailoring';
      case '5':
        return 'Wash/Dry/Fold , Tailoring';
      case '6':
        return 'Wash/Dry/Fold , Tailoring';
      case '7':
        return 'Wash/Dry/Fold , Dry Cleaning';
      case '8':
        return 'Wash/Dry/Fold , Dry Cleaning';
      case '9':
        return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
      case '10':
        return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
      default:
        return '-';
    }
  }


  return (
    <>
    <Dialog fullWidth maxWidth={editData?.isManualOrder ? "sm" : "lg"} open={open} onClose={handleClose}>
      <DialogTitle id="responsive-dialog-title">
        <Typography variant="h5" align="right" color="primary"> {editData?.orderStatus === "process" ? "processing" : editData?.orderStatus} </Typography>
        {editData?.isManualOrder && <Typography style={{
          fontWeight: "bold", fontSize: "1rem", textAlign: "center",
        }} mt={{ lg: 0, md: 0, sm: 0, xs: 3, }} mb={1} align="center" >Manual Order</Typography>}
        <Typography variant="h5" mt={{ lg: 0, md: 0, sm: 0, xs: 3, }} align="center" >{`Order ID: ${editData.orderId}`}</Typography>
      </DialogTitle>
      <DialogContent>
        <Formik initialValues={extra} validationSchema={validationSchema} enableReinitialize >
          {({ isValid, values, setFieldValue, dirty }) => {
            return (
              <Form>
                <Grid container item mt={2} justifyContent="center" >
                  <Grid container item lg={editData?.isManualOrder ? 10 : 5} md={6} sm={12} xs={12} mt={2} rowSpacing={2} >
                    <Grid item lg={10} md={10} sm={12} xs={12} >
                      <TextField fullWidth disabled={true} variant="outlined" label={(sessionStorage.getItem("role") === "corporate" || (sessionStorage.getItem("managerType") === "corporate" && sessionStorage.getItem("role") === "manager")) ? "Corporate Business Name" : "Franchisee Business Name"} value={`${editData?.franchiseeId?.fullName}`} />
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12} >
                      <TextField fullWidth disabled={true} variant="outlined" label="Customer Name" value={`${editData?.customer?.firstName} ${editData?.customer?.lastName}`} />
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12} >
                      <TextField fullWidth disabled={true} variant="outlined" label="Email Address" value={editData?.customer?.email} />
                    </Grid>
                    {editData?.pickupDate && (<Grid item lg={10} md={10} sm={12} xs={12} >
                      <TextField name="pickupDate" label={`PickUp Date`} value={pickUpDateValue} onChange={(newValue) => { setpickUpDateValue(newValue.target.value); }}
                        type="date" InputProps={{ inputProps: { min: `${todayDate}` } }} onKeyDown={(e) => e.preventDefault()} />
                    </Grid>)}
                    {editData?.diliverDate && (<Grid item lg={10} md={10} sm={12} xs={12} >
                      <TextField name="deliveryDate" label={`Delivery Date`} value={deliveryDateValue}
                        onChange={(newValue) => { setdeliveryDateValue(newValue.target.value); }}
                        type="date" onKeyDown={(e) => e.preventDefault()} InputProps={{ inputProps: { min: `${pickUpDateValue}` } }} />
                    </Grid>)}
                    {editData?.specialDate && (
                      <Grid item lg={10} md={10} sm={12} xs={12} >
                        <TextField name="specialDate" label={`Special care date`} value={specialCareDate}
                          onChange={(newValue) => { setSpecialCareDate(newValue.target.value); }}
                          onKeyDown={(e) => e.preventDefault()} type="date" InputProps={{ inputProps: { min: `${deliveryDateValue}` } }} /> </Grid>
                    )}
                    <Grid item lg={10} md={10} sm={12} xs={12} >
                      <Button data-testid="save-date" onClick={cngPickupDelivery} variant="contained"
                        disabled={waitPDdate || (pickUpDateValue === oldPickUpDateValue && deliveryDateValue === oldDeliveryDateValue &&
                          specialCareDate === oldSpecialCareDate)} > Save </Button>
                    </Grid>
                    <Grid container item xs={12} md={8} xl={12} lg={8} spacing={1} >
                      {(!editData?.orderType === "2" && !editData?.orderType === "3" && !editData?.orderType === "5") && (
                        <Grid item xs={12} md={4} xl={4} lg={4}> <Button onClick={() => addShowItems("washDry")} variant="outlined" > Wash Dry </Button>
                        </Grid>)}
                      {editData?.dryItem.length > 0 && (
                        <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={12} > <Button variant="outlined" onClick={() => addShowItems("dryClean")} > Dry clean </Button> </Grid>)}
                      {editData?.tailItem.length > 0 && (
                        <Grid item xs={12} md={4} xl={4} lg={4}> <Button variant="outlined" onClick={() => addShowItems("alteration")} > Alterations </Button> </Grid>)}
                    </Grid>
                    {showItems.length > 0 && (
                      <Grid container>
                        <Grid item xs={12} md={10} xl={8} lg={8} style={{ fontWeight: "bold" }}>
                          <Grid container item style={{ border: "1px solid #e0e0e0", padding: "1rem", marginTop: "1rem" }}>
                            <Grid item xs={5} md={5} lg={5}>
                              Item
                            </Grid>
                            <Grid item xs={4} md={4} lg={4}>
                              Item Price
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                              Quantity
                            </Grid>
                          </Grid>
                        </Grid>

                        {showItems.map((item, index) => (
                          <Grid item xs={12} md={10} xl={8} lg={8} key={index}>
                            <Grid container item style={{ border: "1px solid #e0e0e0", padding: "1rem" }}>
                              <Grid item xs={5} md={5} lg={5}>
                                {item?.itemId?.item?.name ? item?.itemId?.item?.name : "Wash/Dry/Clean price"}
                              </Grid>
                              <Grid item xs={4} md={4} lg={4}>
                                $ {(item?.itemId?.price).toFixed(2)}
                              </Grid>
                              <Grid item xs={3} md={3} lg={3}>
                                {item?.itemId?.quantity}
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                    {(editData?.deliveryNote || editData?.pickupNote || editData?.orderNote) && editData?.orderType &&
                      <Grid item lg={12} md={12} sm={12} xs={12} > <Typography>Services - : {orderTypeName(editData?.orderType)}</Typography> </Grid>
                    }
                    {(editData?.deliveryNote || editData?.pickupNote || editData?.orderNote) && <Grid container item spacing={2} xs={12} md={12} xl={12} lg={12} >
                      <Grid item lg={12} md={12} sm={12} xs={12} > <Typography>Instructions</Typography> </Grid>
                      {editData?.pickupNote && <Grid item lg={10} md={10} sm={12} xs={12} >
                        <TextField fullWidth multiline maxRows={4} disabled={true} variant="outlined" label="Pickup Instructions" value={editData?.pickupNote} />
                      </Grid>}
                      <Grid item lg={10} md={10} sm={12} xs={12} >
                        <TextField fullWidth multiline maxRows={4} disabled={true} variant="outlined" label="Laundry Instructions" value={editData?.orderNote} />
                      </Grid>
                      {editData?.deliveryNote && <Grid item lg={10} md={10} sm={12} xs={12} >
                        <TextField fullWidth multiline maxRows={4} disabled={true} variant="outlined" label="Delivery Instructions" value={editData?.deliveryNote} />
                      </Grid>}
                    </Grid>}
                    {!editData?.isManualOrder && editData?.orderStatus === "process" && (
                      <Grid item container xs={12} md={8} xl={8} lg={8} spacing={2} >
                        <Grid item xs={12} md={8} xl={8} lg={8}> <Typography>Extra charges</Typography> </Grid>
                        <Grid item lg={6} md={6} xs={12} xl={6} >
                          <Field data-testid="extraCharge" name="extra" label="Fees $" component={TF} variant="outlined" type="number" fullWidth onChange={handleChange} required ></Field>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} xl={6} >
                          <Field data-testid="extraReason" name="reason" label="Reason" component={TF} variant="outlined" fullWidth onChange={handleChange} required ></Field>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12} xl={12}>
                          <LoadingButton data-testid="add-extracharge" loading={backendCall} fullWidth variant="contained" color="primary" disabled={exChargedisable || !isValid || exChargeFeesdisable || exChargeReasondisable}
                            size="small" onClick={() => submitExtra(setFieldValue)} > Add extra charges </LoadingButton>
                        </Grid>

                        <Grid item xs={12} md={8} xl={12} lg={8}> 
                        <Typography>
                           Total weight <span style={{ color: '#ABABAB' }}>(1lb = ${weightPrice})</span>
                        </Typography> 
                        </Grid>
                        <Grid item xs={12} md={12} xl={12} lg={12}>
                          <Field data-testid="extra-weight" name="extraWeight" label="Total Weight" component={TF} variant="outlined" fullWidth type="number" onChange={handleChange} required ></Field>
                        </Grid>

                        {/* <Grid item xs={12} md={12} xl={12}>
                          <LoadingButton data-testid="add-extra-weight" loading={backendCall} fullWidth variant="contained" color="primary" size="small"
                            disabled={backendCall || isCustomerChargedForWeight} onClick={() => submitDraftTotalWeight(setFieldValue)} > Save Weight </LoadingButton>
                        </Grid> */}

                        <Grid item xs={12} md={12} xl={12}>
                          <LoadingButton loading={backendCallWeight} fullWidth variant="contained" color="primary" size="small" onClick={() => submitWeightParent()}
                            disabled={ isCustomerChargedForWeight || backendCallWeight } > Save weight </LoadingButton>
                        </Grid>

                      </Grid>)}
                    <Grid container item spacing={1} lg={"auto"} md={"auto"} sm={12} xs={12} >
                      <Grid item lg={4} md={4} sm={12} xs={12} >
                        <Button name="pending" variant="contained" color="primary" fullWidth={true} onClick={() => changeStatus("pending")}
                          disabled={editData?.orderStatus === "pending" || editData?.orderStatus === "process" || editData?.orderStatus === "completed" ? true : false} > Pending </Button>
                      </Grid>
                      <Grid item xs={12} md={4} xl={4}>
                        <Button data-testid="processing-btn" name="process" variant="contained" color="primary" fullWidth={true} onClick={() => changeStatus("process")}
                          disabled={editData?.orderStatus === "process" || editData?.orderStatus === "completed" ? true : false} > Processing </Button>
                      </Grid>
                      <Grid item xs={12} md={4} xl={4}>
                        <Button data-testid="completed-btn" name="completed" variant="contained" color="primary" fullWidth={true} onClick={() => changeStatus("completed")}
                          disabled={(editData?.orderStatus === "completed" || backendCallWeight || backendCall) ? true : false} > Completed </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  {!editData?.isManualOrder && <Grid container item lg={4} md={"auto"} sm={"auto"} xs={"auto"}
                    mt={{ lg: 0, md: 2, sm: 2, xs: 2, }} >
                    <Grid item sx={{ border: "1px solid #e0e0e0", padding: "2rem", backgroundColor: "#f5f5f5", maxWidth: { lg: "25rem", md: "25rem", sm: "25rem", xs: "20rem", }, maxHeight: "100%", }} >
                      <Grid item lg={12} md={12} sm={12} xs={12} mb={3} > <Typography sx={{ textAlign: "center" }} variant="h5">Invoice</Typography> </Grid>
                      <Grid container item lg={12} md={12} sm={12} xs={12} rowSpacing={3} columnSpacing={2} >
                        <Grid item lg={6} md={6} sm={6} xs={7} >
                          <Typography sx={{ textAlign: "right" }} >Wash/Dry/Fold</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={5}>
                          <Typography data-testid="washPrice">$ {editData?.orderType === "1" || editData?.orderType === "5" ||
                            editData?.orderType === "6" || editData?.orderType === "7" || editData?.orderType === "8" ||
                            editData?.orderType === "9" || editData?.orderType === "10"
                            ? (undefinedValueChecker(editData?.servicePrice - ((
                              (editData?.orderType === "10" || editData?.orderType === "8" || editData?.orderType === "6")
                              && editData?.specialCarePrice) ? editData?.specialCarePrice : 0)).toFixed(2)) : 0}
                          </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={7}> <Typography sx={{ textAlign: "right" }} >Special care Services</Typography> </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={5}> <Typography>$ {undefinedValueChecker(editData?.specialCarePrice).toFixed(2)}</Typography> </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={7}> <Typography sx={{ textAlign: "right" }} >Processing Fee</Typography> </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={5}> <Typography>$ {undefinedValueChecker(editData?.processingFee).toFixed(2)}</Typography> </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={7}> <Typography sx={{ textAlign: "right" }} >Promo code</Typography> </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={5}> <Typography>($ 0)</Typography> </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={7}> <Typography sx={{ textAlign: "right" }} >Reward discount</Typography> </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={5}> <Typography>(${undefinedValueChecker(editData?.discount).toFixed(2)})</Typography> </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={7}> <Typography sx={{ textAlign: "right" }} >Something extra</Typography> </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={5}> <Typography>$ {undefinedValueChecker(editData?.extra).toFixed(2)}</Typography> </Grid>
                        {extraList?.length > 0 &&
                          extraList?.map((item) => {
                            return (<Grid container item key={item._id}>
                              <Grid item lg={12} md={12} sm={12} xs={12}> <Typography sx={{ textAlign: "center" }} >+ Added $ {undefinedValueChecker(item.extra).toFixed(2)} Extra Charge for {item.reason} </Typography> </Grid>
                              {/* <Grid item lg={5} md={5} sm={5} xs={4}> <Typography></Typography> </Grid> */}
                            </Grid>);
                          })}
                        <Divider />
                        <Grid item lg={6} md={6} sm={6} xs={7}> <Typography style={{ fontWeight: "bold", textAlign: "right" }}> Sub Total </Typography> </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={5}> <Typography data-testid="subTotal" style={{ fontWeight: "bold" }}> $ {undefinedValueChecker(subTotal).toFixed(2)} </Typography> </Grid>
                      </Grid>
                    </Grid>
                  </Grid>}
                </Grid>
                <AlertMain setAlert={setAlert} alert={alert} />
              </Form>);
          }}
        </Formik>
      </DialogContent>
      <DialogActions> <Button data-testid="close-btn" autoFocus onClick={handleClose} color="primary"> Close </Button> </DialogActions>
    </Dialog>

     <Dialog
                open={openModel}
                onClose={() => setOpenModel(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={"sm"}
            >
                <DialogTitle id="alert-dialog-title">Charge customer for the weight</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This action cannot be undone. Are you sure ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                    onClick={() => submitWeightParent()} 
                    variant="contained" color="success"
                    disabled={isCustomerChargedForWeight || backendCallWeight}
                    loading={backendCallWeight}
                    >
                        Yes
                    </Button>
                    <Button 
                    onClick={() => setOpenModel(false)} 
                    variant="contained" color="error"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
    </>
  );
}
