// value === 'gg' ? ' - Go Green' : ' - Agrowcell'
import { ADD_SELECTED_ID, ROLE_TYPE_CREATE } from '../actions/types';

const initialState = {
  selectedId: '',
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_SELECTED_ID:
      return {
        ...state,
        ...action.payload,
      };
      case ROLE_TYPE_CREATE:
        return {
          ...state,
          ...action.payload,
        };  
    default:
      return state;
  }
}
