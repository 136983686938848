import React from 'react';
import DataGrid from '../layouts/DataGrid';
import { Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';


export default function CustomerReport() {
  let navigate = useNavigate();

  const onGoback = () => {
    navigate('/franchisee-reports')
  }

  const status = false;

  const columns = [
    {
      field: 'customerName',
      headerName: 'Customer Name',
      minWidth: 210,
      flex:1
      // valueGetter: (params) => {
      //   return `${params.row.firstName}`;
      // },
    },
    {
      field: 'address',
      headerName: 'Address',
      minWidth: 480,
      // valueGetter: (params) => {
      //   return `${params.row.suitNo}`;
      // },
    },

    {
      field: 'email',
      headerName: 'Email',
      minWidth: 210,
       flex:1
      // valueGetter: (params) => {
      //   return `${moment(params.row.createdAt)}`;
      // },
    },

    {
      field: 'roleID',
      headerName: 'Locations ID',
      minWidth: 210,
       flex:1
      // valueGetter: (params) => {
      //   return `${moment(params.row.createdAt)}`;
      // },
    },

    {
      field: 'franchiseeName',
      headerName: 'Locations Business Name',
      minWidth: 220,
       flex:1
      // valueGetter: (params) => {
      //   return `${moment(params.row.createdAt)}`;
      // },
    },
  ];

  return (
    <Grid container 
    // xs={12} sm={12} md={12}
    >
      <Grid container justifyContent="space-between">
          <Grid item >
            <IconButton onClick={() => onGoback()}>
              <ArrowBackIcon sx={{ color: "#343434", }} />
            </IconButton>
          </Grid>
          <Grid item >
            <div style={{ width: '10px' }}></div>
          </Grid>
        </Grid>
      <Grid item xs={12} md={12}>
        <DataGrid
          autoHeight
          columns={columns}
          fetchDataUrl='/user/customer/report'
          status={status}
          title='Customers'
          updatePath='/update-franchisee/'
          fixHeight={true}
        />
      </Grid>
    </Grid>
  );
}
