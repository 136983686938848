import React, { useState } from 'react';
import DataGridTable from '../../../components/layouts/DataGrid';
import { Grid, IconButton } from '@mui/material';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from 'react-router-dom';
import moment from "moment";

const columns = [
  {
    field: 'orderId',
    headerName: 'Order ID',
    minWidth: 80,
    flex: 0.9,
  },
  {
    field: 'userid',
    headerName: 'User ID',
    minWidth: 200,
  },
  // {
  //   field: 'id',
  //   headerName: 'User',
  //   minWidth: 100,
  // },
  {
    field: 'hasKey',
    headerName: 'Has Key',
    minWidth: 100,
  },
  {
    field: 'deliveryDate',
    headerName: 'Delivery Date', // mm/dd/yyyy
    minWidth: 250,
    valueGetter: (params) => {
        let deliveryDate = params?.row?.deliveryDate
        if (deliveryDate?.includes("Z")) {
          deliveryDate = (deliveryDate?.split("T")[0])
        } else {
          deliveryDate = params?.row?.deliveryDate
        }
        return `${params?.row?.deliveryDate ? moment(deliveryDate).format("MMMM Do dddd YYYY") : "Not applicable"}`;
    },
  },
  {
    headerName: 'Delivery time', // hh:mm
    minWidth: 160,
    flex: 0.8,
    valueGetter: (params) => {
      return `${params?.row?.deliveryTimeSlot ? params?.row?.deliveryTimeSlot : "Not applicable"}`;
  },
  },
  {
    field: 'deliveryWindowStart',
    headerName: 'Delivery Window start time', // hh:mm
    minWidth: 185,
  },
  {
    field: 'deliveryWindowEnd',
    headerName: 'Delivery Window end time', // hh:mm
    minWidth: 185,
  },
  {
    field: 'zoneName',
    headerName: 'Group',
    minWidth: 80,
  },

  {
    field:'firstName',
    headerName: 'Name',
    minWidth: 200,
    valueGetter: (params) => {
      return `${params.row.firstName} ${params.row.lastName}`
    },
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone',
    minWidth: 120,
  },
  {
    field: 'streetAddress',
    headerName: 'Street',
    minWidth: 200,
  },
  {
    field: 'streetAddress2',
    headerName: 'Street2',
    minWidth: 100,
  },
  {
    field: 'address',
    headerName: 'Address',
    minWidth: 480,
    valueGetter: (params) => {
      return `${params.row.orderAddress}`
    },
  },
  {
    field: 'city',
    headerName: 'City',
    minWidth: 100,
  },
  {
    field: 'state',
    headerName: 'State',
    minWidth: 40,
  },
  {
    field: 'zipCode',
    headerName: 'Zipcode',
    minWidth: 40,
    valueGetter: (params) => {
      return `${params.row.zipCode}`
    },
  },
  {
    field:'country',  
    headerName: 'Country',
    minWidth: 40,
  },
  {
    field: 'orderType',
    headerName: 'Services',
    minWidth: 280,
    flex: 2,
    valueGetter: (params) => {
      if (params.row.isManualOrder) {
        return "Manual Order"
      } else {
      switch (params.row.orderType) {
        case '1':
          return 'Wash/Dry/Fold';
        case '2':
          return 'Dry Cleaning';
        case '3':
          return 'Tailoring';
        case '4':
          return 'Dry Cleaning & Tailoring';
        case '5':
          return 'Wash/Dry/Fold , Tailoring';
        case '6':
          return 'Wash/Dry/Fold , Tailoring';
        case '7':
          return 'Wash/Dry/Fold , Dry Cleaning';
        case '8':
          return 'Wash/Dry/Fold , Dry Cleaning';
        case '9':
          return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
        case '10':
          return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
        default:
          return '-';
      }
    }
    },
  },
  // {
  //   field: 'orderNote',
  //   headerName: 'Order Notes',
  //   minWidth: 150,
  // },
  {
    field: 'deliveryNote',
    headerName: 'Notes',
    minWidth: 350,
  },
  {
    field: 'deliveries',
    headerName: 'deliveries',
    minWidth: 20,
  },
  {
    field: 'serviceTime',
    headerName: 'service time (Min)',
    minWidth: 200,
    valueGetter: (params) => {
      const formattedMinutes =  moment.duration(params.row.serviceTime, 'seconds').asMinutes().toFixed(2);
      return `${formattedMinutes}`
    },
  },
];

function GetDeliveris() {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item ml={1}>
        <IconButton
          variant='contained'
          color='primary'
          onClick={() => navigate('/schedule')}
          aria-label='goback'
        >
          <ArrowBackIcon />
        </IconButton>
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h1>Get Deliveries</h1>
      </Grid>
      <Grid item xs={12} sm={12}>
        <DataGridTable
          fetchDataUrl={`/orders/get-deliveries`}
          columns={columns}
          status={status}
          title={''}
        />
      </Grid>
    </Grid>
  );
}

export default GetDeliveris;
