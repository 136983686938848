import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Card, CardContent, CardActions, CardHeader, InputAdornment, IconButton, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import LoadingButton from "@mui/lab/LoadingButton";
import AlertInlineMain from "components/common/AlertInlineMain";
// validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  gridMargin: {
    marginTop: "10rem", marginBottom: "1rem",
    [theme.breakpoints.down("md")]: { marginTop: "5rem", marginBottom: "0rem", },
    [theme.breakpoints.down("sm")]: { marginTop: "5rem", marginBottom: "0rem", },
    [theme.breakpoints.down("xs")]: { marginTop: "5rem", marginBottom: "0rem", },
  },

  fieldWidth: {
    width: "25vw",
    [theme.breakpoints.down("md")]: { width: "35vw", },
    [theme.breakpoints.down("sm")]: { width: "50vw", },
    [theme.breakpoints.down("xs")]: { width: "70vw", },
  },

  center: {
    textAlign: "center",
  },
  padding: {
    paddingTop: "5rem",
    paddingBottom: "5rem",
    width: "42vw",
    height: "auto",
    boxShadow: "0 0 5px 0 rgb(0 0 0 / 20%)",
    borderRadius: "6px",
    [theme.breakpoints.down("md")]: { width: "65vw", },
    [theme.breakpoints.down("sm")]: { width: "70vw", },
    [theme.breakpoints.down("xs")]: { width: "90vw", },
  },

  button: {
    backgroundColor: "#2cca5c", color: "white", width: "100%",
    "&:hover": { backgroundColor: "#2cca5c", color: "white", },
  },

}));

let initialValues = { newPassword: "", confirmPassword: "", };
let SignUpSchema = Yup.object().shape({
  newPassword: Yup.string().matches(/(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
    "One Uppercase, One Lowercase, One Number,one special case Character and Must Contain 8 Characters")
  .min(8, "Must Contain 8 Characters"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("newPassword"), null],
    "Passwords must match"
  ),
});
function SetPassword() {
  const axios = useAxiosPrivate();
  const classes = useStyles();
  const { email, verifyTokens } = useParams();
  const [backendCall, setBackendCall] = useState(false);
  const [newPass, setNewPass] = useState(false);
  const [consfirmPass, setConfirmPass] = useState(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  let navigate = useNavigate();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submit = async (e, { resetForm }) => {
    setBackendCall(true)
    try {
      const data = {
        newPassword: e.newPassword,
        confirmPassword: e.confirmPassword,
        email: email,
        verifyTokens: verifyTokens,
      }
      await axios.put("/auth/user/reset-password", data);
      setBackendCall(false)
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Password Changed Successfully",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "Password Changed Successfully",
          message: "",
        });

        navigate("/");

      }, 3000);

    } catch (error) {
      setBackendCall(false)
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: error.response.data.message,
        });
      } else if (error.response.status === 501) {
        setAlert({
          showAlert: true,
          severity: "error",
          message:
            "You are temporary block. Please contact your administrator!",
        });
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server error!",
        });
      }
    }
  };

  return (
    <Grid container className={classes.gridMargin} justifyContent="center" alignItems="center" >

      <Grid container item lg={12} md={12} sm={12} alignItems="center" justifyContent="center">
        <Card className={classes.padding} variant="outlined" sx={{ boxShadow: 3, }} >

          <Grid container alignItems="center" justifyContent="center">
            <Grid container item lg={12} md={12} sm={12} alignItems="center" justifyContent="center">

              <Grid item lg={12} md={12} sm={12}>
                <CardHeader title="Change Password" className={classes.center} ></CardHeader>
              </Grid> 
              <Formik initialValues={initialValues} onSubmit={submit} validationSchema={SignUpSchema} >
                {({ dirty, isValid }) => {
                  return (
                    <Form>
                      <CardContent>
                        <Grid item lg={12} md={12} sm={12}>
                          <Field className={classes.fieldWidth} name="newPassword" label="New Password"
                            component={TextField} variant="outlined" fullWidth margin="dense" required
                            type={newPass ? "text" : "password"}
                            InputProps={{
                              style: { fontFamily: "Overpass, sans-serif" },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton aria-label="toggle password visibility" onClick={() => setNewPass(!newPass)}
                                    onMouseDown={handleMouseDownPassword} > {newPass ? ( <Visibility /> ) : ( <VisibilityOff /> )} </IconButton>
                                </InputAdornment> ),
                            }}
                          ></Field>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12}>
                          <Field className={classes.fieldWidth} name="confirmPassword" label="Confirm Password"
                            component={TextField} variant="outlined" fullWidth margin="dense" type={consfirmPass ? "text" : "password"}
                            required
                            InputProps={{
                              style: { fontFamily: "Overpass, sans-serif" },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton aria-label="toggle password visibility" onClick={() => setConfirmPass(!consfirmPass)}
                                    onMouseDown={handleMouseDownPassword} >
                                    {consfirmPass ? ( <Visibility /> ) : ( <VisibilityOff /> )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          ></Field>
                        </Grid>
                      </CardContent>
                      <Grid item lg={12} md={12} sm={12}>
                        <CardActions>
                          <LoadingButton loading={backendCall} variant="contained" color="primary"
                            fullWidth type="submit" disabled={!dirty || !isValid} >
                            Change Password </LoadingButton>
                        </CardActions>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid container item alignItems="center" justifyContent="center"
        lg={12} md={12} sm={12} xs={10} style={{ marginTop: "1rem" }}>
        {alert.showAlert && ( <AlertInlineMain setAlert={setAlert} alert={alert} /> )}
      </Grid>
    </Grid>
  );
}
export default SetPassword;
