import React, { createRef } from "react";
import Bar from "components/dashboard/BarChart2";
import { Grid, Card, CardContent } from "@mui/material";
import styles from "../dashboard.module.css";
import { useScreenshot, createFileName } from "use-react-screenshot";

export default function Sales() {
  const ref = createRef(null);
  // eslint-disable-next-line
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);
  return (
    <Card className={styles.dashboradStyle}>
      <CardContent  ref={ref}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Bar downloadScreenshot={downloadScreenshot} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
