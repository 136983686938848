import React, { useState } from "react";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Radio, FormControlLabel, FormControl,
  RadioGroup, FormLabel, Select, MenuItem, InputLabel, } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import AlertInlineMain from "components/common/AlertInlineMain";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { TextField as TF } from "formik-material-ui";

function CreateDeliveryTimeSlots({ setStatus, status }) {
  const axios = useAxiosPrivate()
  const [open, setOpen] = useState(false);
  const [zones, setZones] = useState([]);
  const [zone, setZone] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [disab, setDisabled] = useState(true);
  const [initialValues, setInitialValues] = useState({
    zone: "",
    limit: "",
    startTime: "",
    endTime: "",
    isPublished: false,
  });

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const handleClickOpen = () => {
    setInitialValues({
      zone: "",
      limit: "",
      startTime: "",
      endTime: "",
      isPublished: false,
    });
    setTimeout(() => {
      getZones();
      setOpen(true);
    }, 100);
    setStatus(!status);
  };

  const handleClose = () => {
    setOpen(false);
    setInitialValues({
      zone: "",
      limit: "",
      startTime: "",
      endTime: "",
      isPublished: "",
    });
    setStartTime(null);
    setEndTime(null);
    setZone("");
    setZones([]);
    setStatus(!status);
  };

  const handleZone = (e, setFieldValue) => {
    const val = e.target.value;
    setZone(val);
    setFieldValue("zone", val);
  };

  // get zones
  const getZones = async () => {
    try {
      const { data } = await axios.get("/createZone");
      const zoneArray = data.map((zone) => { return { value: zone._id, label: zone.zoneName, }; });
      setZones(zoneArray);
    } catch (err) {
      if (err.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: err.response.data.message,
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
          setOpen(false);
        }, 4000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Zone Loading Failed!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Zone Loading Failed!",
          });
          setOpen(false);
        }, 4000);
      }
    }
  };

  // insert data to database
  const onSubmit = async (e, { resetForm }) => {
    try {
      const data = {
        zone: e.zone,
        limit: e.limit,
        startTime: e.startTime,
        endTime: e.endTime,
        isPublished: e.isPublished,
      };
      resetForm();
      setStartTime(null);
      setEndTime(null);
      setZone("");
      setZones([]);
      await axios.post("/delivery/timeslot", {
        zone: data.zone,
        limit: data.limit,
        startTime: data.startTime,
        endTime: data.endTime,
        isPublished: data.isPublished,
      });
      setStatus(!status);
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Time slot created!",
      });
      
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "Time slot created!",
          message: "",
        });
        setOpen(false);
      }, 1000);
    } catch (err) {
      if (err.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: err.response.data.message,
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
          handleClose();
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Request Failed!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Request Failed!",
          });
          handleClose();
        }, 3000);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    zone: Yup.string().required("Zone is required!"),
    startTime: Yup.string().required("Start Time is required!"),
    endTime: Yup.string().required("End Time is required!"),
    limit: Yup.number().required("Limit is required")
      .typeError("Please enter a valid number")
      .min(0, "Minimum atleast 0")
      .test("is-decimal", "invalid decimal", (value) =>
        (value + "").match(/^\d*$/)
      ),
  });

  const convertTo24Hour = (e) => {
    return e?.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      hourCycle: 'h23',
    });
  };

  // endtime should be greater than starttime
  const timeChecker = (startT, endT) => {
    if (endT !== null && startT !== null) {
      if (startT.getTime() >= endT.getTime()) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "End time should be greater than start time!",
        });
        setTimeout(() => {
          setDisabled(true);
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 3000);
      } else {
        setDisabled(false);
      }
    }
  };
  const handleStartTimeChange = (e, setFieldValue) => {
    const time = convertTo24Hour(e);
    timeChecker(e, endTime);
    setStartTime(e);
    setFieldValue("startTime", time);

  };
  const handleEndTimeChange = (e, setFieldValue) => {
    const time = convertTo24Hour(e);
    timeChecker(startTime, e);
    setEndTime(e);
    setFieldValue("endTime", time);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen}> <h3>Create Time Slot</h3> </Button>
      <Dialog fullWidth maxWidth={"md"} open={open} onClose={handleClose} >
        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} >
          {({ isValid, handleChange, resetForm, setFieldValue, }) => {
            return (
              <Form noValidate autoComplete="off" encType="multipart/form-data">
                <DialogTitle style={{ cursor: "move" }} id="time-slot-create-id" > Create Delivery Time Slot
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item mt={1} xs={12} md={12} xl={12}>
                      <FormControl fullWidth>
                        <InputLabel id="zone-selector-name">Zone</InputLabel>
                        <Select labelId="zone-selector" id="zone-selector" name="zone" value={zone} label="Zone"
                          onChange={(e) => handleZone(e, setFieldValue)} >
                          {zones?.map((option) => ( <MenuItem key={option.value} value={option.value}> {option.label} </MenuItem> ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid container item spacing={1} justifyContent={"flex-middle"} >
                      <Grid item lg={6} md={6} sm={12} xs={12} >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker label="Start Time" name="startTime" value={startTime} closeOnSelect={true}  placeholder="Start Time"
                            onChange={(e) => handleStartTimeChange(e, setFieldValue) } renderInput={(params) => <TextField fullWidth {...params} />} />
                        </LocalizationProvider>
                      </Grid> 
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker label="End Time" name="endTime" value={endTime} closeOnSelect={true} placeholder="End Time"
                            onChange={(e) => handleEndTimeChange(e, setFieldValue) } renderInput={(params) => <TextField fullWidth {...params} />} />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Field name="limit" label="Limit" variant="outlined" style={{ display: "flex", }} sx={{ marginTop: { lg: "1rem", md: 0, sm: 0, xs: 0, }, }} component={TF} />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12} >
                        <FormControl>
                          <FormLabel id="time-slots-radio-buttons"> Publish Time Slot </FormLabel>
                          <RadioGroup name="isPublished" defaultValue={false}
                            onChange={(e) => { handleChange(e); setFieldValue("isPublished", e.target.value); }} >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {alert.showAlert && ( <Grid item md={12} m={2}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid> )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button color="success" onClick={onSubmit} type="submit" disabled={!isValid && disab} > Save </Button>
                  <Button color="error" onClick={handleClose}> Close </Button>
                </DialogActions>
              </Form>  ); }}
        </Formik>
      </Dialog>
    </> ); }
export default CreateDeliveryTimeSlots;
