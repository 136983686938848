import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider, unstable_createMuiStrictModeTheme as createTheme, } from "@material-ui/core/styles";
import NavBar from "../src/components/layouts/NavBar";
import Drawer from "./components/layouts/Drawer";
import "../src/index.css";
import purple from "@material-ui/core/colors/purple";
import { Provider as ReduxProvider } from "react-redux";
import store from "./store/store";
import Loader from "./components/UI/Loader";
import ProtectedRoute from "./components/common/ProtectedRoute";
import { routes } from "./routes";
import Login from "../src/components/layouts/Login";
import ForgotPassword from "../src/components/layouts/ForgotPassword";
import SetPassword from "../src/components/layouts/SetPassword";
import NotFound from "../src/components/layouts/PageNotFound";
import PermissionDenied from "../src/components/layouts/PermissionDenied";
import Auth from "./components/common/Auth";
import ConfirmScreen from "components/franchiseeManagement/ConfirmationScreen";
import Payment from "components/franchiseeManagement/Payment";
import SalesConfig from "components/franchiseeManagement/SalesConfig";
import Success from "components/franchiseeManagement/SuccessPage";
import Fail from "components/franchiseeManagement/Fail";
import Verification from "components/franchiseeManagement/Verification";
import AwaitSignIn from './screens/awaitSignIn';

import * as Sentry from "@sentry/react";

const darkTheme = createTheme({ palette: { type: "dark", }, });


const lightTheme = createTheme({
  palette: {
    type: "light",
    secondary: {
      main: purple[500],
    },
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  self: {
    backgroundColor: "blue",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function App(props) {
  const classes = useStyles();

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={lightTheme}>
        <div className={classes.root}>
          <NavBar {...props}> </NavBar>
          <ThemeProvider theme={darkTheme}>
            <Drawer />
          </ThemeProvider>
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <Auth>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/await-signin" element={<AwaitSignIn />} />

                <Route
                  path="/permission-denied"
                  element={<PermissionDenied />}
                />
                ​
                {routes.map(({ element, path, name, role, roleTwo, roleThree, roleFour}, index) => (
                  <Route
                    key={index}
                    path={path}
                    element={<ProtectedRoute element={element} role={role} roleTwo={roleTwo} roleThree={roleThree} roleFour={roleFour}/>}
                  />
                ))}
                ​<Route component={NotFound}></Route>
                <Route path="/confirm" element={<ConfirmScreen />} />
                <Route path="/payment" element={<Payment />} />
                <Route path="/sales-config" element={<SalesConfig />} />
                <Route path="/success/:id" element={<Success />} />
                <Route path="/fail" element={<Fail />} />
                <Route path="/verification" element={<Verification />} />
                <Route path="/set-password/:email/:verifyTokens" element={<SetPassword />} />

              </Routes>
            </Auth>
          </Suspense>
        </div>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default Sentry.withProfiler(App);
// point to dev
