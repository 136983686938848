import React, { useState, useEffect, useCallback, useContext, useMemo } from "react";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from "@react-google-maps/api";
import { Skeleton, Grid, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setDepot } from "../../../src/store/actions/RouteAction";
import { JobContext } from '../../screens/Routing/jobContext';
import { setTimelineSelected } from "../../../src/store/actions/RouteAction";

const mapStyles = {
  height: "60vh",
  width: "100%",
};

const zoom = 10;

const options = {
  zoomControl: true,
  streetViewControl: true,
  mapTypeControl: true,
  fullscreenControl: true,
};

const pointSvg = "M49.001,0H10.999C4.934,0,0,4.934,0,10.999v38.003C0,55.066,4.934,60,10.999,60h38.002C55.065,60,60,55.066,60,49.001  V10.999C60,4.934,55.065,0,49.001,0z";
const depotSvg = "M 15.385 2.412 C 10.195 2.412 6 6.597 6 11.772 c 0 1.21 0.586 3.06 1.5 5.082 c 0.914 2.02 2.142 4.231 3.371 6.273 c 2.256 3.746 4.156 6.373 4.514 6.873 c 0.357 -0.5 2.258 -3.127 4.513 -6.873 c 1.23 -2.042 2.458 -4.252 3.372 -6.273 c 0.913 -2.022 1.5 -3.872 1.5 -5.083 c 0 -5.174 -4.196 -9.359 -9.385 -9.359 Z m 0 2 c 4.136 0 7.5 3.364 7.5 7.5 s -3.364 7.5 -7.5 7.5 s -7.5 -3.364 -7.5 -7.5 s 3.364 -7.5 7.5 -7.5 Z m 0 1 a 6.492 6.492 0 0 0 -6.5 6.5 c 0 3.596 2.904 6.5 6.5 6.5 s 6.5 -2.904 6.5 -6.5 s -2.904 -6.5 -6.5 -6.5 Z m 0 1.5 l 2.535 2.34 v -0.11 a 0.5 0.5 0 1 1 1 0 v 0.909 a 0.51 0.51 0 0 1 -0.012 0.113 l 0.977 0.9 h -1.242 v 4.846 h -2.4 v -3.115 h -1.716 v 3.115 h -2.4 v -4.846 h -1.242 l 4.5 -4.152 Z";

const MapContainer = ({ state }) => {
  const serviceTime = useSelector((state) => state.route.serviceTime) ?? sessionStorage.getItem("serviceTime");

  const [map, setMap] = useState(null);
  const { newJob } = useContext(JobContext);

  let dispatch = useDispatch();

  const jobs = useSelector((state) => state.route.jobs);
  const depot = useSelector((state) => state.route.depot);
  const timelineSelected = useSelector((state) => state.route.timelineSelected);
  const selectedOrderInTimeline = useSelector((state) => state.route.timelineSelected?.id);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_KEY,
    libraries: ["places"],
  });
  const [activeMarker, setActiveMarker] = useState(null);

  useEffect(() => {

    if(selectedOrderInTimeline){

      setActiveMarker(selectedOrderInTimeline)

    }else{

      setActiveMarker(null)

    }

  },[selectedOrderInTimeline])

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };


  const [google, setGoogle] = useState(window.google = window.google ? window.google : {});

  const onLoad = useCallback((map) => {
    // const bounds = new window.google.maps.LatLngBounds(center);
    setGoogle(window.google)
    // map.fitBounds(bounds);
    setMap(map)
    // eslint-disable-next-line
  }, [map])

  const [icon, setIcon] = useState(null);


  useEffect(() => {
    dispatch(setDepot());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // const [center, setCenter] = useState({
  //   lat: Number(depot?.location?.latitude),
  //   lng: Number(depot?.location?.longitude)
  // })

  const center = useMemo(() => { return { lat: Number(depot?.location?.latitude), lng: Number(depot?.location?.longitude) } }, [depot]);
  useEffect(() => {
    if (isLoaded) {
      setIcon({
        path: depotSvg, // svg here
        fillColor: depot?.color,
        fillOpacity: 0.7,
        strokeWeight: 0,
        scale: 2,
        anchor: new google.maps.Point(15, 30)
      });
    }

    // eslint-disable-next-line
  }, [state, isLoaded, depot]);

  const onUnmount = useCallback((map) => { setMap(null) }, []);


  return (isLoaded && depot?.location !== "undefined") ? (
    <Grid container >
      {/* Google Map Box */}
      <Grid item
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <GoogleMap
          center={center}
          zoom={zoom}
          mapContainerStyle={mapStyles}
          options={options}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={() => setActiveMarker(null)}
        >
          <Marker position={center} icon={icon} />


          {jobs?.map((job) => {
            const position = {
              lat: job?.orderID[0]?.orderLocation?.lat ?? center.lat,
              lng: job?.orderID[0]?.orderLocation?.lng ?? center.lng,
            }
            return (
              <Marker
                position={position}
                title={job?.orderID[0]?.orderId}
                label={job?.orderID[0]?.orderId}
                onClick={() => handleActiveMarker(job?.id)}
                icon={{
                  path: pointSvg, // svg here
                  fillColor: job?.vehicle?.color ?? '#000000',
                  fillOpacity: 1.0,
                  strokeWeight: (timelineSelected?.mapOrderId ?? timelineSelected?.id)===(job?.mapOrderId ?? job?.id) ? 4 : 0,
                  strokeColor: (timelineSelected?.mapOrderId ?? timelineSelected?.id)===(job?.mapOrderId ?? job?.id) ? "#4C9AFF" : "",
                  scale: 0.4,
                  anchor: isLoaded ? new google.maps.Point(15, 30) : null,
                }}

              >
                {(activeMarker === job?.id) ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <div>
                      <h1 style={{textTransform:"capitalize"}}>{job?.customerName}</h1>
                      <h3>Order Id:{" "}{job?.orderID[0]?.orderId}</h3>
                      <h3>Address:{" "}{job?.orderID[0]?.orderAddress}</h3>
                      <h3>Time Window:{" "}{job?.timeFrame}</h3>
                      <h3>Type:{" "}{job?.type}</h3>
                      {serviceTime !== null && <h3>Service Time:{" "}{Number(serviceTime) / 60} minutes</h3>}
                    </div>
                  </InfoWindow>
                ) : null}
              </Marker>
            );
          })}

          {newJob?.map((order) => {
            const position = {
              lat: Number(order?.lat) ?? center.lat,
              lng: Number(order?.lng) ?? center.lng,
            };
            return (
              <Marker
                position={position}
                onClick={() => handleActiveMarker(order?.id)}
                title={order?.mapOrderId}
                label={order?.mapOrderId}
                icon={{
                  path: pointSvg, // svg here
                  fillColor: order?.itemProps?.style?.background ?? '#000000',
                  fillOpacity: 1.0,
                  strokeWeight: timelineSelected?.mapOrderId===order?.mapOrderId ? 4 : 0,
                  strokeColor: timelineSelected?.mapOrderId===order?.mapOrderId ? "#4C9AFF" : "",
                  scale: 0.4,
                  anchor: isLoaded ? new google.maps.Point(15, 30) : null,
                }}
              >
                {(activeMarker === order?.id) ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <div>
                      <h1 style={{textTransform:"capitalize"}}>{order?.customerName}</h1>
                      <h3>Order Id:{" "}{order?.mapOrderId}</h3>
                      <h3>Address:{" "}{order?.address}</h3>
                      <h3>Time Window:{" "}{order?.timeFrame}</h3>
                      <h3>Type:{" "}{order?.type}</h3>
                    </div>
                  </InfoWindow>
                ) : null}
              </Marker>
            );
          })}

          <Button
            sx={{
              top: "60px",
              left: "10px",
              zIndex: "11"
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              map.panTo(center);
              map.setZoom(zoom);
            }}
          >
            Center Map
          </Button>
        </GoogleMap>
      </Grid>
    </Grid >
  ) : <Skeleton
    sx={{ bgcolor: "grey.900" }}
    variant="rectangular"
    width={"100%"}
    height={"100vh"}
  />;
};
export default MapContainer;
