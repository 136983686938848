import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import AlertInlineMain from "components/common/AlertInlineMain";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { TextField as TF } from "formik-material-ui";

function EditTimeSlots({ setOpen, open, editData, setStatus, status }) {
  const [disab, setDisabled] = useState(true);
  const axios = useAxiosPrivate()

  const [initialValues, setInitialValues] = useState({
    id: "",
    zone: "",
    limit: "",
    startTime: "",
    endTime: "",
    isPublished: "",
  });

  const timeSetter = (d) => {
    const hour = d?.split(":")[0];
    if (hour === "24" || hour === "00") { return new Date(2022, 0, 1, 0, d?.split(":")[1]); }
    else { return new Date(2022, 1, 1, hour, d?.split(":")[1]); }
  };

  const [startTime, setStartTime] = useState({});
  const [endTime, setEndTime] = useState({});

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  useEffect(() => {
    setInitialValues({
      id: editData?.id,
      zoneName: editData?.zoneName,
      zone: editData?.zone?._id,
      limit: editData?.limit,
      startTime: editData?.startTime,
      endTime: editData?.endTime,
      isPublished: editData?.isPublished,
    });
    setStartTime(timeSetter(editData?.startTime));
    setEndTime(timeSetter(editData?.endTime));
  }, [editData]);

  const handleClose = () => {
    setOpen(false);
    setStatus(!status);
  };

  // endtime should be greater than starttime
  const timeChecker = (startT, endT) => {
    if (endT !== null && startT !== null) {
      if (startT.getTime() >= endT.getTime()) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "End time should be greater than start time!",
        });
        setTimeout(() => {
          setDisabled(true);
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 3000);
      } else {
        setDisabled(false);
      }
    }
  };

  // update data to database
  const onSubmit = async (e, { resetForm }) => {
    try {
      const data = {
        id: e.id,
        zone: e.zone,
        limit: e.limit,
        startTime: e.startTime,
        endTime: e.endTime,
        isPublished: e.isPublished,
      };
      resetForm();
      await axios.put(`/${editData?.type === 'Pickup' ? 'schedules':'delivery'}/timeslot/${data.id}`, {
        zone: data.zone,
        limit: data.limit,
        startTime: data.startTime,
        endTime: data.endTime,
        isPublished: data.isPublished,
      });
      setStatus(!status);
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Time slot updated!",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "Time slot updated!",
          message: "",
        });
        setOpen(false);
      }, 1000);
    } catch (err) {
      if (err.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: err.response.data.message,
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
          handleClose();
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Request Failed!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Request Failed!",
          });
          handleClose();
        }, 3000);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    startTime: Yup.string().required("Start Time is required!"),
    endTime: Yup.string().required("End Time is required!"),
    limit: Yup.number()
      .typeError("Please enter a valid number")
      .min(0, "Minimum atleast 0")
      .test("is-decimal", "invalid decimal", (value) =>
        (value + "").match(/^\d*$/)
      ),
  });

  const convertTo24Hour = (e) => {
    return e?.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      hourCycle: 'h23',
    });
  };

  const handleStartTimeChange = (e) => {
    const time = convertTo24Hour(e);
    timeChecker(e, endTime);
    setStartTime(e);
    setInitialValues((prevState) => ({
      ...prevState,
      startTime: time,
    }));
  };

  const handleEndTimeChange = (e) => {
    const time = convertTo24Hour(e);
    timeChecker(startTime, e);
    setEndTime(e);
    setInitialValues((prevState) => ({
      ...prevState,
      endTime: time,
    }));
  };

  const handleispublished = (e) => {
    setInitialValues((prevState) => ({
      ...prevState,
      isPublished: e.target.value,
    }));
  };

  return (
    <>
      <Dialog fullWidth maxWidth={"md"} open={open} onClose={handleClose} >
        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} >
          {({ values, isValid, resetForm }) => {
            return (
              <Form noValidate autoComplete="off" encType="multipart/form-data">
                <DialogTitle style={{ cursor: "move" }} id="time-slot-create-id" >
                  {`Edit ${editData?.type} Time Slot`}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item mt={1} xs={12} md={12} xl={12}>
                      <Field labelId="zone-selector" id="zone-selector" name="zoneName" label="Zone" disabled={true}
                        component={TF} variant="outlined" style={{ marginTop: "1rem", display: "flex", }} >
                        {values.zoneName} </Field>
                    </Grid>
                    <Grid container item spacing={1} justifyContent={"flex-middle"} >
                      <Grid item lg={6} md={6} sm={12} xs={12} >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker label="Start Time" name="startTime" value={startTime} defaultValue={startTime} closeOnSelect={true}
                            onChange={handleStartTimeChange} renderInput={(params) => <TextField fullWidth {...params} />} />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker label="End Time" name="endTime" value={endTime} closeOnSelect={true} onChange={handleEndTimeChange}
                            renderInput={(params) => <TextField fullWidth {...params} />} />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Field name="limit" label="Limit" variant="outlined" style={{ display: "flex", }}
                          sx={{ marginTop: { lg: "1rem", md: 0, sm: 0, xs: 0, }, }} component={TF} />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12} >
                        <FormControl>
                          <FormLabel id="time-slots-radio-buttons"> Publish Time Slot </FormLabel>
                          <RadioGroup name="isPublished" value={values.isPublished} onChange={handleispublished} defaultValue={values.isPublished} >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {alert.showAlert && (<Grid item md={12} m={2}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid>)}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button color="success" onClick={onSubmit} type="submit" disabled={!isValid && disab} > Save </Button>
                  <Button color="error" onClick={handleClose}> Close </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
}
export default EditTimeSlots;
