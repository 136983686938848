import Dashboard from './screens/Dashboard';
import FranchiseeDashboard from './screens/FranchiseeDashboard';
import Reports from './screens/Reports';
import Request from './components/manageRequest/Request';
import RequestView from './components/manageRequest/RequestView';
import FolderView from './screens/learning/FolderView';
import LwlServices from './screens/LwlServices';
import RoyaltyManagement from './components/royalty/RoyaltyManagement';
import RoyaltySchems from './components/royalty/RoyaltySchems';
import ViewRoyaltyPayments from './components/royalty/ViewRoyaltyPayments';
import ServiceTwo from './components/services/ServiceTwo';
import ServiceOneView from './components/services/ServiceOneView';
import FranchiseeFolderView from 'screens/learning/FranchiseeFolderView';
import FranchiseeMgt from './components/franchiseeManagement/FranchiseeMgt';
import FranchiseeManagementView from 'screens/FranchiseeManagement';
import ChangePassword from 'components/layouts/ChangePassword';
import AccountManagement from 'components/franchiseeManagement/AccountMenagment';
import FranchiseeListView from 'screens/learning/FranchiseeListView';
import CorporateListView from 'screens/learning/CorporateListView';
import RoyaltyPayment from './screens/RoyaltyPayment';
import FranchiseeReports from 'components/franchiseeManagement/Reports';
import CustomerReport from 'components/franchiseeManagement/CustomerReport';
import SalesReport from 'screens/reports/Sales';
import PaymentReport from 'screens/reports/Payments';
import Delivery from 'screens/reports/franchisee/Delivery';
import DryCleanService from 'components/franchiseeManagement/DryCleanService';
import TailoringAndAlterations from 'components/franchiseeManagement/TailoringAndAlterations';
import FranchiseeRequestView from 'screens/FranchiseeRequest';
import CreateZone from 'components/franchiseeManagement/CreateZone';
import ViewZone from 'components/franchiseeManagement/ZoneFeture';
import FranchiseeAccount from 'components/franchiseeManagement/Account';
import Scheduling from './screens/Scheduling';
import Schedules from './screens/scheduling/Schedules';
import PickUpTimeSlots from 'screens/scheduling/PickUpTimeSlots';
import DeliverySchedules from './screens/scheduling/DeliverySchedules';
import DeliveryTimeSlots from 'screens/scheduling/DeliveryTimeSlots';
import OrderManagement from './screens/orders/OrderManagement';
// franchisee management reports
import FranchiseeSalesReport from 'screens/reports/franchisee/Sales';
import FranchiseeCustomerReport from 'screens/reports/franchisee/CustomerReport';
// Customer XP
import Customers from './screens/reward/Customers';
//Routing
import Routing from './screens/Routing/Main';
import Depot from "./screens/Depot/Main";
import Jobs from "./screens/jobs/Main";
import CustomOrders from "./screens/CustomOrders/CustomOrders";
// pickup abd delivery schedules
import GetPickups from './screens/scheduling/reports/GetPickups';
import GetDeliveris from './screens/scheduling/reports/GetDeliveris';
import ManagersView from './screens/userManagement/userView';
import CreateUser from './screens/userManagement/createUser';
import CorporateReport from 'screens/reports/franchisee/CorporateReport';
import JobSearch from 'screens/jobs/JobSearch';
// import CronjobTable from './screens/Routing/cronjobTableView';
// import Matrix from './screens/Matrix/matrixView';

export const routes = [
  { name: 'Dashboard', element: <Dashboard />, path: '/dashboard', role: 'franchisor', },
  { name: 'Dashboard', element: <FranchiseeDashboard />, path: '/franchisee-dashboard', role: 'franchisee',  roleThree: 'corporate', roleFour:'manager' },
  { name: 'Schedule', element: <Scheduling />, path: '/schedule', role: 'franchisee', roleThree:'corporate', roleFour:'manager'  },
  { name: 'Orders', element: <OrderManagement />, path: '/orders', role: 'franchisee', roleThree:'corporate', roleFour:'manager'  },
  { name: 'Request View', element: <FranchiseeRequestView />, path: '/franchisee-request-view', role: 'franchisee',  roleThree:'corporate', roleFour:'manager' },
  { name: 'Customers', element: <Customers />, path: '/customer-xp', role: 'franchisee', roleTwo: "franchisor", roleThree:'corporate', roleFour:'manager' },
  { element: <FranchiseeMgt />, path: '/create-franchisee', role: 'franchisor', },
  { name: 'Users Management', element: <ManagersView />, path: '/managers-view', role: 'franchisee', roleThree:'corporate'},
  { element: <FranchiseeAccount />, path: '/franchisee-account', role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  { element: <CreateZone />, path: '/create-zone', role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  { element: <ViewZone />, path: '/view-zone', role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  { element: <DryCleanService />, path: '/dry-clean-service', role: 'franchisor', },
  { element: <TailoringAndAlterations />, path: '/tailoring-and-alterations', role: 'franchisor', },
  { path: '/franchisee-reports', element: <FranchiseeReports />, role: 'franchisee', roleTwo: "franchisor",  roleThree:'corporate', roleFour:'manager' },
  { path: '/customer-reports', element: <CustomerReport />, role: 'franchisor', },
  { path: '/sales-report', element: <SalesReport />, role: 'franchisor', },
  { path: '/payment-report', element: <PaymentReport />, role: 'disabled', },
  { path: '/schedules', element: <Schedules />, role: 'franchisee', roleThree:'corporate', roleFour:'manager'},
  { path: '/pick-up-time-slots', element: <PickUpTimeSlots />, role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  { path: '/delivery-schedules', element: <DeliverySchedules />, role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  { path: '/delivery-time-slots', element: <DeliveryTimeSlots />, role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  { element: <FranchiseeMgt />, path: '/franchiseeMgt', role: 'franchisor', },
  { name: 'Franchisee Mgt', element: <FranchiseeManagementView />, path: '/view-franchisee', role: 'franchisor', },
  { name: 'Franchisee List View', element: <FranchiseeListView />, path: '/franchiseeListView', role: 'franchisor', },
  { name: 'Franchisee List View', element: <CorporateListView />, path: '/corporateListView', role: 'franchisor', },
  { path: '/update-franchisee', element: <FranchiseeMgt />, isHidden: true, role: 'franchisor', },
  { name: 'LWL Request', path: '/lwl-request', element: <Request />, role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  { name: 'Account Management', path: '/account-management', element: <AccountManagement />, role: 'franchisee', roleThree:'corporate', roleFour:'manager'  },
  { name: 'Franchisee Learning', path: '/FranchiseeFolderView', element: <FranchiseeFolderView />, role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  { name: 'Royalty Payment', path: '/RoyaltyPayment', element: <RoyaltyPayment />, role: 'franchisee', roleFour:'manager'  },
  { name: 'LWL Request view', path: '/lwl-request-view', element: <RequestView />, role: 'franchisor', },
  { name: 'LWL Learning', path: '/lwl-learning', element: <FolderView />, role: 'franchisee', roleTwo: "franchisor", roleFour:'manager', roleThree:'corporate' },
  { name: 'View-Wash/Dry/Fold', path: '/lwl-service-1-view', element: <ServiceOneView />, role: 'franchisor', },
  { name: 'DRY CLEANING', path: '/dry-cleaning', element: <ServiceTwo />, role: 'franchisor', },
  { name: 'Royalty Management', path: '/royalty-management', element: <RoyaltyManagement />, role: 'franchisor', },
  { name: 'Royalty Payments', path: '/royalty-payments', element: <ViewRoyaltyPayments />, role: 'franchisor', },
  { name: 'Royalty Schemes', path: '/royalty-schemes', element: <RoyaltySchems />, role: 'franchisor', },
  { name: 'LWL Services', path: '/lwl-services', element: <LwlServices />, role: 'franchisee', roleTwo: "franchisor", roleThree:'corporate', roleFour:'manager' },
  { name: 'Reports', path: '/reports', element: <Reports />, role: 'franchisor', },
  { path: '/change-password', element: <ChangePassword />, isHidden: true, role: 'franchisee', roleTwo: "franchisor", roleThree:'corporate', roleFour:'manager' },
  { path: '/franchisee-sales-report', element: <FranchiseeSalesReport />, role: 'franchisee', roleTwo: "franchisor", roleThree:'corporate', roleFour:'manager' },
  { path: '/franchisee-customer-reports', element: <FranchiseeCustomerReport />, role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  { path: '/franchisee-delivery-report', element: <Delivery />, role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  { path: '/routing-main', element: <Routing />, role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  { path: '/depot-main', element: <Depot />, role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  { path: '/jobs', element: <Jobs />, role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  { path: '/jobs-search', element: <JobSearch />, role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  { path: '/custom-orders', element: <CustomOrders />, role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  // { path: '/matrix', element: <Matrix />, role: 'franchisee', },
  { path: '/get-pick-up', element: <GetPickups />, role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  { path: '/get-delivery', element: <GetDeliveris />, role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  // { path: '/routing-cronjob', element: <CronjobTable />, role: 'franchisor', },

  { path: '/create-manager', element: <CreateUser />, role: 'franchisee', roleThree:'corporate', roleFour:'manager' },
  { path: '/update-manager', element: <CreateUser />, isHidden: true,  role: 'franchisee', roleThree:'corporate', roleFour:'manager'  },
  { path: '/corporate-payments-report', element: <CorporateReport />, roleThree:'corporate', roleFour:'manager'  },



];
