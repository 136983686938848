import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Chip, Button } from '@mui/material';
import DataGridTable from '../../layouts/DataGrid';
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import RoyaltyPaymentsPopup from '../RoyaltyPaymentsPopup';
import AlertInlineMain from "components/common/AlertInlineMain";
const calculateRoyaltyBalance = (payments, balance) => {
  let royaltyBalance = 0;
  payments?.forEach((element) => {
    royaltyBalance += element?.royaltyBalance;
  });
  royaltyBalance = royaltyBalance - balance 
  return royaltyBalance.toFixed(2);
}
const numberWithCommas = (x) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default function RoyaltyPaymentHistory() {
  const id = window.sessionStorage.getItem('id');
  const axios = useAxiosPrivate()
  const [status, setStatus] = useState(false);
  const [statusNew, setStatusNew] = useState(false);
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState(id);
  const [payments, setPayments] = useState([]);
  const [balance, setAccBalance] = useState(0);
  const [amount, SetAmount] = useState(0);
  const [valLoading, setvalLoading] = useState(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const onClick = () => {
    // get id from local storage
    setRow(id);
    setOpen(true);
  };

  const getAccountBalance = async () => {
    try {
      setvalLoading(true);
      const { data } = await axios.get('/royalty/account-balance');
      setAccBalance(data?.balance);
      setvalLoading(false);
    } catch (e) {
      setvalLoading(false);
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Data loading failed',
      });
    }
  };

  const getBalance = async () => {
    try {
      const { data } = await axios.get('/orders/get-account-balance');
      SetAmount(data);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Account Balance failed!',
      });
      setTimeout(() => {
        setAlert({ showAlert: false, severity: 'error', message: '' });
      }, 3000);
    }
  };

  const getRoyaltyPayments = async () => {
    try {
      setvalLoading(true);
      const { data } = await axios.get('/royalty/royalty-payment-history/');
      setPayments(data);
      setvalLoading(false);
    } catch (e) {
      setvalLoading(false);
      setAlert({
        showAlert: true,
        severity: 'error',
        message: 'Data loading failed',
      });
    }
  };

  const royaltyBalance = useMemo(() => calculateRoyaltyBalance(payments, balance), [payments, balance]);
  useEffect(() => {
    setStatus(!status);
    getAccountBalance();
    getBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRoyaltyPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance]);

  const columns = [
    { field: 'salesMonth', headerName: 'SALES MONTH', minWidth: 100, flex: 0.2,
      valueGetter: (params) => {
        function getMonth(month) {
          let monthName;
          switch (month) {
            case 1:
              monthName = ' January';
              break;
            case 2:
              monthName = ' February';
              break;
            case 3:
              monthName = ' March';
              break;
            case 4:
              monthName = ' April';
              break;
            case 5:
              monthName = ' May';
              break;
            case 6:
              monthName = ' June';
              break;
            case 7:
              monthName = ' July';
              break;
            case 8:
              monthName = ' August';
              break;
            case 9:
              monthName = ' September';
              break;
            case 10:
              monthName = ' October';
              break;
            case 11:
              monthName = ' November';
              break;
            case 12:
              monthName = ' December';
              break;

            default:
              break;
          }
          return monthName;
        }
        return `${params.row._id?.year ? String(params.row._id?.year) + getMonth(params.row._id?.month) : '' }`;
      },
    },
    { field: 'totalSaleAmount', headerName: 'SALES($)', minWidth: 100, flex: 0.5, align: "right", headerAlign: 'right',
      valueGetter: (params) => {
        function calculateSales(value) { return numberWithCommas(Number(value).toFixed(2)); }
        return `$${calculateSales(params.row.totalSaleAmount)}`; },
    },
    { field: 'royaltyTotal', headerName: 'ROYALTY FEES($)', minWidth: 100, flex: 0.5, align: "right", headerAlign: 'right',
      valueGetter: (params) => {
        function calculateRoyaltyTotal(royaltyBalance) {
          return numberWithCommas(Number(royaltyBalance).toFixed(2)); }
        return `$${calculateRoyaltyTotal(params.row.royaltyTotal ?? 0)}`; },
    },
    { field: 'status', headerName: 'Status', width: 130,
      renderCell: (params) => {
        return params.row.royaltyBalance <= 0 ? (<Chip label='Paid' color='success' variant='outlined' />
        ) : ( <Chip label='Not Paid' color='error' variant='outlined' /> ); },
      valueGetter: (params) => `${params.row.royaltyBalance <= 0 ? "Paid" : "Not Paid"}`
    },
  ];

  return (
    <Grid container spacing={1}>
      <Grid container item
        justifyContent={{ lg:'flex-end', md:'flex-end', sm:'center', xs:'center', }} 
        xs={12} md={12} marginTop={5} pr={{ lg:5, md:5, sm:0, xs:0, }} pb={1} >
        Amount payable($) : {!valLoading ? numberWithCommas(amount ?? 0) : "Loading..."} </Grid>
      <Grid container item justifyContent={{ lg: 'flex-end', md: 'flex-end', sm: 'center', xs: 'center', }}
        xs={12} md={12} pr={{ lg:5, md:5, sm:0, xs:0, }} pb={1} style={{ fontWeight: 'bold' }} >
        Royalty Balance($) : {!valLoading ? numberWithCommas(royaltyBalance && royaltyBalance !== "NaN" ? royaltyBalance : 0) : "Loading..."}
      </Grid>
      <Grid item xs={12} md={12} >
        <DataGridTable fetchDataUrl={`/royalty/royalty-payment-history/`} columns={columns} status={status} title='Royalty Payments History' />
        {alert.showAlert && ( <Grid item md={12} m={2}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid> )}
      </Grid>
      <Grid container item xs={12} md={12} sx={{ flexDirection: 'row', justifyContent: 'right', alignItems: 'right', }} >
        <Button variant='contained' color='primary' onClick={onClick}> Royalty Pay History </Button>
      </Grid>
      <RoyaltyPaymentsPopup setOpen={setOpen} open={open} row={row} setStatus={setStatusNew} status={statusNew} />
    </Grid>
  );
}
