import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import AlertInlineMain from "components/common/AlertInlineMain";
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from '@mui/material/TextField';
import { Form, Formik, Field } from 'formik';
import { TextField as TF } from 'formik-material-ui';
import CreateTimeSlotsFunctions from './CommonFunctions/CreateTimeSlotsFunctions';

function CreateTimeSlots({ setStatus, status }) {

  const [scheduleType, setScheduleType] = useState(1);
  const [isStartingTimeInvalid, setIsStartingTimeInvalid] = useState(true);
  const [isEndingTimeInvalidTest, setIsEndingTimeInvalid] = useState(true);

  const { open, zones, zone, disab, initialValues, alert, handleClickOpen,
    handleZone, onSubmit, validationSchema, handleStartTimeChange, handleEndTimeChange,
    handleClose, startTime, endTime, setAlert } = CreateTimeSlotsFunctions({ setStatus, status, scheduleType });
    
    const handleChangeScheduleType = (e) => {
      setScheduleType(e?.target?.value)
  }

  useEffect(() => {
    setIsEndingTimeInvalid(true)
    setIsStartingTimeInvalid(true)
  },[open])

  const isStartTimeInvalid = (e, setFieldValue) => {
    if(isNaN(new Date(e))){
      setIsStartingTimeInvalid(true)
    }else{
      handleStartTimeChange(e, setFieldValue)
      setIsStartingTimeInvalid(false)
    }
  }

  const isEndTimeInvalid = (e, setFieldValue) => {
    if(isNaN(new Date(e))){
      setIsEndingTimeInvalid(true)
    }else{
      handleEndTimeChange(e, setFieldValue)
      setIsEndingTimeInvalid(false)
    }
  }


  return (
    <>
      <Button variant='contained' color='primary' onClick={handleClickOpen}> <h3>Create Time Slot</h3> </Button> 
      <Dialog fullWidth={'md'} maxWidth={'md'} open={open} onClose={handleClose} >
        <Formik enableReinitialize initialValues={initialValues} onSubmit={(e) => onSubmit(e)} validationSchema={validationSchema} >
          {({ isValid, handleChange, setFieldValue, }) => {
            return (
              <Form noValidate autoComplete='off' encType='multipart/form-data'>
                <DialogTitle style={{ cursor: 'move' }} id='time-slot-create-id' > Create Time Slot </DialogTitle>
                <DialogContent>

                <Grid container item mt={1} xs={12} md={6} xl={6} justifyContent={"start"}>
                          <FormControl style={{padding:"1px", minWidth:"127px", marginBottom:"10px"}}>
                          <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={scheduleType}
                          onChange={handleChangeScheduleType}
                          inputProps={{ 'aria-label': 'Without label' }}
                          > 
                          <MenuItem value={1}>Pickups</MenuItem>
                          <MenuItem value={2}>Deliveries</MenuItem>
                        </Select>
                        </FormControl>
                        </Grid>

                  <Grid container spacing={2}>
                    <Grid item mt={1} xs={12} md={12} lg={12} xl={12}>
                      <FormControl fullWidth>
                        <InputLabel id='zone-selector-name'>Zone</InputLabel>
                        <Select labelId='zone-selector' id='zone-selector' name='zone' value={zone} label='Zone' onChange={(e) => handleZone(e, setFieldValue)} >
                          {zones?.map((option) => ( <MenuItem key={option.value} value={option.value}> {option.label} </MenuItem> ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid container item spacing={1} justifyContent={'flex-middle'} >
                      <Grid item lg={6} md={6} sm={12} xs={12} >
                        <LocalizationProvider data-testid="start-time" dateAdapter={AdapterDateFns}>
                          <TimePicker  label='Start Time' name='startTime' value={startTime} placeholder='Start Time' closeOnSelect={true}
                            onChange={(e) => isStartTimeInvalid(e, setFieldValue) } renderInput={(params) => <TextField fullWidth {...params} />} />
                        </LocalizationProvider>
                      </Grid> 
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <LocalizationProvider data-testid="end-time" dateAdapter={AdapterDateFns}>
                          <TimePicker  label='End Time' name='endTime' value={endTime} placeholder='End Time' closeOnSelect={true}
                            onChange={(e) => isEndTimeInvalid(e, setFieldValue) } renderInput={(params) => <TextField fullWidth {...params} />} />
                        </LocalizationProvider>
                      </Grid> 
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Field data-testid="limit" name='limit' label='Limit' variant='outlined' style={{ display: "flex", }} sx={{ marginTop: { lg: "1rem", md: 0, sm: 0, xs: 0, }, }} component={TF} />
                      </Grid> 
                      <Grid item lg={6} md={6} sm={6} xs={12} > 
                        <FormControl> <FormLabel id='time-slots-radio-buttons'> Publish Time Slot </FormLabel>
                          <RadioGroup name='isPublished' defaultValue={true}
                            onChange={(e) => { handleChange(e); setFieldValue('isPublished', e.target.value); }} >
                            <FormControlLabel value={true} control={<Radio />} label='Yes' />
                            <FormControlLabel value={false} control={<Radio />} label='No' />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {alert.showAlert && ( <Grid item md={12} m={2}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid> )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button color='success' onClick={onSubmit} type='submit' disabled={(!isValid || disab) && (isStartingTimeInvalid || isEndingTimeInvalidTest)} > Save </Button>
                  <Button color='error' onClick={handleClose}> Close </Button>
                </DialogActions> </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
}
export default CreateTimeSlots;
