import React from "react";
import DataGrid from "components/layouts/DataGrid";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

export default function CustomerReport() {
  let navigate = useNavigate();

  const onGoback = () => {
    navigate('/franchisee-reports')
  } 
  const columns = [
    { field: "name", headerName: "Name", minWidth: 150 },
    { field: "address", headerName: "Address", minWidth: 480, },
    { field: "email", headerName: "Email", minWidth: 250 },
    {
      field: "contactNumber",
      headerName: "Contact Number",
      minWidth: 150,
    },
    { field: "totalSaleAmount", headerName: "Sales", minWidth: 150 },
    {
      field: "createdAt",
      headerName: "Created Date",
      minWidth: 250,

      valueGetter: (params) => {
        return `${moment(params.row.createdAt).format("MMMM Do YYYY")}`;
      },
    },
  ];

  return (
    <div>
              <Grid container justifyContent="space-between">

<Grid item >
  <IconButton onClick={()=>onGoback()}>
    <ArrowBackIcon sx={{ color: "#343434", }} />
  </IconButton>
</Grid>


  <Grid item >
  <div style={{width:'10px'}}></div>
</Grid>
  </Grid>
      <DataGrid
        autoHeight
        columns={columns}
        fetchDataUrl="/user/getCustomerData"
        // status={status}
        title="Customer Report"
        fixHeight={true}
      />
    </div>
  );
}
