import React, { useState, useEffect } from 'react';

import { DateRangePicker } from 'react-date-range';
import { Button, Dialog, DialogActions } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export default function BasicDateRangePicker(props) {
  const { value, setValue } = props;
  const [open, setOpen] = useState(false);

  const Today = new Date();
  const firstDay = new Date(Today.getFullYear(), Today.getMonth(), 1);
  const lastDay = new Date(Today.getFullYear(), Today.getMonth() + 1, 0);

  const handleClickOpen = () => {
    setValue({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    });
    setOpen(true);
  };

  const handleSlenRangeChange = (newRange) => {
    if (newRange.selection) {
      const newValue = {
        startDate: newRange.selection.startDate ?? newRange.startDate,
        endDate: newRange.selection.endDate ?? newRange.endDate,
        key: newRange.selection.key,
      };

      setValue(newValue);
      
    } else if (newRange.endDate && newRange.startDate) { 
      const newValue = {
        startDate: newRange.startDate,
        endDate:  newRange.endDate,
      };

      setValue(newValue);
    }
    
    
  };

  useEffect(() => {
   const sendVal = {
     startDate: firstDay,
     endDate: lastDay
    }
    handleSlenRangeChange(sendVal);
// eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <>
      <Button variant='contained' onClick={handleClickOpen}>
        <DateRangeIcon />
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='form-dialog-title'
      >
        <DateRangePicker ranges={[value]} onChange={handleSlenRangeChange} />
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              props.setStatus ? props.setStatus(!props.status) : void 0;
              props.trigger ? props.trigger() : void 0;
            }}
            color='primary'
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
