import React, {useState, useEffect} from 'react';
import { Grid } from "@mui/material/";
import Navigation from "components/jobs/Navigation";
import Header from 'components/routing/Header';
import { setDepot } from "../../store/actions/RouteAction";
import { useDispatch } from "react-redux";

export default function Main() {
    let dispatch = useDispatch();
    const franchiseeTimeZone = sessionStorage.getItem("franchiseeTimeZone");
    const today = new Date();
    const todayDateFromTimezone = new Date(today.toUTCString('en-US', { timeZone: `${franchiseeTimeZone}` }).slice(0, -4))
    todayDateFromTimezone.setHours(0, 0, 0)
    const [date, setDate] = useState(todayDateFromTimezone);

  useEffect(() => {
    dispatch(setDepot());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
    
    return (
        <Grid container >
            <Grid item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            >
            <Header navFrom={"jobs"}/>
            </Grid>
            <Grid item
                lg={12}
                md={12}
                sm={12}
                xs={12}
            >
                <Navigation date={date} setDate={setDate} />
            </Grid>
        </Grid>
    );
}