import React, { useState } from 'react';
import SalesFigure from './SalesFigure';
import { useNavigate } from "react-router-dom";

import { Grid } from '@mui/material';
import DaywiseSales from './DaywiseSales';
import LineChart from './LineChart';
import SalesOverview from 'components/reports/salesOverview';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import OrderWeightReport from './OrderWeightReport';

export default function Sales() {
  let navigate = useNavigate();
  const [salesDetails, setSalesDetails] = useState({});


  const onGoback = () => {
    navigate('/franchisee-reports')
  }
  return (
      <>
   <Grid container justifyContent="space-between">

<Grid item >
  <IconButton onClick={()=>onGoback()} style={{marginLeft:"30px"}}>
    <ArrowBackIcon sx={{ color: "#343434", }} />
  </IconButton>
</Grid>


  <Grid item >
  <div style={{width:'10px'}}></div>
</Grid>
  </Grid>

       <Grid container rowSpacing={2}
      alignItems="center"
      justifyContent="center"
    >

      <Grid container item
        lg={10}
        md={10}
        sm={10}
        xs={11}
        alignItems="center"
        justifyContent="center"
      >

        <Grid item mt={2}
          lg={3}
          md={4}
          sm={6}
          xs={10}>
              
      
          <SalesFigure setSalesDetails={setSalesDetails}/>
        </Grid>


        <Grid item
          mt={10}
          mb={5}
          lg={10}
          md={12}
          sm={12}
          xs={12}>
          <LineChart />
        </Grid>


        {/* <Grid item mt={2}
        lg = {2}
        md = {2}
        sm = {2}
        xs = {2}>
          <SalesFigure />
        </Grid> */}

      </Grid>
      <Grid item ml={1} mt={2}
        lg={12}
        md={12}
        sm={12}
        xs={12}>
       <SalesOverview salesDetails={salesDetails} />
      </Grid>

      <Grid item ml={1} mt={2}
        lg={12}
        md={12}
        sm={12}
        xs={12}>
        <DaywiseSales />
      </Grid>

      <Grid item ml={1} mt={2}
        lg={12}
        md={12}
        sm={12}
        xs={12}>
        <OrderWeightReport />
      </Grid>
    </Grid>
      </>
    
   
  );
}
