import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material/';
import RouteNav from 'components/routing/RouteNav';
import { Alert, Dialog } from "@mui/material";

import Header from 'components/routing/Header';
import Details from 'components/routing/Details';
import { hideNav } from 'store/actions/headerActions';
import { useNavigate } from 'react-router-dom';
import Snackbar from "@mui/material/Snackbar";

import { useSelector, useDispatch } from "react-redux";

import { JobContext } from "./jobContext";
import { Typography } from '@material-ui/core';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";

export default function Main() {
  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  let dispatch = useDispatch();

  const depot = useSelector((state) => state.route.depot);

  const today = new Date();

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "error",
    message: "",
  });

  const [driverAlert, setDriverAlert] = useState({
    showAlert: false,
    severity: "error",
    message: "",
  });

  const [vehicleAlert, setVehicleAlert] = useState({
    showAlert: false,
    severity: "error",
    message: "",
  });

  const [newJob, setNewJobs] = useState([]);
  const [vehicles, setVehicleDetails] = useState([]);
  const [value, setValue] = useState(today);
  const [shared, setShared] = useState(false);
  const [checkDispatchButton, setCheckDispatchButton] = useState(false);
  const [vehiclesInfo, setVehiclesInfo] = useState(null)
  const [driverInfo, setDriverInfo] = useState(null)


  const getVehicals = async () => {
    const { data } = await axios.get("/vehicle");
    setVehiclesInfo(data)
  }

  const getDrivers = async () => {
    const { data } = await axios.get("/driver");
    setDriverInfo(data)
  }

  useEffect(() => {
    dispatch(hideNav());
    getVehicals();
    getDrivers();
    //eslint-disable-next-line
  }, []);

  const setNewJob = (array) => {
    setNewJobs(array);
  }

  const setVehicles = (array) => {
    setVehicleDetails(array);
  }

  //depot alert

  useEffect(() => {

    if (depot === null) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Depot details missing. Create depot first.",
      });

      setTimeout(() => {
        navigate('/depot-main');
      }, 4000);
    }
    // eslint-disable-next-line
  }, [depot])


  //vehicle alert
  useEffect(() => {
    if (vehiclesInfo?.length === 0) {
      setVehicleAlert({
        showAlert: true,
        severity: "error",
        message: "Vehicle details missing. Please Create a Vehicle.",
      });

      setTimeout(() => {
        setVehicleAlert({
          showAlert: false,
          severity: "error",
          message: "",
        });
      }, 4000);

    }
  }, [vehiclesInfo])


  //driver alert
  useEffect(() => {

    if (driverInfo?.length === 0) {
      setDriverAlert({
        showAlert: true,
        severity: "error",
        message: "Driver details missing. Please Create a Driver.",
      });

      setTimeout(() => {
        setDriverAlert({
          showAlert: false,
          severity: "error",
          message: "",
        });
      }, 4000);
    }
  }, [driverInfo])

  const [refreshTimeLine, setRefreshTimeLine] = useState(false); // State to control refresh

  // Function to trigger refresh
  const triggerRefresh = () => {
    setRefreshTimeLine((prev) => !prev); // Toggle refresh state
  };

  // Handler for successful delete
  const handleDeleteSuccess = useCallback(() => {
    triggerRefresh(); // Trigger refresh in TimeLine
  }, []);



  return (
    <>
      <JobContext.Provider value={{
        newJob, setNewJob, vehicles, setVehicles, shared, setShared, checkDispatchButton,
        setCheckDispatchButton
      }}>
        <Header navFrom={"routing"} />

        <Dialog
          open={alert.showAlert}
          onClose={() =>
            setAlert({
              ...alert,
              showAlert: false,
            })} >
          <Alert
            severity={alert.severity}
            sx={{
              fontSize: "16px",
            }}

            onClose={() =>
              setAlert({
                ...alert,
                showAlert: false,
              })}
          >
            <Typography style={{ textAlign: "center", fontSize: "1.4rem" }}>
              <span style={{ fontWeight: "bold", color: "red" }}>Error!</span> {alert.message}<br></br>
              <span style={{ fontWeight: "bold" }}>redirecting...</span>
            </Typography>
          </Alert>
        </Dialog>

        <Snackbar
          open={driverAlert.showAlert}
          autoHideDuration={3000}
          onClose={() =>
            setDriverAlert({
              ...driverAlert,
              showAlert: false,
            })}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}

          sx={{
            marginTop: "3rem"
          }}
        >
          <Alert
            severity={driverAlert.severity}
            onClose={() =>
              setDriverAlert({
                ...driverAlert,
                showAlert: false,
              })}
          >
            {driverAlert.message}
          </Alert>
        </Snackbar>


        <Snackbar
          open={vehicleAlert.showAlert}
          autoHideDuration={3000}
          onClose={() =>
            setVehicleAlert({
              ...vehicleAlert,
              showAlert: false,
            })}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}

          sx={{
            marginTop: "7rem"
          }}
        >
          <Alert
            severity={vehicleAlert.severity}
            onClose={() =>
              setVehicleAlert({
                ...vehicleAlert,
                showAlert: false,
              })}
          >
            {vehicleAlert.message}
          </Alert>
        </Snackbar>

        <Grid
          container
          sx={{
            paddingTop: '0px',
            marginTop: '0px',
          }}
          rowSpacing={1}
        >
          <Grid container item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            justifyContent="center"
          >
            <Grid item
              lg={"auto"}
              md={"auto"}
              sm={"auto"}
              xs={12}
            // pr={3}
            >
              <Details value={value} setValue={setValue} refresh={refreshTimeLine} />
            </Grid>

            <Grid item
              mt={{
                lg: "0rem",
                md: "1rem",
                sm: "1rem",
                xs: "1rem",
              }}
              lg={"auto"}
              md={12}
              sm={12}
              xs={12}

              sx={{
                overflow: "hidden"
              }}
            >
              <RouteNav date={value} onDeleteSuccess={handleDeleteSuccess} />
            </Grid>

          </Grid>


        </Grid>
      </JobContext.Provider >
    </>
  )
}
