import addZeroToNumbers from "./AddZeroToNumbers"

export default function DateSlashToDashReformat(date) {
  
  if (date) { 
    if (date?.includes("-")) {
      
      const splitISODate = date.split("T")[0]
      const reFormatDate = splitISODate.split("-")
      const reArrageDate = `${reFormatDate[1]}/${addZeroToNumbers(reFormatDate[2])}/${addZeroToNumbers(reFormatDate[0])}`
      return reArrageDate
  
    } else {
      return date
    }
  }

}
