import React, { useState } from "react";
import { Grid } from "@mui/material";
import CreateTimeSlots from "./CreateDeliveryTimeSlots";
import DataGridTable from "../../components/layouts/DataGrid";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import EditTimeSlots from "./DeliveryEditTimeSlots";
import DeleteRecord from "./DeleteRecord";
import Chip from "@mui/material/Chip";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import AlertMain from "components/common/AlertMain";
import AlertInlineMain from "components/common/AlertInlineMain";

export default function DeliveryTimeSlots() {
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [editData, setData] = useState({});

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const deleteRecord = (id) => {
    deleteTimeSlot(id);
  };

  const deleteTimeSlot = async (id) => {
    try {
      await axios.delete(`/delivery/timeslot/${id}`);
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Time Slot Deleted Successfully",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
        setStatus(!status);
      }, 1000);
      // Error in Deleting Time Slot
    } catch (err) {
      if (err.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: err.response.data.message,
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
          setStatus(!status);
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Error in Deleting Time Slot",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Error in Deleting Time Slot!",
          });
          setStatus(!status);
        }, 3000);
      }
    }
  };

  const columns = [
    { field: "zoneName", headerName: "ZONE", minWidth: 100, flex: 0.2 },
    { field: "timeSlots", headerName: "TIME SLOTS", minWidth: 150, flex: 0.5 },
    {
      field: "limit",
      headerName: "LIMIT",
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "isPublished",
      headerName: "PUBLISHED",
      minWidth: 120,
      flex: 0.2,
      renderCell: (params) => {
        return params.row.isPublished ? (
          <Chip label="YES" color="success" variant="outlined" />
        ) : (
          <Chip label="NO" color="secondary" variant="outlined" />
        );
      },
    },
    {
      field: "action",
      headerName: "ACTION",
      minWidth: 130,
      flex: 0.6,
      disableExport: true,
      renderCell: (cellValues) => {
        const onEdit = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setData(cellValues.row);
          handleClickOpen();
          setStatus(!status);
        };
        return (
          <>
            <IconButton aria-label="edit">
              <EditIcon onClick={onEdit} />
            </IconButton>
            <DeleteRecord
              setStatus={setStatus}
              status={status}
              title={"Time Slot Deletion?"}
              message={
                "This time slot will be permanently deleted from this location"
              }
              id={cellValues.row.id}
              deleteRecord={() => deleteRecord(cellValues.row.id)}
              alert={alert}
              setAlert={setAlert}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Grid ml={1} item>
        <IconButton
          onClick={() => navigate("/delivery-schedules")}
          color="primary"
          aria-label="goback"
        >
          <ArrowBackIcon />
        </IconButton>

        <AlertMain setAlert={setAlert} alert={alert} />

      </Grid>
      <h1>Delivery Time Slots</h1>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid
          container item justifyContent="flex-end" mr={6} ml={6} xs={12} md={12} pt={5}
        >
          <CreateTimeSlots setStatus={setStatus} status={status} />
        </Grid>
        <Grid container item xs={12} sm={12} md={12}>
          <DataGridTable
            fetchDataUrl="/delivery/timeslot"
            columns={columns}
            status={status}
            title="Delivery Time Slots"
          />
          {alert.showAlert && (
            <Grid item md={12} m={2}>
             <AlertInlineMain setAlert={setAlert} alert={alert} />
            </Grid>
          )}
        </Grid>
        <EditTimeSlots
          setOpen={setOpen}
          open={open}
          editData={editData}
          setStatus={setStatus}
          status={status}
        />
      </Grid>
    </>
  );
}
