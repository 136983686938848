import React, { useState, useEffect, memo } from "react";
import { Grid, FormControl, TextField,} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import DialogAlertMain from "components/common/DialogAlertMain";
import LoadingButton from "@mui/lab/LoadingButton";

function SelectDriver({ vehicle, setFieldValue, values, users, drivers, getData }) {
  const axios = useAxiosPrivate()
  const [value, setValue] = useState("");
  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });
  const [backendCall, setBackendCall] = useState(false);

  const makeVehicleUnavailable = async () => {
    setBackendCall(true);
    try {
      if (vehicle?._id) {
        await axios.put(`/vehicle/availability/${vehicle?._id}`, { availability: "unavailable" });
        setAlert({ showAlert: true, severity: "success", message: "Updated successfully!", });
          await getData();
          setTimeout(() => { setBackendCall(false); }, 2000); 
      }
    } catch (err) {
      setBackendCall(false);
      setAlert({ showAlert: true, severity: "error", message: err?.response?.data ? err?.response?.data : "Update Failed!", });
    }
  }

  useEffect(() => {
    const isFilled = values.assignment.findIndex(({ vehicleId }) => { return vehicleId === vehicle._id });

    if (isFilled >= 0) {
      const driverId = values.assignment[isFilled].driverId;
      const value = drivers.find(({ _id }) => _id === driverId)
      setValue(value)
    } else { setValue("") }
    // eslint-disable-next-line
  }, [values.assignment])

  return (
    <Grid container item xs={12} md={12} spacing={1}>
      <DialogAlertMain setAlert={setAlert} alert={alert} />
      {vehicle.availability === "available" && (
        <>
          <Grid item xs={12} md={12}>
            {value && vehicle.name}{" "}
            {!value && (
              <Grid container>
                <Grid container item>
                  <Grid item lg={11} md={11} sm={11} xs={11} >
                    {vehicle.name}  <span style={{ color: "red" }}>- Driver not assigned</span>
                  </Grid>
                  <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"} >
                    <LoadingButton variant="contained" color="error" size="small" loading={backendCall} disabled={backendCall}
                      sx={{ textTransform: "capitalize", marginTop: "0.3rem" }}
                      startIcon={<AirportShuttleIcon />}
                      onClick={() => makeVehicleUnavailable(vehicle)} >
                      Make vehicle unavailable </LoadingButton>
                  </Grid>
                </Grid>
              </Grid> )}
          </Grid>
          <Grid item xs={12} md={12}>
            {" "}
            <FormControl fullWidth>
              <Autocomplete name="month" id="tags-outlined"
                options={drivers.filter(
                  (dri) =>
                    !values.assignment
                      .map((assn) => assn.driverId)
                      .includes(dri._id)
                )}
                value={value}
                disabled={backendCall}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                onChange={(event, newValue) => {
                  if (newValue) {
                    const idx = values.assignment.findIndex(
                      (assn) => assn.vehicleId === vehicle._id
                    );
                    setFieldValue(`assignment[${idx >= 0 ? idx : values.assignment.length}]`,
                      { driverId: newValue._id, vehicleId: vehicle._id, });
                  } else {
                    setFieldValue( `assignment`, values.assignment.filter(item => item.vehicleId !== vehicle._id) );
                  }
                }}
                renderInput={(params) => ( <TextField {...params} name="assignment-id" label="Drivers" placeholder="Driver" /> )}
              />
            </FormControl>
          </Grid>
        </>
      )}
    </Grid>
  );
}
export default memo(SelectDriver)