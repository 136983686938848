import React, {useEffect, useState} from "react";
import DataGrid from "../layouts/DataGrid";
import { Button, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import { useSelector } from "react-redux";
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import DeleteRecord from "screens/scheduling/DeleteRecord";
import AlertMain from 'components/common/AlertMain';

export default function Test() {
  const navigate = useNavigate();

  const axios = useAxiosPrivate()
  const [zipCodeList, setZipCodeList] = useState([]);
  const [refreshData, setRefreshData] = useState(false);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });
  const user = useSelector((state) => state);

  const onboardingStatus = user.auth.onboardingStatus;

  const navigatePage = () => {
    if (onboardingStatus === "completed") {
      navigate("/account-management");
    } else {
      navigate("/confirm", { state: { id: user?.auth?.id } });
    }
  };

  useEffect(() => {
    getAvailableZipCodes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const getAvailableZipCodes = async () => {
    try {
      const { data } = await axios.get(`/user/${user.auth.id}`);
      const currentZipCode= await axios.get('/createZone');
      if(currentZipCode.data.length > 0 ){
      const zipsArray = currentZipCode.data?.map((item) => item.zipCodes);
      const newArr = Array.prototype.concat(...zipsArray);
      const unusedZipCodes = data.serviceRegions.filter(zipCode => !newArr.includes(zipCode));
      setZipCodeList(unusedZipCodes);
      }else{
        setZipCodeList(data.serviceRegions);
      }
     
    } catch (error) {
		console.log("getAvailableZipCodes err", error)
    }
  }

  const deleteRecord = async (id) => { 
    try {
      await axios.delete(`/createZone/${id}`);
      setAlert({
        showAlert: true,
        severity: 'success',
        message: 'Zone Deleted Successfully',
      });
      setRefreshData(!refreshData)
      getAvailableZipCodes()
    } catch (err) {
      setAlert({
        showAlert: true,
        severity: 'success',
        message: 'Zone deleting failed',
      });
      setRefreshData(!refreshData)

    }
  };

  const columns = [
    {
      field: "zoneName",
      headerName: "Zone",
      width: 180,
      valueGetter: (params) => {
        return `${params.row.zoneName}`;
      },
    },
    {
      field: "zipCodes",
      headerName: "Zip Codes",
      minWidth: 1050,
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.zipCodes.join(', ')}`;
      },
    },
    {
      field: 'action', headerName: 'ACTION', minWidth: 500, flex: 0.2, disableExport: true,
      renderCell: (cellValues) => {

        return (
          user?.auth?.role !== "manager" &&
          <>
          <Button
        sx={{
          width: {
            lg:"auto",
            md:"auto",
            sm:"auto",
            xs:"100%",
          }
        }}
        variant="contained" component={Link} to={`/create-zone?name=${cellValues.row.zoneName}&id=${cellValues.row.id}`} >
          Edit Zone
        </Button> 
              <DeleteRecord title={'Zone Deletion?'} message={'This Zone will be permanently deleted'}
              deleteRecord={() => deleteRecord(cellValues.row.id)} alert={alert} setAlert={setAlert} />
          </>
        );
      },
    },
  ];


  return (
    <Grid container spacing={2}>
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      justifyContent={"flex-end"}

      marginTop={5}
    >
      <Button
        sx={{
          width: {
            lg:"auto",
            md:"auto",
            sm:"auto",
            xs:"100%",
          },
          visibility: zipCodeList.length === 0 ? "hidden" : "visible"
        }}
        data-testid="create_zones_btn"
        variant="contained" component={Link} to="/create-zone">
          Create Zone +
        </Button>
      </Grid>

      <Grid ml={1} item>
        <IconButton
          onClick={navigatePage}
          color="primary"
          aria-label="add to shopping cart"
          data-testid="back_btn"
        >
          <ArrowBackIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12} md={12}>
        <DataGrid
          autoHeight
          columns={columns}
          fetchDataUrl="/createZone"
          title="View Zones"
          refreshData={refreshData}
        />
      </Grid>
      <AlertMain setAlert={setAlert} alert={alert} />

    </Grid>
  );
}
