import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HomeIcon from "@mui/icons-material/Home";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MapContainer from "./MapContainer";
import Badge from "@mui/material/Badge";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import CarRentalIcon from "@mui/icons-material/CarRental";
import DirectionsBusFilledIcon from "@mui/icons-material/DirectionsBusFilled";
import { useDispatch, useSelector } from "react-redux";
import { setPageVal } from "../../store/actions/RouteAction";


const tabFontSize = {
  fontSize: {
    lg: "0.85rem",
    md: "0.85rem",
    sm: "0.85rem",
    xs: "0.75rem",
  },
  width:"25%"
  }
  
  const tabIconSize = {
    fontSize: {
      lg: "1.6rem",
      md: "1.6rem",
      sm: "1.6rem",
      xs: "1",
    }
  }


function TabPanel(props) {
  let dispatch = useDispatch();


  const { children, value, index, ...other } = props;

  useEffect(() => {
    dispatch(setPageVal(value));
    //eslint-disable-next-line
  }, [value]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function IconLabelTabs() {
  const [value, setValue] = useState(0);

  const [state, setState] = useState({});

  const states = useSelector((s) => s.route?.depot);

  const getState = () => {
    setState(states);
  };

  useEffect(() => {
    getState();
    //eslint-disable-next-line
  }, [states]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{
      width: "100%", mt: {
        lg: "1rem",
        md: "2rem",
        sm: "2.5rem",
        xs: "2.5rem",
      }
    }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider"}}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example" >
          <Tab
            sx={tabFontSize}
            icon={<HomeIcon sx={tabIconSize} />}
            label="Depots"
            {...a11yProps(0)}
          />
          <Tab sx={tabFontSize}
            icon={<DirectionsBusFilledIcon sx={tabIconSize} />}
            label={<Badge color="secondary">Vehicles</Badge>}
            {...a11yProps(1)}
          />
          <Tab sx={tabFontSize}
            icon={<ContactPageIcon sx={tabIconSize} />}
            label={<Badge color="secondary">Driver</Badge>}
            {...a11yProps(2)}
          />
          <Tab sx={tabFontSize}
            icon={<CarRentalIcon sx={tabIconSize} />}
            label={<Badge color="secondary">Vehicle Assignment</Badge>}
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>

      {value === 0 ? (
        <TabPanel value={value} index={0}>
          {<MapContainer state={state} />}
        </TabPanel>
      ) : value === 1 ? (
        <TabPanel value={value} index={1}>
          {<MapContainer state={state} />}
        </TabPanel>
      ) : value === 2 ? (
        <TabPanel value={value} index={2}>
          {<MapContainer state={state} />}
        </TabPanel>
      ) : value === 3 ? (
        <TabPanel value={value} index={3}>
          {<MapContainer state={state} />}
        </TabPanel>
      ) : value === 4 ? (
        <TabPanel value={value} index={4}>
          {<MapContainer state={state} />}
        </TabPanel>
      ) : (
        ""
      )}
    </Box>
  );
}
