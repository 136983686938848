import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";

export default function useFranchiseeMgtOnsubmit({
  componentType,
  createStripeAccount,
  setInitialValues,
  setAlert,
  newState,
  state,
  navigate,
  setBackendCall,
  zipDelArr,
}) {
  const axios = useAxiosPrivate();

  const useOnSubmit = async (e, { resetForm }) => {
    if(sessionStorage.getItem('roleTypeCreate') === 'franchisee'){
    if (componentType === "create") {
      try {
        setBackendCall(true);
        const save = {
          firstName: e.firstName,
          email: e.email,
          ownerName: e.ownerName,
          password: e.password,
          suitNo: e.suitNo,
          address: e.address,
          serviceRegions: e.serviceRegions,
          royaltyScheme: e.royaltyScheme,
          pricing: e.pricing,
          note: e.note,
          state: newState,
          role:sessionStorage.getItem('roleTypeCreate')
        };

        const { data } = await axios.post("/user", save);
        resetForm();
        await createStripeAccount(data);
        setInitialValues({
          fID: "",
          firstName: "",
          email: "",
          suitNo: "",
          ownerName: "",
          address: "",
          serviceRegions: [],
          royaltyScheme: null,
          pricing: "",
          note: "",
          password: "",
        });
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Franchisee created Successfully!",
        });
        setBackendCall(false);
        navigate("/view-franchisee");
      } catch (error) {
        setBackendCall(false);
        if (error.response.data === "Zip code already exists") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Zip code already exists!",
          });
        } else if (error.response.data === "Email already exists!") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Email already exists!",
          });
        } else if (
          error.response.data.email[0] === '"email" must be a valid email'
        ) { setAlert({
            showAlert: true,
            severity: "error",
            message: "Email must be a valid!",
          });
        } else { setAlert({
            showAlert: true,
            severity: "error",
            message: "Franchisee create failed!",
          });
        }
      } finally { setBackendCall(false); }
    } else {
      try {
        setBackendCall(true);
        const data = {
          roleID: e.fID,
          firstName: e.firstName,
          ownerName: e.ownerName,
          email: e.email,
          suitNo: e.suitNo,
          address: e.address,
          serviceRegions: e.serviceRegions,
          royaltyScheme: e.royaltyScheme,
          pricing: e.pricing,
          note: e.note,
          state: newState,
          removedZipCodes: zipDelArr,
          role:e.role,
        };
        await axios.put(`/user/${state.id}`, data);
        navigate("/view-franchisee");
      } catch (error) {
        setBackendCall(false);
        if (error.response.data === "Zip code already exists") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Zip code already exists!",
          });
        } else {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Franchisee update failed!",
          });
        }
      }
    }
  }else{
    if (componentType === "create") {
      try {
        setBackendCall(true);
        const save = {
          firstName: e.corporateFirstName,
          email: e.email,
          ownerName: e.corporateOwnerName,
          password: e.password,
          suitNo: e.suitNo,
          address: e.address,
          serviceRegions: e.serviceRegions,
          pricing: e.pricing,
          note: e.note,
          state: newState,
          role:sessionStorage.getItem('roleTypeCreate')
        };

       await axios.post("/user", save);
        resetForm();
        setBackendCall(false);
        // await createStripeAccount(data);
        setInitialValues({
          fID: "",
          firstName: "",
          email: "",
          suitNo: "",
          ownerName: "",
          address: "",
          serviceRegions: [],
          royaltyScheme: null,
          pricing: "",
          note: "",
          password: "",
          corporateFirstName:"",
          corporateOwnerName: "",
        });
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Corporate created Successfully!",
        });
        navigate("/view-franchisee");
      } catch (error) {
        setBackendCall(false);
        if (error.response.data === "Zip code already exists") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Zip code already exists!",
          });
        } else if (error.response.data === "Email already exists!") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Email already exists!",
          });
        } else if (
          error.response.data.email[0] === '"email" must be a valid email'
        ) { setAlert({
            showAlert: true,
            severity: "error",
            message: "Email must be a valid!",
          });
        } else { setAlert({
            showAlert: true,
            severity: "error",
            message: "Corporate create failed!",
          });
        }
      } finally { setBackendCall(false); }
    } else {
      try {
        setBackendCall(true);
        const data = {
          roleID: e.fID,
          firstName: e.corporateFirstName,
          ownerName: e.corporateOwnerName,
          email: e.email,
          suitNo: e.suitNo,
          address: e.address,
          serviceRegions: e.serviceRegions,
          pricing: e.pricing,
          note: e.note,
          state: newState,
          removedZipCodes: zipDelArr,
          role:e.role,
        };

        await axios.put(`/user/${state.id}`, data);
        navigate("/view-franchisee");
      } catch (error) {
        setBackendCall(false);
        if (error.response.data === "Zip code already exists") {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Zip code already exists!",
          });
        } else {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Corporate update failed!",
          });
        }
      }
    }
  }
  }; 

  return { useOnSubmit };

}