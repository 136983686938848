import axios from 'axios';

const baseUrl = process.env.REACT_APP_BE_URL;

// const setIntercepters = () => {
//   axios.interceptors.request.use(
//     function (config) {
//       config.headers["Authorization"] =
//         "Bearer " + sessionStorage.getItem("token");
//       return config;
//     },
//     null,
//     { synchronous: true }
//   );
// }
const axiosInstance = axios.create({
  baseURL: baseUrl,
  // headers: {
  //   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  // },
});

export default axiosInstance;
