import * as Yup from "yup";

export default function useCorporateMgtValidation() {
  
  const validationSchemaCorporate = Yup.object().shape({
    corporateFirstName: Yup.string().required("Corporate name required")
      .max(60, "Maximum length for the Corporate name is 100!"),
      corporateOwnerName: Yup.string().required("Corporate admin name required")
      .max(100, "Maximum length for the Corporate admin name is 100!"),
    email: Yup.string().required("Email is required!")
      .email("Must be a valid email")
      .matches(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter a valid email")
      .max(320, "Must be less than 320 digits"),
      password: Yup.string().required("Password is required!")
      .matches(
        /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
        "One Uppercase, One Lowercase, One Number,one special case Character and Must Contain 8 Characters")
      .min(8, "Must Contain 8 Characters"),
    address: Yup.string().required("address is required"),
  });


  const validationSchemaCorporateUpdate = Yup.object().shape({
    corporateFirstName: Yup.string().required("Corporate name required")
      .max(60, "Maximum length for the Corporate name is 100!"),
      corporateOwnerName: Yup.string().required("Corporate admin name required")
      .max(100, "Maximum length for the Corporate admin name is 100!"),
    email: Yup.string().required("Email is required!")
      .email("Must be a valid email")
      .matches(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter a valid email")
      .max(320, "Must be less than 320 digits"),
    address: Yup.string().required("address is required"),
  });

  return { validationSchemaCorporate, validationSchemaCorporateUpdate };

}