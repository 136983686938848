import React, { useState } from 'react';
import DataGrid from '../layouts/DataGrid';
import { Chip } from '@mui/material';
import moment from 'moment';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";

export default function ServiceOneView() {
  const axios = useAxiosPrivate()
  const [status, setStatus] = useState(false);

  const changeStatus = async (newStatus, data) => {
    try {
      await axios.put('/service/update-status', {
        status: newStatus,
        id: data.id,
      });

      setStatus(!status);
    } catch (error) {}
  };

  const columns = [
    { field: 'name', headerName: 'Service Name', width: 130 },
    { field: 'price', headerName: 'Price', width: 130 },

    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      renderCell: (params) => {
        const onClick = (e, status) => {
          e.stopPropagation(); // don't select this row after clicking
          changeStatus(status, params.row);
        };

        if (params.row.status === true)
          return (
            <Chip
              onClick={(e) => onClick(e, false)}
              label={`Active`}
              color='success'
              variant='outlined'
            />
          );

        if (params.row.status === false)
          return (
            <Chip
              onClick={(e) => onClick(e, true)}
              label={`Inactive`}
              color='error'
              variant='outlined'
            />
          );
      },
    },

    {
      field: 'createdAt',
      headerName: 'Create Date',
      width: 150,
      valueGetter: (params) => {
        return `${moment(params.row.createdAt).format('MMMM Do YYYY, h:mm:ss a')}`;
      },
    },
  ];

  return (
    <DataGrid
    autoHeight
      columns={columns}
      fetchDataUrl='/service'
      status={status}
      title='Request View'
    />
  );
}
