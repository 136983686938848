import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, CardHeader, Card } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useStyles } from 'utils/styles/styles';
import LinearProgress from '@mui/material/LinearProgress';
import AlertInlineMain from "components/common/AlertInlineMain";

export const StyledDataGrid = withStyles({
  root: {
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'normal',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    },
  },
})(DataGrid);

export default function DataGridStyled({
  columns,
  fetchDataUrl,
  status,
  title,
}) {
  const axios = useAxiosPrivate()
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    // eslint-disable-line
    showAlert: false,
    severity: 'success',
    message: '',
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const loadData = async () => {
    try {
      setLoading(true);
      setRows([]);
      const { data } = await axios.get(fetchDataUrl);
      setLoading(false);
      setRows(data);
    } catch (error) {
      setLoading(false);
      setRows([]);
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Authorized Access!',
        });
        setTimeout(() => setAlert({ showAlert: false, severity: 'error', message: '' }), 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: 'error',
          message: 'Something went wrong!',
        });
        setTimeout(() => setAlert({ showAlert: false, severity: 'error', message: '' }), 3000);
      }
    }
  };

  return (
    <Grid container className={classes.root1} spacing={1}>
      <Grid fullWidth item xs={12} sm={12} md={12}>
        <Card>
          <CardHeader title={`${title}`} />
          <Grid
            item
            xs={12}
            md={12}
            style={{ height: '60vh', margin: '1rem', padding: 0 }}
          >
            <StyledDataGrid
              components={{
                LoadingOverlay: LinearProgress,
              }}
              disableVirtualization
              columns={columns}
              loading={loading}
              disableSelectionOnClick={true}
              disableColumnSelector={true}
              disableDensitySelector={true}
              rows={rows}
            />
          </Grid>
          {alert.showAlert && (
            <Grid item md={12} m={2}>
              <AlertInlineMain setAlert={setAlert} alert={alert} />
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}
