import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
// eslint-disable-next-line
import Chart from 'chart.js/auto';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import AlertInlineMain from "components/common/AlertInlineMain";
import { Grid } from '@mui/material';

export default function AdminSalesLineChart({ selected }) {
  const axios = useAxiosPrivate()
  const [label, setLabel] = useState([]);

  const [newData, setNewData] = useState([]);

  const [details, setDetails] = useState('Sales ($)');

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const monthName = (number) => {
    let month = '';
    switch (number) {
      case '01':
        month = 'January';
        break;
      case '02':
        month = 'February';
        break;
      case '03':
        month = 'March';
        break;
      case '04':
        month = 'April';
        break;
      case '05':
        month = 'May';
        break;
      case '06':
        month = 'June';
        break;
      case '07':
        month = 'July';
        break;
      case '08':
        month = 'August';
        break;
      case '09':
        month = 'September';
        break;
      case '10':
        month = 'October';
        break;
      case '11':
        month = 'November';
        break;
      case '12':
        month = 'December';
        break;
      default:
        month = '';
        break;
    }
    return month;
  };

  const isEmpty = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };

  const alertSetter = (severity, message) => {
    setAlert({
      showAlert: true,
      severity,
      message,
    });
    setTimeout(() => {
      setAlert({
        showAlert: false,
        severity,
        message,
      });
    }, 3000);
  };

  const getMTDSales = async () => {
    try {
      const { data } = await axios.get('/reports/franchiser-sales/mtd');
      setLabel(data.map((item) => item._id));
      setNewData(data.map((item) => item.total));
      setDetails(`MTD Sales ($)`);
      alertSetter('success', 'Successfully fetched MTD sales');
    } catch (error) {
      alertSetter('error', 'Failed to fetch MTD sales');
    }
  };

  const getYearMonthSales = async (year, month) => {
    try {
      const ym = {
        year,
        month,
      };
      const { data } = await axios.post('/reports/franchiser-sales/ym', ym);
      setLabel(data.map((item) => item._id));
      setNewData(data.map((item) => item.total));
      setDetails(`Year : ${year} Month : ${monthName(month)} ($)`);
      alertSetter('success', 'Successfully fetched Year-Month sales');
    } catch (error) {
      alertSetter('error', 'Failed to fetch Year-Month sales');
    }
  };

  const yearlySales = async (year) => {
    try {
      const { data } = await axios.post(`/reports/franchiser-sales/year`, {
        year,
      });
      setLabel(data.map((item) => item._id));
      setNewData(data.map((item) => item.total));
      setDetails(`Year : ${year} ($)`);
      alertSetter('success', 'Successfully fetched Yearly sales');
    } catch (error) {
      alertSetter('error', 'Failed to fetch Yearly sales');
    }
  };

  useEffect(() => {
    if (isEmpty(selected)) {
      getMTDSales();
    } else {
      return;
    }
    //eslint-disable-next-line
  }, []);

  const check = async () => {
    if (selected.year && selected.month === '') {
      await yearlySales(selected.year);
    } else if (selected.year && selected.month) {
      await getYearMonthSales(selected.year, selected.month);
    } else if (selected.year === '' && selected.month === '') {
      await getMTDSales();
    }
  };

  useEffect(() => {
    check();
    // eslint-disable-next-line
  }, [selected]);

  const data = {
    labels: label,
    datasets: [
      {
        label: details,
        fill: false,
        lineTension: 0,
        backgroundColor: 'rgba(131,138,133,0.4)',
        borderColor: 'rgba(131,138,133,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(131,138,133,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(131,138,133,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: newData,
      },
    ],
  };

  const options = {
    title: {
      display: true,
      text: 'Visit with & without Seasonal Impacts',
      fontSize: 20,
    },
    legend: {
      display: true,
      position: 'bottom',
    },
    scales: {
      y:{
          ticks: {
            callback: function (value, index, values) {
              return "$" + value;
              // return value + 'K';
            },
          }, 
      },
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Line data={data} options={options} />
      </Grid>
      {alert.showAlert ? (
        <Grid item md={12}>
          <AlertInlineMain setAlert={setAlert} alert={alert} />
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );
}
