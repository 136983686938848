import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { useSelector, useDispatch } from "react-redux";
import Divider from "@mui/material/Divider";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { setOneVehicle, setOpen, removeOneVehicle, setVehicle } from "../../../store/actions/RouteAction";
import { Typography } from "@material-ui/core";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import DialogAlertMain from "components/common/DialogAlertMain";

export default function FolderList() {
  const axios = useAxiosPrivate()
  const dispatch = useDispatch();

  const vehicle = useSelector((state) => state.route.vehicle);

  const oneVehicle = useSelector((state) => state.route.oneVehicle);

  const state = useSelector((state) => state.route.open);

  const [open, setOpenDepot] = React.useState(state);

  const [backendCall, setBackendCall] = React.useState(false);

  const [alert, setAlert] = React.useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const setDetails = () => {
    dispatch(setOpen(true));

    setOpenDepot(!open);
  };

  const getVehicle = (oneVehicle) => {

    dispatch(setOneVehicle(oneVehicle));
    setDetails();
  };

  const checkVehicle = (id) => {
    if (id === oneVehicle?.id) {
      return true;
    }
  };

  const deleteVehicle = async (vehicle) => {
    setBackendCall(true);

    try {
      // Check if the vehicle has a driver assigned
      if (vehicle.driver) {
        setBackendCall(false);
        setAlert({
          showAlert: true,
          severity: "warning",
          message: "Driver is assigned to this vehicle. Remove the driver assignment first.",
        });
        return;
      }

      // Proceed to delete the vehicle if no driver is assigned
      await axios.delete(`/vehicle/${vehicle._id}`);

      // Update Redux state after deletion
      dispatch(setVehicle());
      dispatch(setOpen(false));
      setOpenDepot(!open);
      dispatch(removeOneVehicle());

      setTimeout(() => {
        dispatch(setOpen(true));
      }, 200);

      setBackendCall(false)
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Vehicle deleted Successfully!",
      });
    } catch (error) {
      setBackendCall(true)
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Vehicle deletion Failed!",
      });
    }
  };

  React.useEffect(() => {
    if (backendCall) {
      return;
    }
    getVehicle();
  }, [backendCall]);

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>

      <DialogAlertMain alert={alert} setAlert={setAlert} />

      {vehicle?.length > 0 &&
        vehicle.map((vehicle) => (
          <div>
            <ListItem
              style={{
                cursor: "pointer",
                backgroundColor: checkVehicle(vehicle.id) ? "#ADD8E6" : "white",
              }}
              onClick={() => getVehicle(vehicle)}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => deleteVehicle(vehicle)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <DirectionsCarIcon style={{ color: vehicle?.color }} />
                </Avatar>
              </ListItemAvatar>

              <span
                style={{
                  fontWeight: "500",
                }} >

                {vehicle?.name}
              </span>

            </ListItem>
            <Stack style={{ paddingLeft: "1rem", backgroundColor: checkVehicle(vehicle.id) ? "#ADD8E6" : "white", }}>
              {vehicle?.routeSettings &&
                vehicle?.routeSettings?.tags?.length > 0 ? (
                <span>
                  <LocalOfferIcon
                    sx={{
                      fontSize: "0.8rem",
                      color: "#5C5C5C",
                    }}
                  />
                  {vehicle?.routeSettings?.tags?.map((tag) => (
                    <>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          color: "white",
                          marginLeft: "0.2rem",
                          fontWeight: "400",
                          paddingLeft: "0.3rem",
                          paddingRight: "0.3rem",
                          paddingTop: "0.09rem",
                          paddingBottom: "0.09rem",
                          backgroundColor: "green",
                          borderRadius: "0.3rem",
                        }}
                      >{tag}</span>
                    </>
                  ))}
                </span>
              ) : (
                <Typography >
                  <LocalOfferIcon
                    sx={{
                      fontSize: "0.8rem",
                      color: "#B3B3B3",
                    }}
                  />
                  <span style={{
                    fontSize: "0.8rem",
                    marginLeft: "0.2rem",
                    color: "#B3B3B3",
                    fontWeight: "400",
                  }}>No Tags</span>

                </Typography>
              )}
            </Stack>
            <Divider style={{ width: "90%", marginTop: "1rem" }} />
          </div>
        ))}
    </List>
  );
}
