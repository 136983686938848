import { LOGIN, LOGOUT, TOGGLE, ONBOARDING, TIMEZONE } from '../actions/types';

const initialState = {
  accessToken: sessionStorage.getItem('accessToken'),
  role: sessionStorage.getItem('role'),
  fullName: sessionStorage.getItem('fullName'),
  ownerName: sessionStorage.getItem('ownerName'),
  id: sessionStorage.getItem('id'),
  routes: JSON.parse(sessionStorage?.getItem('routes')) || "",
  isDrawerOpen: false,
  franchiseeTimeZone: sessionStorage.getItem('franchiseeTimeZone'),
  onboardingStatus: sessionStorage.getItem('onboardingStatus')

};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return { ...state, ...action.payload };

    case TIMEZONE:
      return { ...state, franchiseeTimeZone: action.payload };

    case LOGOUT:
      return {
        accessToken: '',
        role: '',
        fullName: '',
        id: '',
        routes: '',
        isDrawerOpen: false,
        onboardingStatus: ''
      };

    case TOGGLE:
      return { ...state, isDrawerOpen: !state.isDrawerOpen };

    case ONBOARDING:
      return { ...state, onboardingStatus: action.payload };

    default:
      return state;
  }
}
