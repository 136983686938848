import React, { useState } from 'react';
import DataGridTable from '../../../components/layouts/DataGrid';
import { Grid, IconButton } from '@mui/material';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from 'react-router-dom';
import moment from "moment";

const columns = [
  {
    field: 'orderId',
    headerName: 'Order ID',
    minWidth: 80,
    flex: 0.9,
  },
  {
    field: 'userid',
    headerName: 'User ID',
    minWidth: 200,
    flex: 0.8,
  },
  {
    field: 'hasKey',
    headerName: 'Has Key',
    minWidth: 100,
  },
  {
    field: 'pickupDate',
    headerName: 'Pick-up Date', // mm/dd/yyyy
    minWidth: 250,
    valueGetter: (params) => {
        let pickupDate = params?.row?.pickupDate
        if (pickupDate?.includes("Z")) {
          pickupDate = (pickupDate?.split("T")[0])
        } else {
          pickupDate = params?.row?.pickupDate
        }
        return `${params?.row?.pickupDate ? moment(pickupDate).format("MMMM Do dddd YYYY") : "Not applicable"}`;
    },
  },
  {
    field: 'pickupTimeSlot',
    headerName: 'Pick-up time', // hh:mm
    minWidth: 160,
    flex: 0.8,
    valueGetter: (params) => {
      return `${params?.row?.pickupTimeSlot ? params?.row?.pickupTimeSlot : "Not applicable"}`;
  },
  },
  {
    field: 'pickupWindowStart',
    headerName: 'Pick up Window start time', // hh:mm
    minWidth: 185,
  },
  {
    field: 'pickupWindowEnd',
    headerName: 'Pick up Window end time', // hh:mm
    minWidth: 185,
  },
  {
    field: 'zoneName',
    headerName: 'Group',
    minWidth: 80,
  },
  {
    field:'firstName',
    headerName: 'Name',
    minWidth: 200,
    valueGetter: (params) => {
      return `${params.row.firstName} ${params.row.lastName}`
    },
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone',
    minWidth: 120,
  },
  {
    field: 'streetAddress',
    headerName: 'Street',
    minWidth: 200,
  },
  {
    field: 'streetAddress2',
    headerName: 'Street2',
    minWidth: 100,
  },
  {
    field: 'address',
    headerName: 'Address',
    minWidth: 480,
    valueGetter: (params) => {
      return `${params.row.orderAddress}`
    },
  },
  {
    field: 'city',
    headerName: 'City',
    minWidth: 100,
  },
  {
    field: 'state',
    headerName: 'State',
    minWidth: 40,
  },
  {
    field: 'zipCode',
    headerName: 'Zipcode',
    minWidth: 40,
    valueGetter: (params) => {
      return `${params.row.zipCode}`
    },
  },
  {
    field:'country',  
    headerName: 'Country',
    minWidth: 40,
  },
  {
    field: 'orderType',
    headerName: 'Services',
    minWidth: 280,
    flex: 2,
    valueGetter: (params) => {
      if (params.row.isManualOrder) {
        return "Manual Order"
      } else {
      switch (params.row.orderType) {
        case '1':
          return 'Wash/Dry/Fold';
        case '2':
          return 'Dry Cleaning';
        case '3':
          return 'Tailoring';
        case '4':
          return 'Dry Cleaning & Tailoring';
        case '5':
          return 'Wash/Dry/Fold , Tailoring';
        case '6':
          return 'Wash/Dry/Fold , Tailoring';
        case '7':
          return 'Wash/Dry/Fold , Dry Cleaning';
        case '8':
          return 'Wash/Dry/Fold , Dry Cleaning';
        case '9':
          return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
        case '10':
          return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
        default:
          return '-';
      }
    }
    },
  },
  {
    field: 'orderNote',
    headerName: 'Order Notes',
    minWidth: 350,
  },
  {
    field: 'pickupNote',
    headerName: 'Pickup Notes',
    minWidth: 350,
  },
  {
    field: 'pickUps',
    headerName: 'pick ups',
    minWidth: 20,
  },
  {
    field: 'serviceTime',
    headerName: 'service time (Min)',
    minWidth: 200,
    valueGetter: (params) => {
      const formattedMinutes =  moment.duration(params.row.serviceTime, 'seconds').asMinutes().toFixed(2);
      return `${formattedMinutes}`
    },
  },
];

function GetPickups() {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item ml={1}>
        <IconButton
          variant='contained'
          color='primary'
          onClick={() => navigate('/schedule')}
          aria-label='goback'
        >
          <ArrowBackIcon />
        </IconButton>
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h1>Get Pickups</h1>
      </Grid>
      <Grid item xs={12} sm={12}>
        <DataGridTable
          fetchDataUrl={`/orders/get-pick-ups`}
          columns={columns}
          status={status}
          title={''}
        />
      </Grid>
    </Grid>
  );
}

export default GetPickups;
