import React, { Fragment, useState } from 'react';
import { Card, CardContent } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { TableCell } from '@material-ui/core';
import FolderIcon from '@mui/icons-material/Folder';
import FolderViewTable from './FolderViewTable';
import DeleteConfirmation from '../../components/common/DeleteConfirmation';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import Folder from './Folder';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { isMobile } from 'react-device-detect';
import MFolderView from './mobile/MFolderView';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import AlertMain from 'components/common/AlertMain';

const headers = [
  { text: 'Name', value: 'name', },
  { text: 'File Extension', value: 'File Extension', },
  { text: 'Owner', value: 'Owner', },
  { text: 'Last Modified', value: 'Last Modified', },
  { text: 'File Size', value: 'File Size', },
  { text: 'Action', value: 'action', },
];

const headers2 = [
  { text: 'Name', value: 'name', },
  { text: 'File Extension', value: 'File Extension', },
  { text: 'Owner', value: 'Owner', },
  { text: 'Last Modified', value: 'Last Modified', },
  { text: 'File Size', value: 'File Size',},
];

const FolderView = (props) => {
  const axios = useAxiosPrivate()
  const [level1, setLevel1] = useState('');
  const [level2, setLevel2] = useState('');
  const [level1n, setLevel1n] = useState('');
  const [level2n, setLevel2n] = useState('');
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  }); 
  
  const franchiseeTimeZone = sessionStorage.getItem("franchiseeTimeZone") ? sessionStorage.getItem("franchiseeTimeZone") : Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { state } = useLocation();
  const TableB = ({ item, changeStatus, classes, onUpdate, loadData }) => {
    const changeUrl =  debounce((id, name) => {
      if (!level1 && !level2) {
        setLevel1n(name);
        setLevel1(id);
        loadData(`/folder/sub/${id}`);
      } else if (level1 && !level2) {
        setLevel2n(name);
        setLevel2(id);
        loadData(`/folder/sub/${id}`);
      }
    },2000);
    const role = sessionStorage.getItem('role');
    const history = useNavigate();
    const changeShare1 = async (id, share) => { await axios.put('/folder/fileshare', { id, share: !share, });

      setAlert({
        showAlert: true,
        severity: "success",
        message: !share ? "Successfully Shared" : "Share disabled",
      });
      
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 3000);

      if (!level1 && !level2) { loadData('/folder/all'); }
      else if (level1 && !level2) { loadData(`/folder/sub/${level1}`); }
      else if (level1 && level2) { loadData(`/folder/sub/${level2}`); }
    };

    const changeShare2 = async (id, share) => {
      await axios.put('/folder/foldershare', { id, share: !share, });
      setAlert({
        showAlert: true,
        severity: "success",
        message: !share ? "Successfully Shared" : "Share disabled",
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 3000);

      if (!level1 && !level2) { loadData('/folder/all'); }
      else if (level1 && !level2) { loadData(`/folder/sub/${level1}`); }
      else if (level1 && level2) { loadData(`/folder/sub/${level2}`); }
    };

    const changeDirectionFranchisee = (id) => { history('/franchiseeListView'); };
    const changeDirectionCorporate = (id) => { history('/corporateListView'); };

    return (
      <>
       <AlertMain setAlert={setAlert} alert={alert} />
      <Fragment>
        {item.size ? (
          <>
            <TableCell key={item.name}>
              <a target='_blank' rel='noopener noreferrer' href={item.url} style={{ textDecoration: 'none', color: '#000' }} >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {' '}
                  <DescriptionIcon />
                  <p style={{ paddingLeft: 5 }}> {item.name}</p>
                </div>
              </a>
            </TableCell>
            <TableCell>{item.Filetype}</TableCell>
            <TableCell key={item._id}>
              {`${item.owner.firstName} ${item.owner.lastName ? item.owner.lastName : ""}`}
            </TableCell>
            <TableCell key={item.updatedAt}>{`${moment.tz(item.updatedAt, `${franchiseeTimeZone}`).format( "MMMM Do YYYY, h:mm:ss a" )}`}</TableCell>
            <TableCell key={item.firstName}> {(item.size / 1024 / 1024).toFixed(2)} MB </TableCell>
            {(role === 'franchisor' || state?.user === 'user') && (
              <TableCell key={item.owner.lastName}>
                {state?.user.length !== 24 && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Folder level1={level1} level2={level2} loadData={loadData} file='file' id={item._id} filename={item.name} />
                    <DeleteConfirmation title={'File Deletion?'} message={ 'This file will be permanently deleted from this Drive' }
                      changeStatus={changeStatus} id={item._id} status={item.status} file='file' />
                    {state?.user === 'user' && (
                      <IconButton color={item.share ? 'success' : 'error'} onClick={() => changeShare1(item._id, item.share)} >
                        <ShareIcon />
                      </IconButton>
                    )}
                  </div>
                )}
              </TableCell>
            )}
          </>
        ) : item.different ? (
          <>
            <TableCell onClick={item?.name === "Franchisee Shared" ? changeDirectionFranchisee :  changeDirectionCorporate }>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', }} >
                {' '}
                <FolderSharedIcon />
                <p style={{ paddingLeft: 5 }}> {item?.name}</p>
              </div>
            </TableCell>
            <TableCell>folder type</TableCell>
            <TableCell onClick={() => changeUrl(item?._id, item?.name)}>
              Franchisor
            </TableCell>
            <TableCell key={Date.now()}>{`${moment.tz(Date.now(), `${franchiseeTimeZone}`).format( 'MMMM Do YYYY, h:mm:ss a' )}`}</TableCell>
            <TableCell key={item?.firstName}>{'-- --'}</TableCell>
          </>
        ) : (
          <>
            <TableCell onClick={() => changeUrl(item?._id, item?.name)} key={item?.name} >
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', }} >
                {' '}
                <FolderIcon />
                <p style={{ paddingLeft: 5 }}> {item?.name}</p>
              </div>
            </TableCell>
            <TableCell>folder type</TableCell>
            <TableCell onClick={() => changeUrl(item._id, item.name)} key={item._id} >
              {`${item?.owner?.firstName} ${item?.owner?.lastName ? item?.owner?.lastName : ""}`}
            </TableCell>
            <TableCell key={item?.updatedAt}>{`${moment.tz(item.updatedAt, `${franchiseeTimeZone}`).format( "MMMM Do YYYY, h:mm:ss a" )}`}</TableCell>
            <TableCell key={item?.firstName}>{'-- --'}</TableCell>
            {(role === 'franchisor' || state?.user === 'user') && (
              <TableCell key={item?.owner.lastName}>
                {state?.user.length !== 24 && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Folder level1={level1} level2={level2} loadData={loadData} edit='edit' id={item?._id} filename={item?.name} />
                    <DeleteConfirmation title={'Folder Deletion?'} message={ 'This folder will be permanently deleted from this Drive' }
                      changeStatus={changeStatus} id={item?._id} status={item?.status} />
                    {state?.user === 'user' && (
                      <IconButton color={item.share ? 'success' : 'error'} onClick={() => changeShare2(item?._id, item?.share)} >
                        <ShareIcon />
                      </IconButton>
                    )}
                  </div>
                )}
              </TableCell>
            )}
          </>
        )}
      </Fragment>
      </>
    );
  };

  return (
    <Card>
      {isMobile ? ( <> <MFolderView />  </> ) : (
        <CardContent style={{ padding: '2rem' }}>
          <h2 style={{ marginBottom: '1rem' }}>LWL Learning</h2>
          <FolderViewTable headers={ state?.user.length === 24 || state?.user === 'admin' ? headers2 : headers }
            items={[]} TableB={TableB} fetchDataUrl='/folder/all' statusChangeUrl='/folder/status' level1n={level1n}
            level2n={level2n} level1={level1} level2={level2} learn={state?.user} setLevel1={setLevel1} setLevel2={setLevel2}
            setLevel1n={setLevel1n} setLevel2n={setLevel2n} {...props} />
        </CardContent>
      )}
    </Card>
  );
};

export default FolderView;
