import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DropZone2 from './DropZone2';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useMediaQuery from '@mui/material/useMediaQuery';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ setImage, image }) {
  const [open, setOpen] = React.useState(false);
  const marginsSm = useMediaQuery("(max-width: 899px)");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
     {marginsSm ? ( <IconButton
        aria-label='create label'
        onClick={handleClickOpen}
        style={{
          backgroundColor: 'green',
          color: 'white',
          position: 'fixed',
          right: 10,
          bottom: 55,
          zIndex: 1,
        }}
      >
        <CloudUploadIcon />
      </IconButton>):(
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handleClose}
        >
          File Upload
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <DropZone2 setImage={setImage} image={image} mobile={'mobile'} />
        </DialogContent>
      </BootstrapDialog>)}
    </div>
  );
}
