import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, Card, CardContent } from '@mui/material';
import CreateSchedule from './CreateDeliverySchedule';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './calendar.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@mui/material/IconButton';
import DataGridTable from '../../components/layouts/DataGridStyled';
import DeleteRecord from './DeleteRecord';
import EditIcon from '@mui/icons-material/Edit';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import UpdateSchedule from './UpdateDelivery';
import AlertInlineMain from "components/common/AlertInlineMain";
import DateSlashToDashReformat from 'components/common/Functions/DateSlashToDashReformat';

export default function DeliverySchedules() {
  const axios = useAxiosPrivate()
  const franchiseeTimeZone = sessionStorage.getItem("franchiseeTimeZone");
  const today = new Date();
  const todayDateFromTimezone = new Date(today.toLocaleString('en-US', { timeZone: `${franchiseeTimeZone}` }).slice(0, -4))
  const [selectedDate, setSelectedDate] = useState(todayDateFromTimezone);
  let navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [editData, setData] = useState({});
  const [minDate, setMinDate] = useState(todayDateFromTimezone);
  const [maxDate, setMaxDate] = useState(new Date(todayDateFromTimezone.getDate() + 30));
  const [datesToAddContentTo, setDatesToAddContentTo] = useState([]);
  const [selected, setSelected] = useState(DateSlashToDashReformat(todayDateFromTimezone));

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setStatus(!status);
    const val = DateSlashToDashReformat(date);
    setSelected(val);
  };

  const handleClickOpen = () => {
    setOpen(true);
    getAvailableSchedules();
  };

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const tileContent = ({ date, view }) => {
    const isSameDay = (first, second) =>
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();
    // Add class to tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      if (datesToAddContentTo.find((dDate) => isSameDay(dDate, date))) { return '*'; }
    }
  };

  const getAvailableSchedules = async () => {
    try {
      const { data } = await axios.get('/delivery/available');
      if (data.dateRange.length > 0) {
        let arr = [];
        arr = data.dateRange.map((date) => { return new Date(new Date(date).toUTCString().slice(0, -4)) });
        setDatesToAddContentTo(arr);
      }

      if (data.start !== '' && data.end !== '') {

        const getStartDate = new Date(new Date(data.start).toUTCString().slice(0, -4));
        const getEndDate = new Date(new Date(data.end).toUTCString().slice(0, -4));

        setMinDate(getStartDate);
        setMaxDate(getEndDate);
      }
      setStatus(!status);
    } catch (err) {
      setAlert({
        showAlert: true,
        severity: 'success',
        message: 'Dates Loading failed',
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'success',
          message: '',
        });
        setStatus(!status);
      }, 3000);
    }
  };

  useEffect(() => {
    getAvailableSchedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteRecord = (id) => {
    deleteSchedule(id);
  };

  const deleteSchedule = async (id) => {
    try {
      await axios.delete(`/delivery/${id}`);
      setStatus(!status);
      getAvailableSchedules();
      setAlert({
        showAlert: true,
        severity: 'success',
        message: 'Delivery Shedule Delete Successfully',
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'success',
          message: '',
        });
      }, 1000);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: 'success',
        message: error.response.data ?? 'Error in Deleting Delivery Schedule',
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'success',
          message: '',
        });
        setStatus(!status);
      }, 3000);
    }
  };

  const columns = [
    { field: 'zoneName', headerName: 'ZONE', minWidth: 100, },
    {
      field: 'timeSlotsNew', headerName: 'TIME SLOTS', minWidth: 200, flex: 0.1,
      renderCell: (params) => {
        return (
          <>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', }} >
              {params.value.map((item, index) => {
                return (<div style={{ marginTop: '5px', marginBottom: '5px' }} key={index} >
                  {item.timeSlots} </div>);
              })}
            </div>
          </>
        );
      },
    },
    {
      field: 'limit', headerName: 'LIMIT', minWidth: 100, flex: 0.1,
      renderCell: (params) => {
        return (
          <>
            <div>
              {params.value.map((item, index) => {
                return (<div style={{ marginTop: '5px', marginBottom: '5px' }} key={index} >
                  {item.limit}
                </div>
                );
              })}
            </div>
          </>
        );
      },
    },
    { field: 'unattendedLimit', headerName: 'UNATTENDED LIMIT', minWidth: 110, flex: 0.2, },
    {
      field: 'action', headerName: 'ACTION', minWidth: 130, flex: 1, disableExport: true,
      renderCell: (cellValues) => {
        const onEdit = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setData(cellValues.row);
          handleClickOpen();
          setStatus(!status);
        };
        return (
          <>
            <IconButton onClick={onEdit} aria-label='edit'>
              <EditIcon setStatus={setStatus} status={status} />
            </IconButton>
            <DeleteRecord title={'Delivery Schedule Deletion?'}
              message={'This Delivery Schedule will be permanently deleted from this Franchisee'}
              deleteRecord={() => deleteRecord(cellValues.row.id)} alert={alert} setAlert={setAlert} />
          </>
        );
      },
    },
  ];
  return (
    <>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item ml={1}>
              <IconButton onClick={() => navigate('/schedule')} color='primary' >
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <h1>Delivery schedules</h1>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={5} mb={5}
              direction='row' columnSpacing={10} alignItems="center"
              justifyContent={{ lg: "flex-start", md: "center", sm: "center", xs: "center", }} >
              <Grid item lg={7} md={12} sm={12} xs={12} >
                <Calendar className='react-calendar'
                  showNeighboringMonth={false}
                  onClickDay={handleDateChange} value={selectedDate} calendarType='US'
                  minDate={minDate} maxDate={maxDate} tileContent={tileContent} />
              </Grid>
              <Grid container item lg={3} md={12} sm={12} xs={12}>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={5} >
                  <CreateSchedule setStatus={setStatus} status={status} selected={selected} refresh={() => getAvailableSchedules()} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} >
                  <Button variant='contained' color='primary' fullWidth={true} onClick={() => navigate('/delivery-time-slots')} >
                    <h3>Delivery time Slots </h3>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12}>
              <DataGridTable fetchDataUrl={`/delivery/${selected}`} columns={columns} status={status} title={`Delivery schedules for ${selected}`} />
              {alert.showAlert && (<Grid item md={12} m={2}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid>)}
            </Grid>
            <UpdateSchedule setOpen={setOpen} open={open} editData={editData} setStatus={setStatus} status={status} />
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
