import React, { useState, useEffect } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Grid, TextField, CardHeader, Card } from '@mui/material';
import AlertInlineMain from "components/common/AlertInlineMain";
import { useStyles } from 'utils/styles/styles';

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className='icon' />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className='icon' />;
}

export default function CustomSortIcons({
  columns,
  title,
  newData,
}) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const [alert, setAlert] = useState({
    // eslint-disable-line
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const onSearch = (e) => {
    const text = String(e.target.value).toLowerCase();
    setSearchText(text);

    if (text) {
      // eslint-disable-next-line
      const result = rows.filter((item) => {
        const str = JSON.stringify(item).toLowerCase();

        if (str.search(text) >= 0) return item;
      });
      // setPage(0);
      setFilteredItems(result);
    } else {
      setFilteredItems(rows);
    }
  };

  useEffect(() => {
    if (newData) {
      setRows(newData);
      setFilteredItems(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);

  return (
    <Grid container className={classes.root1} spacing={1}>
      <Grid fullWidth item xs={12} sm={12} md={12}>
        <Card>
          <CardHeader title={`${title}`} />
          <Grid container spacing={2}>
            {' '}
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                label='Search'
                fullWidth
                variant='outlined'
                style={{ margin: '1rem' }}
                onChange={onSearch}
                value={searchText}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            style={{ margin: '1rem', padding: 0 }}
          >
            <DataGrid
              autoHeight
              columns={columns}
              disableSelectionOnClick={true}
              disableColumnSelector={true}
              disableDensitySelector={true}
              rows={filteredItems.filter(item => item?.id != null)}
              components={{
                Toolbar: GridToolbar,
                ColumnSortedDescendingIcon: SortedDescendingIcon,
                ColumnSortedAscendingIcon: SortedAscendingIcon,
              }}
              initialState={{
                filter: {
                  filterModel: {
                    items: [
                      {
                        columnField: 'price',
                        operatorValue: '>',
                        value: '2.5',
                      },
                    ],
                  },
                },
              }}
            />
          </Grid>
          {alert.showAlert && (
            <Grid item md={12} m={2}>
              <AlertInlineMain setAlert={setAlert} alert={alert} />
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}
