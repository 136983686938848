import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import RoyaltyPaymentsPopup from "./RoyaltyPaymentsPopup";
import DataGridTable from "../layouts/DataGrid";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import { useNavigate } from 'react-router-dom';
import AlertInlineMain from "components/common/AlertInlineMain";
const numberWithCommas = (x) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};



export default function ViewRoyaltyPayments() {
  const navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const [row, setRow] = useState();
  const [open, setOpen] = useState(false);



  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
    successAlert();
  };

  const successAlert = () => {
    setAlert({
      showAlert: true,
      severity: "success",
      message: "Data found",
    });
  };

  const columns = [
    {
      field: "role_ID",
      headerName: "Franchisee ID",
      align: "center",
      width: 120,
    },
    { field: "franchisee_Name", headerName: "Franchisee Business Name", width: 200 },
    {
      field: "balance",
      headerName: "Royalty Balance $",
      align: "right",
      headerAlign: 'right',
      width: 150,
      valueGetter: (params) => {
        function calculateSales(value) {
          return numberWithCommas(Number(value).toFixed(2));
        }
        // return `$${params.row.account_excess ?? 0} ${params.row.balance ?? 0}`;
        return `$${calculateSales((params.row.balance ?? 0) - (params.row.account_excess ?? 0))}`;
      },
    },
    // {
    //   field: "account_excess",
    //   headerName: "Ongoing balance $",
    //   align: "right",
    //   headerAlign: 'right',
    //   width: 150,
    //   valueGetter: (params) => {
    //     function calculateSales(value) {
    //       return numberWithCommas(Number(value).toFixed(2));
    //     }
    //     return `$${calculateSales(params.row.account_excess ?? 0)}`;
    //   },
    // },
    {
      field: "stripeAccountBalance",
      headerName: "Stripe Account $",
      align: "right",
      headerAlign: 'right',
      width: 150,
      valueGetter: (params) => {
        function calculateSales(value) {
          return numberWithCommas(Number(value).toFixed(2));
        }
        return `$${calculateSales(params.row.stripeAccountBalance ?? 0)}`;
      },
    },
    {
      field: "paymentAndDate",
      headerName: "Last Payment & date",
      width: 160,
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      width: 130,
    },
    {
      field: "payment_History",
      headerName: "Payment History",
      width: 300,
      align: "center",
      disableExport: true,
      renderCell: (cellValues) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setRow(cellValues.row.id);
          setStatus(!status);
          handleClickOpen();
        };

        return (
          <Button onClick={onClick} variant="contained">
            Payment History
          </Button>
        );
      },
      valueGetter: (params) => { 

      }
    },
  ];

  const handleNavigation = () => {
    navigate("/view-franchisee", { state: { value: 2 } });

  };

  return (
    <>
      <Grid ml={1} item>
        <IconButton
          onClick={handleNavigation}
          color='primary'
          aria-label='add to shopping cart'
        >
          <ArrowBackIcon />
        </IconButton>
      </Grid>
      <Grid container spacing={1}>

        <Grid container item style={{ height: 350, width: "100%" }} xs={12} md={12}>
          <DataGridTable
            fetchDataUrl="/royalty/payments"
            columns={columns}
            status={status}
            title="Royalty Payments"
          />

          {alert.showAlert && (
            <Grid item md={12} m={2}>
              <AlertInlineMain setAlert={setAlert} alert={alert} />
            </Grid>
          )}
        </Grid>
        <RoyaltyPaymentsPopup
          setOpen={setOpen}
          open={open}
          row={row}
          setStatus={setStatus}
          status={status}
        />
      </Grid>
    </>
  );
}
