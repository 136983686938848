import React, { useMemo, useState } from "react";
import DataGrid from "components/layouts/DataGridJobs";
import { Button } from "@mui/material";
import JobDetailsModal from './jobDetailsModal';
import DateSlashToDashReformat from 'components/common/Functions/DateSlashToDashReformat';
import { useSelector } from "react-redux";
import EditNewJobDialog from "./EditNewJobDialog";

export default function Main({ date, status, setCircularBarData, setEditJobDialogOpen, setEditJobDetails, editJobDialogOpen, addJobDialogOpen  }) {
  const [openDetailsView, setOpenDetailsView] = useState(false);
  const [rowDetails, setRowDetails] = useState(null);
  const filterValue = useSelector((state) => state.route.progressDataGridFilter);
    
  const fomratedPayloadDate = useMemo(() => { 
    const returnFormatedDate = DateSlashToDashReformat(date);
    return returnFormatedDate 
  }, [date]);
  const [isCopyJob, setCopyJob] = useState(false);

  const columns = [
    {
      field: "view",
      headerName: "Job Details",
      width: 120,
      disableExport: true,
      renderCell: (params) => {
        const onClick = () => {
          setOpenDetailsView(true)
          setRowDetails(params?.row)
        };
        return <Button disabled={params?.row?.status === 'PICKUP_IN_PROGRESS' ? true : false} onClick={onClick}>View</Button>;
      },
    },
    {
      field: "edit_jobs",
      headerName: "Edit Jobs",
      width: 120,
      disableExport: true,
      renderCell: (params) => {

        const onClick = () => {
          setEditJobDetails(params?.row)
          setEditJobDialogOpen(true)
          // setOpenDetailsView(true)
          // setRowDetails(params?.row)
        };
        return <Button onClick={onClick}>Edit</Button>;
      },
    },
    {
      field: "orderId",
      headerName: "Order ID",
      width: 120,
      valueGetter: (params) => {
        return `${params?.row?.orderDetail?.orderId}`;
      },
    },
    {
      field: "pickupTime",
      headerName: "Pickup Time",
      width: 200,
      valueGetter: (params) => {
        return `${params?.row?.timeFrame}`;
      },
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 480,
      valueGetter: (params) => {
        return `${params?.row?.address}`;
      },
    },
    {
      field: "first",
      headerName: "First",
      width: 150,
      valueGetter: (params) => {
        return `${params?.row?.customerDetail?.firstName}`;
      },
    },
    {
      field: "last",
      headerName: "Last",
      width: 150,
      valueGetter: (params) => {
        return `${params?.row?.customerDetail?.lastName}`;
      },
    },
    {
      field: "assignTo",
      headerName: "Assign To",
      maxWidth: 100,
      valueGetter: (params) => {
        return `${params?.row?.driverDetail?.name ?? "Unassigned"}`;
      },
    },
    {
      field: 'orderType',
      headerName: 'Services',
      minWidth: 180,
      valueGetter: (params) => {
        if (params.row?.orderDetail?.isManualOrder && !params.row?.orderDetail?.orderType) {
          return "Manual Order"
        } else {
        switch (params.row.orderDetail?.orderType) {
          case '1':
            return 'Wash/Dry/Fold';
          case '2':
            return 'Dry Cleaning';
          case '3':
            return 'Tailoring';
          case '4':
            return 'Dry Cleaning & Tailoring';
          case '5':
            return 'Wash/Dry/Fold , Tailoring';
          case '6':
            return 'Wash/Dry/Fold , Tailoring';
          case '7':
            return 'Wash/Dry/Fold , Dry Cleaning';
          case '8':
            return 'Wash/Dry/Fold , Dry Cleaning';
          case '9':
            return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
          case '10':
            return 'Wash/Dry/Fold , Dry Cleaning, Tailoring';
          default:
            return '-';
        }
      }
    }
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      valueGetter: (params) => {
        switch ((params?.row?.orderDetail?.specialCareTracker)
        ? params?.row?.orderDetail?.specialCareTracker :
        params?.row?.status) {
          case 'placingOrder':
            return 'Placing order';
          case 'SET_TO_PICKUP' || 'setToPickup':
            return 'Set to pickup';
          case 'PICKED_UP' || 'pickup':
            return 'Picked up';
          case 'processing':
            return 'Processing';
          case 'PICKUP_CANCELED' || 'pickupCancel':
            return 'Pickup cancelled';
          case 'SET_TO_DELIVER' || 'setToDeliver':
            return 'Set to deliver';
          case 'DELIVERED' || 'completed':
            return 'Delivered';
          case 'DELIVERY_CANCELED' || 'deliveryCancel':
            return 'Delivery cancelled';
          case 'specialSetToDeliver':
            return 'Special set to deliver';
            case 'specialCompleted':
            return 'Special delivered';
          case 'specialDeliveryCancel':
            return 'Special delivery cancelled';
          case 'DELIVER_IN_PROGRESS':
            return 'Delivery in progress';   
          case 'PICKUP_IN_PROGRESS':
              return 'Pickup in progress';    
          default:
            return '-';
        }
      },
    },
  ];

  return (
    <>
    <div>
      <DataGrid
       editJobDialogOpen={editJobDialogOpen}
       addJobDialogOpen={addJobDialogOpen}  
       isCopyJob={isCopyJob}      
       autoHeight
        columns={columns}
        fetchDataUrl={`/job/job-franchisee/${fomratedPayloadDate}/${status}`}
        status={status}
        title={"Pickups"}
        setCircularBarData={setCircularBarData}
        filterModel={{
          items: [{ columnField: 'status', operatorValue: 'contains', value: filterValue }],
        }}
      />
      <JobDetailsModal openDetailsView={openDetailsView} setOpenDetailsView={setOpenDetailsView} rowDetails={rowDetails} isCopyJob={isCopyJob} setCopyJob={setCopyJob}/>
    </div>
    </>
  );
}
