import React, { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { useNavigate } from "react-router-dom";

export default function Fail() {
  let navigate = useNavigate();

  const redirect = () => {
    setTimeout(() => {
      navigate("/");
    }, 3000);
  };

  useEffect(() => {
    redirect();
    //eslint-disable-next-line
  }, []);

  return (
    <Grid
      container
      spacing={1}
      alignContent="center"
      style={{ paddingTop: "10rem" }}
    >
      <Grid
        container
        justifyContent="flex-end"
        item
        xs={10}
        md={7}
        alignContent="center"
      >
        <Typography variant="h5">Payment Failed</Typography>
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        item
        xs={2}
        md={5}
        alignContent="center"
      >
        <DangerousIcon />
      </Grid>
    </Grid>
  );
}
