import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Button } from '@mui/material';
import AddUserDialog from './AddUserDialog';
import EditUserDialog from './EditUserDialog';
import { Grid, Typography } from '@material-ui/core';
import OrderForm from './OrderForm';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Papa from "papaparse";
import DataGridManualOrders from 'components/layouts/DataGridManualOrders';
import useAxiosPrivate from 'utils/hooks/useAxiosPrivate';
import moment from 'moment';
import {LinearProgress} from '@mui/material';

const CustomOrders = () => {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();

  const marginsSm = useMediaQuery("(max-width: 899px)");
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    // eslint-disable-line
    showAlert: false,
    severity: 'success',
    message: '',
  });
  const [successBulkOrdersCreation, setSuccessBulkOrdersCreation] = useState(false);
  const [progress, setProgress] = useState(0);

  //add user dialog
  const addUserHandleOpenDialog = () => {
    setAddUserDialogOpen(true);
  };
  const addUserHandleCloseDialog = () => {
    setAddUserDialogOpen(false);
  };


  //edit user dialog
  const editUserHandleOpenDialog = () => {
    setEditUserDialogOpen(true);
  };

  const editUserHandleCloseDialog = () => {
    setEditUserDialogOpen(false);
  };
  const onGoback = () => {
    navigate('/routing-main')
  }

  const handleChangeForManualOrderCsvFile = (e) => {
    const fileType = e.target.files[0].type;
    const fileName = e.target.files[0].name;
    const fileExtension = fileName.split(".").pop();
    if (fileType !== "application/vnd.ms-excel" && fileExtension !== "csv") {
      // return setCsvAlert({ showAlert: true, severity: "error", message: "Sorry, thats not a valid CSV file", });
    } else {
      // setCsvFile(e.target.files[0]); 
      Papa.parse(e.target.files[0], {
        header: true,
        complete: updateData,
      });
    }
  };

  const updateData = async (result) => {
    let data = result.data;
    const updatedData = await Promise.all(data.map(item => {
      if (item["pick ups"]) {
        return {
          ...item,
          pickupDate: moment(item["Pick-up Date"]).format("dddd, MMMM D, YYYY"),
          pickupTimeSlot: `${standardizeTimeFormat(item["Pick up window start time"])} - ${standardizeTimeFormat(item["Pick up window end time"])}`,
          pickupNote: item["Pickup Notes"],
          orderNote: item["Order Notes"],
        };
      }
      if (item["deliveries"]) {
        return {
          ...item,
          diliverDate: moment(item["Pick-up Date"]).format("dddd, MMMM D, YYYY"),
          diliverTimeSlot: `${standardizeTimeFormat(item["Pick up window start time"])} - ${standardizeTimeFormat(item["Pick up window end time"])}`,
          deliveryNote: item["Delivery Notes"],
          orderNote: item["Order Notes"],
        };
      }
      return item;
    }));

    setCsvData(updatedData);
  };

  function standardizeTimeFormat(timeString) {
    if (!timeString) {
      return null;
    }
    // Convert the string to lowercase
    let lowercaseTime = timeString.toLowerCase();

    // Check if "pm" exists and capitalize it
    if (lowercaseTime.includes('pm')) {
      lowercaseTime = lowercaseTime.replace('pm', 'PM');
    }

    // Check if "am" exists and capitalize it
    if (lowercaseTime.includes('am')) {
      lowercaseTime = lowercaseTime.replace('am', 'AM');
    }

    return lowercaseTime;
  }

  const columns = [
    {
      field: 'First',
      headerName: 'First Name',
      minWidth: 150,
    },
    {
      field: 'Last',
      headerName: 'Last Name',
      minWidth: 150,
    },
    {
      field: 'Pick-up Date',
      headerName: 'Pick-up Date',
      minWidth: 150,
    },

    {
      field: 'Pick up window start time',
      headerName: 'Pick up window start time',
      minWidth: 210,
    },

    {
      field: 'Pick up window end time',
      headerName: 'Pick up window end time',
      minWidth: 150,
    },

    {
      field: 'Street',
      headerName: 'Street',
      minWidth: 220,
    },
    {
      field: 'City',
      headerName: 'City',
      minWidth: 150,
    },
    {
      field: 'Zip',
      headerName: 'Zip Code',
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.Zip?.split(":")[1]}`;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      valueGetter: (params) => {
        if (params.row.status) {
          return `${params.row?.status}`
        } else {
          return `Pending`;
        }
      },
    },
    {
      field: 'reason',
      headerName: 'Reason',
      minWidth: 200,
      valueGetter: (params) => {
        if (params.row.reason) {
          return `${params.row?.reason}`
        } else {
          return `-`
        }
      },
    },
  ];

  const seperateDataBatches = (data, batchSize) => {
    const parts = Math.ceil(data?.length / batchSize);

    const dataBatches = [];

    for (let i = 0; i < parts; i++) {
      const start = i * batchSize;
      const end = (i + 1) * batchSize;
      const batch = data.slice(start, end);
      dataBatches.push(batch);
    }

    return dataBatches;
  }
  
  const handleBulkManualOrders = async () => {

    try {
      setIsLoading(true);
      
      let dataBatches = seperateDataBatches(csvData, 10)
      setProgress(Math.floor(5))
      let finalData = [];

      for (const [index,batch] of dataBatches.entries()) {
        const { data } = await axios.post("/customer/bulk/manualOrders", { csvData: batch });
        finalData.push(...data)
        setProgress(Math.floor( ( ((index+1)/dataBatches.length) * 100 ) ))
      }

      // const { data } = await axios.post("/customer/bulk/manualOrders", { csvData: csvData });
      if (finalData?.length > 0) {
        setSuccessBulkOrdersCreation(true);
        setCsvData([]);
        // Sorting the array based on "Success" status
        const sortedArray = finalData.sort((a, b) => {
          // Convert status to lower case for case-insensitive comparison
          const statusA = a.status.toLowerCase();
          const statusB = b.status.toLowerCase();

          // Compare based on the "Success" status
          if (statusA === 'success' && statusB !== 'success') {
            return -1; // a comes before b
          } else if (statusA !== 'success' && statusB === 'success') {
            return 1; // b comes before a
          } else {
            return 0; // no change in order
          }
        });
        setCsvData(sortedArray);
      }
    } catch (err) {
      console.log(err)
      return err;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Grid container>

      <Grid container item lg={12} alignItems="center" justifyContent='space-between'>

        <Grid item >
          <IconButton onClick={() => onGoback()}>
            <ArrowBackIcon sx={{ color: "#343434", }} />
          </IconButton>
        </Grid>
        <Typography style={{ marginTop: '1rem', fontSize: "1.8rem", textTransform: "uppercase", fontWeight: "600", textAlign: "center" }}>Manual Orders</Typography>
        <Grid item >
          <div style={{ width: '10px' }}></div>
        </Grid>
      </Grid>

      <Grid container item
        lg={12}
        style={{ marginTop: '1rem' }}
        alignItems="center"
        justifyContent={marginsSm ? "center" : "flex-end"}
        spacing={2}>
        {csvData && !successBulkOrdersCreation &&
          <Grid item lg={"auto"}>
            <Button variant="contained" onClick={() => handleBulkManualOrders()} disabled={isLoading}>Create All Manual Orders</Button>
            <LinearProgress variant="buffer" value={progress}/>
          </Grid>
        }

        {csvData === null &&
          <>
            <Grid item lg={"auto"}>
              <Button variant="contained" onClick={editUserHandleOpenDialog}>Edit User</Button>
            </Grid>
            <Grid item lg={"auto"}>
              <Button variant="contained" onClick={addUserHandleOpenDialog}>Add User</Button>
            </Grid>
            <Grid item lg={"auto"}><Button variant="contained" color="secondary" component="label">Upload File<input type="file" hidden onChange={handleChangeForManualOrderCsvFile} /></Button>
            </Grid>
          </>
        }
      </Grid>

      <Grid container item alignItems="center" justifyContent="center" >
        {csvData ?
          <DataGridManualOrders
            autoHeight
            columns={columns}
            title='Import Manual Orders'
            filteredItems={csvData}
            alert={alert}
            setAlert={setAlert}
          />
          :
          <Grid item>
            <OrderForm />
          </Grid>}
      </Grid>

      {addUserDialogOpen && <AddUserDialog open={addUserDialogOpen} onClose={addUserHandleCloseDialog} />}
      {editUserDialogOpen && <EditUserDialog open={editUserDialogOpen} onClose={editUserHandleCloseDialog} />}
    </Grid>
  );
};

export default CustomOrders;