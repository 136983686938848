import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import CircularProgress from "@mui/material/CircularProgress";

export default function Success() {
  let navigate = useNavigate();
  const axios = useAxiosPrivate()

  const [message, setMessage] = useState("");
  const [messageTwo, setMessageTwo] = useState("");
  const [messageThree, setMessageThree] = useState("");
  const [backendCall, setBackendCall] = useState(false);

  let { id } = useParams();

  const redirect = async () => {
    try {
      setBackendCall(true);
      const { data } = await axios.put(`/user/confirmation/${id}`, {
        status: "payment",
      });

      if (data.status === "payment") {
        setMessage("Stripe Payment Account Being Verified.");
        setMessageTwo("Status :  Verification In-progress");
        setMessageThree("Come back in a short time to check your status.");
      } else if (data.status === "completed") {
        setMessage("Payment process was successful");
      }

      setTimeout(() => {
        navigate("/");
      }, 6000);
    } catch (error) {
      console.log(error);
    } finally {
      setBackendCall(false);
    }
  };

  useEffect(() => {
    redirect();
    //eslint-disable-next-line
  }, []);
  return (
    <Grid
      container
      spacing={1}
      alignContent="center"
      style={{ paddingTop: "10rem" }}
    >
      <Grid
        container
        justifyContent="center"
        item
        xs={12}
        md={12}
        alignContent="center"
      >
        <Typography variant="h5">{message}</Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        item
        xs={12}
        md={12}
        alignContent="center"
        style={{ marginTop: "1rem" }}
      >
        <Typography variant="h5">{messageTwo}</Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        item
        xs={12}
        md={12}
        alignContent="center"
        style={{ marginTop: "1rem" }}
      >
        <Typography variant="h5">{messageThree}</Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        item
        xs={12}
        md={12}
        alignContent="center"
      >
        {backendCall && (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
