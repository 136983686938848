import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import Papa from "papaparse";
import underscore from "underscore";
import { useDispatch } from "react-redux";
import { setSelectedID, setRoleTypeCreate } from "store/actions/otherActions";
import useFranchiseeMgtOnsubmit from "./useFranchiseeMgtOnsubmit";
import useFranchiseeMgtValidation from "./FranchiseeMgtValidation";
import ExtractZips from "./ExtractZips";
import useCorporateMgtValidation from "./CorporateValidation";

export default function FranchiseeMgt() {
  let dispatch = useDispatch();
  const axios = useAxiosPrivate()
  let navigate = useNavigate();
  const handleSelectedId = (id) => { dispatch(setSelectedID(id)); };
  const [loadingScreen, setLoading] = useState(true);
  const [backendCall, setBackendCall] = useState(false);
  const [orderCount, setOrderCount] = useState(1);
  const [disabledButtonOnZip, setdisabledButtonOnZip] = useState(true);
  const [disabledButtonOnZipCoop, setdisabledButtonOnZipCoop] = useState(true);
  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });
  const [royaltyAlert, setRoyaltyAlert] = useState({ showAlert: false, severity: "success", message: "", });
  const [zipAlert, setZipAlert] = useState({ showAlert: false, severity: "success", message: "", });
  const [csvAlert, setCsvAlert] = useState({ showAlert: false, severity: "success", message: "", });
  const [invalidZipAlert, setInvalidZipsAlert] = useState({ showAlert: false, severity: "success", message: "", });
  const [invalidZipCodes, setInvalidZipCodes] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [number, setNumber] = React.useState();
  const handleClickOpen = (num) => { setOpen(true); setNumber(num); };
  const [franchiseeId, setFranchiseeId] = useState();
  const [franchiseeData, setfranchiseeData] = useState(null);
  const [royaltySchemes, setRoyaltySchemes] = useState([]);
  const [componentType, setComponentType] = useState("create");
  const [zipDelArr, setZipDelArr] = useState([]);
  const { state } = useLocation();
  const [initialValues, setInitialValues] = useState({ fID: "", role: "", firstName: "", ownerName: "", email: "", password: "", suitNo: "", address: "",
  serviceRegions: [], royaltyScheme: null, pricing: { serviceOne: 450, serviceTwo: 1200, serviceThree: 850 }, note: "", corporateFirstName:'',corporateOwnerName: "",
});

  const [csvFile, setCsvFile] = useState([]);
  const [currentZips, setCurrentZips] = useState([]);
  const [newState, setNewState] = useState("");
  const [serviceRegionsUpdate, setServiceRegionsUpdate] = useState([]);

  const [selectedTypeOption, setSelectedTypeOption] = useState('franchisee');

  const handleTypeOptionChange = (event) => {
    setSelectedTypeOption(event.target.value);
    dispatch(setRoleTypeCreate(event.target.value)); 
  };

  // create Stripe account
  const createStripeAccount = async (user) => {
    try {
      await axios.post("/user/franchisee-new", user);
      setAlert({ showAlert: true, severity: "success", message: "Franchisee Stripe account Created!", });
      setTimeout(() => { setAlert({ showAlert: false, severity: "success", message: "", }); }, 3000);
    } catch (error) {
      setAlert({ showAlert: true, severity: "error", message: "Franchisee Stripe account Creation Failed!", });
      setTimeout(() => { setAlert({ showAlert: false, severity: "success", message: error.response.data.message, }); }, 3000);
    }
  };
  const { useOnSubmit } = useFranchiseeMgtOnsubmit({ componentType, createStripeAccount, setInitialValues, setAlert, newState, state,
    navigate, setBackendCall, zipDelArr});
  // get Company
  const get = async (id) => {
    try {
      const { data } = await axios.get(`/user/${id}`);
      setFranchiseeId(id);
      setfranchiseeData(data);
      dispatch(setRoleTypeCreate(data.role)); 
      setSelectedTypeOption(data.role)
      if(data.role === 'franchisee'){
      setInitialValues({ fID: data.roleID, role: data.role, firstName: data.firstName, ownerName: data.ownerName, email: data.email, suitNo: data.suitNo,
        address: data.address, serviceRegions: data.serviceRegions, royaltyScheme: data.royaltyScheme, pricing: data.pricing, note: data.note, });
      }else{
        setInitialValues({ fID: data.roleID, role: data.role, corporateFirstName: data.firstName, corporateOwnerName: data.ownerName, email: data.email, suitNo: data.suitNo,
          address: data.address, serviceRegions: data.serviceRegions, royaltyScheme: data.royaltyScheme, pricing: data.pricing, note: data.note, });
      }
      setServiceRegionsUpdate(data.serviceRegions);

    } catch (error) {
      setAlert({ showAlert: true, severity: "error", message: "Data loading failed!", });
    }
  };

  const getRoyalty = async () => {
    try {
      const { data } = await axios.get("/royalty/");
      setRoyaltySchemes(data);
    } catch (error) {
      setAlert({ showAlert: true, severity: "error", message: "Data loading failed!", });
    }
  };


  const getFranchiseeZips = async () => {
    try {
      const { data } = await axios.get("/user/");
      let zips = data?.map((e) => e.serviceRegions);
      let allInOne = await ExtractZips(zips);
      setCurrentZips([...allInOne]);
    } catch (error) {
      setAlert({ showAlert: true, severity: "error", message: "Data loading failed!", });
    }
  };

  const checkFranchiseeZipsInUpdate = () => {
    try {
      let duplicates = currentZips.filter(function (val) { return initialValues.serviceRegions.indexOf(val) !== -1; });
      let uniqueArray = duplicates.filter((item, pos) => { return duplicates.indexOf(item) === pos; });
      let uniqueArrayInUpdate =[];

      const validZips = [];
      const invalidZips = [];

      initialValues?.serviceRegions?.map((ele) => {
        if (isValidUSZip(ele)) { validZips.push(ele); }
        else { invalidZips.push(ele); }
      });

      setInvalidZipCodes(invalidZips);
      
      if(serviceRegionsUpdate.length > 0 ){
      const zipsToDeleteSet = new Set(serviceRegionsUpdate);
       uniqueArrayInUpdate = uniqueArray.filter((zip) => { return !zipsToDeleteSet.has(zip); });
      }

      if (invalidZips?.length > 0) {
        setInvalidZipsAlert({ showAlert: true, severity: "error", message: `Zip code is not valid please remove ${invalidZips}`, });
        setdisabledButtonOnZip(false);
        setdisabledButtonOnZipCoop(false);
      }else{
        if (uniqueArrayInUpdate?.length === 1) {
          setZipAlert({ showAlert: true, severity: "error", message: `Zip code is already exists please remove ${uniqueArrayInUpdate}`, });
          setdisabledButtonOnZip(false);
          setdisabledButtonOnZipCoop(false);
        } else if (uniqueArrayInUpdate?.length > 0) {
          setZipAlert({ showAlert: true, severity: "error", message: `Some zips are already exists please remove ${uniqueArrayInUpdate}`, });
          setdisabledButtonOnZip(false);
          setdisabledButtonOnZipCoop(false);
        } else if (uniqueArrayInUpdate?.length === 0 && initialValues?.serviceRegions?.length !== 0) { 
          setZipAlert({ showAlert: false,  }, 
          setdisabledButtonOnZip(true),
          setdisabledButtonOnZipCoop(true)); 
        }
      }

    } catch (error) {
      setAlert({ showAlert: true, severity: "error", message: "Data loading failed!", });
    }
  };

  function isValidUSZip(sZip) { return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(sZip); }

  const checkFranchiseeZips = () => {
    try {
      let duplicates = currentZips.filter(function (val) { return initialValues.serviceRegions.indexOf(val) !== -1; });
      let uniqueArray = duplicates.filter((item, pos) => { return duplicates.indexOf(item) === pos; });
      const validZips = [];
      const invalidZips = [];
      // eslint-disable-next-line
      initialValues?.serviceRegions?.map((ele) => {
        if (isValidUSZip(ele)) { validZips.push(ele); }
        else { invalidZips.push(ele); }
      });
      setInvalidZipCodes(invalidZips);
      if (invalidZips?.length > 0) {
        setInvalidZipsAlert({ showAlert: true, severity: "error", message: `Zip code is not valid please remove ${invalidZips}`, });
        setdisabledButtonOnZip(false);
        setdisabledButtonOnZipCoop(false);
      }
      if (uniqueArray?.length === 1) {
        setZipAlert({ showAlert: true, severity: "error", message: `Zip code is already exists please remove ${uniqueArray}`, });
        setdisabledButtonOnZip(false);
        setdisabledButtonOnZipCoop(false);;
      } else if (uniqueArray?.length > 0) {
        setZipAlert({ showAlert: true, severity: "error", message: `Some zips are already exists please remove ${uniqueArray}`, });
        setdisabledButtonOnZip(false);
        setdisabledButtonOnZipCoop(false);
      } else if (uniqueArray?.length === 0) {  //setZipAlert({ showAlert: false, }); 
      } 
    } catch (error) {
      setAlert({ showAlert: true, severity: "error", message: "Data loading failed!", });
    }
  };
  const zipAndRoyility = () => {

    if( selectedTypeOption === "franchisee" ){

      if (initialValues?.serviceRegions?.length === 0) {
        
      setZipAlert({ showAlert: true, severity: "error", message: `Zipcode can not be empty`, });
      setdisabledButtonOnZip(false)

    } else if (initialValues.royaltyScheme === null) {

      setRoyaltyAlert({ showAlert: true, severity: "error", message: `Royalty Program Can't be Empty`, });
      setdisabledButtonOnZip(false)
      
    }
    if (initialValues?.serviceRegions?.length > 0 || initialValues.royaltyScheme !== null) {

      if(initialValues.royaltyScheme !== null){
      setRoyaltyAlert({ showAlert: false, });
      setdisabledButtonOnZip(true)
      }

      if(initialValues?.serviceRegions?.length > 0){
      setZipAlert({ showAlert: false, });
      setdisabledButtonOnZip(true)
      }

     }
  }else{

    if (initialValues?.serviceRegions?.length === 0) {
      setZipAlert({ showAlert: true, severity: "error", message: `Zipcode can not be empty`, });
      setdisabledButtonOnZipCoop(false)
    }else if (initialValues?.serviceRegions?.length > 0) {
      setZipAlert({ showAlert: false, });
      setdisabledButtonOnZipCoop(true)
     }
  }
  }
  useEffect(() => {
    zipAndRoyility();
    // eslint-disable-next-line
  }, [initialValues])

  const removeValue = () => { document.getElementById("name").value = ""; };
  //use effect hook
  useEffect(() => {
    if (state && state?.id) {
      setComponentType("update");
      get(state.id);
      handleSelectedId(state.id);
    }
    // eslint-disable-next-line
  }, [state?.id]);
  const getState = () => {
    if (initialValues?.address) {
      const stateArr = initialValues?.address?.split(",");
      const state = stateArr[stateArr?.length - 2]?.trim();
      setNewState(state);
    }
  };
  const { validationSchema, validationSchemaUpdate } = useFranchiseeMgtValidation();
  const {validationSchemaCorporate, validationSchemaCorporateUpdate}= useCorporateMgtValidation();
  //CSV upload
  const handleChangeForCsvFile = (e) => {
    const fileType = e.target.files[0].type;
    const fileName = e.target.files[0].name;
    const fileExtension = fileName.split(".").pop();
    if (fileType !== "application/vnd.ms-excel" && fileExtension !== "csv") {
      return setCsvAlert({ showAlert: true, severity: "error", message: "Sorry, thats not a valid CSV file", });
    } else { setCsvFile(e.target.files[0]); }
  };

  const importCSV = () => {
    let data = csvFile;
    if (data?.length === 0) {
      return setCsvAlert({ showAlert: true, severity: "error", message: "Please, Upload a CSV file", });
    } else { Papa.parse(data, { header: true, complete: updateData,});
    }
  };

  const updateData = (result) => {
    let data = result.data;
    let validZips = data.map((e) => e.zips);
    const validLength = validZips.filter((e) => { return e !== undefined; });
    if (validLength?.length > 0) {
      try {
        const validRecords = validZips.filter((e) => { return e !== ""; });
        const newArray = validRecords.filter( (val) => !initialValues.serviceRegions.includes(val) );
        const uniqueArray = Array.from(new Set(newArray));
        setInitialValues((pre) => ({ ...pre, serviceRegions: [...pre.serviceRegions, ...uniqueArray], }));
        setCsvFile([]);
      } catch (error) {
        setAlert({ showAlert: true, severity: "error", message: 'CSV file header should be "zips"', });
      }
    }
  };

  const handleFieldValues = (e) => { setInitialValues((pre) => ({ ...pre, [e.target.name]: e.target.value, })); };
  // Add Chips
  const handleAddChip = (chip) => {
    setInitialValues((pre) => ({ ...pre, serviceRegions: [...pre.serviceRegions, chip], }));
//setting deleted zip array for delete zones (API payload)
    setZipDelArr(oldVal => {
      if (oldVal.find(item => item === chip)) {
        return oldVal.filter(filterItm=> filterItm!==chip)
      } else { 
        return oldVal
      }
    })

  };
  // Delete Chips
  const handleDeleteChip = (chip) => {
    if (componentType === "update" && orderCount > 0 && !(!serviceRegionsUpdate.find(item => item === chip))) { return }
    else {
      setInitialValues((pre) => ({ ...pre, serviceRegions: underscore.without(pre.serviceRegions, chip), }));

      //setting deleted zip array for delete zones (API payload)
      setZipDelArr(oldVal => {
        if (oldVal.find(item => item === chip)) {
          return oldVal
        } else { 
          return ([...oldVal, chip])
        }
      })
    }
  };
  //get orders relevant to the franchisee
  const getOrders = async () => {
    setLoading(true)
    try {
      setLoading(true)
      const { data } = await axios.get(`/orders/getorders/${franchiseeData._id}`);
      setOrderCount(data)
      setLoading(false)
    } catch (error) { setLoading(false) }
  }
  useEffect(() => {
    if (franchiseeData) { getOrders(); }
    // eslint-disable-next-line
  }, [franchiseeData])
  useEffect(() => {
    getState();
    // eslint-disable-next-line
  }, [initialValues.address]);
  useEffect(() => {
    getRoyalty(); getFranchiseeZips();
    dispatch(setRoleTypeCreate("franchisee")); 

    // eslint-disable-next-line
  }, []);
  
  useEffect(() => { 
    if (componentType === "create") { checkFranchiseeZips();
      // eslint-disable-next-line
    } else if (componentType === "update") { checkFranchiseeZipsInUpdate(); }
    // eslint-disable-next-line
  }, [initialValues]);

  useEffect(() => {
    if (csvFile?.length === 0) { removeValue(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvFile]);
  useEffect(() => {
    if (componentType === "create") { setOrderCount(false) } else { setOrderCount(1) }
    // eslint-disable-next-line
  }, [componentType])

  return { loadingScreen, backendCall, disabledButtonOnZip, alert, royaltyAlert, zipAlert, csvAlert, invalidZipAlert,
    invalidZipCodes, open, number, handleClickOpen, franchiseeId, royaltySchemes, useOnSubmit, validationSchema, handleChangeForCsvFile,
    importCSV, handleFieldValues, handleAddChip, handleDeleteChip, componentType, navigate, orderCount, setInitialValues, setInvalidZipsAlert,
    setCsvAlert, setZipAlert, initialValues, setAlert, setOpen, handleTypeOptionChange, selectedTypeOption, setSelectedTypeOption, validationSchemaCorporate, disabledButtonOnZipCoop, validationSchemaCorporateUpdate, validationSchemaUpdate};
}
