import {
  SHOW_HEADER_ITEMS,
  HIDE_HEADER_ITEMS,
  HIDE_NAV,
  SHOW_NAV,
} from "../actions/types";

const hideItems = (state = {}, action) => {
  switch (action.type) {
    case SHOW_HEADER_ITEMS:
      return {
        ...state,
        showItems: true,
      };
    case HIDE_HEADER_ITEMS:
      return {
        ...state,
        showItems: false,
      };

    case SHOW_NAV:
      return {
        ...state,
        showNav: true,
      };

    case HIDE_NAV:
      return {
        ...state,
        showNav: false,
      };
    default:
      return state;
  }
};

export default hideItems;
