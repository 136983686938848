import React, { useState, useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Clock from 'react-live-clock';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    mobileTime: {
        [theme.breakpoints.down("xs")]: {
            // display: "none",
            paddingLeft:"1rem"
        },
    }
}));

const DateTime = () => {
    const classes = useStyles();
    const state = useSelector((state) => state.auth.franchiseeTimeZone);

    //for first time load
    const franchiseeTimeZone = sessionStorage.getItem("franchiseeTimeZone")
    const [date, setDate] = useState(franchiseeTimeZone);
    useEffect(() => {
        setDate(state)
    }, [state]);

    return (
        <div className={classes.mobileTime}>
            <Clock format={'L LTS'} ticking={true} timezone={`${date}`} />
        </div>
    )
}

export default DateTime