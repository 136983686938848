import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, Card, CardContent } from '@mui/material';
import CreateSchedule from './CreateSchedule';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './calendar.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@mui/material/IconButton';
import DataGridTable from '../../components/layouts/DataGridStyled';
import DeleteRecord from './DeleteRecord';
import EditIcon from '@mui/icons-material/Edit';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import UpdateSchedule from './UpdateSchedule';
import AlertInlineMain from "components/common/AlertInlineMain";
import DateSlashToDashReformat from 'components/common/Functions/DateSlashToDashReformat';
import DeleteSchedules from './DeleteSchedules';

export default function Schedules() {
  const axios = useAxiosPrivate()
  const franchiseeTimeZone = sessionStorage.getItem("franchiseeTimeZone");
  const today = new Date();
  const todayDateFromTimezone = new Date(today.toLocaleString('en-US', { timeZone: `${franchiseeTimeZone}` }).slice(0, -4))
  const [selectedDate, setSelectedDate] = useState(todayDateFromTimezone);
  let navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [editData, setData] = useState({});
  const [minDate, setMinDate] = useState(todayDateFromTimezone);
  const [maxDate, setMaxDate] = useState(new Date(todayDateFromTimezone.getDate() + 30));
  const [datesToAddContentTo, setDatesToAddContentTo] = useState([]);
  const [selected, setSelected] = useState(DateSlashToDashReformat(todayDateFromTimezone));
  const [calendarMonths, setCalendarMonths] = useState([]);
  const [calendarYears, setCalendarYears] = useState([]);
  const [uniqueMonthsAndYear, setUniqueMonthsAndYear] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setStatus(!status);
    const val = DateSlashToDashReformat(date)
    setSelected(val);
  };

  useEffect(() => {

    if (datesToAddContentTo !== 0) getUniqueMonths(datesToAddContentTo)

  }, [datesToAddContentTo])

  const getYearAndMonth = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return { year, month: monthNames[month] };
  }

  const removeDuplicates = (array) => {
    const uniqueMonthsAndYears = {};
    const result = [];

    for (const item of array) {
      const key = `${item.monthName}-${item.year}`;
      if (!uniqueMonthsAndYears[key]) {
        uniqueMonthsAndYears[key] = true;
        result.push(item);
      }
    }

    return result;
  };

  const getUniqueMonths = (data) => {
    const months = [];
    const seenMonths = new Set();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const seenYears = new Set();
    let monthsAndYear = [];

    for (const timestamp of data) {
      const date = new Date(timestamp);
      const month = date.getMonth();
      const monthName = monthNames[month];
      const year = date.getFullYear();

      monthsAndYear = [...monthsAndYear, { monthName, year }];

      if (!seenMonths.has(monthName)) {
        months.push(monthName);
        seenMonths.add(monthName);
      }

      if (!seenYears.has(year)) {
        seenYears.add(year);
      }
    }

    setUniqueMonthsAndYear(removeDuplicates(monthsAndYear))
    setCalendarMonths(months)
    setCalendarYears(Array.from(seenYears))

  }

  const handleClickOpen = () => {
    setOpen(true);
    getAvailableSchedules();
  };

  const tileContent = ({ date, view }) => {
    const isSameDay = (first, second) =>
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();
    // Add class to tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      if (datesToAddContentTo.find((dDate) => isSameDay(dDate, date))) {
        return '*';
      }
    }
  };

  const doesMonthYearExist = (data, month, year) => {

    for (let i = 0; i < data.length; i++) {
      if (data[i].monthName === month && data[i].year === year) {
        return true;
      }
    }
    return false;
  }

  const tileClassName = ({ date, view }) => {
    const isSameDay = (first, second) =>
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();
    // Add class to tiles in month view only
    const { year, month } = getYearAndMonth(date);

    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      if (datesToAddContentTo.find((dDate) => isSameDay(dDate, date))) {
        return 'react-calendar-seleceted-cell';
      }
    }
    if (view === 'year') {
      return doesMonthYearExist(uniqueMonthsAndYear, month, year) ? 'react-calendar-seleceted-month' : 'react-calendar-seleceted-month-sorted';
    }
    if (view === 'decade') {
      return calendarYears.includes(year) ? 'react-calendar-seleceted-month' : 'react-calendar-seleceted-month-sorted';
    }
    if (view === 'century') {
      return 'react-calendar-seleceted-month';
    }
  };


  const tileDisabled = ({ date, view }) => {
    const isSameDay = (first, second) =>
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();
    // Add class to tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      if (!datesToAddContentTo.find((dDate) => isSameDay(dDate, date))) {
        return date;
      }
    }
  };


  const getAvailableSchedules = async () => {
    try {
      const { data } = await axios.get('/schedules/all/availableSchedules');
      if (data.dateRange.length > 0) {
        let arr = [];
        arr = data.dateRange.map((date) => { return new Date(new Date(date).toUTCString().slice(0, -4)) });
        setDatesToAddContentTo(arr);
      }else{
        setDatesToAddContentTo([]);
      }
      if (data.start !== '' && data.end !== '') {
        const getStartDate = new Date(new Date(data.start).toUTCString().slice(0, -4));
        const getEndDate = new Date(new Date(data.end).toUTCString().slice(0, -4));

        setMinDate(getStartDate);
        setMaxDate(getEndDate);
      }else{
        setMinDate(todayDateFromTimezone);
        setMaxDate(new Date(todayDateFromTimezone.getDate() + 30));
      }
      setStatus(!status);
    } catch (err) {
      setAlert({
        showAlert: true,
        severity: 'success',
        message: 'Dates Loading failed',
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'success',
          message: '',
        });
        setStatus(!status);
      }, 3000);
    }
  };

  useEffect(() => {
    getAvailableSchedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const deleteRecord = (id, type) => { deleteSchedule(id, type); };

  const deleteSchedule = async (id, type) => {

    try {
      if (type === 'Pickup') {
        await axios.delete(`/schedules/${id}`);
      } else {
        await axios.delete(`/delivery/${id}`);
      }
      setStatus(!status);
      getAvailableSchedules();
      setAlert({
        showAlert: true,
        severity: 'success',
        message: 'Schedule Deleted Successfully',
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'success',
          message: '',
        });
      }, 1000);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: 'success',
        message: error.response.data ?? 'Error in Deleting Schedule',
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'success',
          message: '',
        });
        setStatus(!status);
      }, 3000);
    }
  };

  // Delete All Schedules
  const deleteAllSchedules = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`/schedules/delete/all`);
      setStatus(!status);
      await getAvailableSchedules();
      setAlert({
        showAlert: true,
        severity: 'success',
        message: 'All Schedules Deleted Successfully',
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'success',
          message: '',
        });
      }, 1000);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: 'success',
        message: error.response.data ?? 'Error in Deleting Schedules',
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: 'success',
          message: '',
        });
        setStatus(!status);
      }, 3000);
    }finally{
      setIsLoading(false);
    }
  };

  const columns = [
    { field: 'zoneName', headerName: 'ZONE', minWidth: 100, },
    {
      field: 'timeSlotsNew', headerName: 'TIME SLOTS', minWidth: 200, flex: 0.1,
      renderCell: (params) => {
        return (
          <>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', }} >
              {params.value.map((item, index) => {
                return (<div style={{ marginTop: '5px', marginBottom: '5px' }} key={index} > {item.timeSlots} </div>);
              })}
            </div>
          </>
        );
      },
    },
    {
      field: 'limit', headerName: 'LIMIT', minWidth: 100, flex: 0.1,
      renderCell: (params) => {
        return (
          <>
            <div>
              {params.value.map((item, index) => {
                return (<div style={{ marginTop: '5px', marginBottom: '5px' }} key={index} > {item.limit} </div>);
              })}
            </div>
          </>
        );
      },
    },
    { field: 'unattendedLimit', headerName: 'UNATTENDED LIMIT', minWidth: 210, flex: 0.2, },
    {
      field: 'action', headerName: 'ACTION', minWidth: 130, flex: 0.2, disableExport: true,
      renderCell: (cellValues) => {
        const onEdit = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setData(cellValues.row);
          handleClickOpen();
          setStatus(!status);
        };
        return (
          <>
            <IconButton onClick={onEdit} aria-label='edit'>
              <EditIcon status={status} />
            </IconButton>
            <DeleteRecord title={'Schedule Deletion?'} message={'This Schedule will be permanently deleted from this location'}
              deleteRecord={() => deleteRecord(cellValues.row.id, cellValues.row.type)} alert={alert} setAlert={setAlert} />
          </>
        );
      },
    },
    { field: 'type', headerName: 'TYPE', flex: 0.2, },
    {
      field: 'isPublished', headerName: 'PUBLISH STATUS', flex: 0.2,
      renderCell: (cellValues) => {
        return <div style={{ color: cellValues?.row?.isPublished ? "green" : "red" }}>{cellValues?.row?.isPublished ? "Published" : "Not Published"}</div>;
      }
    },
  ];
  return (
    <>
      <Card>
        <CardContent>
          <Grid container>
            <h1>Schedules</h1>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={5} mb={5}
              direction='row' columnSpacing={10} alignItems="center"
              justifyContent={{ lg: "flex-start", md: "center", sm: "center", xs: "center", }} >
              <Grid item lg={7} md={12} sm={12} xs={12}>
                <Calendar
                  className='react-calendar'
                  showNeighboringMonth={false}
                  onClickDay={handleDateChange}
                  // value={selectedDate}
                  calendarType={'US'}
                  minDate={minDate}
                  maxDate={maxDate}
                  // tileContent={tileContent} 
                  tileClassName={tileClassName}
                  tileDisabled={tileDisabled}
                />
              </Grid>
              <Grid container item lg={3} md={12} sm={12} xs={12} rowSpacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={3} >
                  <CreateSchedule setStatus={setStatus} status={status} selected={selected} refresh={() => getAvailableSchedules()} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} >
                  <Button data-testid="timeSlot-create-btn" variant='contained' color='primary' fullWidth={true} onClick={() => navigate('/pick-up-time-slots')} >
                    <h3>Time Slots </h3>
                  </Button>
                </Grid>
                <DeleteSchedules title={'Delete schedules'} message={'All schedules that were created will be permanently deleted from this location. This action cannot be undone. Do you wish to continue?'}
                  deleteAllSchedules={() => deleteAllSchedules()} alert={alert} setAlert={setAlert} isLoading={isLoading} />
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12}>
              <DataGridTable fetchDataUrl={`/schedules/allSchedules/${selected}`} columns={columns} status={status} title={`Schedules for ${selected}`} />
              {alert.showAlert && (<Grid item md={12} m={2}> <AlertInlineMain setAlert={setAlert} alert={alert} /> </Grid>)}
            </Grid>
            <UpdateSchedule setOpen={setOpen} open={open} editData={editData} setStatus={setStatus} status={status} />
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
