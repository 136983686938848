import addZeroToNumbers from "./AddZeroToNumbers"

export default function YmDreformat( date ) {
  
  if (date.includes("/")) {
    
    const reFormatDate = date.split("/")
    const reArrageDate = `${reFormatDate[2]}-${addZeroToNumbers(reFormatDate[0])}-${addZeroToNumbers(reFormatDate[1])}`
    return reArrageDate

  } else { 
    return date
  }

}
