import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ element, path, role, roleTwo, roleThree, roleFour}) {
  const token = sessionStorage.getItem('accessToken');
  const userRole = sessionStorage.getItem('role');
  return token ? (userRole===role || userRole===roleTwo || userRole===roleThree ||userRole===roleFour) && element : <Navigate to='/permission-denied' />;
}

export default ProtectedRoute;
