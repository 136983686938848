import React, { useState, useEffect, useCallback } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Map from './Map';
import OrderView from './Orders';
import Badge from '@mui/material/Badge';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import TimeLine from 'components/TimeLine/TimeLiine';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  //please edit these later
  timelineInsideWidth: {
    marginTop: "0.4rem",
    width: "94rem",
    [theme.breakpoints.down(1844)]: {
      width: "80vw"
    },
    [theme.breakpoints.down(1757)]: {
      width: "75vw"
    },
    [theme.breakpoints.down(1616)]: {
      width: "75vw"
    },
    [theme.breakpoints.down(1497)]: {
      width: "73vw"
    },
    [theme.breakpoints.down(1394)]: {
      width: "72vw"
    },
    [theme.breakpoints.down(1280)]: {
      width: "99vw"
    },
    [theme.breakpoints.down(1200)]: {
      width: "95vw"
    },
    [theme.breakpoints.down(730)]: {
      width: "93vw"
    },
    [theme.breakpoints.down(523)]: {
      width: "89vw"
    },
  },

}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function IconLabelTabs({ date, onDeleteSuccess }) {
  const axios = useAxiosPrivate()
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [dateVal, setDateVal] = useState('');
  const [updateOrders, setUpdateOrders] = useState(null);
  const [pendingOrders, setOrders] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setReqNumber = async () => {
    const newDate = new Date(date);
    // convert date to YYYY-MM-DD
    const dateString = newDate?.toISOString().split('T')[0];
    setDateVal(dateString);
    const { data } = await axios.get(`/orders/orders-date/${dateString}`);
    // const filteredData = data.filter((e) => e.orderStatus !== 'completed');
    setOrders(data?.length);
  };

  useEffect(() => {
    setReqNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, updateOrders]);

  return (
    <Box sx={{ maxWidth: '70vw' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'>
          <Tab
            sx={{
              fontSize: "0.8rem",
            }}
            iconPosition="start"
            icon={<AltRouteIcon fontSize='small' />}
            label='Routing'
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              fontSize: "0.8rem",
            }}
            iconPosition="start"
            icon={<FeaturedPlayListIcon fontSize='small' />}
            label={
              <Badge badgeContent={pendingOrders} color='secondary'>
                Order List
              </Badge>
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      <Box
        className={classes.timelineInsideWidth}
        sx={{
          overflowX: {
            lg: "scroll",
            md: "scroll",
            sm: "scroll",
            xs: "scroll",
          }
        }}>
        <TimeLine value={date} />
      </Box>

      {value === 0 ? (
        <TabPanel value={value} index={0}>
          <Map />
        </TabPanel>
      ) : value === 1 ? (
        <TabPanel value={value} index={1}>
          <OrderView setUpdateOrders={setUpdateOrders} dateVal={dateVal} setReqNumber={setReqNumber} onDeleteSuccess={onDeleteSuccess} />
        </TabPanel>
      ) : (
        ''
      )}

    </Box>
  );
}
