import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Grid, FormControl, InputLabel, MenuItem, Select, Button, Alert } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Form, Formik, Field } from "formik";
import { TextField as TF } from "formik-material-ui";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import WDFDialog from "components/franchiseeManagement/WDFDialog";
import SearchLocation from "./SearchLocation";
import ChipInput from "material-ui-chip-input";
import LoadingButton from "@mui/lab/LoadingButton";
import useFranchiseeMgtFunctions from "./commonFunctions/useFranchiseeMgtFunctions";

export default function FranchiseeMgt() {

  const { loadingScreen, backendCall, disabledButtonOnZip, alert, royaltyAlert, zipAlert, csvAlert, invalidZipAlert,
    invalidZipCodes, open, number, handleClickOpen, franchiseeId, royaltySchemes, useOnSubmit, validationSchema, handleChangeForCsvFile,
    importCSV, handleFieldValues, handleAddChip, handleDeleteChip, componentType, navigate, orderCount, setInitialValues, setInvalidZipsAlert,
    setCsvAlert, setZipAlert, initialValues, setAlert, setOpen, handleTypeOptionChange, selectedTypeOption, validationSchemaCorporate,validationSchemaUpdate, disabledButtonOnZipCoop, validationSchemaCorporateUpdate } = useFranchiseeMgtFunctions();

  return (
    <>
      {loadingScreen && componentType === "update" ? (
        <Grid container>
          <Grid container item alignItems="center" justifyContent="center"
            lg={12} md={12} sm={12} xs={12} sx={{ marginTop: "40vh" }} >
            <Grid item alignItems="center" justifyContent="center" >
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      ) : (<Card>
        <CardContent>
          <Grid style={{ marginRight: "30rem" }} item>
            <IconButton
              onClick={() => navigate("/view-franchisee")}
              color="primary"
              aria-label="add to shopping cart" >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid container justifyContent={"center"} mb={5}>
            <Grid item>
              <Typography gutterBottom variant="h5" component="div">
                {componentType === "create"
                  ? "Create Location"
                  : "Update the Location"}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} xl={6}>
            {componentType === "create" &&
              <Select
                onChange={handleTypeOptionChange}
                value={selectedTypeOption}
                style={{ width: "100%", marginBottom: '3%' }}
                data-testid="location_type" 
              >
                <MenuItem value="franchisee" data-testid="franchisee_dropdown_val">Franchisee</MenuItem>
                <MenuItem value="corporate" data-testid="corporate_dropdown_val">Corporate</MenuItem>
              </Select>
            }
          </Grid>
          {selectedTypeOption === "franchisee" ?
             <Formik initialValues={initialValues} onSubmit={useOnSubmit} validationSchema={componentType === "create" ? validationSchema : validationSchemaUpdate} enableReinitialize >
              {({ values, isValid, handleChange, setFieldValue, }) => {
                return (
                  <Form noValidate autoComplete="off" encType="multipart/form-data">
                    <Grid container item spacing={2} xs={12} md={12} xl={12} justifyContent={"flex-start"} >
                      {componentType === "update" && (
                        <>
                          <Grid item xs={12} md={6}>
                            <Grid item xs={12} md={6}>
                              <Field name="fID" label="Franchisee ID" variant="outlined" component={TF} fullWidth required disabled InputLabelProps={{ shrink: true }} ></Field>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={6} >
                            <Grid item xs={12} md={6}>
                              <Field name="role" label="Role" variant="outlined" component={TF} fullWidth disabled InputLabelProps={{ shrink: true }} ></Field>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12} md={6} xl={6}>
                        <Field component={TF} name="firstName" label="Franchisee Business Name" variant="outlined" onChange={componentType === "create" ? handleFieldValues : handleChange} fullWidth required data-testid="franchisee_name" />
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}>
                        <Field name="ownerName" component={TF} label="Franchisee owner name" variant="outlined" fullWidth onChange={componentType === "create" ? handleFieldValues : handleChange} fullWidth required data-testid="franchisee_owner_name"/>
                      </Grid>
                      <Grid item xs={12} md={12} xl={12}>
                        <Field disabled={orderCount <= 0 ? false : true} name="email" component={TF} label="Email Address" variant="outlined" fullWidth required
                          onChange={componentType === "create" ? handleFieldValues : handleChange} data-testid="franchisee_email"></Field>
                      </Grid>
                      {componentType === "create" && (
                        <Grid item xs={12} md={12} xl={12}>
                          <Field name="password" component={TF} label="Create Password" variant="outlined" fullWidth
                            onChange={componentType === "create" ? handleFieldValues : handleChange} data-testid="franchisee_password"></Field>
                        </Grid>
                      )}

                      <Grid item xs={12} md={12}>
                        <Typography style={{ marginTop: "1rem", fontWeight: "bold", }} >
                          Business Location </Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Grid item xs={6} md={6}>
                          <FormControl fullWidth>
                            <SearchLocation orderCount={orderCount} setFieldValue={setFieldValue} setInitialValues={setInitialValues} values={values}/>
                          </FormControl>
                        </Grid>{" "}
                      </Grid>
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        <Field component={TF} name="address" label="Address" variant="outlined" fullWidth required
                          onChange={componentType === "create" ? handleFieldValues : handleChange} data-testid="franchisee_address"></Field>
                      </Grid>
                      <Grid item xs={8} md={8}>
                        <Field label="Apt/Suite no" name="suitNo" component={TF} variant="outlined" fullWidth
                          onChange={componentType === "create" ? handleFieldValues : handleChange} data-testid="franchisee_apt"
                        ></Field>
                      </Grid>

                      <Grid item xs={12} md={6} xl={6} mt={3} mb={2}>
                        <ChipInput name="serviceRegions" data-testid="franchisee_zipcodes" label="Approved Zip Codes for Franchisee / Zip Codes" value={initialValues.serviceRegions}
                          onAdd={(chip) => handleAddChip(chip)} onDelete={(chip, index) => handleDeleteChip(chip, index)} variant="outlined" required fullWidth/>
                      </Grid>
                      {zipAlert.showAlert && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Alert severity={zipAlert.severity} onClose={() => setZipAlert({ ...zipAlert, showAlert: false, })} data-testid="franchisee_zipcode_alert"> {zipAlert.message} </Alert>
                        </Grid>
                      )}
                      {invalidZipAlert?.showAlert && invalidZipCodes?.length > 0 && (
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                          <Alert severity={invalidZipAlert.severity} onClose={() => setInvalidZipsAlert({ ...invalidZipAlert, showAlert: false, })} data-testid="franchisee_zipcode_invalid_alert"> {invalidZipAlert.message} </Alert>
                        </Grid>
                      )}
                      <Grid container item xs={12} md={6} mt={2} mb={2}>
                        <Grid item xs={12} md={12} mb={0}>
                          <Typography style={{ fontWeight: "bold", }} >
                            Upload a CSV file with a list of Zip codes in the first
                            column without any header </Typography>
                        </Grid>
                        <Grid container item xs={12} md={12}>
                          <Grid item xs={12} md={4} mt={2}>
                            <Field id="name" name="file" type="file" label="CSV Uploads here" fullWidth onChange={handleChangeForCsvFile} />
                          </Grid>
                          <Grid item xs={12} md={8} mt={1}>
                            <Button onClick={() => importCSV()} variant="contained" size="small" >
                              {" "}
                              Upload now!
                            </Button>
                          </Grid>
                          <Grid item xs={12} md={8} mt={1}>
                            <a href="https://storage.googleapis.com/mediaservice-f6142.appspot.com/1706504003301.csv" >
                              Download Sample CSV
                            </a>
                          </Grid>
                        </Grid>
                      </Grid>

                      {csvAlert.showAlert && (
                        <Grid item md={12} m={2}>
                          <Alert severity={csvAlert.severity} onClose={() => setCsvAlert({ ...csvAlert, showAlert: false, })} >
                            {csvAlert.message} </Alert>
                        </Grid>
                      )}
                      <Grid container item xs={12} md={12}>
                        <Typography
                          style={{ marginTop: "1rem", marginBottom: "1rem", fontWeight: "bold", }} > Other Information </Typography>
                        <Grid item xs={12} md={12} xl={12}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label"> Royalty Program </InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select" name="royaltyScheme" value={values.royaltyScheme} required label="Royalty Program"
                              onChange={componentType === "create" ? handleFieldValues : handleChange}>
                              {royaltySchemes.filter(items => items?.status === true)?.map((option) => (
                                <MenuItem name="royaltyScheme" required key={option.id} value={option.id} style={{ display: "flex", justifyContent: "space-between", }}>
                                  <Typography>{option.scheme_Name}</Typography>
                                  <Typography> {option.royalty_Percentage}% </Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        {royaltyAlert.showAlert && (
                          <Grid item lg={12} md={12} sm={12} xs={12} mt={2} mb={2}>
                            <Alert severity={royaltyAlert.severity} onClose={() => setZipAlert({ ...royaltyAlert, showAlert: false, })} > {royaltyAlert.message} </Alert>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Field name="payment" component={TF} label="Payment type and reference" variant="outlined" fullWidth
                          onChange={componentType === "create" ? handleFieldValues : handleChange} data-testid="franchisee_payment"></Field>
                      </Grid>
                      {componentType === "update" && (
                        //edit franchisee
                        <>
                          <Grid item xs={12} md={12}>
                            <Typography style={{ marginTop: "1rem", fontWeight: "bold", }} > Pricing </Typography>
                          </Grid>
                          <Grid item lg={4} md={4} sm={12} xs={12} >
                            <Button
                              sx={{ width: { lg: "15rem", md: "15rem", sm: "100%", xs: "100%", }, backgroundColor: "#f2a127" }}
                              variant="contained"
                              onClick={() => handleClickOpen(1)} > Wash/Dry/Fold </Button>
                          </Grid>
                          <Grid item lg={4} md={4} sm={12} xs={12} >
                            <Button
                              sx={{ width: { lg: "15rem", md: "15rem", sm: "100%", xs: "100%", }, backgroundColor: "#f2a127" }}
                              variant="contained"
                              onClick={() => {
                                sessionStorage.setItem('selectedIduserName', initialValues?.firstName);
                                sessionStorage.setItem('ownerNameforFranchisor', initialValues?.ownerName);
                                navigate("/dry-clean-service");
                              }} > Dry Cleaning </Button>
                          </Grid>
                          <Grid item lg={4} md={4} sm={12} xs={12} >
                            <Button
                              sx={{ width: { lg: "15rem", md: "15rem", sm: "100%", xs: "100%", }, backgroundColor: "#f2a127" }}
                              variant="contained"
                              onClick={() => {
                                sessionStorage.setItem('selectedIduserName', initialValues?.firstName);
                                sessionStorage.setItem('ownerNameforFranchisor', initialValues?.ownerName);
                                navigate("/tailoring-and-alterations")
                              }} > Tailoring and alteration </Button>
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12} md={12}>
                        <Field name="note" component={TF} label="Misc notes" variant="outlined" fullWidth multiline
                          onChange={componentType === "create" ? handleFieldValues : handleChange} data-testid="franchisee_misc"></Field>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: "1rem" }}>
                      {(disabledButtonOnZip && initialValues?.serviceRegions?.length !== 0) &&
                        <LoadingButton loading={backendCall} style={{ width: "8rem" }} variant="contained" type="submit"
                          color={componentType === "create" ? "primary" : "secondary"}
                          disabled={componentType === "create" ? !isValid : !isValid || backendCall} data-testid="franchisee_submit_button">
                          {componentType === "create" ? "save" : "update"}
                        </LoadingButton>}
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
            :
            <Formik initialValues={initialValues} onSubmit={useOnSubmit} validationSchema={componentType === "create" ? validationSchemaCorporate : validationSchemaCorporateUpdate} enableReinitialize >
              {({ values, isValid, handleChange, setFieldValue, }) => {
                return (
                  <Form noValidate autoComplete="off" encType="multipart/form-data">
                    <Grid container item spacing={2} xs={12} md={12} xl={12} justifyContent={"flex-start"} >
                      {componentType === "update" && (
                        <>
                          <Grid item xs={6} md={6}>
                            <Grid item xs={12} md={6}>
                              <Field name="fID" label="Corporate ID" variant="outlined" component={TF} fullWidth required disabled InputLabelProps={{ shrink: true }} ></Field>
                            </Grid>
                          </Grid>
                          <Grid item xs={6} md={6} >
                            <Grid item xs={12} md={6}>
                              <Field name="role" label="Role" variant="outlined" component={TF} fullWidth disabled InputLabelProps={{ shrink: true }} ></Field>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12} md={6} xl={6}>
                        <Field data-testid="corporate_name" component={TF} name="corporateFirstName" label="Corporate Name" variant="outlined" onChange={componentType === "create" ? handleFieldValues : handleChange} fullWidth required />
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}>
                        <Field data-testid="corporate_owner_name" name="corporateOwnerName" component={TF} label="Corporate owner name" variant="outlined" fullWidth onChange={componentType === "create" ? handleFieldValues : handleChange} fullWidth required/>
                      </Grid>
                      <Grid item xs={12} md={12} xl={12}>
                        <Field data-testid="corporate_email" disabled={orderCount <= 0 ? false : true} name="email" component={TF} label="Email Address" variant="outlined" fullWidth required
                          onChange={componentType === "create" ? handleFieldValues : handleChange} ></Field>
                      </Grid>
                      {componentType === "create" && (
                        <Grid item xs={12} md={12} xl={12}>
                          <Field  data-testid="corporate_password" name="password" component={TF} label="Create Password" variant="outlined" fullWidth
                            onChange={componentType === "create" ? handleFieldValues : handleChange} ></Field>
                        </Grid>
                      )}

                      <Grid item xs={12} md={12}>
                        <Typography style={{ marginTop: "1rem", fontWeight: "bold", }} >
                          Business Location </Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Grid item xs={6} md={6}>
                          <FormControl fullWidth>
                            <SearchLocation orderCount={orderCount} setFieldValue={setFieldValue} setInitialValues={setInitialValues} values={values} />
                          </FormControl>
                        </Grid>{" "}
                      </Grid>
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        <Field data-testid="corporate_address" component={TF} name="address" label="Address" variant="outlined" fullWidth required
                          onChange={componentType === "create" ? handleFieldValues : handleChange} ></Field>
                      </Grid>
                      <Grid item xs={8} md={8}>
                        <Field data-testid="corporate_apt" label="Apt/Suite no" name="suitNo" component={TF} variant="outlined" fullWidth
                          onChange={componentType === "create" ? handleFieldValues : handleChange}
                        ></Field>
                      </Grid>

                      <Grid item xs={12} md={6} xl={6} mt={3} mb={2}>
                        <ChipInput data-testid="corporate_zip" name="serviceRegions" label="Approved Zip Codes for Corporate / Zip Codes" value={initialValues.serviceRegions}
                          onAdd={(chip) => handleAddChip(chip)} onDelete={(chip, index) => handleDeleteChip(chip, index)} variant="outlined" required fullWidth />
                      </Grid>
                      {zipAlert.showAlert && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Alert severity={zipAlert.severity} onClose={() => setZipAlert({ ...zipAlert, showAlert: false, })} > {zipAlert.message} </Alert>
                        </Grid>
                      )}
                      {invalidZipAlert?.showAlert && invalidZipCodes?.length > 0 && (
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                          <Alert severity={invalidZipAlert.severity} onClose={() => setInvalidZipsAlert({ ...invalidZipAlert, showAlert: false, })} > {invalidZipAlert.message} </Alert>
                        </Grid>
                      )}
                      <Grid container item xs={12} md={6} mt={2} mb={2}>
                        <Grid item xs={12} md={12} mb={0}>
                          <Typography style={{ fontWeight: "bold", }} >
                            Upload a CSV file with a list of Zip codes in the first
                            column without any header </Typography>
                        </Grid>
                        <Grid container item xs={12} md={12}>
                          <Grid item xs={12} md={4} mt={2}>
                            <Field id="name" name="file" type="file" label="CSV Uploads here" fullWidth onChange={handleChangeForCsvFile} />
                          </Grid>
                          <Grid item xs={12} md={8} mt={1}>
                            <Button onClick={() => importCSV()} variant="contained" size="small" >
                              {" "}
                              Upload now!
                            </Button>
                          </Grid>
                              <Grid item xs={12} md={8} mt={1}>
                            <a href="https://storage.googleapis.com/mediaservice-f6142.appspot.com/1706504003301.csv" >
                              Download Sample CSV
                            </a>
                          </Grid>
                        </Grid>
                      </Grid>

                      {csvAlert.showAlert && (
                        <Grid item md={12} m={2}>
                          <Alert severity={csvAlert.severity} onClose={() => setCsvAlert({ ...csvAlert, showAlert: false, })} >
                            {csvAlert.message} </Alert>
                        </Grid>
                      )}
                      <Grid container item xs={12} md={12}>
                        <Typography
                          style={{ marginTop: "1rem", fontWeight: "bold", }} > Other Information </Typography>

                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Field  data-testid="corporate_payment" name="payment" component={TF} label="Payment type and reference" variant="outlined" fullWidth
                          onChange={componentType === "create" ? handleFieldValues : handleChange} ></Field>
                      </Grid>
                      {componentType === "update" && (
                        //edit franchisee
                        <>
                          <Grid item xs={12} md={12}>
                            <Typography style={{ marginTop: "1rem", fontWeight: "bold", }} > Pricing </Typography>
                          </Grid>
                          <Grid item lg={4} md={4} sm={12} xs={12} >
                            <Button
                              sx={{ width: { lg: "15rem", md: "15rem", sm: "100%", xs: "100%", }, backgroundColor: "#f2a127" }}
                              variant="contained"
                              onClick={() => handleClickOpen(1)} > Wash/Dry/Fold </Button>
                          </Grid>
                          <Grid item lg={4} md={4} sm={12} xs={12} >
                            <Button
                              sx={{ width: { lg: "15rem", md: "15rem", sm: "100%", xs: "100%", }, backgroundColor: "#f2a127" }}
                              variant="contained"
                              onClick={() => {
                                sessionStorage.setItem('selectedIduserName', initialValues?.corporateFirstName);
                                sessionStorage.setItem('ownerNameforFranchisor', initialValues?.corporateOwnerName);
                                navigate("/dry-clean-service");
                              }} > Dry Cleaning </Button>
                          </Grid>
                          <Grid item lg={4} md={4} sm={12} xs={12} >
                            <Button
                              sx={{ width: { lg: "15rem", md: "15rem", sm: "100%", xs: "100%", }, backgroundColor: "#f2a127" }}
                              variant="contained"
                              onClick={() => {
                                sessionStorage.setItem('selectedIduserName', initialValues?.corporateFirstName);
                                sessionStorage.setItem('ownerNameforFranchisor', initialValues?.corporateOwnerName);
                                navigate("/tailoring-and-alterations")
                              }} > Tailoring and alteration </Button>
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12} md={12}>
                        <Field data-testid="corporate_note" name="note" component={TF} label="Misc notes" variant="outlined" fullWidth multiline
                          onChange={componentType === "create" ? handleFieldValues : handleChange} ></Field>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: "1rem" }}>
                      {(disabledButtonOnZipCoop && initialValues?.serviceRegions?.length !== 0) &&
                        <LoadingButton loading={backendCall} style={{ width: "8rem" }} variant="contained" type="submit"
                        data-testid="corporate_submit_button"
                          color={componentType === "create" ? "primary" : "secondary"}
                          disabled={componentType === "create" ? !isValid : !isValid || backendCall}>
                          {componentType === "create" ? "save" : "update"}
                        </LoadingButton>}
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          }

          {alert.showAlert && (
            <Grid item md={12} mt={2}>
              <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, showAlert: false, })} > {alert.message} </Alert>
            </Grid>)}
        </CardContent>
        <WDFDialog franchiseeId={franchiseeId} ownerName={initialValues?.ownerName || initialValues?.corporateOwnerName} setOpen={setOpen} open={open} number={number} selectedTypeOption={selectedTypeOption} />
      </Card>)}
    </>
  );
}