import React, { useState, useEffect } from "react";
import DataGrid from "../layouts/DataGrid";
import moment from "moment";
import { Grid, Button } from "@mui/material";
import AlertInlineMain from "components/common/AlertInlineMain";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

export default function FranchiseeViewRequest({ newStatus }) {
  const axios = useAxiosPrivate()
  const [newData, setNewData] = useState([]);
  let navigate = useNavigate();
  
  const franchiseeTimeZone = sessionStorage.getItem("franchiseeTimeZone")

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const loadData = async () => {
    try {
      const { data } = await axios.get(`/request/franchisee/${newStatus}`);

      setNewData(data);
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Authorized Access!",
        });
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Something went wrong!",
        });
      }
    }
  };

  useEffect(() => {
    if (newStatus) {
      loadData();
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newStatus]);

  const columns = [
    { field: "requestId", headerName: "Request ID", flex: 1, minWidth: 150 },
    { field: "subject", headerName: "Subject", flex: 1, minWidth: 150 },

    {
      field: "createdAt",
      headerName: "Requested Date",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${moment.tz(params.row.createdAt, `${franchiseeTimeZone}`).format(
          "MMMM Do YYYY, h:mm:ss a"
        )}`;
      },
      
    },
  ];

  return (
    <div>
      <Grid container spacing={2}>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent={"flex-end"}

          marginTop={{
            lg:0,
            md:0,
            sm:5,
            xs:5,
          }}
        >
          <Button
            sx={{
              width: {
                lg:"auto",
                md:"auto",
                sm:"auto",
                xs:"100%",
              }
            }}
            variant="contained"
            onClick={() => navigate("/lwl-request")}>
            + Create Request
          </Button>
        </Grid>

        <DataGrid
          autoHeight
          columns={columns}
          fetchDataUrl={`/request/franchisee/${newStatus}`}
          newData={newData}
          title="LWL Requests"
          fixHeight={true}
        />
        {alert.showAlert && (
          <Grid item md={12}>
            <AlertInlineMain setAlert={setAlert} alert={alert} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
