import React, { useState } from "react";
import DataGrid from "../layouts/DataGrid";
import { Button, Chip } from "@mui/material";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RequestDialog from "./RequestDialog";

export default function Test({ setReqNumber }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const columns = [
    { field: "requestId", headerName: "Request ID", flex: 1, minWidth: 150 },
    { field: "email", headerName: "Email", flex: 1, minWidth: 150 },
    { field: "subject", headerName: "Subject", flex: 1, minWidth: 150 },
    {
      field: "description",
      headerName: "Short Description",
      renderCell: (params) => {
        return `${params.row?.description.substring(0, 15)}...`;
      },
      flex: 1,
      minWidth: 180
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        if (params.row.status === "pending")
          return (
            <Chip
              label={`${params.row.status}`}
              color="primary"
              variant="outlined"
            />
          );

        if (params.row.status === "approved")
          return (
            <Chip
              label={`${params.row.status}`}
              color="success"
              variant="outlined"
            />
          );

        if (params.row.status === "denied")
          return (
            <Chip
              label={`${params.row.status}`}
              color="error"
              variant="outlined"
            />
          );
      },
    },
    {
      field: "fullName",
      headerName: "Franchisee Business Name",
      flex: 1,
      minWidth: 220,
      valueGetter: (params) => {
        console.log(params.row?.requester?.fullName)
        return `${
          params.row?.requester?.fullName
            ? params.row?.requester?.fullName.split("undefined")[0]
            : "-"
        }`;
      },
    },

    {
      field: "createdAt",
      headerName: "Requested Date",
      flex: 5,
      minWidth: 150,
      valueGetter: (params) => {
        return `${moment(new Date(params.row.createdAt).toLocaleDateString('en-US')).format(
          "MMMM Do YYYY"
        )}`;
      },
    },

    {
      field: "updatedAt",
      headerName: "Responded Date",
      flex: 4,
      minWidth: 150,
      valueGetter: (params) => {
        return params.row.status !== "pending" ? `${moment(new Date(params.row.respondedDate).toLocaleDateString('en-US')).format(
          "MMMM Do YYYY"
        )}`
        :  "-";
      },
    },

    {
      field: "action",
      headerName: "View",
      flex: 1,
      minWidth: 150,
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          setData(params.row);

          handleClickOpen();
        };

        return (
          <Button onClick={onClick}>
            <VisibilityIcon />
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <DataGrid
        autoHeight
        columns={columns}
        fetchDataUrl="/request"
        status={status}
        title="Request View"
        fixHeight={true}
      />
      <RequestDialog
        handleClickOpen={handleClickOpen}
        setOpen={setOpen}
        open={open}
        data={data}
        setStatus={setStatus}
        newStatus={status}
        setReqNumber={setReqNumber}
      />
    </div>
  );
}
