import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { styled } from "@material-ui/core/styles";
import LinearProgress from "@mui/material/LinearProgress";
import { Grid } from "@material-ui/core";
import AlertInlineMain from "components/common/AlertInlineMain";

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  display: "flex",
  textAlign: "center",
  height: "4.5rem",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#C4C4C4",
  border: `1px dashed grey`,
  "&:hover": { opacity: 0.72, cursor: "pointer" },
  [theme.breakpoints.up("xs")]: { textAlign: "left", flexDirection: "row" },
}));

export default function AcceptMaxFiles({ setImage, image, mobile }) {
  const onDrop = useCallback((acceptedFiles) => {
    setImage(acceptedFiles);
    // eslint-disable-next-line
  }, []);

  const { acceptedFiles, fileRejections, getRootProps,getInputProps } = useDropzone({
    maxFiles: 5,
    onDrop,
  });
  //alerting
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  // eslint-disable-next-line
  const acceptedFileItems = acceptedFiles.map((file) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    );
  });
  // eslint-disable-next-line
  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    );
  });

  return (
    <>
      <div {...getRootProps({ className: "dropzone" })}>
        {image.length !== 0 && (
          <LinearProgress style={{ marginTop: 5, marginBottom: 5 }} />
        )}
        {alert.showAlert && (
          <Grid item sm={12} md={12} xs={12}>
            <AlertInlineMain setAlert={setAlert} alert={alert} />
          </Grid>
        )}
        <DropZoneStyle style={{ marginBottom: 5 }}>
          <input {...getInputProps()} id="name" />
          <p>Drag 'n' drop some files here, or click to select files</p>
          <em>(5 files are the maximum number of files you can drop here at once)</em>
        </DropZoneStyle>
      </div>
    </>
  );
}

<AcceptMaxFiles />;
