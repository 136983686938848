import React, { Fragment, useEffect, useMemo, useState, useRef, useCallback } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { useStyles } from "utils/styles/styles";

import {
  TextField,
  Popper,
  Grid,
  Typography
} from "@material-ui/core";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function SearchLocation({
  setFieldValue,
  // setInitialValues,
  setZipCode,
  isValid,
  values,
}) {
  const classes = useStyles();
  const [value, setValue] = useState({
    description: "",
  });
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);
  const [placesId, setPlacesId] = useState("");

  const getPlacesPostCodeById = async (placeId) =>{
    new Promise((resolve, reject) => {
      if (!placeId) reject("placeId not provided");

      try {
        new window.google.maps.places.PlacesService(
          document.createElement("div")
        ).getDetails(
          {
            placeId,
            fields: ["address_components"],
          },
          (details) => {
            let postcode = null;
            details?.address_components?.forEach((entry) => {
              if (entry.types?.[0] === "postal_code") {
                postcode = entry.long_name;
              }
            });
            setZipCode(postcode);
            setFieldValue("zipCode", postcode);
            return resolve(postcode);
          }
        );
      } catch (e) {
        reject(e);
      }
    });}

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );


  const getCoordinates = (placeId) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId }, (results, status) => {
      if (status === "OK") {
        const location = results[0].geometry.location;
        setFieldValue("location.lat", location.lat());
        setFieldValue("location.lng", location.lng());
      }
    });
  };

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const PopperMy = function (props) {
    return <Popper {...props} placement="none" />;
  };

  const handleClick = useCallback(async () => {
    if (placesId) { 
        await getPlacesPostCodeById(placesId); 
    }
    //eslint-disable-next-line
  }, [placesId]);

  useEffect(() => { 
    if (placesId !== "" && placesId) { 
      handleClick();
    }
  }, [placesId, handleClick]);

  return (
    <Fragment>
      <Autocomplete
        style={{
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
        }}
        PopperComponent={PopperMy}
        size="small"
        id="google-map-demo"
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        defaultValue={{
          description: "Homagama",
        }}
        value={values?.address ?? value}
        onChange={(event, newValue) => {
          if (newValue) {
            getCoordinates(newValue.place_id);
            setOptions(newValue ? [newValue, ...options] : options);

            setValue(newValue);
            setPlacesId(newValue.place_id);
            setFieldValue("address", newValue.description);
          } else {
            setValue(null);
            setZipCode("");
            setFieldValue("zipCode", "");

            setFieldValue("coordinates.lat", null);
            setFieldValue("coordinates.lng", null);
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <div>
            <TextField
              {...params}
              variant="outlined"
              defaultValue={{
                description: "Homagama",
              }}
              placeholder="Street Address"
              label="Street Address"
              // InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
              }}
              fullWidth
            />

          </div>
        )}
        renderOption={(option, { selected }) => {
          let parts = [];
          if (option.structured_formatting) {
            const matches =
              option.structured_formatting.main_text_matched_substrings;
            parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [
                match.offset,
                match.offset + match.length,
              ])
            );
          }
          return (
            <Grid container alignItems="center" onClick={() => selected()}>
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting &&
                    option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TextField value={values?.apartmentNo} className={classes.fieldWidth} name="apartmentNo" label="Suite / Apt #"
          onChange={(e) => { setFieldValue("apartmentNo", e.target.value); }}
          variant="outlined" fullWidth margin="dense" ></TextField>
      </Grid>
      {/* <Grid style={{ marginTop: "1rem" }}>
        {(getZipCodeAlert && inputValue !== "" && !isValid) && <Typography color="error" >Please click "GET ZIPCODE" to continue.</Typography>}
        <Button 
          style={{
            fontSize:"0.79rem",
            fontWeight:"600"
          }}
          variant="contained" color="primary" onClick={handleClick}>
          Get zipcode
        </Button>
      </Grid> */}
    </Fragment>
  );
}
